export default class Declaration {
  constructor (data = {}) {
    this._id = data._id;
    this.declarationId = data.declarationId || null;
    this.createdBy = data.createdBy || null;
    this.reference = data.reference || null;
    this.version = data.version || null;
    this.year = data.year || null;
    this.month = data.month || null;
    this.file = data.file || null;
    this.invoices = data.invoices || [];
    this.createdAt = data.createdAt || null;
    this.updatedAt = data.updatedAt || null;
    this.events = data.events || [];
  }
}
