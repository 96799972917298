export default {
  SET_LIST_INVOICES (state, list) {
    state.listInvoices = list;
  },
  SET_LIST_INVOICES_PRINT (state, list) {
    state.listInvoicesPrint = list;
  },
  SET_INVOICE_LIST_PAGE (state, currentPage) {
    state.listInvoicesPaginate.currentPage = currentPage;
  },
  SET_INVOICE_LIST_TOTAL_PAGES (state, totalPages) {
    state.listInvoicesPaginate.totalPages = totalPages;
  },
  SET_LIST_INVOICE_STATISTICS (state, list) {
    state.listInvoicesStatistics = list.status;
  },
  SET_INVOICE_SHOW_FILTERS (state, value) {
    state.showInvoicesFilters = value;
  },
  ADD_INVOICE_LIST_FILTER (state, filters) {
    if (state.listInvoicesFilters.indexOf(filters) === -1) {
      state.listInvoicesFilters.push(filters);
    }
  },
  REMOVE_INVOICE_LIST_FILTER (state, filters) {
    if (state.listInvoicesFilters.indexOf(filters) !== -1) {
      state.listInvoicesFilters.splice(state.listInvoicesFilters.indexOf(filters), 1);
    }
  },
  SET_INVOICE_LIST_CUSTOM_FILTER (state, filter) {
    state.listInvoicesCustomFilters = { ...state.listInvoicesCustomFilters, ...filter };
  },
  SET_INVOICE_LIST_SEARCH (state, search) {
    state.listInvoicesSearch = search;
  },
  SET_INVOICE_LIST_SORT (state, header) {
    const newSort = {};

    if (state.listInvoicesSort.constructor === Object && header && header.name) {
      if (!(header.name in state.listInvoicesSort)) {
        newSort[header.name] = 'asc';
      } else {
        if (state.listInvoicesSort[header.name] === 'asc') {
          newSort[header.name] = 'desc';
        }
        if (state.listInvoicesSort[header.name] === 'desc') {
          newSort[header.name] = 'asc';
        }
      }

      state.listInvoicesSort = newSort;
    }
  },
};
