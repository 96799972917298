<template>
  <div class="sidebar-component">
    <div class="sidebar">
      <div class="slim-scroll-div">
        <div data-scrollbar="true" data-height="100%" data-init="true" style="overflow: hidden; width: auto; height: 100%;">
          <ct-user-menu ></ct-user-menu>

          <!-- end sidebar user -->
          <!-- begin sidebar nav -->
          <ct-menu title="Menu"
                   :items="menuItems"
                   @toggle-sidebar-minified="toggleSidebarMinified"
          ></ct-menu>
        </div>
        <div class="slim-scroll-bar"></div>
        <div class="slim-scroll-rail"></div>
      </div>
    </div>
    <div class="sidebar-bg"></div>
  </div>
</template>

<script>
import CtMenu from '@/components/Shared/Menu.vue';
import CtUserMenu from '@/components/Dashboard/UserMenu.vue';
import fileMenu from '../../menu.js';
export default {
  name: 'Sidebar',
  components: {
    CtMenu,
    CtUserMenu,
  },
  data () {
    return {
      menuItems: fileMenu.menuItems,
    };
  },
  props: {},
  methods: {
    toggleSidebarMinified () {
      this.$emit('toggle-sidebar-minified');
    },
  },
};
</script>

<style scoped lang="scss">
.sidebar-component {
  .slim-scroll-div {
    position: relative;
    overflow: hidden;
    width: auto;
    height: 100%;
  }
  .slim-scroll-bar {
    background: rgb(0, 0, 0);
    width: 7px;
    position: absolute;
    top: 87.4923px;
    opacity: 0.4;
    display: none;
    border-radius: 7px;
    z-index: 99;
    right: 1px;
    height: 728.508px;
  }
  .slim-scroll-rail {
    width: 7px;
    height: 100%;
    position: absolute;
    top: 0px;
    display: none;
    border-radius: 7px;
    background: rgb(51, 51, 51);
    opacity: 0.2;
    z-index: 90;
    right: 1px;
  }
}
@media print {
  .sidebar-component {
    display: none;
  }
}
</style>
