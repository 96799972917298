<template>
<div class="row">
  <div class="col-xs-12 col-sm-6">
    <div class="form-group" :class="{'is-invalid': errors.has('bank')}">
      <label for="bank">{{ $t('invoices.modalReversal.fiat.shipment.bank') }} *</label>
      <ct-select
        name="bank"
        id="bank"
        :key="'shipmentBank'"
        class="from-control"
        :class="{'is-invalid': errors.has('bank')}"
        :data-vv-as="$t('invoices.modalReversal.fiat.shipment.bank')"
        v-model="shipment.bank"
        :options="banks"
        :clearSearchOnSelect="true"
        :multiple="false"
        :filterable="true"
        v-validate="{ required: true }"
      />
      <div class="invalid-feedback">{{ errors.first('bank') }}</div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-6">
    <div class="form-group" :class="{'is-invalid': errors.has('agency')}">
      <label for="agency">{{ $t('invoices.modalReversal.fiat.shipment.agency') }} *</label>
      <input
        class="form-control"
        id="agency"
        :key="'shipmentAgency'"
        name="agency"
        :placeholder="$t('invoices.modalReversal.fiat.shipment.agency')"
        :class="{'is-invalid': errors.has('agency')}"
        :data-vv-as="$t('invoices.modalReversal.fiat.shipment.agency')"
        type="text"
        @input="applyAgencyMask"
        v-model="shipment.agency"
        v-validate="{ required: true }"
      >
      <div class="invalid-feedback">{{ errors.first('agency') }}</div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-6">
    <div class="form-group" :class="{'is-invalid': errors.has('account')}">
      <label for="account">{{ $t('invoices.modalReversal.fiat.shipment.account') }} *</label>
      <input
        class="form-control"
        id="account"
        name="account"
        :key="'shipmentAccount'"
        :placeholder="$t('invoices.modalReversal.fiat.shipment.account')"
        :class="{'is-invalid': errors.has('account')}"
        :data-vv-as="$t('invoices.modalReversal.fiat.shipment.account')"
        type="text"
        @input="applyAccountMask(false)"
        @change="changeAccount"
        v-model="shipment.account"
        v-validate="{ required: true }"
      >
      <div class="invalid-feedback">{{ errors.first('account') }}</div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-6">
    <div class="form-group" :class="{'is-invalid': errors.has('kindAccount')}">
      <label for="kindAccount">{{ $t('invoices.modalReversal.fiat.shipment.kind') }} *</label>
      <ct-select
        name="kindAccount"
        id="kindAccount"
        :key="'shipmentKind'"
        class="from-control"
        :class="{'is-invalid': errors.has('kindAccount')}"
        :data-vv-as="$t('invoices.modalReversal.fiat.shipment.kind')"
        v-model="shipment.kind"
        :options="accountTypes"
        :multiple="false"
        :filterable="true"
        v-validate="{ required: true }"
      />
      <div class="invalid-feedback">{{ errors.first('kindAccount') }}</div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-6" v-if="shipment.bank === '104'">
    <div class="form-group" :class="{'is-invalid': errors.has('operation')}">
      <label for="operation">{{ $t('invoices.modalReversal.fiat.shipment.operation') }} *</label>
      <input
        class="form-control"
        id="operation"
        name="operation"
        :key="'shipmentOperation'"
        :placeholder="$t('invoices.modalReversal.fiat.shipment.operation')"
        :class="{'is-invalid': errors.has('operation')}"
        :data-vv-as="$t('invoices.modalReversal.fiat.shipment.operation')"
        type="text"
        v-model="shipment.operation"
        v-validate="{ required: true }"
      >
      <div class="invalid-feedback">{{ errors.first('operation') }}</div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-6">
    <div class="form-group" :class="{'is-invalid': errors.has('shipmentAmount')}">
      <label for="shipmentAmount">{{ $t('invoices.modalReversal.fiat.amount') }} *</label>
      <ct-input-numeric
        :thousand-separator="$t(`currencies.BRL.thousands`)"
        :decimal-separator="$t(`currencies.BRL.decimal`)"
        :precision="2"
        autocomplete="off"
        class="form-control"
        :class="{'is-invalid': errors.has('shipmentAmount')}"
        v-validate="{ required: true, max_value: maxValueForAmount }"
        id="shipmentAmount"
        name="shipmentAmount"
        :key="'shipmentAmount'"
        :data-vv-as="$t('invoices.modalReversal.fiat.amount')"
        :placeholder="$t('invoices.modalReversal.fiat.amountPlaceholder')"
        v-model="shipment.amount"
      ></ct-input-numeric>
      <div class="invalid-feedback">{{ errors.first('shipmentAmount') }}</div>
    </div>
  </div>

  <div class="col-xs-12 col-sm-6">
    <div class="form-group" :class="{'is-invalid': errors.has('shipmentFee')}">
      <label for="shipmentFee">{{ $t('invoices.modalReversal.fiat.fee') }} *</label>
      <ct-input-numeric
        :thousand-separator="$t(`currencies.BRL.thousands`)"
        :decimal-separator="$t(`currencies.BRL.decimal`)"
        :precision="2"
        autocomplete="off"
        class="form-control"
        :class="{'is-invalid': errors.has('shipmentFee')}"
        v-validate="{ required: true, max_value: maxValueForFee }"
        id="shipmentFee"
        name="shipmentFee"
        :key="'shipmentFee'"
        :data-vv-as="$t('invoices.modalReversal.fiat.fee')"
        :placeholder="$t('invoices.modalReversal.fiat.amountPlaceholder')"
        v-model="shipment.fee"
        @change="changeModel"
      ></ct-input-numeric>
      <div class="invalid-feedback">{{ errors.first('shipmentFee') }}</div>
    </div>
  </div>
</div>
</template>

<script>
import { mask } from 'vue-the-mask';
import CtInputNumeric from '../../Shared/InputNumeric';
import { mapState, mapActions } from 'vuex';
import { CtSelect } from '@citartech/core-ui';

export default {
  name: 'ReversalFiatShipment',
  props: {
    value: {
      type: Object,
      required: true,
    },
    sumReversalValue: {
      type: Number,
    },
  },
  components: {
    CtInputNumeric,
    CtSelect,
  },
  directives: {
    mask,
  },
  data: () => ({
    shipment: {
      account: null,
      agency: null,
      bank: null,
      kind: null,
      operation: null,
      amount: 0,
      fee: 0,
    },
  }),
  watch: {
    value () {
      this.shipment = { ...this.shipment, ...this.value };
    },
  },
  mounted () {
    this.setVal();
  },
  async created () {
    await this.getBankList();
  },
  computed: {
    ...mapState({
      banks: state => state.selects.banks || {},
      accountTypes: state => state.selects.accountTypes || {},
    }),
    maxValueForFee () {
      return Number((this.sumReversalValue - this.shipment.amount).toFixed(2));
    },
    maxValueForAmount () {
      return Number((this.sumReversalValue - this.shipment.fee).toFixed(2));
    },
  },
  methods: {
    ...mapActions([
      'getBankList',
    ]),
    setVal () {
      this.shipment.validator = this.$validator;
      this.changeModel();
    },
    changeModel () {
      this.$emit('input', this.shipment);
    },
    changeAccount () {
      this.applyAccountMask(true);
    },
    applyAgencyMask () {
      const agency = this.shipment.agency.replace(/[^\d]/g, '');
      if (agency && agency.length > 4) {
        this.shipment.agency = [agency.slice(0, agency.length - 1), '-', agency.slice(agency.length - 1)].join('');
      }
    },
    applyAccountMask (changed = false) {
      const account = this.shipment.account.replace(/[^\d]/g, '');
      const position = account.length - 1;
      if (account.length > 11 || changed) {
        this.shipment.account = [account.slice(0, position), '-', account.slice(position)].join('');
      }
    },
  },
};
</script>
