<template>
  <div class="ct-login-2fa" >
    <div class="login-info-title">
      <p>{{ $t('login.twoFA.title')}}</p>
    </div>
    <div class="ct-form-with-icon">
      <i class="fas fa-key"></i>
      <input type="text" class="form-control form-control-lg" id="login-2fa" name="2fa" @change="codeTwoFA = $event.target.value" :placeholder=" $t('login.twoFA.formPlaceholder.accessCode') "/>
    </div>
    <div class="ct-submit-button">
      <button type="submit" id="" class="btn ct-submit-button btn-block btn-lg" @click=" sendTwoFA ">{{ $t('login.btnSendText') }}</button>
    </div>
    <ct-alert-message v-if="message" :message="message" />
    <div class="ct-server-msg"></div>
    <div class="m-t-20 m-b-40 p-b-40 text-inverse ct-login-before">
      <a @click="backToLogin">
        <i class="fas fa-arrow-left"></i> {{ $t('login.backStep') }}
      </a>
    </div>
  </div>
</template>

<script>
import CtAlertMessage from '@/components/Shared/AlertMessage.vue';
export default {
  name: 'Login',
  components: {
    CtAlertMessage,
  },
  props: {
    message: {
      type: String,
      required: false,
    },
  },
  data () {
    return {
      codeTwoFA: '',
    };
  },
  methods: {
    sendTwoFA () {
      this.$emit('code', this.codeTwoFA);
    },
    backToLogin () {
      this.$emit('return', true);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/themes/color-admin/_ct_variable.scss';
  .ct-login-2fa{
    .login-info-title{
      p{
        color: $body_text_color;
        font-size: 16px;
      }
    }
    .ct-login-before{
      a{
        cursor: pointer;
        color: $body_text_color;
        font-size: 14px;
      }
    }
  }
</style>
