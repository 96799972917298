import http from '@/services/HttpService';

export default {
  async getList ({ filters, search, limit, page, orderBy }) {
    try {
      let url = `/api/v1/transactions?page=${page}&limit=${limit}`;
      if (!page || limit === 0) {
        url = `/api/v1/transactions?limit=${limit}`;
      }

      if (filters && filters.length > 0) {
        const filtersQuery = JSON.stringify({
          field: 'status',
          operation: 'in',
          value: filters,
        });
        url += `&filters[]=${filtersQuery}&`;
      }
      const liked = [];
      if (search && search.value && search.value !== '') {
        const filterSearch = JSON.stringify({
          field: search.field,
          operation: liked.indexOf(search.field) >= 0 ? 'like' : '=',
          value: search.value,
        });
        url += `&filters[]=${filterSearch}`;
      }
      if (orderBy) {
        const key = Object.keys(orderBy)[0];
        url += `&orderBy=${key}&orderByDirection=${orderBy[key] || 'desc'}`;
      }

      const response = await http().get(url);
      return response.data || {
        data: [],
        meta: {},
      };
    } catch (error) {
      return error;
    }
  },

  async listStatistics () {
    const res = await http().get('/api/v1/transactions/statistics');
    return res.data;
  },

  async updateTransaction (id, params) {
    try {
      const response = await http().put(`/api/v1/transactions/${id}`, params);
      const data = response && response.data && response.data.data ? response.data.data : false;
      return {
        success: !!data,
        data,
      };
    } catch (e) {
      const message = e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message;
      return {
        success: false,
        message: message || e.message || 'ERROR_ON_RELEASE_TRANSACTION',
      };
    }
  },

  async releaseTransaction (id) {
    try {
      await http().get(`/api/v1/transactions/${id}/release`);
      return {
        success: true,
      };
    } catch (e) {
      const message = e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message;
      return {
        success: false,
        message: message || e.message || 'ERROR_ON_RELEASE_TRANSACTION',
      };
    }
  },

  async getOrdersByTransaction (id) {
    try {
      const response = await http().get(`/api/v1/transactions/${id}/orders?limit=200`);
      return {
        success: true,
        data: response.data && response.data.data ? response.data.data : [],
      };
    } catch (e) {
      const message = e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message;
      return {
        success: false,
        message: message || e.message || 'ERROR_ON_RELEASE_TRANSACTION',
      };
    }
  },

  async recheckOrder (id) {
    try {
      const response = await http().get(`/api/v1/transaction-orders/${id}/check`);
      return {
        success: true,
        data: response.data && response.data.data ? response.data.data : [],
      };
    } catch (e) {
      const message = e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message;
      return {
        success: false,
        message: message || e.message || 'ERROR_ON_RECHECK_ORDER',
      };
    }
  },

  async resendTransaction (id) {
    const res = await http().get(`/api/v1//transactions-orders/${id}/resend`);
    return res.data;
  },
};
