<template>
  <div class="invoice-widget-component">
    <ct-panel
      :title="$t('invoices.title')"
      :reloading="loading"
      :actionButtons="actionButtonsHeader"
      :enableFilterButton="true"
      :isFiltersChecked="isFiltersChecked"
      :isShowFilters="showInvoicesFilters"
      @button-click="actionClick"
      @reload="updateInvoiceList"
      @remove="removePanel"
      @filter="updateFilters"
      v-if="showPanel"
    >
      <b-row slot="panel-content">
        <b-col cols="12" sm="12" md="12" :lg="showInvoicesFilters ? '10' : '12'" order-lg="1" order-md="2" order="2">
          <ct-data-grid
            :filters="filters"
            :table="listInvoices | invoicesFormat(listInvoicesSort, listInvoicesColumns, { user, statusNames: listStatusColumns, billStatusNames: listBillStatusColumns, statusSeconday: $t('invoices.listStatusSecondary') })"
            :pagination="listInvoicesPaginate"
            :actionButtons="actionButtons"
            :emptyTableMessage="$t('dataGrid.emptyTableMessage')"
            :showModalPrint="showModalPrint"
            :printListing="listInvoicesPrint | invoicesFormat(listInvoicesSort, listInvoicesColumns, { user, statusNames: listStatusColumns, billStatusNames: listBillStatusColumns, statusSeconday: $t('invoices.listStatusSecondary') })"
            :modalSubtitle="$t('invoices.title')"
            :filtersPrint="listInvoicesFilters"
            @sort="changeSort"
            @search="searchChange"
            @filter="changeFilter"
            @print="printClick"
            @page="changePage"
            @action="actionClick"
            @selected="selectRows"
            @button="actionClick"
            @doubleClick="doubleClickRow"
          >
            <template v-slot:filters v-if="showInvoicesFilters">
              <ct-button-switch
                v-if="isInternal"
                :label="$t('invoices.filters.requiresIntervention')"
                :value="listInvoicesCustomFilters.requiresIntervention"
                @change="toggleRequiresIntervention"
              />
            </template>
          </ct-data-grid>
        </b-col>
        <b-col
          cols="12"
          sm="12"
          md="12"
          lg="2"
          order-lg="2"
          order-md="1"
          order="1"
          class="status-filters"
          v-if="showInvoicesFilters"
        >
          <b-row>
            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="NEW"
                :title="$t('invoices.listStatus.NEW')"
                :value="listInvoicesStatistics.NEW || 0"
                color="orange"
                :active="verifyStateFilter('NEW')"
                icon="fa-star"
              />
            </b-col>
            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="EXPIRED"
                :title="$t('invoices.listStatus.EXPIRED')"
                :value="listInvoicesStatistics.EXPIRED || 0"
                color="black"
                :active="verifyStateFilter('EXPIRED')"
                icon="fa-stopwatch"
              />
            </b-col>
            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="PENDING"
                :title="$t('invoices.listStatus.PENDING')"
                :value="listInvoicesStatistics.PENDING || 0"
                color="blue"
                :active="verifyStateFilter('PENDING')"
                icon="fa-spinner"
              />
            </b-col>
            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="PAID"
                :title="$t('invoices.listStatus.PAID')"
                :value="listInvoicesStatistics.PAID || 0"
                color="lime"
                :active="verifyStateFilter('PAID')"
                icon="fa-check"
              />
            </b-col>
            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="OVERPAID"
                :title="$t('invoices.listStatus.OVERPAID')"
                :value="listInvoicesStatistics.OVERPAID || 0"
                color="yellow"
                :active="verifyStateFilter('OVERPAID')"
                icon="fa-exclamation"
              />
            </b-col>
            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="UNDERPAID"
                :title="$t('invoices.listStatus.UNDERPAID')"
                :value="listInvoicesStatistics.UNDERPAID || 0"
                color="grey"
                :active="verifyStateFilter('UNDERPAID')"
                icon="fa-unlink"
              />
            </b-col>
            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="FINISHED"
                :title="$t('invoices.listStatus.FINISHED')"
                :value="listInvoicesStatistics.FINISHED || 0"
                color="green"
                :active="verifyStateFilter('FINISHED')"
                icon="fa-check-double"
              />
            </b-col>
            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="REVERSAL"
                :title="$t('invoices.listStatus.REVERSAL')"
                :value="listInvoicesStatistics.REVERSAL || 0"
                color="indigo"
                :active="verifyStateFilter('REVERSAL')"
                icon="fa-exchange-alt"
              />
            </b-col>
            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="CANCELLED"
                :title="$t('invoices.listStatus.CANCELLED')"
                :value="listInvoicesStatistics.CANCELLED || 0"
                color="red"
                :active="verifyStateFilter('CANCELLED')"
                icon="fa-ban"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </ct-panel>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import config from '../../config';
import CtButtonSwitch from '@/components/Shared/ButtonSwitch.vue';
import CtDataGrid from '@/components/Shared/DataGrid/DataGrid.vue';
import CtWidgetStatFilter from '@/components/Dashboard/WidgetStatFilter.vue';
import CtPanel from '@/components/Dashboard/Panel.vue';
import invoicesFormat from '@/filters/invoicesFormat';

export default {
  name: 'InvoiceWidget',
  components: {
    CtDataGrid,
    CtWidgetStatFilter,
    CtPanel,
    CtButtonSwitch,
  },
  filters: {
    invoicesFormat,
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      listInvoices: state => state.invoices.listInvoices,
      listInvoicesStatistics: state => state.invoices.listInvoicesStatistics,
      listInvoicesPrint: state => state.invoices.listInvoicesPrint,
      listInvoicesPaginate: state => state.invoices.listInvoicesPaginate,
      listInvoicesSort: state => state.invoices.listInvoicesSort,
      isReversal: state => state.invoices.isReversal,
      listInvoicesFilters: state => state.invoices.listInvoicesFilters,
      listInvoicesCustomFilters: state => state.invoices.listInvoicesCustomFilters,
      listInvoicesSearch: state => state.invoices.listInvoicesSearch,
      showInvoicesFilters: state => state.invoices.showInvoicesFilters,
    }),
    ...mapGetters(['isInternal']),
    isFiltersChecked () {
      return !!(this.listInvoicesFilters && this.listInvoicesFilters.length);
    },
    filters () {
      const filters = {
        inputSearchText: this.$t('dataGrid.inputSearchText'),
        items: [],
        enableFilterText: true,
        enablePrint: true,
        filterTextCurrent: {},
        additional: [
          {
            name: 'items.billet.digitableLine',
            text: this.$t('invoices.listColumns.billet'),
            isSpecialSearch: true,
          },
          {
            name: 'source.name',
            text: this.$t('invoices.listColumns.origin'),
            isSpecialSearch: true,
          },
          {
            name: 'userId',
            text: this.$t('invoices.listColumns.user'),
            isSpecialSearch: true,
          },
        ],
      };

      if (this.isInternal) {
        filters.additional.push({
          name: 'bill.code',
          text: this.$t('invoices.listColumns.bill'),
          isSpecialSearch: true,
        });
      }

      return filters;
    },
  },
  methods: {
    ...mapActions([
      'setInvoiceListFilter',
      'removeInvoiceListFilter',
      'setInvoiceListCustomFilter',
      'getInvoiceList',
      'getListInvoiceStatistics',
      'setInvoiceListSort',
      'setInvoiceListPage',
      'setInvoiceListSearch',
      'setInvoiceShowFilters',
      'approveExpiredInvoice',
      'approveForAutoExecutableInvoice',
      'emitBills',
    ]),
    removePanel () {
      this.showPanel = false;
    },
    changeFilter (filter) {
      this.setInvoiceListFilter(filter);
    },
    toggleRequiresIntervention () {
      this.setInvoiceListCustomFilter({
        requiresIntervention: !this.listInvoicesCustomFilters.requiresIntervention,
      });
    },
    printClick () {
      const isPrint = true;
      this.getInvoiceList(isPrint);
    },
    toggleModalPrint () {
      this.showModalPrint = !this.showModalPrint;
    },
    async actionClick (event) {
      if (event.action && event.action === 'approveExpired') {
        await this.handleApproveExpired(event);
      }

      if (event.action && event.action === 'approveExecutable') {
        await this.handleApproveForAutoExecutable(event);
      }

      if (event.action && event.action === 'emitBill') {
        await this.handleEmitBill(event);
      }
    },
    async handleApproveExpired (event) {
      const result = await this.$alert({
        title: this.$t('invoices.approveExpired.title'),
        type: 'success',
        showCancelButton: true,
        confirmButtonText: this.$t('invoices.approveExpired.confirmText'),
        cancelButtonText: this.$t('invoices.approveExpired.cancelText'),
        confirmButtonColor: '#32a932',
        reverseButtons: true,
      });
      if (result.value) {
        try {
          await this.approveExpiredInvoice(event.data._id);
          this.updateInvoiceList();
        } catch (error) {
          this.$notify({
            type: 'error',
            title: this.$t('invoices.approveExpired.notificationErrorTitle'),
            text: error.response.data.message,
          });
        }
      }
    },
    async handleApproveForAutoExecutable (event) {
      const result = await this.$alert({
        title: this.$t('invoices.approveForAutoExecutableInvoice.title'),
        type: 'success',
        showCancelButton: true,
        confirmButtonText: this.$t('invoices.approveForAutoExecutableInvoice.confirmText'),
        cancelButtonText: this.$t('invoices.approveForAutoExecutableInvoice.cancelText'),
        confirmButtonColor: '#32a932',
        reverseButtons: true,
      });
      if (result.value) {
        try {
          await this.approveForAutoExecutableInvoice(event.data._id);
          this.updateInvoiceList();
        } catch (error) {
          this.$notify({
            type: 'error',
            title: this.$t('invoices.approveForAutoExecutableInvoice.notificationErrorTitle'),
            text: error.response.data.message,
          });
        }
      }
    },
    async handleEmitBill (event) {
      const result = await this.$alert({
        title: this.$t('invoices.emitBill.title'),
        type: 'success',
        showCancelButton: true,
        confirmButtonText: this.$t('invoices.emitBill.confirmText'),
        cancelButtonText: this.$t('invoices.emitBill.cancelText'),
        confirmButtonColor: '#32a932',
        reverseButtons: true,
      });
      if (result.value) {
        try {
          await this.emitBills([event.data._id]);
          this.updateInvoiceList();
        } catch (error) {
          this.$notify({
            type: 'error',
            title: this.$t('invoices.emitBill.notificationErrorTitle'),
            text: error.response.data.message,
          });
        }
      }
    },
    searchChange (search) {
      this.setInvoiceListSearch(search);
    },
    changeSort (header) {
      this.setInvoiceListSort(header);
    },
    changePage (page) {
      this.setInvoiceListPage(page);
    },
    selectRows (rows) {},
    buttonClick () {},
    doubleClickRow (idField) {
      if (idField) {
        const invoiceId = idField.text || idField;
        this.$router.push({ path: `/invoices/${invoiceId}` });
      }
    },
    async updateInvoiceList () {
      try {
        this.loading = true;
        await this.getInvoiceList();
        await this.getListInvoiceStatistics();
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    updateFilters (isFiltered) {
      this.setInvoiceShowFilters(isFiltered);
    },
    filterResults (v) {
      if (!v.activated) {
        this.setInvoiceListFilter(v.value);
      } else {
        this.removeInvoiceListFilter(v.value);
      }
    },
    verifyStateFilter (filter) {
      if (this.listInvoicesFilters.indexOf(filter) !== -1) {
        return true;
      }
      return false;
    },
  },
  async created () {
    this.filters.filterTextCurrent = this.listInvoicesSearch;
    this.filters.filterStatusCurrent = this.listInvoicesFilters;
    this.updateInvoiceList();
    this.interval = setInterval(async () => {
      await this.updateInvoiceList(true);
    }, config.reloadInterval);
  },
  async beforeDestroy () {
    clearInterval(this.interval);
  },
  data () {
    return {
      loading: false,
      interval: false,
      panel: false,
      showPanel: true,
      showModalPrint: false,
      actionButtons: [],
      actionButtonsHeader: [],
      listInvoicesColumns: {
        marker: this.$t('invoices.listColumns.marker'),
        _id: this.$t('invoices.listColumns._id'),
        createdAt: this.$t('invoices.listColumns.createdAt'),
        email: this.$t('invoices.listColumns.email'),
        services: this.$t('invoices.listColumns.services'),
        fiatAmount: this.$t('invoices.listColumns.fiatAmount'),
        cryptoPrice: this.$t('invoices.listColumns.cryptoPrice'),
        cryptoAmount: this.$t('invoices.listColumns.cryptoAmount'),
        status: this.$t('invoices.listColumns.status'),
        statusSecondary: this.$t('invoices.listColumns.statusSecondary'),
        approveExpired: this.$t('invoices.listColumns.approveExpired'),
        bill: this.$t('invoices.listColumns.bill'),
      },
      listStatusColumns: [
        {
          name: '',
          text: this.$t('invoices.listStatus.ALL'),
        },
        {
          name: 'NEW',
          text: this.$t('invoices.listStatus.NEW'),
          icon: 'fas fa-star',
          color: 'orange',
        },
        {
          name: 'EXPIRED',
          text: this.$t('invoices.listStatus.EXPIRED'),
          icon: 'fas fa-stopwatch',
          color: 'black',
        },
        {
          name: 'PENDING',
          text: this.$t('invoices.listStatus.PENDING'),
          icon: 'fas fa-spinner',
          color: 'blue',
        },
        {
          name: 'PAID',
          text: this.$t('invoices.listStatus.PAID'),
          icon: 'fas fa-check',
          color: 'lime',
        },
        {
          name: 'OVERPAID',
          text: this.$t('invoices.listStatus.OVERPAID'),
          icon: 'fas fa-exclamation',
          color: 'yellow',
        },
        {
          name: 'UNDERPAID',
          text: this.$t('invoices.listStatus.UNDERPAID'),
          icon: 'fas fa-unlink',
          color: 'grey',
        },
        {
          name: 'PROCESSING',
          text: this.$t('invoices.listStatus.PROCESSING'),
          icon: 'fas fa-ellipsis-h',
          color: 'aqua',
        },
        {
          name: 'FINISHED',
          text: this.$t('invoices.listStatus.FINISHED'),
          icon: 'fas fa-check-double',
          color: 'green',
        },
        {
          name: 'REVERSAL',
          text: this.$t('invoices.listStatus.REVERSAL'),
          icon: 'fas fa-exchange-alt',
          color: 'indigo',
        },
        {
          name: 'CANCELLED',
          text: this.$t('invoices.listStatus.CANCELLED'),
          icon: 'fas fa-times',
          color: 'red',
        },
      ],
      listBillStatusColumns: [
        {
          name: 'OPEN',
          text: this.$t('invoices.listBillStatus.OPEN'),
        },
        {
          name: 'PROCESSING',
          text: this.$t('invoices.listBillStatus.PROCESSING'),
        },
        {
          name: 'FINISHED',
          text: this.$t('invoices.listBillStatus.FINISHED'),
        },
        {
          name: 'ERROR',
          text: this.$t('invoices.listBillStatus.ERROR'),
        },
        {
          name: 'CANCELLED',
          text: this.$t('invoices.listBillStatus.CANCELLED'),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.invoice-widget-component {
  margin: 0 10px;
  .status-filters {
    padding-left: 10px;
  }
}
</style>
