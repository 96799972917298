<template>
  <div class="content component-users">
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">
        <a href="javascript:;">Home</a>
      </li>
      <li class="breadcrumb-item active">{{ $t('transactionOrders.subtitle') }}</li>
    </ol>
    <h1 class="page-header">
      {{ $t('app.name') }}
      <small>{{ $t('transactionOrders.subtitle') }}</small>
    </h1>

    <b-row>
      <b-col cols="12">
        <ct-transaction-orders-widget/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CtTransactionOrdersWidget from '@/components/TransactionOrders/TransactionOrdersWidget.vue';

export default {
  name: 'TransactionOrders',
  components: {
    CtTransactionOrdersWidget,
  },
};
</script>

<style scoped lang="scss">

</style>
