import InvoiceReferralService from './../../../services/InvoiceReferralService';

export default {
  async getInvoiceReferralsList ({ commit, state }, isPrint = false) {
    try {
      const list = await InvoiceReferralService.getList({
        filters: state.listFilters,
        search: state.listSearch,
        page: isPrint ? 0 : (state.listPaginate.currentPage || 1),
        limit: isPrint ? 0 : (state.listPaginate.perPage || 10),
        orderBy: state.listSort,
      });
      if (isPrint) {
        commit('SET_LIST_INVOICE_REFERRALS_PRINT', list);
      } else {
        commit('SET_LIST_INVOICE_REFERRALS', list);
        if (list.meta) {
          commit('SET_INVOICE_REFERRAL_LIST_TOTAL_PAGES', list.meta.pagination.totalPages);
        }
      }
    } catch (error) {
      console.error(error);
    }
  },

  async getListInvoiceReferralsStatistics ({ commit }) {
    const list = await InvoiceReferralService.listStatistics();
    if (list) {
      commit('SET_LIST_INVOICE_REFERRAL_STATISTICS', list.data);
    }
  },

  addInvoiceReferralsListFilter ({ commit, dispatch }, value) {
    commit('SET_INVOICE_REFERRAL_LIST_PAGE', 1);
    commit('ADD_INVOICE_REFERRAL_LIST_FILTER', value);
    dispatch('getInvoiceReferralsList');
  },

  setInvoiceReferralsShowFilters ({ commit }, value) {
    commit('SET_INVOICE_REFERRALS_SHOW_FILTERS', value);
  },

  removeInvoiceReferralsListFilter ({ commit, dispatch }, value) {
    commit('SET_INVOICE_REFERRAL_LIST_PAGE', 1);
    commit('REMOVE_INVOICE_REFERRAL_LIST_FILTER', value);
    dispatch('getInvoiceReferralsList');
  },

  setInvoiceReferralsListPage ({ commit, dispatch }, value) {
    commit('SET_INVOICE_REFERRAL_LIST_PAGE', value);
    dispatch('getInvoiceReferralsList');
  },

  setInvoiceReferralsListTotalPages ({ commit }, value) {
    commit('SET_INVOICE_REFERRAL_LIST_PAGE', 1);
    commit('SET_INVOICE_REFERRAL_LIST_TOTAL_PAGES', value);
  },

  setInvoiceReferralsListSort ({ commit }, value) {
    commit('SET_INVOICE_REFERRAL_LIST_SORT', value);
  },

  setInvoiceReferralListSearch ({ commit }, value) {
    commit('SET_INVOICE_REFERRAL_LIST_SEARCH', value);
  },
};
