<template>
  <div class="declarations-widget-component">
    <ct-panel
      :title="$t('declarations.title')"
      :reloading="isLoading"
      @reload="updateDeclarationsList"
      :actionButtons="actionButtons"
      v-if="showPanel"
    >
      <b-row slot="panel-content">
        <b-col cols="12" sm="12" md="12" lg="12">
          <ct-data-grid
            :filters="filters"
            :table="list | declarationsFormat(listSort, listColumns)"
            :pagination="listPaginate"
            :actionButtons="actionButtons"
            :emptyTableMessage="$t('dataGrid.emptyTableMessage')"
            :showModalPrint="showModalPrint"
            :printListing="listPrint | declarationsFormat(listSort, listColumns)"
            :modalSubtitle="$t('declarations.title')"
            :filtersPrint="listFilters"
            @sort="changeSort"
            @search="searchChange"
            @filter="changeFilter"
            @print="printClick"
            @page="changePage"
            @action="actionClick"
            @selected="selectRows"
            @button="buttonClick"
            @doubleClick="doubleClickRow"
          ></ct-data-grid>
        </b-col>
      </b-row>
    </ct-panel>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import config from '../../config';
import CtDataGrid from '@/components/Shared/DataGrid/DataGrid.vue';
import CtPanel from '@/components/Dashboard/Panel.vue';
import declarationsFormat from '@/filters/declarationsFormat';
import Declaration from '@/model/Declaration';

export default {
  name: 'DeclarationsWidget',
  components: {
    CtDataGrid,
    CtPanel,
  },
  filters: {
    declarationsFormat,
  },
  computed: {
    ...mapState({
      list: state => state.declarations.list,
      listPrint: state => state.declarations.listPrint,
      listPaginate: state => state.declarations.listPaginate,
      listSort: state => state.declarations.listSort,
      listFilters: state => state.declarations.listFilters,
      listSearch: state => state.declarations.listSearch,
      authUser: state => (state.auth.user ? state.auth.user : []),
    }),
  },
  methods: {
    ...mapActions([
      'addDeclarationsListFilter',
      'removeDeclarationsListFilter',
      'getDeclarationsList',
      'getListDeclarationStatistics',
      'setDeclarationsListSort',
      'setDeclarationListSearch',
      'setDeclarationsListPage',
    ]),
    async updateDeclarationsList (background = false) {
      try {
        this.isLoading = !background;
        await this.getDeclarationsList();
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    printClick (event) {
      const isPrint = true;
      this.getDeclarationsList(isPrint);
    },
    toggleModalPrint () {
      this.showModalPrint = !this.showModalPrint;
    },
    reloadPanel () {
      this.toggleReload();
    },
    toggleReload () {
      this.panel = !this.panel;
    },
    removePanel () {
      this.showPanel = false;
    },
    searchEnterPress (value) {},
    changeFilter (filter) {},
    actionClick (action) {
      this.buttonClick(action);
    },
    async searchChange (search) {
      await this.setDeclarationListSearch(search);
      await this.updateDeclarationsList();
    },
    async changeSort (header) {
      await this.setDeclarationsListSort(header);
      await this.updateDeclarationsList();
    },
    changePage (page) {
      this.setDeclarationsListPage(page);
    },
    buttonClick (button) {
      if (button && button.action === 'download') {
        var win = window.open(button.url, '_blank');
        win.focus();
      }
    },
    selectRows (rows) {},
    doubleClickRow (id) {
    },
  },
  async created () {
    this.filters.filterTextCurrent = this.listSearch;
    await this.updateDeclarationsList();
    this.interval = setInterval(async () => {
      await this.updateDeclarationsList(true);
    }, config.reloadInterval);
  },
  async beforeDestroy () {
    clearInterval(this.interval);
  },
  data () {
    return {
      declaration: new Declaration(),
      interval: false,
      isLoading: false,
      showPanel: true,
      showModalPrint: false,
      filters: {
        inputSearchText: this.$t('dataGrid.inputSearchText'),
        items: [],
        enableFilterText: true,
        enablePrint: true,
        filterTextCurrent: {},
      },
      actionButtons: [
      ],
      listColumns: {
        _id: this.$t('declarations.listColumns._id'),
        'createdBy.email': this.$t('declarations.listColumns.createdBy'),
        reference: this.$t('declarations.listColumns.reference'),
        version: this.$t('declarations.listColumns.version'),
        year: this.$t('declarations.listColumns.year'),
        month: this.$t('declarations.listColumns.month'),
        file: this.$t('declarations.listColumns.file'),
        createdAt: this.$t('declarations.listColumns.createdAt'),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.declarations-widget-component {
  .status-filters {
    padding-left: 10px;
  }
}
</style>
