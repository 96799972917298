<template>
  <div class="invoice-description-component col-12 ct-margin-top-20" @keyup.esc="showEditDescription = false" >
    <div class="row">
      <h3 class="ct-invoice-title-description">
        {{ $t('invoices.details.description.title') }}
      </h3>
      <div class="description-container" :class="{ 'col-lg-10 col-md-10 col-xs-11 col-sm-10': !showEditDescription, 'col-12': showEditDescription }" >
        <p class="ct-invoice-description" v-if="!showEditDescription && isInternal" >
          {{ this.description || $t('invoices.details.description.noDescription') + ' ' + $t('invoices.details.description.clickToEdit') }}
        </p>
        <p class="ct-invoice-description" v-if="!showEditDescription && !isInternal" >
          {{ this.description || $t('invoices.details.description.noDescription') }}
        </p>
        <div class="row ct-div-textarea" v-if="showEditDescription">
          <div class="col-xs-11 col-sm-10 col-md-10">
            <div class="form-group">
              <textarea class="form-control ct-textarea" v-model="mutableDescription" rows="5"></textarea>
            </div>
          </div>
          <div class="col-xs-1 col-sm-2 col-md-2">
            <div class="ct-buttons-edit">
              <i class="fas fa-times ct-fa-times" @click="showEditDescription = false"></i>
              <i class="fas fa-check ct-fa-check" @click="saveDescription" ></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-1 col-sm-2 col-md-2 ct-edit-description d-print-none" v-if="isInternal && !showEditDescription">
        <i class="fas fa-edit ct-fa-edit" @click="toggleEditDescription" ></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'InvoiceDescription',
  filters: {},
  data () {
    return {
      showEditDescription: false,
      mutableDescription: null,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'isInternal',
    ]),
  },
  methods: {
    ...mapActions([
      'getInvoiceDetail',
      'updateInvoiceDescription',
    ]),
    changeDescription () {
      this.$emit('change', this.mutableDescription);
    },
    toggleEditDescription () {
      this.mutableDescription = this.description;
      this.showEditDescription = !this.showEditDescription;
    },
    async saveDescription () {
      const objDescription = {
        id: this.id,
        description: this.mutableDescription,
      };
      const response = await this.updateInvoiceDescription(objDescription);
      if (response) {
        this.showEditDescription = false;
        this.$notify({
          type: 'success',
          duration: 10000,
          title: this.$t('invoices.details.description.saveSuccessTitle'),
          text: this.$t('invoices.details.description.saveSuccessDesc'),
        });
        this.changeDescription();
        return true;
      }
      return false;
    },
    mounted () {
      this.mutableDescription = this.description;
    },
  },
};
</script>

<style lang="scss">
.invoice-description-component {
  h3 {
    font-weight: 400;
    width: 100%;
  }

  .description-container {
    padding: 0;

    .ct-textarea {
      border: none;
      overflow: auto;
      outline: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      resize: none;
      padding: 10px 0;
    }

    .ct-div-textarea {
      border: 1px solid #d6d6d6;
      border-radius: 4px;

    }

    .ct-invoice-description {
      font-size: 12px;
      color: #717478;
      text-align: left;
    }
  }
  .ct-buttons-edit {
    margin-top: 46px;
    text-align: right;
    .ct-fa-times {
      color: red;
      font-size: 20px;
      margin-right: 14px;
      cursor: pointer;
    }
    .ct-fa-check {
      color: green;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .ct-edit-description {
    text-align: right;
    padding-right: 0;
    cursor: pointer;
    .ct-fa-edit {
      font-size: 20px;
    }
  }

  @media (max-width: 767px) {
    .ct-buttons-edit {
      text-align: right;
    }
  }
}
</style>
