import RechargeService from '../../../services/RechargeService';

export default {
  async getRechargeOperators ({ commit }, ddd) {
    const operators = await RechargeService.getOperators(ddd);
    commit('SET_RECHARGE_OPERATORS', operators.data.map((operator) => {
      return {
        value: operator.id,
        label: operator.label,
      };
    }));
  },
  async getRechargeAmounts ({ commit }, { ddd, operator }) {
    const amounts = await RechargeService.getAmounts(ddd, operator);
    commit('SET_RECHARGE_AMOUNTS', amounts.data);
  },
};
