<template>
  <section class="opey-items-section" v-if="opeyRecharges.length !== 0">
    <div class="col-md-12">
      <h3>{{ $t('invoices.details.opeyRecharge.title') }}</h3>
    </div>
    <div class="table-responsive body-table">
      <table class="table table-opeyRecharges">
        <thead>
          <tr>
            <th>{{ $t('invoices.details.opeyRecharge.status') }}</th>
            <th>{{ $t('invoices.details.opeyRecharge.id') }}</th>
            <th>{{ $t('invoices.details.opeyRecharge.name') }}</th>
            <th>{{ $t('invoices.details.opeyRecharge.document') }}</th>
            <th>{{ $t('invoices.details.opeyRecharge.amount') }}</th>
            <th>{{ $t('invoices.details.opeyRecharge.fee') }}</th>
            <th class="text-center">{{ $t('invoices.details.opeyRecharge.ProofOfPayment') }}</th>
            <th width="12%" class="text-right action-col" v-if="isInternal"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in opeyRecharges" :key="item._id + index">
            <td>
              <ct-status-icon
                :iconSymbol="symbols[item.status]"
                :color="colors[item.status]"
                :description="statusTranslate[item.status]"
              />
            </td>
            <td>
              <ct-cell-id :text="item._id"></ct-cell-id>
            </td>
            <td>{{ item.opey.name }}</td>
            <td>{{ item.opey.document }}</td>
            <td>R$ {{ (item.amount || 0) | currency }}</td>
            <td>R$ {{ (item.fee || 0) | currency }}</td>
            <td class="text-center">
              <span v-if="item.receipt">
                <a :href="item.receipt" target="_blank" >
                  <i class="fa fa-lg fa-eye" />
                </a>
              </span>

              <span v-else-if="item.receiptNumber">
                {{ item.receiptNumber }}
              </span>

              <span v-else>
                <i class="far fa-lg fa-eye-slash disabled" />
              </span>
            </td>
            <td class="text-center" v-if="isInternal">
              <span v-if="isInternal && canSubmitReceipt(item)">
                <a @click="toggleUploadReceipt({ id: item._id, fileRequired: false, status: item.status })">
                  <i class="fa fa-lg fa-upload" />
                </a>
              </span>
              <span>
                <a @click.prevent="openModalEdit(item)">
                  <i class="fa fa-lg fa-cog"  />
                </a>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <ct-modal-view
      v-if="editingItem"
      :title="$t('invoices.details.editItem.title')"
      :show="showModalEdit"
      size="small"
      @close="toggleModalEdit"
    >
      <ct-edit-item-modal
        slot="body"
        :invoiceId="invoice._id"
        :item="editingItem"
        @close="toggleModalEdit"
        @save="refreshInvoice"
        :notEditing="!canEditItem(editingItem)"
        :isItemReprocess="isDisabledForItem(editingItem)"
        :isItemProcess="isDisabledForItemProcess(editingItem)"
        @reprocessItem="reprocessCurrentItem"
        @processItem="processCurrentItem"
      />
    </ct-modal-view>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import CtCellId from '@/components/Shared/DataGrid/CellId.vue';
import CtStatusIcon from '@/components/Dashboard/StatusIcon';
import ItemsActionsMixin from '@/mixins/ItemsActions';
import currency from '@/filters/currency';

export default {
  name: 'OpeyItems',
  filters: {
    currency,
  },
  components: {
    CtCellId,
    CtStatusIcon,
  },
  props: {
    opeyRecharges: {
      type: [Array, Object],
      required: false,
      default: () => ([]),
    },
  },
  mixins: [
    ItemsActionsMixin,
  ],
  computed: {
    ...mapGetters([
      'isInternal',
    ]),
    editingItem () {
      return this.opeyRecharges.find((opeyRecharge) => opeyRecharge._id === this.editingItemId);
    },
  },
  methods: {
    openModalEdit (item) {
      this.editingItemId = item._id;
      this.toggleModalEdit();
    },
    async reprocessCurrentItem () {
      await this.reprocessItem(this.editingItem._id);
    },
    async processCurrentItem () {
      await this.processItem(this.editingItem._id);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../themes/color-admin/_ct_variable.scss";
.opey-items-section {
  i.fa-lg {
    cursor: pointer;
    &.disabled {
      color: $ct_grey_1;
    }
  }
  h3 {
    font-weight: 400;
    margin-left: -10px;
  }
}
</style>
