import DeclarationService from './../../../services/DeclarationService';

export default {
  async getDeclarationsList ({ commit, state }, isPrint = false) {
    try {
      const list = await DeclarationService.getList({
        filters: state.listFilters,
        search: state.listSearch,
        page: isPrint ? 0 : (state.listPaginate.currentPage || 1),
        limit: isPrint ? 0 : (state.listPaginate.perPage || 10),
        orderBy: state.listSort,
      });
      if (isPrint) {
        commit('SET_LIST_DECLARATIONS_PRINT', list);
      } else {
        commit('SET_LIST_DECLARATIONS', list);
        if (list.meta) {
          commit('SET_DECLARATION_LIST_TOTAL_PAGES', list.meta.pagination.totalPages);
        }
      }
    } catch (error) {
      console.error(error);
    }
  },

  addDeclarationsListFilter ({ commit, dispatch }, value) {
    commit('SET_DECLARATION_LIST_PAGE', 1);
    commit('ADD_DECLARATION_LIST_FILTER', value);
    dispatch('getDeclarationsList');
  },

  removeDeclarationsListFilter ({ commit, dispatch }, value) {
    commit('SET_DECLARATION_LIST_PAGE', 1);
    commit('REMOVE_DECLARATION_LIST_FILTER', value);
    dispatch('getDeclarationsList');
  },

  setDeclarationsListPage ({ commit, dispatch }, value) {
    commit('SET_DECLARATION_LIST_PAGE', value);
    dispatch('getDeclarationsList');
  },

  setDeclarationsListTotalPages ({ commit }, value) {
    commit('SET_DECLARATION_LIST_PAGE', 1);
    commit('SET_DECLARATION_LIST_TOTAL_PAGES', value);
  },

  setDeclarationsListSort ({ commit }, value) {
    commit('SET_DECLARATION_LIST_SORT', value);
  },

  setDeclarationListSearch ({ commit }, value) {
    commit('SET_DECLARATION_LIST_SEARCH', value);
  },
};
