import BankService from './../services/BankService';

export default {
  async getBankList ({ commit }) {
    try {
      const list = await BankService.getBankList();
      if (list.data) {
        const bankList = [];
        for (const key in list.data) {
          if (list.data[key].code && list.data[key].name) {
            bankList.push({
              value: list.data[key].code,
              label: `${list.data[key].code} - ${list.data[key].name}`,
            });
          }
        }
        commit('SET_BANKS', bankList);
      }
    } catch (error) {
      console.error(error);
    }
  },
};
