<template>
  <div class="item-table-events-component">
    <div class="table-responsive body-table ct-margin-top-20">
      <span class="legend legend-title modal-edit">
        <i @click="showDetail = !showDetail"
          class="icon fa fas fa-eye"
          :class="{ 'fa-eye': !showDetail,'fa-eye-slash': showDetail}"></i>
        {{ $t('invoices.details.events.title') }}
      </span>

      <table class="table table-wallets">
        <thead>
        <tr>
          <th width="20%">{{ $t('invoices.details.events.createdAt') }}</th>
          <th width="80%">{{ $t('invoices.details.events.message') }}</th>
        </tr>
        </thead>
        <tbody v-if="events">
          <tr v-for="item in events" :key="item._id">
            <td>{{ item.createdAt | datetime}}</td>
            <td>{{ item.message }}<div v-if="showDetail"><br />{{item.detail}}</div></td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7">{{ $t('invoices.details.events.notFound') }}</td>
          </tr>
        </tbody>
      </table>
      <p class="not-event" v-if="events.length === 0">{{ $t('invoices.details.events.notEvent') }}</p>
    </div>
  </div>
</template>

<script>
import datetime from '@/filters/datetime';
export default {
  name: 'ItemTableEvents',
  filters: { datetime },
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  data: () => {
    return {
      showDetail: false,
    };
  },
};
</script>

<style lang="scss">
@import "@/themes/color-admin/_variable.scss";

.item-table-events-component {
  margin-top: 210px;
  padding-top: 20px;

  .table-responsive.body-table {
    overflow-y: auto;
    min-height: 120px;
  }

  h3 {
    font-weight: 400;
  }

  hr {
    padding: 0;
  }

  .not-event {
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    color: $black_darker
  }
}
</style>
