<template>
  <div class="invoiceReferrals-widget-component">
    <ct-panel
      :title="$t('transactionOrders.title')"
      :reloading="isLoading"
      @reload="updateTransactionOrderList"
      :isFiltersChecked="isFiltersChecked"
      :actionButtons="actionButtons"
      :isShowFilters="showFilters"
      @button-click="actionClick"
      @filter="updateFilters"
      :enableFilterButton="true"
      v-if="showPanel"
    >
      <b-row slot="panel-content">
        <b-col cols="12" sm="12" md="12" :lg="showFilters ? '10' : '12'" order-lg="1" order-md="2" order="2">
          <ct-data-grid
            :filters="filters"
            :table="list | transactionOrderFormat(listSort, listColumns, { user, statusNames: listStatusColumns })"
            :pagination="listPaginate"
            :actionButtons="actionButtons"
            :emptyTableMessage="$t('dataGrid.emptyTableMessage')"
            :showModalPrint="showModalPrint"
            :printListing="listPrint | transactionOrderFormat(listSort, listColumns, { user, statusNames: listStatusColumns })"
            :modalSubtitle="$t('transactionOrders.title')"
            :filtersPrint="listFilters"
            @sort="changeSort"
            @search="searchChange"
            @filter="changeFilter"
            @print="printClick"
            @page="changePage"
            @action="actionClick"
            @selected="selectRows"
            @button="buttonClick"
            @doubleClick="doubleClickRow"
          ></ct-data-grid>
        </b-col>
        <b-col
          cols="12"
          sm="12"
          md="12"
          lg="2"
          order-lg="2"
          order-md="1"
          order="1"
          class="status-filters"
          v-if="showFilters"
        >
          <b-row>
            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="OPEN"
                :title="$t('transactionOrders.listStatus.OPEN')"
                :value="listStatistics.OPEN || 0"
                color="orange"
                :active="verifyStateFilter('OPEN')"
                icon="fa-star"
              />
            </b-col>

            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="PROCESSING"
                :title="$t('transactionOrders.listStatus.PROCESSING')"
                :value="listStatistics.PROCESSING || 0"
                color="blue"
                :active="verifyStateFilter('PROCESSING')"
                icon="fa-spinner"
              />
            </b-col>

            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="ERROR"
                :title="$t('transactionOrders.listStatus.ERROR')"
                color="pink"
                :value="listStatistics.ERROR || 0"
                :active="verifyStateFilter('ERROR')"
                icon="fa fa-bug"
              />
            </b-col>

            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="CANCELLED"
                :title="$t('transactionOrders.listStatus.CANCELLED')"
                color="red"
                :value="listStatistics.CANCELLED || 0"
                :active="verifyStateFilter('CANCELLED')"
                icon="fa-ban"
              />
            </b-col>

            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="PARTIAL"
                :title="$t('transactionOrders.listStatus.PARTIAL')"
                :value="listStatistics.PARTIAL || 0"
                color="indigo"
                :active="verifyStateFilter('PARTIAL')"
                icon="fas fa-exchange-alt"
              />
            </b-col>

            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="FINISHED"
                :title="$t('transactionOrders.listStatus.FINISHED')"
                :value="listStatistics.FINISHED || 0"
                color="green"
                :active="verifyStateFilter('FINISHED')"
                icon="fa-check-double"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </ct-panel>

    <ct-modal-view
      key="modal-events-view"
      :title="$t('transactionOrders.modal.titleEvents')"
      :show="showModalEvents"
      @close="closeModalEvents()"
      size="small"
    >
      <ct-modal-table-events slot="body" :events="events" />
    </ct-modal-view>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import config from '../../config';
import CtDataGrid from '@/components/Shared/DataGrid/DataGrid.vue';
import CtPanel from '@/components/Dashboard/Panel.vue';
import transactionOrderFormat from '@/filters/transactionOrderFormat';
import CtWidgetStatFilter from '@/components/Dashboard/WidgetStatFilter.vue';
import CtModalTableEvents from '@/components/Invoices/ModalTableEvents';
import CtModalView from '@/components/Shared/ModalView.vue';

export default {
  name: 'TransactionOrdersWidget',
  components: {
    CtDataGrid,
    CtPanel,
    CtWidgetStatFilter,
    CtModalTableEvents,
    CtModalView,
  },
  filters: {
    transactionOrderFormat,
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      list: state => state.transactionOrders.list,
      listPrint: state => state.transactionOrders.listPrint,
      listPaginate: state => state.transactionOrders.listPaginate,
      listSort: state => state.transactionOrders.listSort,
      listFilters: state => state.transactionOrders.listFilters,
      listSearch: state => state.transactionOrders.listSearch,
      listStatistics: state => state.transactionOrders.listStatistics,
      showFilters: state => state.transactionOrders.showFilters,
      authUser: state => (state.auth.user ? state.auth.user : []),
    }),
    isFiltersChecked () {
      return !!(this.listFilters && this.listFilters.length);
    },
  },
  methods: {
    ...mapActions([
      'addTransactionOrdersListFilter',
      'removeTransactionOrdersListFilter',
      'getTransactionOrdersList',
      'getListTransactionOrderStatistics',
      'setTransactionOrdersListSort',
      'setTransactionOrderListSearch',
      'setTransactionOrdersListPage',
      'getListTransactionOrdersStatistics',
      'setTransactionOrdersShowFilters',
      'reexecuteOrder',
    ]),
    updateFilters (isFiltered) {
      this.setTransactionOrdersShowFilters(isFiltered);
    },
    copyToClipboard (value) {
      const el = document.createElement('textarea');
      el.value = value;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },
    async updateTransactionOrderList (background = false) {
      try {
        this.isLoading = !background;
        await this.getTransactionOrdersList();
        await this.getListTransactionOrdersStatistics();
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    verifyStateFilter (filter) {
      if (this.listFilters.indexOf(filter) !== -1) {
        return true;
      }
      return false;
    },
    filterResults (v) {
      if (!v.activated) {
        this.addTransactionOrdersListFilter(v.value);
      } else {
        this.removeTransactionOrdersListFilter(v.value);
      }
    },
    printClick (event) {
      const isPrint = true;
      this.getTransactionOrdersList(isPrint);
    },
    toggleModalPrint () {
      this.showModalPrint = !this.showModalPrint;
    },
    reloadPanel () {
      this.toggleReload();
    },
    toggleReload () {
      this.panel = !this.panel;
    },
    removePanel () {
      this.showPanel = false;
    },
    searchEnterPress (value) {},
    changeFilter (filter) {},
    actionClick (action) {
      this.buttonClick(action);
    },
    async searchChange (search) {
      await this.setTransactionOrderListSearch(search);
      await this.updateTransactionOrderList();
    },
    async changeSort (header) {
      await this.setTransactionOrdersListSort(header);
      await this.updateTransactionOrderList();
    },
    changePage (page) {
      this.setTransactionOrdersListPage(page);
    },
    buttonClick (button) {
      if (button && button.action === 'invoice') {
        this.$router.push({ path: `/invoices/${button.data.invoiceId}` });
      }

      if (button && button.action === 'reexecute') {
        this.reexecute(button.data._id);
      }

      if (button && button.action === 'events') {
        this.events = button.data.events;
        this.showModalEvents = true;
      }
    },
    closeModalEvents () {
      this.events = [];
      this.showModalEvents = false;
    },
    async reexecute (id) {
      const result = await this.$alert({
        title: this.$t('transactionOrders.reexecuteOrder.title'),
        type: 'success',
        showCancelButton: true,
        confirmButtonText: this.$t('transactionOrders.reexecuteOrder.confirmText'),
        cancelButtonText: this.$t('transactionOrders.reexecuteOrder.cancelText'),
        confirmButtonColor: '#32a932',
        reverseButtons: true,
      });
      if (result.value) {
        try {
          await this.reexecuteOrder(id);
          this.$notify({
            type: 'success',
            title: this.$t('transactionOrders.reexecuteOrder.notificationSuccessTitle'),
            text: this.$t('transactionOrders.reexecuteOrder.notificationSuccessMessage'),
          });
          this.updateTransactionOrderList();
        } catch (error) {
          this.$notify({
            type: 'error',
            title: this.$t('transactionOrders.reexecuteOrder.notificationErrorTitle'),
            text: error.response.data.message,
          });
        }
      }
    },
    selectRows (rows) {},
    doubleClickRow (id) {
    },
  },
  async created () {
    this.filters.filterTextCurrent = this.listSearch;
    await this.updateTransactionOrderList();
    this.interval = setInterval(async () => {
      await this.updateTransactionOrderList(true);
    }, config.reloadInterval);
  },
  async beforeDestroy () {
    clearInterval(this.interval);
  },
  data () {
    return {
      interval: false,
      isLoading: false,
      showPanel: true,
      showModalPrint: false,
      showModalEvents: false,
      events: [],
      filters: {
        inputSearchText: this.$t('dataGrid.inputSearchText'),
        items: [],
        enableFilterText: true,
        enablePrint: true,
        filterTextCurrent: {},
      },
      actionButtons: [
      ],
      listColumns: {
        _id: this.$t('transactionOrders.listColumns._id'),
        amount: this.$t('transactionOrders.listColumns.amount'),
        amountExecuted: this.$t('transactionOrders.listColumns.amountExecuted'),
        currencyPrimary: this.$t('transactionOrders.listColumns.currencyPrimary'),
        currencySecondary: this.$t('transactionOrders.listColumns.currencySecondary'),
        status: this.$t('transactionOrders.listColumns.status'),
        operation: this.$t('transactionOrders.listColumns.operation'),
        priceExecuted: this.$t('transactionOrders.listColumns.priceExecuted'),
        invoice: this.$t('transactionOrders.listColumns.invoice'),
        reexecute: this.$t('transactionOrders.listColumns.reexecute'),
        events: this.$t('transactionOrders.listColumns.events'),
        createdAt: this.$t('transactionOrders.listColumns.createdAt'),
        executedAt: this.$t('transactionOrders.listColumns.executedAt'),
      },

      listStatusColumns: [
        {
          name: 'OPEN',
          text: this.$t('transactionOrders.listStatus.OPEN'),
          icon: 'fas fa-star',
          color: 'orange',
        },
        {
          name: 'PROCESSING',
          text: this.$t('transactionOrders.listStatus.PROCESSING'),
          icon: 'fas fa-spinner',
          color: 'blue',
        },
        {
          name: 'FINISHED',
          text: this.$t('transactionOrders.listStatus.FINISHED'),
          icon: 'fas fa-check-double',
          color: 'green',
        },
        {
          name: 'ERROR',
          text: this.$t('transactionOrders.listStatus.ERROR'),
          icon: 'fa fa-bug',
          color: 'pink',
        },
        {
          name: 'CANCELLED',
          text: this.$t('transactionOrders.listStatus.CANCELLED'),
          icon: 'fas fa-times',
          color: 'red',
        },
        {
          name: 'PARTIAL',
          text: this.$t('transactionOrders.listStatus.PARTIAL'),
          icon: 'fas fa-exchange-alt',
          color: 'indigo',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.invoiceReferrals-widget-component {
  .status-filters {
    padding-left: 10px;
  }
}
</style>
