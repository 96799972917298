<template>
  <div class="receipt-form-component">
    <h1 class="page-header">
      {{ $t('invoices.modalReceipt.subtitle') }}
      <small>{{ invoiceId }}</small>
    </h1>
    <form @submit.prevent>
      <div class="row">
        <div class="col-12">
          <div class="form-group files color">
            <input
              ref="file"
              type="file"
              class="form-control"
              accept=".jpeg, .jpg, .gif, .png, .pdf"
            >
          </div>
          <div class="form-group">
            <label>{{ $t('invoices.modalReceipt.receiptNumber') }}</label>
            <input type="text" class="form-control" v-model="receiptNumber"/>
          </div>
          <div class="form-group">
            <div v-if="errorMessage && errorMessage !== ''" class="alert alert-danger text-center">
              {{ errorMessage }}
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-12">

          <div class="form-group pull-left form-inline">
            <button type="submit" class="btn btn-default btn-lg" v-if="isFinished" @click="downloadReceiptForProviderHandler()">
              <i class="fas fa-spinner fa-pulse" v-if="isLoading"></i>
              {{ $t('invoices.modalReceipt.downloadReceiptForProviderHandler') }}
            </button>
          </div>
          <div class="form-group pull-right form-inline">
            <button
              type="submit"
              class="btn btn-default btn-lg"
              @click="close()"
            >{{$t('invoices.modalReceipt.cancel')}}</button>
            <button type="submit" class="btn btn-primary btn-lg" @click="submitFile()">
              <i class="fas fa-spinner fa-pulse" v-if="isLoading"></i>
              {{ $t('invoices.modalReceipt.upload') }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    invoiceId: {
      type: String,
      required: true,
    },
    itemId: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: false,
      default: '',
    },
    fileRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    isFinished () {
      return this.status === 'FINISHED';
    },
  },
  methods: {
    ...mapActions(['uploadInvoiceReceipt', 'downloadReceiptForProvider']),
    close () {
      this.$emit('close');
    },
    async downloadReceiptForProviderHandler () {
      try {
        this.isLoading = true;
        await this.downloadReceiptForProvider({ id: this.invoiceId, itemId: this.itemId });
        this.notify(
          'success',
          this.$t('invoices.modalReceipt.downloadReceiptSuccessTitle'),
          this.$t('invoices.modalReceipt.downloadReceiptSuccessDesc'),
        );
        this.isLoading = false;
        this.$emit('finished');
      } catch (e) {
        const message = e.response ? e.response.data.message : e.message || 'Não foi possível fazer o download do recibo';
        this.notify('error', this.$t('invoices.modalReceipt.downloadReceiptErrorTitle'), message);
        this.isLoading = false;
      }
    },

    async submitFile () {
      const receiptNumberRequired = !this.fileRequired && (!this.receiptNumber || this.receiptNumber === '');
      const receiptRequired = this.fileRequired && this.$refs.file.files.length <= 0;

      if (receiptNumberRequired) {
        this.errorMessage = this.$t('invoices.modalReceipt.numberRequired');
        return;
      }

      if (receiptRequired) {
        this.errorMessage = this.$t('invoices.modalReceipt.fileRequired');
        return;
      }

      if ((this.fileRequired && !receiptRequired) || (!this.fileRequired && !receiptNumberRequired)) {
        this.isLoading = true;
        const data = {
          id: this.invoiceId,
          itemId: this.itemId,
          receiptNumber: this.receiptNumber,
          file: null,
        };

        if (this.fileRequired || (this.$refs.file && this.$refs.file.files && this.$refs.file.files.length > 0)) {
          data.file = this.$refs.file.files[0];
        }

        try {
          await this.uploadInvoiceReceipt(data);
          this.notify(
            'success',
            this.$t('invoices.modalReceipt.uploadSuccessTitle'),
            this.$t('invoices.modalReceipt.uploadSuccessDesc'),
          );
          this.$emit('finished');
          this.file = '';
        } catch (e) {
          const message = e.response ? e.response.data.message : e.message || 'Não foi possível salvar enviar o arquivo';
          this.notify(
            'error',
            this.$t('invoices.modalReceipt.uploadErrorTitle'),
            message,
          );
        }
        this.isLoading = false;
      }
    },
    notify (type, title, text) {
      this.$notify({
        type: type,
        duration: 10000,
        title: title,
        text: text,
      });
    },
  },
  data () {
    return {
      file: '',
      receiptNumber: '',
      errorMessage: '',
      isLoading: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.files input {
  outline: 2px dashed #2d353c;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  padding: 120px 0px 85px 35%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
}
.files input:focus {
  outline: 2px dashed #2d353c;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  border: 1px solid #2d353c;
}
.files {
  position: relative;
}
.files:after {
  pointer-events: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 50px;
  right: 0;
  height: 56px;
  content: "";
  background-image: url("/assets/img/upload-icon.png");
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
}
.color input {
  background-color: #f1f1f1;
}
.files:before {
  position: absolute;
  bottom: 10px;
  left: 0;
  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  content: " Ou arraste.";
  display: block;
  margin: 0 auto;
  color: #f59c1a;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
  bottom: 0px;
}
</style>
