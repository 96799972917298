<template>
  <div id="content">
    {{ itemStatus }}
    <div class="modal-header">
      <h1 class="page-header">
        {{ $t('invoices.details.subtitle') }}
        <a :href="siteInvoiceLink" target="_blank">
          <small>{{ $route.params.id }}</small>
          <i class="fa fa-xs fa-eye" />
        </a>
        <br>
        <div v-if="!invoice.bill && invoice.canEmitBill" class="bill hidden-print">
          <span> {{ $t('invoices.details.subtitleBill') }} </span>
          <span @click="handleEmitBill" class="bill-button"> {{ $t('invoices.details.emitBill') }} </span>
        </div>
        <div v-if="invoice.bill && !invoice.bill.url" class="bill">
          <span> {{ $t('invoices.details.subtitleBill') }} </span>
          <small> {{ $t('invoices.listBillStatus')[invoice.bill.status] }} </small>
        </div>
        <div v-if="invoice.bill && invoice.bill.url" class="bill">
          <span> {{ $t('invoices.details.subtitleBill') }} </span>
          <a :href="invoice.bill.url" target="_blank" class="bill-button"> {{ invoice.bill.code }} - {{ invoice.bill.serie }} </a>
        </div>
      </h1>
      <p>
        <ct-status-icon
          :iconSymbol="symbols[invoice.status]"
          :color="colors[invoice.status]"
          :description="statusTranslate[invoice.status]"
        />

        <!-- status secondary  -->
        <ct-status-icon
          v-if="invoice.statusSecondary"
          :iconSymbol="symbols[invoice.statusSecondary]"
          :color="colors[invoice.statusSecondary]"
          :description="statusTranslate[invoice.statusSecondary]"
          :help="invoice.statusSecondaryDescription || ''"
        />
      </p>

      <span class="pull-right hidden-print">
        <!-- <a class="btn btn-sm btn-white m-b-10" target="_blank">
          <i class="fas fa-download t-plus-1 fa-fw fa-lg"></i>
        </a> -->
        <button
          :disabled="!invoice.referralPaymentId"
          v-if="showReferralButton"
          @click="showDetailReferral"
          class="btn btn-sm btn-white m-b-10 icon-referral"
          :class="{ 'disabled': !invoice.referralPaymentId }"
          :title="!invoice.referralPaymentId ? $t('invoices.details.withouReferralTitle') : ''"
          v-b-tooltip.hover
        >
          <i class="fas fa-spinner fa-pulse" v-if="isLoadingReferral"></i>
          <i class="fas fa-hand-holding-usd fa-fw fa-lg"></i>
        </button>

        <a href="javascript:;" onclick="window.print()" class="btn btn-sm btn-white m-b-10 icon-print">
          <i class="fa fa-print t-plus-1 fa-fw fa-lg"></i>
        </a>
      </span>
    </div>
    <div class="invoice">
      <div class="invoice-header row-invoice row">
        <div class="col-md-8 invoice-from">
          <address class="m-t-5 m-b-5">
            {{ $t('invoices.details.user') }}
            <br>
            <strong class="text-inverse">{{ invoice.user && invoice.user.email ? invoice.user.email : '' }}</strong>
            <br>
            {{ invoice.user && invoice.user.name ? invoice.user.name : '' }}
            <br>
            {{ $t('invoices.details.cellphone') }}: {{ invoice.user && invoice.user.phone ? invoice.user.phone : '' }}
            <br>
            {{ $t('invoices.details.document') }}: {{ invoice.user && invoice.user.document ? invoice.user.document : '' }}
          </address>
        </div>
        <div class="col-md-4 invoice-date ct-margin-top-4">
          <small>{{ $t('invoices.details.createdAt') }}</small>
          <div class="date text-inverse m-t-5">{{ invoice.createdAt | datetime }}</div>
          <div class="invoice-detail">
            {{ $t('invoices.details.origin') }}: {{ invoice.source && invoice.source.name ? invoice.source.name : 'SITE' }}
          </div>
          <div class="invoice-detail">
            {{ $t('invoices.details.network') }}:
            <span class="label label-info">{{ invoice.network && invoice.network ? invoice.network : 'BTC' }}</span>
          </div>
        </div>
      </div>

      <ct-invoice-description @change="changeDescription" :id="invoice._id" :description="invoice.description || ''" v-if="invoice._id" />

      <div class="invoice-content">
        <ct-billet-items
          :billets="billets"
          :invoice="invoice"
          @toggleUploadReceipt="toggleUploadReceipt"
          :availableBalance="availableBalance"
          :colors="colors"
          :symbols="symbols"
          :statusTranslate="statusTranslate"
          @refreshInvoice="refreshInvoice"
        />

        <ct-recharge-items
          :recharges="recharges"
          :invoice="invoice"
          @toggleUploadReceipt="toggleUploadReceipt"
          :availableBalance="availableBalance"
          :colors="colors"
          :symbols="symbols"
          :statusTranslate="statusTranslate"
          @refreshInvoice="refreshInvoice"
        />

        <ct-shipment-items
          :shipments="shipments"
          :invoice="invoice"
          @toggleUploadReceipt="toggleUploadReceipt"
          :availableBalance="availableBalance"
          :colors="colors"
          :symbols="symbols"
          :statusTranslate="statusTranslate"
          @refreshInvoice="refreshInvoice"
        />

        <ct-pix-items
          :pix="pix"
          :invoice="invoice"
          @toggleUploadReceipt="toggleUploadReceipt"
          :availableBalance="availableBalance"
          :colors="colors"
          :symbols="symbols"
          :statusTranslate="statusTranslate"
          @refreshInvoice="refreshInvoice"
        />

        <ct-opey-items
          :opeyRecharges="opeyRecharges"
          :invoice="invoice"
          @toggleUploadReceipt="toggleUploadReceipt"
          :availableBalance="availableBalance"
          :colors="colors"
          :symbols="symbols"
          :statusTranslate="statusTranslate"
          @refreshInvoice="refreshInvoice"
        />

        <ct-gift-card-items
          :giftCards="giftCards"
          :invoice="invoice"
          @toggleFinishGiftCard="toggleFinishGiftCard"
          :availableBalance="availableBalance"
          :colors="colors"
          :symbols="symbols"
          :statusTranslate="statusTranslate"
          @refreshInvoice="refreshInvoice"
        />

        <div class="invoice-price">
          <div class="invoice-price-left">
            <small class="invoice-total">{{ $t('invoices.details.priceInvoice') }}</small>
            <span class="f-w-600">R$ {{ (invoice.cryptoPrice || 0) | currency }}</span>
          </div>
          <div class="invoice-price-center">
            <div v-if="billets.length !== 0" class="item-price-center">
              <small class="invoice-total">{{ $t('invoices.details.billet.title') }}</small>
              <span class="f-w-600">
                <i class="fab fa-btc" v-if="invoice.crypto === 'BTC'"></i>
                <span v-if="invoice.crypto !== 'BTC'">{{ invoice.crypto }}</span>
                {{ getValueBTCFormated(sumBilletsValues) }}
              </span>
              <span class="invoice-small-price">R$ {{ sumBilletsValues | currency }}</span>
            </div>
            <i v-if="(recharges.length !== 0 && billets.length !== 0)" class="fa fa-plus"></i>
            <div v-if="recharges.length !== 0" class="item-price-center">
              <small class="invoice-total">{{ $t('invoices.details.recharge.title') }}</small>
              <span class="f-w-600">
                <i class="fab fa-btc" v-if="invoice.crypto === 'BTC'"></i>
                <span v-if="invoice.crypto !== 'BTC'">{{ invoice.crypto }}</span>
                {{ getValueBTCFormated(sumRechargesValues) }}
              </span>
              <span class="invoice-small-price">R$ {{ sumRechargesValues | currency }}</span>
            </div>
            <i v-if="shipments.length !== 0 && (recharges.length !== 0 || billets.length !== 0)" class="fa fa-plus"></i>
            <div v-if="shipments.length !== 0" class="item-price-center">
              <small class="invoice-total">{{ $t('invoices.details.shipment.title') }}</small>
              <span class="f-w-600">
                <i class="fab fa-btc" v-if="invoice.crypto === 'BTC'"></i>
                <span v-if="invoice.crypto !== 'BTC'">{{ invoice.crypto }}</span>
                {{ getValueBTCFormated(sumShipmentsValues) }}
                </span>
              <span class="invoice-small-price">R$ {{ sumShipmentsValues | currency }}</span>
            </div>
            <i v-if="pix.length !== 0 && (shipments.length !== 0 && recharges.length !== 0 || billets.length !== 0)" class="fa fa-plus"></i>
            <div v-if="pix.length !== 0" class="item-price-center">
              <small class="invoice-total">{{ $t('invoices.details.pix.title') }}</small>
              <span class="f-w-600">
                <i class="fab fa-btc" v-if="invoice.crypto === 'BTC'"></i>
                <span v-if="invoice.crypto !== 'BTC'">{{ invoice.crypto }}</span>
                {{ getValueBTCFormated(sumPixValues) }}
              </span>
              <span class="invoice-small-price">R$ {{ sumPixValues | currency }}</span>
            </div>
            <i v-if="opeyRecharges.length !== 0 && (pix.length !== 0 && recharges.length !== 0 || billets.length !== 0 || shipments.length !== 0)" class="fa fa-plus"></i>
            <div v-if="opeyRecharges.length !== 0" class="item-price-center">
              <small class="invoice-total">{{ $t('invoices.details.opeyRecharge.title') }}</small>
              <span class="f-w-600">
                <i class="fab fa-btc" v-if="invoice.crypto === 'BTC'"></i>
                <span v-if="invoice.crypto !== 'BTC'">{{ invoice.crypto }}</span>
                {{ getValueBTCFormated(sumOpeyRechargesValues) }}
              </span>
              <span class="invoice-small-price">R$ {{ sumOpeyRechargesValues | currency }}</span>
            </div>
            <i v-if="giftCards.length !== 0 && (opeyRecharges.length !== 0 || recharges.length !== 0 || billets.length !== 0 || shipments.length !== 0)" class="fa fa-plus"></i>
            <div v-if="giftCards.length !== 0" class="item-price-center">
              <small class="invoice-total">{{ $t('invoices.details.giftCard.title') }}</small>
              <span class="f-w-600">
                <i class="fab fa-btc" v-if="invoice.crypto === 'BTC'"></i>
                <span v-if="invoice.crypto !== 'BTC'">{{ invoice.crypto }}</span>
                {{ getValueBTCFormated(sumGiftCardsValues) }}
              </span>
              <span class="invoice-small-price">R$ {{ sumGiftCardsValues | currency }}</span>
            </div>
          </div>
          <div class="invoice-price-right invoice-price-right-first">
            <small class="invoice-total">{{ $t('invoices.details.totalFiat') }}</small>
            <span class="f-w-900">R$ {{ invoice.fiatAmount | currency }}</span>
          </div>
          <div class="invoice-price-right">
            <small class="invoice-total">{{ $t('invoices.details.total') }}</small>
            <span class="f-w-900">
              <i class="fab fa-btc" v-if="invoice.crypto === 'BTC'"></i>
              <span v-if="invoice.crypto !== 'BTC'">{{ invoice.crypto }}</span>
              {{ invoice.cryptoAmount | currency(8) }}
            </span>
          </div>
        </div>

        <ct-transactions
          :invoice="invoice"
          @verifyTransactions="verifyTransactions"
        />

        <div class="invoice-price">
          <div class="invoice-price-left ct-no-background">
          </div>
          <div class="invoice-price-center ct-no-background">
            <br>
            <br>
            <br>
          </div>
          <div class="invoice-price-right invoice-price-right-first">
            <small class="invoice-total">{{ $t('invoices.details.wallet.totalReceived') }}</small>
            <span class="f-w-900">
              <i class="fab fa-btc" v-if="invoice.crypto === 'BTC'"></i>
              <span v-if="invoice.crypto !== 'BTC'">{{ invoice.crypto }}</span>
              {{ totalAmountReceived | currency(8) }}
            </span>
          </div>
          <div class="invoice-price-right">
            <small class="invoice-total">{{ $t('invoices.details.wallet.totalBalance') }}</small>
            <small class="invoice-difference-status" v-if="transactionsBalance < 0 || totalAmountReceived < totalAmountConfirmedReceived">
              <ct-status-icon
                :iconSymbol="symbols['OVERPAID']"
                :color="colors['OVERPAID']"
              />
            </small>
            <span class="f-w-900">
              <i class="fab fa-btc" v-if="invoice.crypto === 'BTC'"></i>
              <span v-if="invoice.crypto !== 'BTC'">{{ invoice.crypto }}</span>
              {{ (invoice.cryptoAmount - transactionsBalance) | currency(8) }}
            </span>
          </div>
        </div>

        <ct-invoice-events :events="invoice.events" v-if="isInternal && invoice.events" />

        <div class="col-md-12 ct-div-return">
          <router-link :to="{ name: 'home' }">
            <button class="ct-button-return d-print-none" :to="{ name: 'home' }">
              <i class="fas fa-arrow-left ct-arrow-return"></i> {{ $t('invoices.details.returnButton') }}
            </button>
          </router-link>

          <button class="action-button" v-if="isInternal && !isApprovalButtonDisabled" @click="approveExpired()">
            <i class="fas fa-check"></i> {{ $t('invoices.details.approveExpired') }}
          </button>

          <button class="action-button" v-if="isInternal && isEnabledReversalButton" @click="reversalButton()">
            <i class="fas fa-exchange-alt"></i> {{ getValueStatusForReversal }}
          </button>
        </div>
      </div>
    </div>

    {{ itemStatus }}

    <ct-modal-view
      key="modal-upload"
      :title="$t('invoices.modalReceipt.title')"
      :show="showModalUploadReceipt"
      @close="toggleUploadReceipt"
      size="small"
    >
      <ct-form-upload-item
        slot="body"
        @close="toggleUploadReceipt"
        @finished="finishedUpload"
        :invoiceId="id"
        :itemId="itemId"
        :fileRequired="fileRequired"
        :status="itemStatus"
      />
    </ct-modal-view>

    <ct-modal-view
      key="modal-finish-gift-card"
      :title="$t('invoices.modalFinishGiftCard.title')"
      :show="showModalFinishGiftCard"
      @close="toggleFinishGiftCard"
      size="small"
    >
      <ct-form-finish-gift-card
        slot="body"
        @close="toggleFinishGiftCard"
        @finished="finishedGiftCard"
        :invoiceId="id"
        :itemId="itemId"
      />
    </ct-modal-view>

    <ct-modal-view
      key="modal-reversal"
      :title="$t('invoices.modalReversal.title')"
      :show="showModalReversal"
      @close="toggleReversal"
      size="small"
    >
      <ct-form-reversal
        slot="body"
        @close="toggleReversal"
        @finished="finishedReversal"
        :invoiceId="id"
        :reversalCryptoValue="reversalCryptoValue"
        :sumReversalValue="sumReversalValue"
        :isFinishedItems="isFinishedItems"
      />
    </ct-modal-view>

    <ct-modal-view
      :title="$t('invoices.details.modalReferralTitle')"
      :show="showModalReferral"
      size="small"
      @close="toggleReferral"
    >
      <ct-modal-referral-payment
        slot="body"
        :invoiceId="invoice._id"
        :invoiceReferral="invoiceReferral"
        @close="toggleReferral"
        :reexecute="reexecuteReferral"
        @copy="copyToClipboard"
      ></ct-modal-referral-payment>
    </ct-modal-view>

  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import config from '../../config';
import currency from '@/filters/currency';
import date from '@/filters/date';
import datetime from '@/filters/datetime';
import CtStatusIcon from '@/components/Dashboard/StatusIcon';
import CtModalView from '@/components/Shared/ModalView.vue';
import CtFormUploadItem from '@/components/Invoices/FormUploadReceipt.vue';
import CtFormFinishGiftCard from '@/components/Invoices/FormFinishGiftCard.vue';
import CtModalReferralPayment from './ModalReferralPayment.vue';
import CtFormReversal from '@/components/Invoices/FormReversal.vue';
import CtInvoiceDescription from '@/components/Invoices/InvoiceDetails/InvoiceDescription.vue';
import CtInvoiceEvents from '@/components/Invoices/InvoiceDetails/InvoiceEvents.vue';
import { isDefinitiveStatus } from '@/model/Invoice.js';
import { validStatus as validItemStatus } from '@/model/InvoiceItem.js';
import CtOpeyItems from './InvoiceItems/OpeyItems';
import CtRechargeItems from './InvoiceItems/RechargeItems';
import CtBilletItems from './InvoiceItems/BilletItems';
import CtShipmentItems from './InvoiceItems/ShipmentItems';
import CtPixItems from './InvoiceItems/PixItems';
import CtGiftCardItems from './InvoiceItems/GiftCardItems';
import CtTransactions from './InvoiceItems/Transactions';

export default {
  name: 'InvoiceDetails',
  filters: {
    currency,
    date,
    datetime,
  },
  props: {
    id: {
      type: String,
    },
  },
  computed: {
    ...mapState({
      banks: state => state.selects.banks || {},
    }),
    ...mapGetters([
      'isInternal',
    ]),

    isFinished () {
      return (this.currentItem && this.currentItem.status === 'FINISHED');
    },
    showReferralButton () {
      return this.invoice.referralUserId && this.invoice.referralFee > 0;
    },
    isApprovalButtonDisabled () {
      return !(this.invoice.status === 'PENDING' && this.invoice.statusSecondary === 'APPROVING_PAID_EXPIRED');
    },
    isEnabledReversalButton () {
      if (this.hiddenReversalButton) {
        return false;
      }
      return !isDefinitiveStatus(this.invoice.status) &&
      !this.isItemsInProgress &&
      this.invoice.cryptoAmountPaid &&
      this.sumReversalValue;
    },
    getValueStatusForReversal () {
      return this.invoice.status === 'REVERSAL'
        ? this.$t('invoices.details.reversed')
        : this.$t('invoices.details.reversal');
    },
    totalAmountReceived () {
      if (!this.invoice.transactions) {
        return 0;
      }
      return this.invoice.transactions.reduce((sum, item) => {
        return item.kind === 'D' ? sum + item.amount : sum;
      }, 0);
    },
    totalAmountConfirmedReceived () {
      if (!this.invoice.transactions) {
        return 0;
      }
      return this.invoice.transactions.reduce((sum, item) => {
        return item.confirmations && item.kind === 'D' ? sum + item.amount : sum;
      }, 0);
    },
    totalAmountSended () {
      if (!this.invoice.transactions) {
        return 0;
      }
      return this.invoice.transactions.reduce((sum, item) => {
        return item.kind === 'C' ? sum + item.amount : sum;
      }, 0);
    },
    totalAmountConfirmedSended () {
      if (!this.invoice.transactions) {
        return 0;
      }
      return this.invoice.transactions.reduce((sum, item) => {
        return item.confirmations && item.kind === 'C' ? sum + item.amount : sum;
      }, 0);
    },
    billets () {
      if (!this.invoice.items) {
        return [];
      }
      const billet = this.invoice.items.filter(item => {
        return item.kind === 'BILLET';
      });
      return billet || [];
    },
    sumBilletsValues () {
      return this.billets.reduce((sum, item) => sum + item.total, 0);
    },
    shipments () {
      if (!this.invoice.items) {
        return [];
      }
      const shipment = this.invoice.items.filter(item => {
        return item.kind === 'SHIPMENT';
      });
      return shipment || [];
    },
    pix () {
      if (!this.invoice.items) {
        return [];
      }
      const shipment = this.invoice.items.filter(item => {
        return item.kind === 'PIX';
      });
      return shipment || [];
    },
    isItemsInProgress () {
      if (!this.invoice.items) {
        return false;
      }
      const items = this.invoice.items.filter((item) => {
        return item.status === 'PROCESSING';
      });
      return items.length > 0;
    },
    isFinishedItems () {
      if (!this.invoice.items) {
        return false;
      }
      const items = this.invoice.items.filter((item) => {
        return item.status === 'FINISHED';
      });
      return items.length > 0;
    },
    reversalCryptoValue () {
      return (this.invoice.cryptoAmountPaid - this.invoice.cryptoFee - config.invoice.reversalNetworkRate) || 0;
    },
    sumReversalValue () {
      if (!this.invoice.items) {
        return 0;
      }

      const finishedItems = this.invoice.items.filter(item => item.status === 'FINISHED');
      const finishedValue = finishedItems.reduce((sum, item) => sum + item.total, 0);

      return (this.invoice.fiatAmountPaid - finishedValue) || 0;
    },
    sumShipmentsValues () {
      return this.shipments.reduce((sum, item) => sum + item.total, 0);
    },
    sumPixValues () {
      return this.pix.reduce((sum, item) => sum + item.total, 0);
    },
    recharges () {
      if (!this.invoice.items) {
        return [];
      }
      const recharge = this.invoice.items.filter(item => {
        return item.kind === 'RECHARGE';
      });
      return recharge || [];
    },
    sumRechargesValues () {
      return this.recharges.reduce((sum, item) => sum + item.total, 0);
    },
    opeyRecharges () {
      if (!this.invoice.items) {
        return [];
      }
      const opeyRecharge = this.invoice.items.filter(item => {
        return item.kind === 'OPEY';
      });
      return opeyRecharge || [];
    },
    sumOpeyRechargesValues () {
      return this.opeyRecharges.reduce((sum, item) => sum + item.total, 0);
    },
    giftCards () {
      if (!this.invoice.items) {
        return [];
      }
      const giftCards = this.invoice.items.filter(item => {
        return item.kind === 'GIFTCARD';
      });
      return giftCards || [];
    },
    sumGiftCardsValues () {
      return this.giftCards.reduce((sum, item) => sum + item.total, 0);
    },
    totalPaidValueInvoice () {
      if (!this.invoice.items) return 0;
      return this.invoice.items
        .filter(item => this.paidItemStatuses.includes(item.status))
        .reduce((sum, item) => sum + item.total, 0);
    },
    transactionsBalance () {
      return this.totalAmountReceived - this.totalAmountSended;
    },
    availableBalance () {
      return this.totalAmountConfirmedReceived - this.totalAmountSended - this.getValueBTC(this.totalPaidValueInvoice);
    },
    invoiceBalance () {
      return this.invoice.cryptoAmount - this.transactionsBalance;
    },
    siteInvoiceLink () {
      return `${config.siteUrl}/checkout/${this.$route.params.id}`;
    },
  },
  components: {
    CtStatusIcon,
    CtModalView,
    CtFormUploadItem,
    CtFormFinishGiftCard,
    CtInvoiceDescription,
    CtInvoiceEvents,
    CtOpeyItems,
    CtRechargeItems,
    CtBilletItems,
    CtShipmentItems,
    CtPixItems,
    CtGiftCardItems,
    CtTransactions,
    CtFormReversal,
    CtModalReferralPayment,
  },
  methods: {
    ...mapActions([
      'getBankList',
      'getInvoiceDetail',
      'setInvoiceStatus',
      'approveExpiredInvoice',
      'emitBills',
      'verifyInvoiceTransactions',
      'getInvoiceReferralDetail',
      'reprocessInvoiceReferral',
    ]),
    changeDescription (description) {
      this.invoice.description = description;
    },

    async handleEmitBill () {
      const result = await this.$alert({
        title: this.$t('invoices.emitBill.title'),
        type: 'success',
        showCancelButton: true,
        confirmButtonText: this.$t('invoices.emitBill.confirmText'),
        cancelButtonText: this.$t('invoices.emitBill.cancelText'),
        confirmButtonColor: '#32a932',
        reverseButtons: true,
      });
      if (result.value) {
        try {
          await this.emitBills([this.invoice._id]);
          this.invoice = await this.getInvoiceDetail(this.id);
        } catch (error) {
          this.$notify({
            type: 'error',
            title: this.$t('invoices.emitBill.notificationErrorTitle'),
            text: error.response.data.message,
          });
        }
      }
    },
    copyToClipboard (value) {
      const el = document.createElement('textarea');
      el.value = value;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },
    async approveExpired () {
      const result = await this.$alert({
        title: this.$t('invoices.approveExpired.title'),
        type: 'success',
        showCancelButton: true,
        confirmButtonText: this.$t('invoices.approveExpired.confirmText'),
        cancelButtonText: this.$t('invoices.approveExpired.cancelText'),
        confirmButtonColor: '#32a932',
        reverseButtons: true,
      });
      if (result.value) {
        try {
          await this.approveExpiredInvoice(this.invoice._id);
          this.invoice = await this.getInvoiceDetail(this.id);
        } catch (error) {
          this.$notify({
            type: 'error',
            title: this.$t('invoices.approveExpired.notificationErrorTitle'),
            text: error.response.data.message,
          });
        }
      }
    },
    reversalButton () {
      this.toggleReversal();
    },
    async refreshInvoice () {
      this.invoice = await this.getInvoiceDetail(this.id);
    },
    async verifyTransactions () {
      try {
        this.invoice = await this.verifyInvoiceTransactions({ invoiceId: this.invoice._id });
        this.$notify({
          type: 'success',
          title: this.$t('invoices.details.wallet.actions.verifyTransactions.messageSuccessTitle'),
          text: this.$t('invoices.details.wallet.actions.verifyTransactions.messageSuccess'),
        });
      } catch (e) {
        this.$notify({
          title: this.$t('invoices.details.wallet.actions.verifyTransactions.messageErrorTitle'),
          type: 'error',
          text: e.response.data.message,
        });
      }
    },
    getValueBTC (amount) {
      if (!this.invoice.cryptoPrice || !amount) return 0;
      return amount / this.invoice.cryptoPrice;
    },
    getValueBTCFormated (amount) {
      if (!this.invoice.cryptoPrice || !amount) return 0;
      return currency(this.getValueBTC(amount), 8);
    },
    toggleUploadReceipt (item) {
      if (item && item.id) {
        this.itemId = item.id;
        this.fileRequired = item.fileRequired !== undefined ? item.fileRequired : false;
        this.itemStatus = item.status || '';
      }

      this.showModalUploadReceipt = !this.showModalUploadReceipt;
    },
    toggleFinishGiftCard (item = null) {
      if (item && item.id) {
        this.itemId = item.id;
        this.itemStatus = item.status || '';
      }

      this.showModalFinishGiftCard = !this.showModalFinishGiftCard;
    },
    toggleReversal () {
      this.showModalReversal = !this.showModalReversal;
    },
    toggleReferral () {
      this.showModalReferral = !this.showModalReferral;
    },
    async reexecuteReferral () {
      try {
        this.invoiceReferral = await this.reprocessInvoiceReferral(this.invoiceReferral._id);
        this.$notify({
          type: 'success',
          title: this.$t('invoices.modalReferralData.sucessTitleReexecuteReferral'),
          text: this.$t('invoices.modalReferralData.sucessMessageReexecuteReferral'),
        });
      } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
          this.$notify({
            type: 'error',
            title: this.$t('invoices.modalReferralData.errorTitleReexecuteReferral'),
            text: e.response.data.message,
          });
        }
      }
    },
    async showDetailReferral () {
      if (this.showReferralButton && this.invoice.referralPaymentId) {
        try {
          this.isLoadingReferral = true;
          this.invoiceReferral = await this.getInvoiceReferralDetail(this.invoice.referralPaymentId);
          this.toggleReferral();
        } catch (e) {
          this.$notify({
            type: 'error',
            title: this.$t('invoices.modalReferralData.errorMessage'),
          });
        } finally {
          this.isLoadingReferral = false;
        }
      }
    },
    async finishedUpload () {
      this.showModalUploadReceipt = false;
      this.invoice = await this.getInvoiceDetail(this.id);
    },
    async finishedGiftCard () {
      this.showModalFinishGiftCard = false;
      this.invoice = await this.getInvoiceDetail(this.id);
    },
    async finishedReversal () {
      this.showModalReversal = false;
      this.$notify({
        type: 'success',
        title: this.$t('invoices.details.reversalNotifyTitle'),
      });
      this.invoice = await this.getInvoiceDetail(this.id);
      this.hiddenReversalButton = true;
    },
  },
  async created () {
    await this.getBankList();
    this.invoice = await this.getInvoiceDetail(this.id);
    if (!this.invoice) {
      this.$router.push({ name: 'home' });
    }
  },
  data () {
    return {
      itemStatus: null,
      colors: {
        NEW: 'orange',
        EXPIRED: 'black',
        PENDING: 'blue',
        PAID: 'lime',
        OVERPAID: 'yellow',
        UNDERPAID: 'grey',
        PROCESSING: 'aqua',
        FINISHED: 'green',
        REVERSAL: 'indigo',
        CANCELLED: 'red',
        OPEN: 'orange',
        ERROR: 'pink',
        WAITING: 'aqua',
        PAID_EXPIRED: 'black',
        APPROVING_PAID_EXPIRED: 'black',
        APPROVING: 'purple',
        APPROVED: 'lime',
        PARTIAL: 'indigo',
      },
      symbols: {
        NEW: 'fas fa-star',
        EXPIRED: 'fas fa-stopwatch',
        PENDING: 'fas fa-spinner',
        PAID: 'fas fa-check',
        OVERPAID: 'fas fa-exclamation',
        UNDERPAID: 'fas fa-unlink',
        PROCESSING: 'fas fa-ellipsis-h',
        FINISHED: 'fas fa-check-double',
        REVERSAL: 'fas fa-exchange-alt',
        CANCELLED: 'fas fa-times',
        OPEN: 'fas fa-star',
        ERROR: 'fa fa-bug',
        WAITING: 'fas fa-exclamation-circle',
        PAID_EXPIRED: 'fas fa-stopwatch',
        APPROVING_PAID_EXPIRED: 'far fa-calendar-check',
        APPROVING: 'fas fa-hourglass-half',
        APPROVED: 'fas fa-thumbs-up',
        PARTIAL: 'fa fa-not-equal',
      },
      statusTranslate: {
        NEW: this.$t('invoices.listStatus.NEW'),
        EXPIRED: this.$t('invoices.listStatus.EXPIRED'),
        PENDING: this.$t('invoices.listStatus.PENDING'),
        PAID: this.$t('invoices.listStatus.PAID'),
        OVERPAID: this.$t('invoices.listStatus.OVERPAID'),
        UNDERPAID: this.$t('invoices.listStatus.UNDERPAID'),
        PROCESSING: this.$t('invoices.listStatus.PROCESSING'),
        FINISHED: this.$t('invoices.listStatus.FINISHED'),
        REVERSAL: this.$t('invoices.listStatus.REVERSAL'),
        CANCELLED: this.$t('invoices.listStatus.CANCELLED'),
        OPEN: this.$t('invoices.listStatus.OPEN'),
        ERROR: this.$t('invoices.listStatus.ERROR'),
        WAITING: this.$t('invoices.listStatusSecondary.WAITING'),
        PAID_EXPIRED: this.$t('invoices.listStatusSecondary.PAID_EXPIRED'),
        APPROVING_PAID_EXPIRED: this.$t('invoices.listStatusSecondary.APPROVING_PAID_EXPIRED'),
        APPROVING: this.$t('invoices.listStatusSecondary.APPROVING'),
        APPROVED: this.$t('invoices.listStatusSecondary.APPROVED'),
        PARTIAL: this.$t('invoices.listStatusSecondary.PARTIAL'),
      },
      invoice: {},
      showModalUploadReceipt: false,
      showModalFinishGiftCard: false,
      showModalReversal: false,
      showModalReferral: false,
      isLoadingReferral: false,
      invoiceReferral: {},
      itemId: null,
      fileRequired: true,
      showEvents: false,
      hiddenReversalButton: false,
      paidItemStatuses: [
        validItemStatus.PENDING,
        validItemStatus.FINISHED,
        validItemStatus.PROCESSING,
      ],
    };
  },
};
</script>
<style scoped lang="scss">
@import "../../themes/color-admin/_variable.scss";
.pos {
  position: relative;
}
.ballon {
  z-index: 9999;
  min-width: 40em;
}
.action-button {
  float: right;
  width: 202px;
  height: 25px;
  border-radius: 4px;
  font-size: 10.5px;
  margin-top: 20px;
  border: none;
  background-color: #f0f3f4;
  color: #2e353c;
  cursor: pointer;
  text-transform: uppercase;
  &:disabled {
    color: #c2c2c2;
    cursor: no-drop;
  }
}

.page-header {
  .fa-eye {
    color: #7c7f83;
    margin-left: 5px;
  }
}

.invoice {
  padding: 8px;
  .row-invoice {
    margin-left: -8px;
    margin-right: -8px;
  }
  .invoice-from {
    line-height: 20px;
  }
  .invoice-content {
    .table-responsive {
      margin-bottom: 15px;
    }
    .invoice-header {
      margin: 0 -5px;
    }
    .table-invoice {
      a {
        color: $heading_text_color;
        &:hover {
          color: lighten($heading_text_color, 20%);
        }
      }
    }
    .invoice-total {
      text-transform: uppercase;
    }
    .invoice-difference-status {
      top: 32px;
      opacity: 1;
    }
    .fa-lg {
      color: #707478;
      cursor: pointer;
    }
    .fa-copy {
      cursor: pointer;
      font-size: 1.2em;
    }
    .fa-file-alt {
      font-size: 1.5em;
    }
    h3 {
      font-weight: 400;
      margin-left: -10px;
    }
    .invoice-price {
      margin-bottom: 0;
    }
    .invoice-price-left {
      width: 16%;
      color: white;
      background: #717478;
    }
    .invoice-price-right {
      width: 22%;
      font-size: 20px;
      .f-w-900 {
        font-weight: 900 !important;
      }
    }
    .invoice-price-right-first {
      width: 18%;
      background: #d3d8de;
      color: black;
    }
    .invoice-price-center {
      display: table-cell;
      padding: 15px 20px 5px 20px;
      font-size: 14px;
      font-weight: 600;
      position: relative;
      vertical-align: middle;
      .item-price-center {
        display: inline-block;
        small {
          font-size: 11px;
        }
      }
      .invoice-small-price {
        display: block;
        font-size: 12px;
      }
      .fa-plus {
        padding-left: 25px;
        padding-right: 25px;
        top: -22px;
        position: relative;
        color: #b7c2c9;
      }
    }
    .ct-no-background {
      background: white;
    }
    .ct-margin-top-20 {
      margin-top: 20px;
    }
  }
  .ct-div-return {
    height: 40px;
    padding-left: 5px;
    .ct-button-return {
      float: left;
      width: 202px;
      height: 25px;
      border-radius: 4px;
      font-size: 10.5px;
      margin-top: 20px;
      border: none;
      background-color: #f0f3f4;
      color: #2e353c;
      cursor: pointer;
      text-transform: uppercase;
    }
    .ct-button-return:hover {
      background-color: #d3d8de;
    }
    .ct-arrow-return {
      font-size: 14px;
      color: #2e353c;
      margin-right: 5px;
    }
  }
  .ct-margin-top-4 {
    margin-top: 4px;
  }
}
.ct-no-padding {
  padding-left: 0;
}

.modal-header {
  border: none;
  background: white;

  .bill {
    margin-right: 8px;
    font-size: 20px;
  }
  .bill-button {
    font-size: 14px;
    color: #348ee3;
    cursor: pointer;
  }
}

.small-title {
  font-size: 14px !important;
}

.history-title {
  margin-top: 50px;
  font-size: 21px;
}

.btn {
  border: none;
  padding: 5px;
  min-width: 35px;
}

.icon-referral.disabled:hover {
  cursor: not-allowed;
}

p {
  &.last-events {
    width: 100%;
    border-bottom: solid 1px #f0f3f4;
    margin: 0;
    padding: 10px 15px;
    &:last-child {
      border: none;
    }
  }
}
@media (max-width: 1302px) {
  .invoice-price-left {
    .invoice-total {
      font-size: 10px;
    }
    span {
      font-size: 15px;
    }
  }
  .invoice-price-center {
    font-size: 12px;
    .item-price-center {
      display: block;
      padding-right: 10px;
      padding-bottom: 10px;
    }
    .fa-plus {
      display: none;
    }
  }
}
@media (max-width: 1029px) {
  .action-button {
    font-size: 10px !important;
  }
}
@media (max-width: 767px) {
  .modal-header {
    max-height: 60px;
  }
  .invoice-header {
    margin-bottom: 0;
  }
  .invoice-date {
    margin-top: 0 !important;
  }
  .invoice-price-center {
    display: none !important;
  }
  .invoice-price-left {
    text-align: center;
  }
  .action-button {
    width: 100% !important;
  }
}
@media (max-width: 518px) {
  .modal-header {
    max-height: 90px;
  }
}
@media (max-width: 351px) {
  .pull-right {
    display: none;
  }
}
@media print {
  .icon-print, .icon-referral, .hidden-print {
    display: none;
  }
}
</style>

<style lang="scss">
.dropdown-actions {
  position: absolute;
  display: inherit;

  .dropdown-menu {
    z-index: 1030;
  }

  .dropdown-item {
    &:focus {
      background-color: transparent;
    }
    &:hover {
      background-color: #f2f3f4;
    }
    &.disabled {
      color: #98999a;
      pointer-events: inherit;

      &:hover {
        cursor: not-allowed;
        background-color: transparent;
      }
    }
  }
}
</style>
