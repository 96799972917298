<template>
  <div class="content component-users">
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">
        <a href="javascript:;">Home</a>
      </li>
      <li class="breadcrumb-item active">{{ $t('reports.title') }}</li>
    </ol>
    <h1 class="page-header">
      {{ $t('app.name') }}
      <small>{{ $t('reports.title') }}</small>
    </h1>

    <b-row>
      <b-col cols="12">
        <ct-reports-widget/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CtReportsWidget from '@/components/Reports/Widget.vue';

export default {
  name: 'Reports',
  components: {
    CtReportsWidget,
  },
};
</script>

<style scoped lang="scss">
@media print {
  .content {
    margin-left: 0;
  }
  .breadcrumb {
    display: none;
  }
  .page-header {
    display: none;
  }
}
</style>
