<template>
  <div class="content component-dashboard">
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item active">Home</li>
      <li class="breadcrumb-item active">{{ $t('dashboard.title') }}</li>
    </ol>
    <h1 class="page-header">{{ $t('invoices.details.title') }}</h1>

    <div class="row">
      <div class="col-12 col-sm-12 col-md-7 col-lg-5 col-xl-4">
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <ct-invoice-details :id="$route.params.id"/>
      </div>
    </div>
  </div>
</template>

<script>
import CtInvoiceDetails from '@/components/Invoices/InvoiceDetails.vue';

export default {
  name: 'Invoices',
  components: {
    CtInvoiceDetails,
  },
  data () {
    return {
      invoice: {},
    };
  },
  computed: {},
  methods: {},
  async created () {
    if (!this.$route.params.id) {
      this.$router.push({ name: 'home' });
    }
  },
};
</script>

<style scoped lang="scss">
.component-dashboard {
  .breadcrumb {
    background-color: transparent;
  }
}
@media print {
  .content {
    margin-left: 0;
  }
  .breadcrumb {
    display: none;
  }
  .page-header {
    display: none;
  }
}
</style>
