import currency from '../filters/currency';

const formatValue = (list, sort, columnsNames, { user, statusNames }) => {
  const isInternal = Array.isArray(user.roles) && (user.roles.indexOf('operator') !== -1 || user.roles.indexOf('admin') !== -1);

  const table = {
    headers: [
      {
        name: '_id',
        text: columnsNames && '_id' in columnsNames ? columnsNames._id : '',
        width: '4%',
        order: sort && '_id' in sort ? sort._id : true,
        isFilter: true,
        isPrint: true,
        isSpecialSearch: true,
      },
      {
        name: 'userName',
        text: columnsNames && 'userName' in columnsNames ? columnsNames.userName : '',
        width: '10%',
        order: false,
        isFilter: false,
        isPrint: true,
      },
      {
        name: 'userEmail',
        text: columnsNames && 'userEmail' in columnsNames ? columnsNames.userEmail : '',
        width: '10%',
        order: false,
        isFilter: false,
        isPrint: true,
      },
      {
        name: 'origin',
        text: columnsNames && 'origin' in columnsNames ? columnsNames.origin : '',
        width: '10%',
        order: false,
        isFilter: false,
        isPrint: true,
      },
      {
        name: 'fee',
        text: columnsNames && 'fee' in columnsNames ? columnsNames.fee : '',
        width: '10%',
        order: false,
        isFilter: false,
        isPrint: true,
      },
      {
        name: 'value',
        text: columnsNames && 'value' in columnsNames ? columnsNames.value : '',
        width: '10%',
        order: false,
        isFilter: false,
        isPrint: true,
      },
      {
        name: 'reexecuteReferral',
        text: columnsNames && 'reexecuteReferral' in columnsNames ? columnsNames.reexecuteReferral : '',
        width: '5%',
        order: false,
        isPrint: false,
      },
      {
        name: 'createdAt',
        text: columnsNames && 'createdAt' in columnsNames ? columnsNames.createdAt : '',
        width: '8%',
        order: sort && 'createdAt' in sort ? sort.createdAt : true,
        isPrint: true,
      },
      {
        name: 'status',
        text: columnsNames && 'status' in columnsNames ? columnsNames.status : '',
        width: '3%',
        order: false,
        isPrint: true,
      },
      {
        name: 'invoice',
        text: columnsNames && 'invoice' in columnsNames ? columnsNames.invoice : '',
        width: '5%',
        order: false,
        isPrint: false,
      },
    ],
    lines: [],
    linesPrint: [],
  };

  table.headers = table.headers.filter((_, index) => {
    const indexesForRemove = isInternal ? [] : [1, 2, 6, 9];
    return indexesForRemove.indexOf(index) < 0;
  });

  if (list && list.data && list.data.length > 0) {
    for (let i = 0; i < list.data.length; i++) {
      const createdAt = new Date(list.data[i].createdAt);

      const id = { type: 'id', text: list.data[i]._id };
      const user = list.data[i].user || {};
      const value = currency(list.data[i].value, 2, 'R$ ');

      const showReexecuteButton = isInternal && list.data[i].status === 'ERROR';
      let reexecutePreviewButton = {
        text: '-',
        textAlign: 'center',
      };
      if (showReexecuteButton) {
        reexecutePreviewButton = {
          name: 'reexecute-preview-' + list.data[i]._id,
          type: 'button',
          text: '',
          hover: true,
          icon: 'fas fa-sync-alt',
          color: 'primary',
          textAlign: 'center',
          value: {
            action: 'reexecute',
            data: list.data[i],
          },
          disabled: !showReexecuteButton,
        };
      }

      const invoiceView = {
        name: 'invoice-preview-' + list.data[i]._id,
        type: 'button-text',
        text: '',
        icon: 'fas fa-eye',
        color: '#348fe2',
        textAlign: 'center',
        value: {
          action: 'invoice',
          data: list.data[i],
        },
      };

      let statusIcon = '';
      let statusColor = '';
      switch (list.data[i].status) {
        case 'OPEN':
          statusIcon = 'fas fa-star';
          statusColor = 'orange';
          break;
        case 'PROCESSING':
          statusIcon = 'fas fa-spinner';
          statusColor = 'blue';
          break;
        case 'FINISHED':
          statusIcon = 'fas fa-check-double';
          statusColor = 'green';
          break;
        case 'ERROR':
          statusIcon = 'fa fa-bug';
          statusColor = 'pink';
          break;
        case 'CANCELLED':
          statusIcon = 'fa-ban';
          statusColor = 'red';
          break;
      }

      let statusName = statusNames.filter((status) => {
        return status.name === list.data[i].status;
      });
      statusName = statusName.shift();

      const status = {
        name: 'status-invoice-' + list.data[i]._id,
        type: 'status-icon',
        color: statusColor,
        icon: statusIcon,
        textAlign: 'center',
        help: list.data[i].statusDescription || '',
        text: '',
      };

      const statusPrint = {
        name: 'status-invoice-' + list.data[i]._id,
        type: 'status-icon',
        color: statusColor,
        icon: statusIcon,
        text: list.data[i].status,
      };

      let lines = [
        id,
        user.name || '-',
        user.email || '-',
        list.data[i].origin,
        list.data[i].fee,
        value,
        reexecutePreviewButton,
        createdAt.toLocaleString('pt-BR'),
        status,
        invoiceView,
      ];

      let linesPrint = [
        list.data[i]._id,
        user.name || '-',
        user.email || '-',
        list.data[i].origin,
        list.data[i].fee,
        value,
        createdAt.toLocaleString('pt-BR'),
        statusPrint,
      ];

      lines = lines.filter((_, index) => {
        const indexesForRemove = isInternal ? [] : [1, 2, 6, 9];
        return indexesForRemove.indexOf(index) < 0;
      });

      linesPrint = linesPrint.filter((_, index) => {
        const indexesForRemove = isInternal ? [] : [1, 2];
        return indexesForRemove.indexOf(index) < 0;
      });

      table.lines.push(lines);
      table.linesPrint.push(linesPrint);
    }
  }

  return table;
};

export default formatValue;
