import currency from '../filters/currency';
import config from '@/config';

const formatValue = (list, sort, columnsNames, { user, statusNames }) => {
  const isInternal = Array.isArray(user.roles) && (user.roles.indexOf('operator') !== -1 || user.roles.indexOf('admin') !== -1);

  const table = {
    headers: [
      {
        name: '_id',
        text: columnsNames && '_id' in columnsNames ? columnsNames._id : '',
        width: '4%',
        order: sort && '_id' in sort ? sort._id : true,
        isFilter: true,
        isPrint: true,
        isSpecialSearch: true,
      },
      {
        name: 'kind',
        text: columnsNames && 'kind' in columnsNames ? columnsNames.kind : '',
        width: '10%',
        order: true,
        isFilter: false,
        isPrint: true,
      },
      {
        name: 'network',
        text: columnsNames && 'network' in columnsNames ? columnsNames.network : '',
        width: '3%',
        order: false,
        isPrint: true,
        isFilter: true,
      },
      {
        name: 'crypto',
        text: columnsNames && 'crypto' in columnsNames ? columnsNames.crypto : '',
        width: '3%',
        order: false,
        isPrint: true,
        isFilter: true,
      },
      {
        name: 'amount',
        text: columnsNames && 'amount' in columnsNames ? columnsNames.amount : '',
        width: '10%',
        order: true,
        isFilter: false,
        isPrint: true,
      },
      {
        name: 'address',
        text: columnsNames && 'address' in columnsNames ? columnsNames.address : '',
        width: '10%',
        order: false,
        isFilter: true,
        isPrint: true,
      },
      {
        name: 'invoice',
        text: columnsNames && 'invoice' in columnsNames ? columnsNames.invoice : '',
        width: '5%',
        order: false,
        isPrint: false,
      },
      {
        name: 'transferTxId',
        text: columnsNames && 'transferTxId' in columnsNames ? columnsNames.transferTxId : '',
        width: '10%',
        order: false,
        isFilter: true,
        isPrint: true,
      },
      {
        name: 'transferFeeTxId',
        text: columnsNames && 'transferFeeTxId' in columnsNames ? columnsNames.transferFeeTxId : '',
        width: '10%',
        order: false,
        isFilter: true,
        isPrint: true,
      },
      {
        name: 'confirmations',
        text: columnsNames && 'confirmations' in columnsNames ? columnsNames.confirmations : '',
        width: '3%',
        order: true,
        isFilter: false,
        isPrint: true,
      },
      {
        name: 'exchangeStatus',
        text: columnsNames && 'exchangeStatus' in columnsNames ? columnsNames.exchangeStatus : '',
        width: '3%',
        order: true,
        isFilter: true,
        isPrint: true,
      },
      {
        name: 'exchangePrice',
        text: columnsNames && 'exchangePrice' in columnsNames ? columnsNames.exchangePrice : '',
        width: '3%',
        order: false,
        isPrint: true,
      },
      {
        name: 'reexecute',
        text: columnsNames && 'reexecute' in columnsNames ? columnsNames.reexecute : '',
        width: '5%',
        order: false,
        isPrint: false,
      },
      {
        name: 'createdAt',
        text: columnsNames && 'createdAt' in columnsNames ? columnsNames.createdAt : '',
        width: '8%',
        order: sort && 'createdAt' in sort ? sort.createdAt : true,
        isPrint: true,
      },
      {
        name: 'receivedAt',
        text: columnsNames && 'receivedAt' in columnsNames ? columnsNames.receivedAt : '',
        width: '8%',
        order: true,
        isPrint: true,
      },
      {
        name: 'confirmedAt',
        text: columnsNames && 'confirmedAt' in columnsNames ? columnsNames.confirmedAt : '',
        width: '8%',
        order: true,
        isPrint: true,
      },
      {
        name: 'events',
        text: columnsNames && 'events' in columnsNames ? columnsNames.events : '',
        width: '5%',
        order: false,
        isPrint: false,
      },
    ],
    lines: [],
    linesPrint: [],
  };

  table.headers = table.headers.filter((_, index) => {
    const indexesForRemove = isInternal ? [] : [1, 2, 6, 9];
    return indexesForRemove.indexOf(index) < 0;
  });

  if (list && list.data && list.data.length > 0) {
    for (let i = 0; i < list.data.length; i++) {
      const createdAt = new Date(list.data[i].createdAt);
      const receivedAt = list.data[i].receivedAt ? (new Date(list.data[i].receivedAt)).toLocaleString('pt-BR') : '-';
      const confirmedAt = list.data[i].confirmedAt ? (new Date(list.data[i].confirmedAt)).toLocaleString('pt-BR') : '-';

      const id = { type: 'id', text: list.data[i]._id };
      const amount = currency(list.data[i].amount || 0, 8, `${list.data[i].crypto} `);
      const exchangePrice = list.data[i].exchangePrice ? currency(list.data[i].exchangePrice, 8) : '-';

      const showResendForWalletButton = ['CONFIRMED'].includes(list.data[i].status) &&
        !list.data[i].transferTxId &&
        list.data[i].crypto === 'BTCB';

      let resendForWalletButton = {
        text: '-',
        textAlign: 'center',
      };

      if (showResendForWalletButton) {
        resendForWalletButton = {
          name: 'resend-preview-' + list.data[i]._id,
          type: 'button',
          text: '',
          hover: true,
          icon: 'fas fa-sync-alt',
          color: 'primary',
          textAlign: 'center',
          value: {
            action: 'resend',
            data: list.data[i],
          },
          disabled: !showResendForWalletButton,
        };
      }

      const invoiceView = {
        name: 'invoice-preview-' + list.data[i]._id,
        type: 'button-text',
        text: '',
        icon: 'fas fa-eye',
        color: '#348fe2',
        textAlign: 'center',
        value: {
          action: 'invoice',
          data: list.data[i],
        },
      };

      const testnet = config.testnet || false;
      const baseBlockcypherUrl = testnet ? 'https://live.blockcypher.com/btc-testnet' : 'https://live.blockcypher.com/btc';
      const baseBscscanUrl = testnet ? 'https://testnet.bscscan.com' : 'https://bscscan.com';

      const getTxIdUrl = (txId) => {
        if (list.data[i].crypto === 'BTCB' && txId) {
          return `${baseBscscanUrl}/tx/${txId}`;
        }
        return txId ? `${baseBlockcypherUrl}/tx/${txId}` : '';
      };

      const getAddressUrl = (address) => {
        if (list.data[i].crypto === 'BTCB') {
          return `${baseBscscanUrl}/address/${address}`;
        }
        return `${baseBlockcypherUrl}/address/${address}`;
      };

      const transferTxId = list.data[i].transferTxId ? {
        type: 'resumed-link',
        text: list.data[i].transferTxId,
        href: getTxIdUrl(list.data[i].transferTxId),
      } : '-';

      const transferFeeTxId = list.data[i].transferFeeTxId ? {
        type: 'resumed-link',
        text: list.data[i].transferFeeTxId,
        href: getTxIdUrl(list.data[i].transferFeeTxId),
      } : '-';

      const address = list.data[i].address ? {
        type: 'resumed-link',
        text: list.data[i].address,
        size: 10,
        href: getAddressUrl(list.data[i].address),
      } : '-';

      const eventsView = list.data[i].events && list.data[i].events.length > 0 ? {
        name: 'invoice-preview-' + list.data[i]._id,
        type: 'button-text',
        text: '',
        icon: 'fas fa-eye',
        color: '#348fe2',
        textAlign: 'center',
        value: {
          action: 'events',
          data: list.data[i],
        },
      } : '';

      let lines = [
        id,
        list.data[i].kind || '-',
        list.data[i].network || '-',
        list.data[i].crypto || '-',
        amount,
        address,
        invoiceView,
        transferTxId,
        transferFeeTxId,
        list.data[i].confirmations || '-',
        list.data[i].exchangeStatus || '-',
        exchangePrice,
        resendForWalletButton,
        receivedAt,
        createdAt.toLocaleString('pt-BR'),
        confirmedAt,
        eventsView,
      ];

      let linesPrint = [
        list.data[i]._id,
        list.data[i].kind || '-',
        list.data[i].network || '-',
        list.data[i].crypto || '-',
        amount || '-',
        list.data[i].address || '-',
        list.data[i].transferTxId || '-',
        transferFeeTxId || '-',
        list.data[i].confirmations || '-',
        list.data[i].exchangeStatus || '-',
        exchangePrice,
        receivedAt,
        createdAt.toLocaleString('pt-BR'),
        confirmedAt,
      ];

      lines = lines.filter((_, index) => {
        const indexesForRemove = isInternal ? [] : [1, 2, 6, 9];
        return indexesForRemove.indexOf(index) < 0;
      });

      linesPrint = linesPrint.filter((_, index) => {
        const indexesForRemove = isInternal ? [] : [1, 2];
        return indexesForRemove.indexOf(index) < 0;
      });

      table.lines.push(lines);
      table.linesPrint.push(linesPrint);
    }
  }

  return table;
};

export default formatValue;
