export default {
  SET_SESSION (state, payload) {
    state.session = payload;
  },
  SET_USER (state, response) {
    state.user = response;
  },
  SET_IS_LOGGED (state, isLogged) {
    state.isLogged = isLogged;
  },
  SET_USER_IMAGE (state, url) {
    state.image = url;
  },
};
