import SpreadsheetService from './SpreadsheetService';
import http from './HttpService';
import moment from 'moment';
import { validStatus } from '@/model/Invoice';

export default {
  async getInvoiceList ({ filters, customFilters, search, limit, page, orderBy }) {
    try {
      let url = `/api/v1/invoices?page=${page}&limit=${limit}`;
      if (!page || limit === 0) {
        url = `/api/v1/invoices?limit=${limit}`;
      }
      if (filters && filters.length > 0) {
        const filtersQuery = JSON.stringify({
          field: 'status',
          operation: 'in',
          value: filters,
        });
        url += `&filters[]=${filtersQuery}`;
      }

      const liked = ['email', 'items.billet.digitableLine'];

      if (search && search.value && search.value !== '') {
        const filterSearch = JSON.stringify({
          field: search.field,
          operation: liked.indexOf(search.field) >= 0 ? 'like' : '=',
          value: search.value,
        });
        url += `&filters[]=${filterSearch}`;
      }

      if (orderBy) {
        const field = Object.keys(orderBy)[0];
        url += `&orderBy=${field}&orderByDirection=${orderBy[field] || 'desc'}`;
      }

      if (customFilters) {
        Object.keys(customFilters).forEach(key => {
          url += `&${key}=${customFilters[key]}`;
        });
      }

      const response = await http().get(url);
      return (
        response.data || {
          data: [],
          meta: {},
        }
      );
    } catch (error) {
      return error;
    }
  },

  async getInvoiceDetail (id, status) {
    try {
      const res = await http().get(`/api/v1/invoices/${id}`, status);
      return res.data;
    } catch (error) {
      return error;
    }
  },

  async getInvoicesForDeclaration (fullDate) {
    const firstDay = moment(fullDate, 'MM/YYYY').startOf('month');
    const lastDay = moment(fullDate, 'MM/YYYY').endOf('month');
    const queryFilters = [
      { field: 'createdAt', operation: '>=', value: firstDay.toDate() },
      { field: 'createdAt', operation: '<', value: lastDay.toDate() },
      { field: 'status', operation: '=', value: validStatus.FINISHED },
    ];

    let url = '/api/v1/invoices?limit=0';
    queryFilters.forEach(filter => {
      url += `&filters[]=${JSON.stringify(filter)}`;
    });
    const response = await http().get(url);
    return response.data;
  },

  async generateSpreadsheet (filters) {
    const queryFilters = [
      { field: 'items.kind', operation: 'in', value: filters.kinds },
      { field: 'items.status', operation: 'in', value: filters.status },
    ];
    const optionalFilters = {
      userEmail: { field: 'email', operation: 'like', value: filters.userEmail },
      orderId: { field: '_id', operation: '=', value: filters.orderId },
      from: { field: 'createdAt', operation: '>=', value: moment(filters.from).startOf('day').format() },
      to: { field: 'createdAt', operation: '<=', value: moment(filters.to).endOf('day').format() },
    };

    for (const key in optionalFilters) {
      if (filters[key]) {
        queryFilters.push(optionalFilters[key]);
      }
    };

    let url = '/api/v1/invoices?limit=0&populate[]=userId&populate[]=orders&populate[]=referrals';
    queryFilters.forEach(filter => {
      url += `&filters[]=${JSON.stringify(filter)}`;
    });

    const invoices = await http().get(url);

    const invoicesData = invoices.data && invoices.data.data ? invoices.data.data : [];
    if (invoicesData.length <= 0) {
      return false;
    }

    const spreadsheetData = [];
    invoicesData.forEach(invoice => {
      const ticker = Number(invoice.cryptoPrice);
      const baseTicker = Number(ticker - (ticker * (Number(invoice.spread || '0') / 100))).toFixed(2);

      const execution = (invoice.orders || []).reduce((ac, order) => {
        const value = Number(order.amountExecuted) * Number(order.priceExecuted);
        return {
          amount: ac.amount + Number(order.amountExecuted),
          value: ac.value + value,
          fee: ac.fee + Number(order.fee || 0),
        };
      }, { amount: 0, value: 0, fee: 0 });
      const executionPrice = (execution.amount > 0) ? execution.value / execution.amount : 0;

      const referralValue = (invoice.referrals || []).reduce((ac, referral) => {
        return ac + Number(referral.value);
      }, 0);

      invoice.items.forEach(item => {
        if (filters.kinds.includes(item.kind) && filters.status.includes(item.status)) {
          // let itemDetail = '';
          let itemKind = '';
          switch (item.kind) {
            case 'BILLET':
              // itemDetail = item.billet.digitableLine;
              itemKind = 'Boleto';
              break;
            case 'SHIPMENT':
              // itemDetail = `${item.shipment.bank} - AG ${item.shipment.agency} ${item.shipment.kind}${item.shipment.account}`;
              itemKind = 'Conversão de Bitcoin';
              break;
            case 'RECHARGE':
              // itemDetail = `(${item.recharge.ddd}) ${item.recharge.phone} (${item.recharge.operator})`;
              itemKind = 'Recarga de Celular';
              break;
            case 'OPEY':
              // itemDetail = item.opey.document;
              itemKind = 'Recarga Opey';
              break;
            case 'GIFTCARD':
              // itemDetail = `${item.giftCard.card}`;
              itemKind = 'GiftCard';
              break;
            case 'PIX':
              // itemDetail = `${item.pix.key}`;
              itemKind = 'PIX';
              break;
          }

          const itemValue = Number(item.amount) + Number(item.fee);
          spreadsheetData.push({
            Data: moment(invoice.createdAt).format('DD/MM/YYYY'),
            Pedido: invoice._id,
            CPF: invoice.user.document,
            Nome: invoice.user.name,
            Cliente: invoice.email,
            Tipo: itemKind,
            Status: item.status,
            'Status do Pedido': invoice.status,
            Valor: Number(item.amount),
            Taxa: Number(item.fee),
            'Valor + Taxa': itemValue,
            'Valor em BTC': itemValue / ticker,
            'Valor da Indicação': Number(invoice.fiatAmountPaid) > 0 ? (referralValue * itemValue) / Number(invoice.fiatAmountPaid) : 0,
            'Taxa Execução': Number(invoice.fiatAmountPaid) > 0 ? (execution.fee * itemValue) / Number(invoice.fiatAmountPaid) : 0,
            'Cotação Base': Number(baseTicker),
            'Cotação Final': ticker,
            'Cotação Média Executada': executionPrice,
            Rede: invoice.network || '',
            Origem: invoice.source && invoice.source.name ? invoice.source.name : 'SITE',
            // 'Dado do serviço': itemDetail,
          });
        }
      });
    });

    const colSizes = [10, 24, 15, 30, 25, 15, 10, 15, 12, 12, 15, 15, 15, 12, 12, 12, 12, 12, 12, 40];
    await SpreadsheetService.download(spreadsheetData, colSizes, 'Relatório', 'relatorio');
    return true;
  },

  async sendReversal (id, data) {
    const res = await http().patch(`/api/v1/invoices/${id}/reversal`, data);
    return res.data;
  },

  async editItem (id, itemId, data) {
    const res = await http().put(`/api/v1/invoices/${id}/item/${itemId}`, data);
    return res.data;
  },

  async uploadReceipt (id, itemId, formData, receiptNumber) {
    const urlParam = receiptNumber ? `?receiptNumber=${receiptNumber}` : '';
    const url = `/api/v1/invoices/${id}/item/${itemId}/receipt${urlParam}`;
    const res = await http().post(url, formData);
    return res.data;
  },

  async downloadReceiptForProvider (id, itemId) {
    const res = await http().patch(`/api/v1/invoices/${id}/items/${itemId}/download-receipt`);
    return res.data;
  },

  async finishGiftCard (invoiceId, itemId, data) {
    const url = `/api/v1/invoices/${invoiceId}/item/${itemId}/finish`;
    const res = await http().post(url, data);
    return res.data;
  },

  async updateInvoiceDescription (id, description) {
    const url = `/api/v1/invoices/${id}/description`;
    const res = await http().patch(url, description);
    return res.data;
  },

  async approveExpired (id) {
    const url = `/api/v1/invoices/${id}/approve-expired`;
    const res = await http().patch(url);
    return res.data;
  },

  async approveForAutoExecutableInvoice (id) {
    const url = `/api/v1/invoices/${id}/approve-pending-executable`;
    const res = await http().patch(url);
    return res.data;
  },

  async emitBills (ids) {
    const url = '/api/v1/invoices/bills';
    const res = await http().post(url, { invoices: ids });
    return res.data;
  },

  async reprocessItem (invoiceId, itemId) {
    const url = `/api/v1/invoices/${invoiceId}/items/${itemId}/reprocess`;
    const res = await http().patch(url, {});
    return res.data;
  },

  async processItem (invoiceId, itemId) {
    const url = `/api/v1/invoices/${invoiceId}/items/${itemId}/process`;
    const res = await http().patch(url, {});
    return res.data;
  },

  async verifyTransactions (invoiceId) {
    const url = `/api/v1/invoices/${invoiceId}/process/`;
    const res = await http().patch(url, {});
    return res.data;
  },

  async listStatistics () {
    const res = await http().get('/api/v1/invoices/statistics');
    return res.data;
  },
};
