export const getState = () => ({
  sidebarMinified: false,
  list: [],
  listUsersStatistics: {
    status: {},
  },
  listPrint: [],
  listSelect: [],
  listPlayerSelect: [],
  userKind: {
    F: {},
    J: {},
  },
  listFilters: [],
  listSearch: {},
  listSort: { createdAt: 'desc' },
  listPaginate: {
    total: 0,
    perPage: 10,
    currentPage: 1,
    totalPages: 1,
    hasNextPage: false,
    hasPrevPage: false,
  },
});

export default Object.assign(getState(), { getState: getState });
