<template>
  <div>
    <div class="menu">
      <ul class="nav">
        <li class="nav-header">{{ title }}</li>
        <router-link tag='li' exact-active-class="active" class="has-sub" v-for="item in itemsWithPermission" :key="item.name" :to="{ name: item.route }">
          <a href="#" >
            <b class="caret" v-if="hasChildren(item)"></b>
            <i class="fa" :class="item.icon"></i>
            <span>{{item.name}}</span>
          </a>
          <ul class="sub-menu" v-if="hasChildren(item)">
            <router-link tag='li' :to="{ name: subitem.route }" class-active="active" v-for="subitem in item.children" :key="subitem.name">
              <a>{{subitem.name}}</a>
            </router-link>
          </ul>
        </router-link>
        <li>
          <a href="#" class="sidebar-minify-btn" @click.prevent="toggleMinified()">
            <i class="fa fa-angle-double-left"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>

</template>

<script>
import { mapState } from 'vuex';
import AuthService from '@/services/AuthService';

export default {
  name: 'GlobalMenu',
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState({
      authUser: state => (state.auth.user ? state.auth.user : []),
    }),
    itemsWithPermission () {
      return this.items.filter((item) => this.checkPermission(item.route));
    },
  },
  methods: {
    toggleMinified () {
      this.$emit('toggle-sidebar-minified');
    },

    hasChildren (item) {
      return item.children && item.children.length > 0;
    },

    checkPermission (route) {
      let routePermission = [];
      this.$router.options.routes.forEach(routeItem => {
        if (routeItem.name === route) {
          routePermission = routeItem.meta ? routeItem.meta.permission : [];
        }
      });
      if (this.authUser.roles !== undefined) {
        return AuthService.checkPermission(
          this.authUser.roles,
          routePermission,
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>
