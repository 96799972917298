<template>
  <div class="ct-alert-msg" :class="['alert-color-' + color]">
    <span>
      {{ message }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'AlertMessage',
  props: {
    message: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'red',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/themes/color-admin/_ct_variable.scss';
  .ct-alert-msg{
    color: white;
    border-radius: 3px;
    padding: 10px;
    margin: 10px 0;
    font-size: 14px;
    &:before {
        content: '\f06a';
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        font-size: 18px;
        margin-right: 5px;
    }

     &[class*='alert-color']{
       &[class*='red']{
         background: $red_darker;
       }
       &[class*='ct-color']{
         background: $ct_color_darker;
       }
       &[class*='blue']{
         background: $blue_darker;
       }
        &[class*='aqua']{
         background: $aqua_darker;
       }
        &[class*='green']{
         background: $green_darker;
       }
       &[class*='orange']{
         background: $orange_darker;
       }
       &[class*='black']{
         background: $black_darker;
       }
       &[class*='silver']{
         background: $silver_darker;
       }
      &[class*='grey']{
         background: $grey_darker;
       }
       &[class*='purple']{
         background: $purple_darker;
       }
       &[class*='lime']{
         background: $lime_darker;
       }
       &[class*='pink']{
         background: $pink_darker;
       }
       &[class*='indigo']{
         background: $indigo_darker;
       }
       &[class*='yellow']{
         background: $yellow_darker;
       }
       &[class*='teal']{
         background: $teal_darker;
       }
    }
  }
</style>
