export default {
  SET_LIST_SETTINGS (state, list) {
    state.listSettings = list;
  },
  SET_LIST_SETTINGS_PRINT (state, list) {
    state.listSettingsPrint = list;
  },
  SET_LIST_SETTINGS_FOR_SELECT (state, list) {
    state.listSettingsSelect = list;
  },
  SET_SETTING_LIST_PAGE (state, currentPage) {
    state.listSettingsPaginate.currentPage = currentPage;
  },
  SET_SETTING_LIST_TOTAL_PAGES (state, totalPages) {
    state.listSettingsPaginate.totalPages = totalPages;
  },
  ADD_SETTING_LIST_FILTER (state, filters) {
    if (state.listSettingsFilters.indexOf(filters) === -1) {
      state.listSettingsFilters.push(filters);
    }
  },
  REMOVE_SETTING_LIST_FILTER (state, filters) {
    if (state.listSettingsFilters.indexOf(filters) !== -1) {
      state.listSettingsFilters.splice(state.listSettingsFilters.indexOf(filters), 1);
    }
  },
  SET_SETTING_LIST_SEARCH (state, search) {
    state.listSettingsSearch = search;
  },
  SET_SETTING_LIST_SORT (state, header) {
    const newSort = {};

    if (state.listSettingsSort.constructor === Object && header && header.name) {
      if (!(header.name in state.listSettingsSort)) {
        newSort[header.name] = 'asc';
      } else {
        if (state.listSettingsSort[header.name] === 'asc') {
          newSort[header.name] = 'desc';
        }
        if (state.listSettingsSort[header.name] === 'desc') {
          newSort[header.name] = 'asc';
        }
      }
      state.listSettingsSort = newSort;
    }
  },
  SET_LIST_SETTINGS_STATISTICS (state, data) {
    state.listSettingsStatistics = data;
  },
  SET_CURRENT_SETTINGS (state, payload) {
    state.currentSettings = payload;
  },
};
