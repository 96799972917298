import axios from 'axios';
import store from './../store/store';

const createAxios = () => {
  const session = store.state.auth.session;

  const options = {
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (session.token) {
    options.headers['x-access-token'] = session.token;
  }

  return axios.create(options);
};

export default createAxios;
