<template>
  <div class="row ct-bank-data-content">
    <div class="col-12 col-sm-12 col-md-12 ct-page-header">
      <h1 class="page-header">
        {{ $t('invoices.modalBankData.title') }}
        <small>
          {{ invoiceId || '' }}
          <i class="far fa-copy"
             @click="copyToClipboard(invoiceId || '')"
             :title="$t('invoices.details.copyTitle')"
             v-b-tooltip.hover
          >
          </i>
        </small>
      </h1>
    </div>
    <div class="col-12 col-sm-12 col-md-12">
      <table class="table table-accounts">
        <thead>
          <tr>
            <th width="50%" class="text-left">{{ $t('invoices.modalBankData.description') }}</th>
            <th width="50%" class="text-right">{{ $t('invoices.modalBankData.value') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-left">{{ $t('invoices.modalBankData.isThirdParty') }}</td>
            <td class="text-right">
              {{
                bankData.isThirdParty ?
                $t('invoices.modalBankData.isThirdPartyYes') :
                $t('invoices.modalBankData.isThirdPartyNo')
              }}
            </td>
          </tr>
          <tr>
            <td class="text-left">{{ $t('invoices.modalBankData.name') }}</td>
            <td class="text-right">
              <i class="far fa-copy"
                @click="copyToClipboard(bankData.name || '')"
                :title="$t('invoices.details.copyTitle')"
                v-b-tooltip.hover
              >
              </i>
              {{ bankData.name || '-' }}
            </td>
          </tr>
          <tr v-if="bankData.key">
            <td class="text-left">{{ $t('invoices.modalBankData.key') }}</td>
            <td class="text-right">
              <i class="far fa-copy" v-if="bankData.key"
                @click="copyToClipboard(bankData.key || '')"
                :title="$t('invoices.details.copyTitle')"
                v-b-tooltip.hover
              >
              </i>
              {{ bankData.key || '-' }}
            </td>
          </tr>
          <tr v-if="bankData.brcode">
            <td class="text-left">{{ $t('invoices.modalBankData.brcode') }}</td>
            <td class="text-right">
              <i class="far fa-copy" v-if="bankData.brcode"
                @click="copyToClipboard(bankData.brcode || '')"
                :title="$t('invoices.details.copyTitle')"
                v-b-tooltip.hover
              >
              </i>
              {{ bankData.brcode || '-' }}
            </td>
          </tr>
          <tr>
            <td class="text-left">{{ $t('invoices.modalBankData.document') }}</td>
            <td class="text-right">
              <i class="far fa-copy"
                @click="copyToClipboard(bankData.document || '')"
                :title="$t('invoices.details.copyTitle')"
                v-b-tooltip.hover
              >
              </i>
              {{ bankData.document || '-' }}
            </td>
          </tr>
          <tr>
            <td class="text-left">{{ $t('invoices.modalBankData.bankName') }}</td>
            <td class="text-right">{{ bankData.bank ? getBankName(bankData.bank) : '-' }}</td>
          </tr>
          <tr>
            <td class="text-left">{{ $t('invoices.modalBankData.kindAccount') }}</td>
            <td class="text-right">{{ bankData.kind ? $t('invoices.modalBankData.kindAccount' + bankData.kind) : '-' }}</td>
          </tr>
          <tr>
            <td class="text-left">{{ $t('invoices.modalBankData.agency') }}</td>
            <td class="text-right">
              <i class="far fa-copy" v-if="bankData.agency"
                @click="copyToClipboard(bankData.agency || '')"
                :title="$t('invoices.details.copyTitle')"
                v-b-tooltip.hover
              >
              </i>
              {{ bankData.agency || '-' }}
            </td>
          </tr>
          <tr>
            <td class="text-left">{{ $t('invoices.modalBankData.account') }}</td>
            <td class="text-right" >
              <i class="far fa-copy" v-if="bankData.account"
                @click="copyToClipboard(bankData.account || '')"
                :title="$t('invoices.details.copyTitle')"
                v-b-tooltip.hover
              >
              </i>
              {{ bankData.account || '-' }}
            </td>
          </tr>
          <tr>
            <td class="text-left">{{ $t('invoices.modalBankData.value') }}</td>
            <td class="text-right">
              <i class="far fa-copy"
                @click="() => copyToClipboard(this.$options.filters.currency(bankData.amount || 0))"
                :title="$t('invoices.details.copyTitle')"
                v-b-tooltip.hover
              >
              </i>
              R$ {{ (bankData.amount || 0) | currency }}
            </td>
          </tr>
          <tr v-if="bankData.isThirdParty">
            <td class="text-left">{{ $t('invoices.modalBankData.thirdPartyEmail') }}</td>
            <td class="text-right">
              <i class="far fa-copy"
                @click="copyToClipboard(bankData.thirdPartyEmail || '')"
                :title="$t('invoices.details.copyTitle')"
                v-b-tooltip.hover
              >
              </i>
              {{ bankData.thirdPartyEmail || '-' }}
            </td>
          </tr>
          <tr v-if="bankData.isThirdParty">
            <td class="text-left">{{ $t('invoices.modalBankData.thirdPartyPurpose') }}</td>
            <td class="text-right">
              <i class="far fa-copy"
                @click="copyToClipboard(bankData.thirdPartyPurpose || '')"
                :title="$t('invoices.details.copyTitle')"
                v-b-tooltip.hover
              >
              </i>
              {{ bankData.thirdPartyPurpose || '-' }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col-12 col-sm-6">

        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group pull-right form-inline">
            <button
              class="btn btn-default btn-lg"
              type="submit"
              @click="close"
            >{{$t('invoices.modalBankData.close')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import currency from '@/filters/currency';

export default {
  filters: {
    currency,
  },
  props: {
    invoiceId: {
      type: String,
    },
    bankData: {
      type: Object,
    },
    banks: {
      type: Array,
    },
  },
  methods: {
    close () {
      this.$emit('close');
    },
    getBankName (bank) {
      if (!this.banks) return '-';

      const bankName = this.banks.filter(item => {
        return item.value === bank;
      });
      return bankName[0] && bankName[0].label
        ? bankName[0].label
        : '-';
    },
    copyToClipboard (value) {
      this.$emit('copy', value);
    },
  },
  created () {
  },
};
</script>
<style lang="scss">
.ct-bank-data-content {
  padding: 5px;
}
.ct-page-header {
  margin-bottom: 15px;
  border-bottom: 1px solid #e1e7ec;
}
.fa-copy {
  cursor: pointer;
  font-size: 1.2em;
  color: #707478;
}
.tooltip.show {
  z-index: 9999999 !important;
}
</style>
