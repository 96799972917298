<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import CtHeader from '@/components/Dashboard/Header.vue';
import CtSidebar from '@/components/Dashboard/Sidebar.vue';

export default {
  name: 'App',
  components: {
    CtHeader,
    CtSidebar,
  },
  props: {},
  computed: {
    ...mapState({
      sidebarMinified: (state) => state.dashboard.sidebarMinified,
      sidebarMobile: state => state.dashboard.sidebarMobile,
      userLang: state => state.auth.user.language,
    }),
    ...mapGetters(['isLogged']),
  },
  methods: {
    ...mapActions(['toggleSidebarMinified', 'toggleSidebarMobile', 'getCurrentSettings']),
  },
  data () {
    return {};
  },
  beforeMount () {
    // const locale = (this.userLang || navigator.language || navigator.userLanguage || 'pt').substr(0, 2);
    const locale = 'pt';
    if (this.$i18n && this.$i18n.locale !== locale) {
      this.$i18n.locale = locale;
    }
  },
  async created () {
    await this.getCurrentSettings();
  },
};
</script>

<template>
  <div id="app" class="page-container fade page-header-fixed show" :class="{'page-sidebar-minified': sidebarMinified && isLogged, 'fade in page-sidebar-fixed page-header-fixed show page-sidebar-toggled': sidebarMobile && isLogged}">
    <ct-header id="header" @mobile-sidebar-toggled="toggleSidebarMobile" v-if="isLogged"/>
    <ct-sidebar @toggle-sidebar-minified="toggleSidebarMinified" v-if="isLogged" />
    <notifications position="right" group="" class="ct-notifications"/>
    <router-view/>
  </div>
</template>

<style lang="scss">
@import '~bootstrap/dist/css/bootstrap.css';
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import 'themes/color-admin/styles.scss';
body {
  background-color: #d9e0e7;
  .btn {
    margin: 0 5px;
    &.btn-block {
      margin: 0;
    }
  }
  .is-invalid {
    .invalid-feedback {
      display: block;
    }
  }
}
</style>
