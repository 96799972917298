<template>
  <section>
    <div class="col-md-12 ct-margin-top-20">
      <h3>
        {{ $t('invoices.details.wallet.title') }}
      </h3>
    </div>
    <div class="table-responsive body-table">
      <table class="table table-wallets">
        <thead>
          <tr>
            <th width="5%">{{ $t('invoices.details.wallet.type') }}</th>
            <th width="10%">{{ $t('invoices.details.wallet.id') }}</th>
            <th width="10%">{{ $t('invoices.details.wallet.crypto') }}</th>
            <th width="15%">{{ $t('invoices.details.wallet.txid') }}</th>
            <th width="25%">{{ $t('invoices.details.wallet.address') }}</th>
            <th width="5%" class="text-center">{{ $t('invoices.details.wallet.confirm') }}</th>
            <th width="18%" class="text-right">{{ $t('invoices.details.wallet.quantity') }}</th>
            <th width="10%" class="text-center" v-if="isInternal && invoice.crypto === 'BTCB'">
              {{ $t('invoices.details.wallet.transfer') }}
            </th>
            <th width="10%" class="text-right" v-if="isInternal">
              {{ $t('invoices.details.wallet.exchangeStatus') }}
            </th>
            <th width="20%" class="text-right" v-if="isInternal">
              {{ $t('invoices.details.wallet.actions.title') }}
            </th>
          </tr>
        </thead>
        <tbody v-if="invoice.transactions">
          <tr v-for="(item, index) in invoice.transactions" :key="index">
            <td><i class="fas " :class="{'fa-minus': item.kind === 'C', 'fa-plus': item.kind === 'D'}"></i></td>
            <td><ct-cell-id :text="item._id" /></td>
            <td>{{ item.crypto }}</td>
            <td>
              <a :href="getTxIdUrl(item)" target="_blank" v-if="item.txId">
                {{ item.txId ? item.txId.slice(-20) : '-' }}
              </a>
            </td>
            <td>
              <a :href="getAddressUrl(item)" target="_blank">
                {{ item.address }}
              </a>
            </td>
            <td class="text-center">{{ item.confirmations }}</td>
            <td class="text-right">{{ item.amount ? item.amount.toFixed(8) : '-' }}</td>
            <td class="text-center action-col" v-if="isInternal && invoice.crypto === 'BTCB' ">
              <a class="btn" :href="getTransferBalanceUrl(item)" target="_blank" v-if="item.transferTxId && item.transferTxId !== 'no-balance'">
                <i class="fas fa-random"></i>
              </a>
              <span v-else>-</span>
            </td>
            <td class="text-right">
              {{ item.exchangeStatus }}
            </td>
            <td class="text-right action-col" v-if="isInternal">
              <button
                v-if="isInternal && canEditTransaction(item)"
                class="btn btn-xs btn-primary"
                @click.prevent="() => editTransaction(item)"
                :title="$t('invoices.details.wallet.actions.editTransaction')"
                v-b-tooltip.hover.top
                :disabled="editSaving"
              >
                <i class="fas fa-spinner fa-spin" v-if="editSaving"></i>
                <i class="fa fa-edit" v-else></i>
              </button>

              <button
                v-if="isInternal && canReleaseTransaction(item)"
                class="btn btn-xs btn-primary"
                @click.prevent="() => releaseTransaction(item)"
                :title="$t('invoices.details.wallet.actions.releaseTransaction')"
                v-b-tooltip.hover.top
              >
                <i class="far fa-share-square"></i>
              </button>

              <button
                v-if="isInternal"
                class="btn btn-xs btn-default"
                @click.prevent="verifyTransactions(item)"
                :disabled="!canVerifyTransactions()"
                :title="$t('invoices.details.wallet.actions.verifyTransactions.labelItem')"
                v-b-tooltip.hover.top

              >
                <i class="fas fa-sync-alt"></i>
              </button>
              <button
                v-if="isInternal"
                class="btn btn-xs btn-default"
                @click.prevent="() => showOrders(item)"
                :disabled="!canShowOrders(item)"
                :title="$t('invoices.details.wallet.actions.showTransactionOrders')"
                v-b-tooltip.hover.top
              >
                <i class="fas fa-list"></i>
              </button>

            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7">{{ $t('invoices.details.wallet.notFound') }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <ct-modal-view
      :title="$t('invoices.details.wallet.ordersTitle')"
      @close="() => toogleModalOrders()"
      :show="showModalOrders"
    >
      <ct-transaction-orders v-if="showModalOrders" slot="body" :transaction="currentTransaction"></ct-transaction-orders>
    </ct-modal-view>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import CtCellId from '@/components/Shared/DataGrid/CellId.vue';
import { isDefinitiveStatus } from '@/model/Invoice';
import TransactionService from '@/services/TransactionService';
import CtModalView from '@/components/Shared/ModalView.vue';
import CtTransactionOrders from '@/components/Invoices/InvoiceItems/TransactionOrders.vue';
import config from '@/config';

export default {
  name: 'Transactions',
  props: {
    invoice: {
      type: Object,
      required: true,
    },

  },
  components: {
    CtCellId, CtModalView, CtTransactionOrders,
  },
  data: () => {
    return {
      currentTransaction: {},
      showModalOrders: false,
      editSaving: false,
    };
  },
  computed: {
    ...mapGetters([
      'isInternal',
    ]),
    testnet () {
      return config.testnet || false;
    },
    baseBlockcypherUrl () {
      return this.testnet ? 'https://live.blockcypher.com/btc-testnet' : 'https://live.blockcypher.com/btc';
    },
    baseBscscanUrl () {
      return this.testnet ? 'https://testnet.bscscan.com' : 'https://bscscan.com';
    },
  },
  methods: {
    canVerifyTransactions () {
      return !isDefinitiveStatus(this.invoice.status);
    },

    canShowOrders (transaction) {
      return transaction.exchangeStatus !== 'OPEN';
    },

    canEditTransaction (transaction) {
      if (transaction.status !== 'OPEN') return false;
      if (transaction.txId) return false;
      return true;
    },

    canReleaseTransaction (transaction) {
      if (transaction.exchangePrice <= 0 || !transaction.txId) return false;
      if (transaction.exchangeStatus === 'ERROR') return true;
      if (transaction.exchangeReleased) return false;

      return transaction.exchangeStatus === 'OPEN';
    },

    async editTransaction (transaction) {
      try {
        const { dismiss, value } = await this.$alert({
          input: 'text',
          inputPlaceholder: 'TxId',
          title: 'Insira o TxId que deve ser vinculado a transação',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Salvar',
          cancelButtonText: 'Fechar',
          confirmButtonColor: '#ffc107',
          reverseButtons: true,
        });

        if (dismiss && dismiss === 'cancel') {
          return { confirmed: false };
        }

        if (!value || value.length < 10) {
          await this.$notify({
            type: 'error',
            title: 'TxId Inválido ou não encontrado',
          });
          return { confirmed: false };
        }

        this.editSaving = true;
        const response = await TransactionService.updateTransaction(transaction._id, { txId: value });
        if (response && response.success) {
          transaction.status = response.data.status;
          transaction.txId = response.data.txId;
          transaction.confirmations = response.data.confirmations;
          transaction.amount = Number(response.data.amount);
          this.$notify({
            type: 'success',
            title: 'Transação atualizada com sucesso',
          });
          this.editSaving = false;
          return { confirmed: true };
        }

        this.$notify({
          type: 'error',
          title: response.message || 'Error ao atualizar a transação',
        });

        this.editSaving = false;
        return {
          confirmed: false,
          value: value,
        };
      } catch (e) {
        this.editSaving = false;
        this.$notify({
          type: 'error',
          title: e.message || 'Error ao atualizar a transação',
        });
        console.error(e);

        return {
          confirmed: false,
        };
      }
    },

    showOrders (item) {
      this.currentTransaction = item;
      this.toogleModalOrders();
    },

    toogleModalOrders () {
      this.showModalOrders = !this.showModalOrders;
    },

    async verifyTransactions () {
      this.$emit('verifyTransactions');
    },

    async releaseTransaction (transaction) {
      const response = await TransactionService.releaseTransaction(transaction._id);
      if (response.success) {
        transaction.exchangeReleased = true;
        transaction.exchangeStatus = 'OPEN';
        return this.$notify({
          type: 'success',
          title: this.$t('invoices.details.wallet.actions.successReleased'),
        });
      }

      return this.$notify({
        type: 'error',
        title: response.message || this.$t('invoices.details.wallet.actions.errorReleased'),
      });
    },

    getTxIdUrl (item) {
      if (item.crypto === 'BTCB' && item.txId) {
        return `${this.baseBscscanUrl}/tx/${item.txId}`;
      }
      return item.txId ? `${this.baseBlockcypherUrl}/tx/${item.txId}` : '';
    },

    getAddressUrl (item) {
      if (item.crypto === 'BTCB') {
        return `${this.baseBscscanUrl}/address/${item.address}`;
      }
      return `${this.baseBlockcypherUrl}/address/${item.address}`;
    },

    getTransferBalanceUrl (item) {
      if (item.transferTxId) {
        return `${this.baseBscscanUrl}/tx/${item.transferTxId}`;
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  h3 {
    font-weight: 400;
    margin-left: -10px;
  }
  .ct-margin-top-20 {
    margin-top: 20px;
  }
  .action-col {
    min-width: 110px;
    padding: 0;
    text-align: right;
    button {
      margin-top: 6px;
      &:disabled {
        cursor: no-drop;
      }
    }
  }
}
</style>
