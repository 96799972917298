<template>
  <div class="datagrid-pagination">
    <ul class="pagination">
      <li class="paginate_button previous" :class="{ disabled: currentPage === 1 }">
        <a href="#" @click.prevent="jumpToPage(currentPage - 1)">{{ $t('paginate.previous') }}</a>
      </li>
      <li class="paginate_button" v-show="rangeFirstPage !== 1" :class="{ active: currentPage === 1 }">
        <a href="#" @click.prevent="jumpToPage(1)">1</a>
      </li>
      <li class="paginate_button" v-show="rangeFirstPage === 3" :class="{ active: currentPage === 1 }">
        <a href="#" @click.prevent="jumpToPage(2)">2</a>
      </li>
      <li class="paginate_button disabled" v-show="rangeFirstPage !== 1 && rangeFirstPage !== 2 && rangeFirstPage !== 3">
        <a href="#">...</a>
      </li>
      <li class="paginate_button"
          v-for="page in range"
          :key="page"
          :class="{ active: currentPage === page }"
      >
        <a href="#" @click.prevent="jumpToPage(page)">{{ page }}</a>
      </li>
      <li class="paginate_button disabled" v-if="rangeLastPage !== totalPages && rangeLastPage !== (totalPages - 1) && rangeLastPage !== (totalPages - 2)">
        <a href="#">...</a>
      </li>
      <li class="paginate_button" v-if="rangeLastPage === (totalPages - 2)">
        <a href="#" @click.prevent="jumpToPage(totalPages - 1)">{{ (totalPages - 1) }}</a>
      </li>
      <li class="paginate_button" v-if="rangeLastPage !== totalPages">
        <a href="#" @click.prevent="jumpToPage(totalPages)">{{ totalPages }}</a>
      </li>
      <li class="paginate_button next" :class="{ disabled: currentPage === totalPages }">
        <a href="#" @click.prevent="jumpToPage(currentPage + 1)" >{{ $t('paginate.next') }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
const rangeMax = 3;
export default {
  name: 'Pagination',

  props: {
    totalPages: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    rangeFirstPage () {
      if (this.currentPage === 1) {
        return 1;
      }

      if (this.currentPage === this.totalPages) {
        if ((this.totalPages - rangeMax) < 0) {
          return 1;
        } else {
          return this.totalPages - rangeMax + 1;
        }
      }

      return (this.currentPage - 1);
    },
    rangeLastPage () {
      return Math.min(this.rangeFirstPage + rangeMax - 1, this.totalPages);
    },
    range () {
      const rangeList = [];
      for (let page = this.rangeFirstPage; page <= this.rangeLastPage; page += 1) {
        rangeList.push(page);
      }
      return rangeList;
    },
    hasFirst () {
      return (this.currentPage === 1);
    },
    hasLast () {
      return (this.currentPage === this.totalPages);
    },
  },
  methods: {
    jumpToPage (page) {
      if (page === this.currentPage || page < 1 || page > this.totalPages) {
        return;
      }
      this.$emit('page', page);
    },
  },
};

</script>

<style lang="scss" scoped>
  .datagrid-pagination{
    margin: 0;
    white-space: nowrap;
    text-align: right;
  }
  .pagination {
    float: right;

  }
  .pagination > li > a {
    padding: 2px 8px;
  }
</style>
