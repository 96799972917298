<template>
<div class="alert-two-fa-component container">
  <div class="row">
    <div class="col-12">
      <i class="fas fa-exclamation-circle"></i>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <h2>{{ $t('rfReporter.enable2FA.title') }}</h2>
      <p>
        {{ $t('rfReporter.enable2FA.message') }}
        <router-link :to="{ name: 'profile'}">
          {{ $t('rfReporter.enable2FA.messageAction') }}
        </router-link>
        {{ $t('rfReporter.enable2FA.messageComplementary') }}
      </p>
      <button type="button" class="btn btn-primary" @click.prevent="$emit('close')" aria-label="">
        {{ $t('rfReporter.enable2FA.closeButton') }}
      </button>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'EnableTwoFa',
};
</script>

<style lang="scss" scoped>
.alert-two-fa-component {
  padding: 20px 20px;
  text-align: center;

  .fa-exclamation-circle {
    font-size: 60px;
  }
}

</style>
