<template>
  <div class="form-group button-switch-component">
    <label class="switch">
      <input
        type="checkbox"
        @input="onInput"
        :checked="value"
      />
      <span class="slider"></span>
    </label>
    <b>{{ label }}</b>
  </div>
</template>

<script>
export default {
  name: 'ButtonSwitch',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
  },
  methods: {
    onInput () {
      this.$emit('change');
    },
  },
};
</script>

<style lang="scss">
@import "@/themes/color-admin/_variable.scss";

.button-switch-component {
  display: flex;

  .switch {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 18px;
    margin-right: 5px;
    margin-bottom: 0px;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider {
        background-color: $blue;
      }

      &:focus + .slider {
        box-shadow: 0 0 1px $grey_darker;
      }

      &:checked + .slider:before {
        transform: translateX(10px);
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $grey;
      transition: .4s;
      border-radius: 19px;

      &:before {
        position: absolute;
        content: "";
        height: 14px;
        width: 14px;
        border-radius: 50%;
        left: 2px;
        bottom: 2px;
        top: 2px;
        right: 2px;
        background-color: $white;
        transition: .4s;
      }
    }
  }
}

</style>
