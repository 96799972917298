import swal from 'sweetalert2';
// import 'sweetalert2/dist/sweetalert2.min.css';

function isBrowser () {
  return typeof window !== 'undefined';
}

const AlertManager = function () {};

AlertManager.install = function (Vue) {
  // 1. adding a global method or property
  const _alert = isBrowser() ? swal : function () {
    return Promise.resolve();
  };

  Vue.alert = _alert;

  // 4. add the instance method
  Object.defineProperty(Vue.prototype, '$alert', {
    get: function get () {
      return _alert;
    },
  });
};

export default AlertManager;
