<template>
  <div class="cell-marker-component" v-if="!hidden">
    <span :class="`cell-marker ${color}`" />
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default: 'grey',
    },
    hidden: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import "@/themes/color-admin/_variable.scss";

.cell-marker-component {
  .cell-marker {
    border-top: 18px solid transparent;
    border-bottom: 18px solid transparent;
    border-right: 8px solid transparent;
    display: block;

    &.orange {
      border-left: 8px solid $orange
    }
    &.black {
      border-left: 8px solid $black;
    }
    &.blue {
      border-left: 8px solid $blue;
    }
    &.lime {
      border-left: 8px solid $lime;
    }
    &.yellow {
      border-left: 8px solid $yellow;
    }
    &.grey {
      border-left: 8px solid $grey_darker;
    }
    &.aqua {
      border-left: 8px solid $aqua;
    }
    &.green {
      border-left: 8px solid $green;
    }
    &.indigo {
      border-left: 8px solid $indigo;
    }
    &.red {
      border-left: 8px solid $red;
    }
  }
}

</style>
