<template>
  <div class="enable-two-fa-component">
    <div class="col-12 col-sm-12 col-md-12 ct-enable2fa-title-header">
      <span>{{ $t('enable2FA.titleHeader') }}</span>
    </div>
    <div class="col-12 col-sm-12 col-md-12">
      <form class="margin-bottom-0" @submit.prevent>
          <div class="ct-enable2fa-default">
            <div class="row">
              <div class="col-12 col-sm-12">
                <img v-if="data.qr" :src="data.qr" class="img-fluid">
                <h5><small>{{ $t('enable2FA.key') }}: </small>{{ data.secret || '-' }}</h5>
                <a href="#" @click="changeKeyTwoFa">{{ $t('enable2FA.changeKey') }}</a>
                <br>
                <br>
                <br>
              </div>
              <div class="col-12 col-sm-6">
                <div class="ct-form-group">
                  <label for="code">{{ $t('enable2FA.codeLabel') }}</label>
                  <input
                    autocomplete="off"
                    class="form-control"
                    :class="{'is-invalid': errors.has('code')}"
                    id="code"
                    name="code"
                    v-validate="{ required: true }"
                    :data-vv-as="$t('enable2FA.code')"
                    type="text"
                    v-model="code"
                  />
                  <div class="invalid-feedback">{{ errors.first('code') }}</div>
                </div>
              </div>
            </div>
            <br>
            <br>
            <div class="row">
              <div class="col-12 col-sm-6">

              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group pull-right form-inline">
                  <button
                    class="btn btn-default btn-lg"
                    type="submit"
                    @click="cancel"
                  >{{$t('enable2FA.cancel')}}</button>
                  <button
                    class="btn btn-primary btn-lg"
                    type="submit"
                    @click="save"
                    :disabled="isLoading"
                  >
                    <i class="fas fa-spinner fa-pulse" v-if="isLoading"></i>
                    {{ $t('enable2FA.submit') }}
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12">
                <div class="alert ct-enable2fa-alert">
                  {{ $t('enable2FA.alert') }}
                </div>
              </div>
            </div>
          </div>
      </form>
    </div>
  </div>
</template>
<script>
import AuthService from '@/services/AuthService';

export default {
  props: {},
  computed: {},
  created () {
    this.requestKeyTwoFa();
  },
  methods: {
    cancel () {
      this.$emit('cancel');
    },
    async requestKeyTwoFa () {
      const response = await AuthService.requestEnable2fa();
      if (response && response.data) {
        this.data = response.data;
      }
    },
    async changeKeyTwoFa () {
      this.requestKeyTwoFa();
    },
    async save () {
      if (this.isLoading) return;

      try {
        const isValid = await this.$validator.validate();

        if (!isValid) {
          this.showErroMessage();
          return;
        }

        this.isLoading = true;

        const response = await AuthService.enable2fa(this.code);
        if (response) {
          this.$notify({
            type: 'success',
            title: this.$t('enable2FA.notificationSuccessTitle'),
            text: this.$t('enable2FA.notificationSuccessMessage'),
          });
          this.$emit('finished', response);
          return;
        }
        this.showErroMessage();
      } catch (e) {
        this.isLoading = false;
        this.$notify({
          type: 'error',
          title: this.$t('enable2FA.notificationErrorTitle'),
          text:
            e.response.data.message ||
            e.message ||
            this.$t('enable2FA.notificationErrorMessage'),
        });
      }
    },
    showErroMessage () {
      this.$notify({
        type: 'error',
        title: this.$t('enable2FA.notificationErrorTitle'),
        text: this.$t('enable2FA.notificationErrorMessage'),
      });
      this.isLoading = false;
    },
  },
  data () {
    return {
      isLoading: false,
      code: '',
      data: {},
    };
  },
};
</script>
<style scoped lang="scss">
.enable-two-fa-component {
  .ct-enable2fa-title-header {
    font-weight: bold;
    margin: 15px 0px;
  }
  .img-fluid {
    min-width: 220px;
    border: #fafafa 1px solid;
    border-radius: 5px;
  }
  h5 {
    small {
      font-weight: 600;
      font-size: 14px;
    }
  }
  .ct-enable2fa-alert {
    background-color: #eff3f4;
    border-color: #eff3f4;
    margin-top: 10px;
  }
}
</style>
