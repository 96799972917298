<template>
  <section>
    <div class="table-responsive body-table">
      <table class="table table-wallets">
        <thead>
          <tr>
            <th width="8%">{{ $t('invoices.details.wallet.orders.headers.id') }}</th>
            <th width="10%">{{ $t('invoices.details.wallet.orders.headers.status') }}</th>
            <th width="10%">{{ $t('invoices.details.wallet.orders.headers.market') }}</th>
            <th width="10%">{{ $t('invoices.details.wallet.orders.headers.amount') }}</th>
            <th width="14%">{{ $t('invoices.details.wallet.orders.headers.amountExecuted') }}</th>
            <th width="10%">{{ $t('invoices.details.wallet.orders.headers.price') }}</th>
            <th width="10%">{{ $t('invoices.details.wallet.orders.headers.priceExecuted') }}</th>
            <th width="10%">{{ $t('invoices.details.wallet.orders.headers.fee') }}</th>
            <th width="10%">{{ $t('invoices.details.wallet.orders.headers.providerId') }}</th>
            <th width="5%" class="text-center" v-if="isInternal">{{ $t('invoices.details.wallet.orders.headers.actions') }}</th>
          </tr>
        </thead>
        <tbody v-if="orders || orders.lenght > 0">
          <tr v-for="(order, index) in orders" :key="index">
            <td><ct-cell-id :text="order._id" /></td>
            <td>{{ order.status }}</td>
            <td>{{ order.currencySecondary }}/{{ order.currencyPrimary }}</td>
            <td>{{ order.amount }}</td>
            <td>{{ order.amountExecuted }}</td>
            <td>{{ order.price }}</td>
            <td>{{ order.priceExecuted }}</td>
            <td>{{ order.fee }}</td>
            <td><ct-cell-id :text="order.providerRequestId || ''" /></td>
            <td class="action-col" v-if="isInternal">
              <button
                class="btn btn-xs btn-primary"
                @click.prevent="() => recheckOrder(order)"
                :title="$t('invoices.details.wallet.orders.recheckOrder')"
                v-b-tooltip.hover
                :disabled="!canRecheckOrder(order)"
              >
                <i class="fas fa-sync-alt"></i>
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="10">{{ $t('invoices.details.wallet.notFound') }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import CtCellId from '@/components/Shared/DataGrid/CellId.vue';
import TransactionService from '@/services/TransactionService';

export default {
  name: 'TransactionOrders',
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  components: {
    CtCellId,
  },
  computed: {
    ...mapGetters([
      'isInternal',
    ]),
  },
  mounted () {
    this.updateOrders();
  },
  methods: {
    async updateOrders () {
      const response = await TransactionService.getOrdersByTransaction(this.transaction._id);
      if (response.success) {
        this.orders = response.data || [];
        return;
      }

      return this.$notify({
        type: 'error',
        title: response.message || this.$t('invoices.details.wallet.actions.errorUpdateOrders'),
      });
    },
    canRecheckOrder (order) {
      if (!order || !order.status) return false;
      if (['FINISHED', 'CANCELLED'].includes(order.status)) return false;

      return true;
    },
    async recheckOrder (order) {
      const response = await TransactionService.recheckOrder(order._id);
      if (response.success && response.data) {
        await this.updateOrders();
        return this.$notify({
          type: 'success',
          title: this.$t('invoices.details.wallet.orders.successRecheck'),

        });
      }

      return this.$notify({
        type: 'error',
        title: response.message || this.$t('invoices.details.wallet.orders.errorRecheck'),
      });
    },
  },
  data: () => {
    return {
      orders: [],
    };
  },
};
</script>

<style lang="scss" scoped>
section {
  .ct-margin-top-20 {
    margin-top: 20px;
  }
  .action-col {
    min-width: 30px;
    padding: 0;
    text-align: center;
    button {
      margin-top: 6px;
      &:disabled {
        cursor: no-drop;
      }
    }
  }
}
</style>
