<template>
  <div class="cell-id-component">
    <span class="cell-id" @click="truncate">
      {{ truncatedId }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 5,
    },
  },
  data () {
    return {
      truncated: true,
    };
  },
  methods: {
    truncate () {
      this.truncated = !this.truncated;
    },
  },
  computed: {
    truncatedId () {
      if (this.truncated) {
        if (this.text.length > this.size) {
          return '...' + (this.text.substr(this.text.length - this.size));
        }
      }
      return this.text;
    },
  },
};
</script>

<style lang="scss" scoped>
.cell-id-component {
  .cell-id {

  }
}
</style>
