<template>
  <div>
    <div class="login login-with-news-feed">
      <!-- begin news-feed -->
      <div class="news-feed" id="news">
        <div class="news-image"></div>
        <div class="news-caption">
          <h4 class="caption-title">
            <span class="text-white">{{ $t('app.nameFormat') }}<span><i class="fab fa-btc"></i>itcoin</span></span> | {{ $t('login.title1') }}
            </h4>
          <p>{{ $t('login.caption.part1') }} </p>

        </div>
      </div>
      <!-- end news-feed -->
      <!-- begin right-content -->
      <div class="right-content">
        <!-- begin login-header -->
        <div class="login-header">
          <div class="brand">
            <!-- <a href="/" class="brand-text">-->
            <!-- $t('login.brandText') -->
            <span class="text-gray">
              {{ $t('app.nameFormat') }}<span><i class="fab fa-btc"></i>itcoin</span>
            </span>
          <!-- </a> -->
            <span class="login-subtitle">{{ $t('login.title1') }}
              <i class="subtitle-light">{{ $t('login.subtitleLight') }}</i>
            </span>
          </div>
          <div class="icon">
            <i class="fa fa-sign-in"></i>
          </div>
        </div>
        <!-- end login-header -->
        <!-- begin login-content -->
        <div class="login-content">
          <form class="margin-bottom-0"  @submit.prevent>
            <transition name='login-slide' v-if="!hasTwoFa">
              <div class="ct-login-default">

                <div class="ct-form-with-icon">
                  <i class="fa fa-user" aria-hidden="true"></i>
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    id="login-email"
                    name="email"
                    v-validate="'required|email'"
                    data-vv-validate-on="change|custom"
                    v-model="user.email"
                    :placeholder="$t('login.formPlaceholder.email')"
                    autocomplete="username"
                  />
                  <ct-alert-balloon color="blue" v-if="errors.has('email')" >
                    {{ $t('login.msgErrorForm.notEmail') }}
                  </ct-alert-balloon>
                </div>

                <div class="ct-form-with-icon">
                  <i class="fa fa-lock" aria-hidden="true"></i>
                  <input
                    type="password"
                    class="form-control form-control-lg"
                    id="login-password"
                    name="password"
                    v-validate="'required'"
                    data-vv-validate-on="change|custom"
                    v-model="user.password"
                    :placeholder=" $t('login.formPlaceholder.password') "
                    autocomplete="current-password"
                  />
                  <ct-alert-balloon v-if="errors.has('password')" >
                    {{ $t('login.msgErrorForm.required') }}
                  </ct-alert-balloon>
                </div>

                <!--<div class="forget-password">
                  <a href="">{{ $t('login.forgetPassword') }}</a>
                </div>-->

                <div class="ct-submit-button" >
                  <button type="submit"
                          id="login-send"
                          class="btn ct-submit-button btn-block btn-lg"
                          style="margin: 0;"
                          @click="sendAuth()"
                  >{{ $t('login.btnSendText') }}</button>
                </div>

                <ct-alert-message v-if="message" :message="message" />
                <div class="m-t-20 ct-login-register">
                  <span>{{ $t('login.register.text') }}</span>
                </div>
              </div>
            </transition>
            <transition name='login-twofa'>
              <ct-form-two-fa-code
                v-if="hasTwoFa"
                :message="message"
                @code="sendAuthTwoFA"
                @return="backToLogin"
              >
              </ct-form-two-fa-code>
            </transition>
          </form>
        </div>
        <!-- end login-content -->
        <hr />
        <p class="login-footer-therms text-center text-grey-darker">
          {{ $t('login.terms.text') }}
          <!--<<a href="/terms-of-use">-->
          {{ $t('login.terms.linkText') }}
          <!-- </a>-->
        </p>
      </div>
      <!-- end right-container -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CtAlertBalloon from '@/components/Shared/AlertBalloon.vue';
import CtAlertMessage from '@/components/Shared/AlertMessage.vue';
import CtFormTwoFaCode from '@/components/Shared/FormTwoFACode.vue';
import User from '@/model/User';
export default {
  name: 'Login',
  components: {
    CtAlertBalloon,
    CtAlertMessage,
    CtFormTwoFaCode,
  },
  props: {},
  data () {
    return {
      user: new User(),
      hasTwoFa: false,
      reference: '',
      message: '',
    };
  },
  computed: {
    ...mapGetters(['isLogged']),
    isValidate () {
      return !(this.user.email.length && this.user.password.length);
    },
  },
  beforeMount () {
    if (this.isLogged) {
      this.$router.push({ name: 'home' });
    }
  },
  methods: {
    ...mapActions(['executeLogin', 'executeLoginWithTwoFA']),
    async sendAuth () {
      try {
        const reference = await this.executeLogin({
          email: this.user.email,
          password: this.user.password,
        });

        if (reference) {
          this.reference = reference;
          this.hasTwoFa = true;
          this.message = '';
          return;
        }
        this.$router.push(this.$route.query.redirect || '/');
      } catch (e) {
        this.message = e.response && e.response.data && e.response.data.message
          ? e.response.data.message
          : this.$t('login.msgErrorForm.invalidLogin');

        if (e.message === 'Unauthorized') {
          this.message = this.$t('login.msgErrorForm.unauthorized');
        }

        this.user = new User();
      }
    },
    async sendAuthTwoFA (code) {
      try {
        await this.executeLoginWithTwoFA({
          reference: this.reference,
          code: code,
        });

        this.$router.push(this.$route.query.redirect || '/');
      } catch (e) {
        this.message =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : this.$t('login.msgErrorForm.twoFA');
        this.user = new User();
      }
    },
    backToLogin () {
      this.hasTwoFa = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../themes/color-admin/_ct_variable.scss";
.component-dashboard {
  .breadcrumb {
    background-color: transparent;
  }
}
.text-gray {
  color: #6b6f72;
  span {
    color: #f59c1a;
  }
}
.text-white {
  color: #ffffff;
  span {
    color: #f59c1a;
  }
}
.login {
  &.login-with-news-feed {
    height: 100%;
  }
}
.right-content,
.ct-login-2fa {
  background: white;
  height: 100%;
}
.pace {
  .pace-activity {
    border-top-color: $ct_color;
    border-left-color: $ct_color;
  }
  .pace-progress {
    background: $ct_color;
  }
}

.text-theme,
a {
  color: $ct_color;
}

.news-image {
  background: url("/assets/img/bp-login-bg.jpg");
  left: 0px !important;
}

.subtitle-light {
  font-style: normal;
  font-weight: 100;
}
.news-caption {
  p {
    font-size: 14px;
  }
}

.brand-text {
  color: $body_text_color;
  text-decoration: none;
}

.login-subtitle {
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin: 5px 0;
}

.forget-password {
  margin-bottom: 15px;
  a {
    color: $body_text_color;
    font-weight: 300;
    text-decoration: underline;
    font-size: 12px;
  }
}

.ct-login-register {
  color: $body_text_color;
  font-size: 16px;
  height: 100%;
}

.login-footer-therms {
  font-size: 9px;
}

.login-slide-enter-active,
.login-twofa-enter-active {
  transition: all 0.5s ease;
}

.login-twofa-leave-to,
.login-slide-enter,
.login-twofa-enter {
  opacity: 0;
  transform: translateY(100px);
}
</style>
