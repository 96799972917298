import http from '@/services/HttpService';

export default {
  async getOperators () {
    const res = await http().get('/api/v1/giftcards/operators');
    return res.data.data;
  },
  async getAmounts (operator) {
    const res = await http().get(`/api/v1/giftcards/values/${operator}`);
    return res.data.data;
  },
};
