<template>
  <div>
    <h1 class="page-header">
      {{ $t('invoices.modalFinishGiftCard.subtitle') }}
      <small>{{ invoiceId }}</small>
    </h1>
    <form @submit.prevent>
      <div class="row">
        <div class="col-xs-12 col-lg-4">
          <div class="form-group">
            <label>{{ $t('invoices.modalFinishGiftCard.pin') }} *</label>
            <input
              type="text"
              id="pin"
              name="pin"
              class="form-control"
              :class="{'is-invalid': errors.has('pin')}"
              v-model="pin"
              v-validate="{ required: true }"
            />
            <div class="invalid-feedback">{{ errors.first('pin') }}</div>
          </div>
        </div>
        <div class="col-xs-12 col-lg-4">
          <div class="form-group">
            <label>{{ $t('invoices.modalFinishGiftCard.lote') }}</label>
            <input
              type="text"
              name="lote"
              class="form-control"
              v-model="lote"
            />
          </div>
        </div>
        <div class="col-xs-12 col-lg-4">
          <div class="form-group">
            <label>{{ $t('invoices.modalFinishGiftCard.pin') }}</label>
            <input
              type="text"
              name="serie"
              class="form-control"
              v-model="serie"
            />
          </div>
        </div>
        <div class="col-xs-12">
          <div class="form-group">
            <div v-if="errorMessage && errorMessage !== ''" class="alert alert-danger text-center">
              {{ errorMessage }}
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="form-group pull-right form-inline">
            <button
              type="submit"
              class="btn btn-default btn-lg"
              @click="close()"
            >{{$t('invoices.modalFinishGiftCard.cancel')}}</button>
            <button type="submit" class="btn btn-primary btn-lg" @click="finishItem()">
              <i class="fas fa-spinner fa-pulse" v-if="isLoading"></i>
              {{ $t('invoices.modalFinishGiftCard.send') }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    invoiceId: {
      type: String,
      required: true,
    },
    itemId: {
      type: String,
      required: true,
    },
  },
  computed: {},
  methods: {
    ...mapActions(['finishGiftCard']),
    close () {
      this.$emit('close');
    },
    async finishItem () {
      const isValid = await this.$validator.validate();

      if (isValid) {
        this.isLoading = true;
        const data = {
          invoiceId: this.invoiceId,
          itemId: this.itemId,
          pin: this.pin,
          lote: this.lote,
          serie: this.serie,
        };

        const result = await this.finishGiftCard(data);
        this.isLoading = false;

        if (!result.message) {
          this.notify(
            'success',
            this.$t('invoices.modalFinishGiftCard.successTitle'),
            this.$t('invoices.modalFinishGiftCard.successDesc'),
          );
          this.$emit('finished');
          return '';
        }

        this.notify(
          'error',
          this.$t('invoices.modalFinishGiftCard.errorTitle'),
          result.message,
        );
      }
    },
    notify (type, title, text) {
      this.$notify({
        type: type,
        duration: 10000,
        title: title,
        text: text,
      });
    },
  },
  data () {
    return {
      pin: '',
      lote: '',
      serie: '',
      errorMessage: '',
      isLoading: false,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
