import InvoiceBilletItem from './InvoiceItemBillet';

export const validKinds = {
  RECHARGE: 'RECHARGE',
  BILLET: 'BILLET',
  SHIPMENT: 'SHIPMENT',
  OPEY: 'OPEY',
  GIFTCARD: 'GIFTCARD',
  PIX: 'PIX',
};

export const validStatus = {
  OPEN: 'OPEN',
  ERROR: 'ERROR',
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED',
  FINISHED: 'FINISHED',
  PROCESSING: 'PROCESSING',
};

export default class InvoiceItem {
  constructor (data = {}) {
    this._id = data._id;
    this.kind = data.kind || null;
    this.status = data.status || validStatus.OPEN;
    this.amount = data.amount ? Number(data.amount) : 0;
    this.fee = data.fee ? Number(data.fee) : 0;
    this.total = data.total ? Number(data.total) : 0;
    this.description = data.description || null;
    this.receipt = data.receipt || null;
    this.receiptNumber = data.receiptNumber || null;
    this.createdAt = data.createdAt || null;
    this.finishedAt = data.finishedAt || null;
    this.updatedAt = data.updatedAt || null;
    this.events = data.events || [];
    this.transactions = data.transactions || [];
    this.action = data.action || [{
      userId: null,
      action: null,
      createdAt: null,
    }];
    this.billet = new InvoiceBilletItem(data.billet || {
      digitableLine: null,
      dueDate: null,
      discount: null,
      increase: null,
      value: null,
    });
    this.recharge = data.recharge || {
      operator: null,
      ddd: null,
      phone: null,
    };
    this.shipment = data.shipment || {
      name: null,
      document: null,
      bank: null,
      operation: null,
      agency: null,
      account: null,
      kind: null,
    };
    this.pix = data.pix || {
      key: null,
      name: null,
      document: null,
      bank: null,
      operation: null,
      agency: null,
      account: null,
      kind: null,
    };
    this.opey = data.opey || {
      name: null,
      document: null,
    };
    this.giftCard = data.giftCard || {
      operator: null,
      card: null,
      pin: null,
      lote: null,
      serie: null,
    };
  }
}
