export const validKinds = {
  C: 'C',
  D: 'D',
};

export const validStatus = {
  OPEN: 'OPEN',
  UNCONFIRMED: 'UNCONFIRMED',
  CONFIRMED: 'CONFIRMED',
  CANCELED: 'CANCELED',
};

export default class Transactions {
  constructor (data = {}) {
    this._id = data._id || null;
    this.invoiceId = data.invoiceId || null;
    this.kind = data.kind || validKinds.C;
    this.crypto = data.crypto || 'BTC';
    this.address = data.address || null;
    this.wallet = data.wallet || null;
    this.txId = data.txId || null;
    this.amount = data.amount ? Number(data.amount) : 0;
    this.blockHeight = data.blockHeight || null;
    this.transferTxId = data.transferTxId || null;
    this.status = data.status || validStatus.OPEN;
    this.exchangeReleased = data.exchangeReleased || false;
    this.exchangeStatus = data.exchangeStatus || 'OPEN';
    this.exchangePrice = data.exchangePrice || '0';
    this.confirmations = data.confirmations ? Number(data.confirmations) : 0;
    this.createdAt = data.createdAt || null;
    this.deletedAt = data.deletedAt || null;
    this.receivedAt = data.receivedAt || null;
    this.confirmedAt = data.confirmedAt || null;
    this.unlockedAt = data.unlockedAt || null;
    this.updatedAt = data.updatedAt || null;
  }
}
