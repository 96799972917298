<template>
  <div class="reversal-form-component">
    <h1 class="page-header">
      {{ $t('invoices.modalReversal.subtitle') }}
      <small>{{ invoiceId }}</small>
    </h1>
    <form @submit.prevent>
      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="form-group" :class="{'is-invalid': errors.has('kind')}">
            <label for="kind">{{ $t('invoices.modalReversal.kind') }} *</label>
            <ct-select
              name="kind"
              id="kind"
              class="from-control"
              :class="{'is-invalid': errors.has('kind')}"
              :data-vv-as="$t('invoices.modalReversal.kind')"
              v-model="reversal.kind"
              :options="validReversalTypes"
              :filterable="true"
              :multiple="false"
              v-validate="{ required: true }"
            />
            <div class="invalid-feedback">{{ errors.first('kind') }}</div>
          </div>
        </div>
      </div>
      <hr />

      <ct-form-reversal-crypto
        v-if="reversal.kind === 'CRYPTO'"
        :is-loading="isLoading"
        :reversal-crypto-value="reversalCryptoValue"
        v-model="reversal.crypto"
        @close="close"
        @submit="submit"
      />
      <ct-form-reversal-fiat
        v-if="reversal.kind === 'FIAT'"
        :sum-reversal-value="sumReversalValue"
        :is-loading="isLoading"
        v-model="reversal.fiat"
        @close="close"
        @submit="submit"
      />
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { CtSelect } from '@citartech/core-ui';
import CtFormReversalCrypto from './Reversal/FormReversalCrypto.vue';
import CtFormReversalFiat from './Reversal/FormReversalFiat.vue';
import InvoiceService from '@/services/InvoiceService.js';

export default {
  name: 'FormReversal',
  components: {
    CtSelect,
    CtFormReversalCrypto,
    CtFormReversalFiat,
  },
  props: {
    invoiceId: {
      type: String,
      required: true,
    },
    sumReversalValue: {
      type: Number,
    },
    reversalCryptoValue: {
      type: Number,
    },
    isFinishedItems: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      reversalTypes: state => state.selects.reversalTypes || [],
    }),
    validReversalTypes () {
      let result = this.reversalTypes;
      if (this.isFinishedItems) {
        result = result.filter(item => {
          return item.value !== 'CRYPTO';
        });
      }
      return result;
    },
  },
  methods: {
    close () {
      this.$emit('close');
    },
    async submit () {
      this.isLoading = true;
      const { confirmed, value } = await this.confirmReversal();
      if (confirmed) {
        const clearedValue = value.trim();
        if (clearedValue === '') {
          this.isLoading = false;
          return this.$notify({
            type: 'error',
            title: this.$t('invoices.details.invalidDescription'),
          });
        }

        await this.processReversalInvoice(clearedValue);
      }
      this.isLoading = false;
    },
    async confirmReversal () {
      const confirmReversal = this.$t('invoices.details.confirmReversal');
      const confirmOk = this.$t('invoices.details.confirmOk');
      const confirmCancel = this.$t('invoices.details.confirmCancel');
      const { dismiss, value } = await this.$alert({
        input: 'textarea',
        inputPlaceholder: this.$t(
          'invoices.details.reversalDescriptionPlaceholder',
        ),
        title: confirmReversal,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: confirmOk,
        cancelButtonText: confirmCancel,
        confirmButtonColor: '#ffc107',
        reverseButtons: true,
      });

      if (dismiss && dismiss === 'cancel') {
        return { confirmed: false };
      }

      return {
        confirmed: true,
        value: value,
      };
    },
    async processReversalInvoice (description) {
      try {
        this.reversal.description = description;
        if (this.reversal && this.reversal.fiat) {
          this.reversal.fiat.map((fiat) => delete fiat.data.validator);
        }

        await InvoiceService.sendReversal(this.invoiceId, this.reversal);
        this.$emit('finished');
      } catch (error) {
        const errors = error.response &&
          error.response.data &&
          error.response.data.errors ? error.response.data.errors : [];
        const message = error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : null;
        this.$notify({
          type: 'error',
          title: errors[0] && errors[0].message ? errors[0].message : this.$t('invoices.details.reversalSaveError'),
          text: message,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
  data: () => ({
    reversal: {
      kind: '',
      description: '',
    },
    isLoading: false,
  }),
};
</script>

<style lang="scss">
.swal2-container {
  z-index: 2011 !important;
}
</style>
