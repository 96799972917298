<template>
  <div class="cell-resumed-link-component">
    <a class="cell-resumed-link" :href="href" target="_blank" :alt="text" :title="text">
      {{ truncatedText }}
    </a>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 5,
    },
  },
  data () {
    return {
      truncated: true,
    };
  },
  methods: {
    truncate () {
      this.truncated = !this.truncated;
    },
  },
  computed: {
    truncatedText () {
      if (this.text.length > this.size) {
        return '...' + (this.text.substr(this.text.length - this.size));
      }

      return this.text;
    },
  },
};
</script>
