import InvoiceService from './../../../services/InvoiceService';
import InvoiceReferralService from './../../../services/InvoiceReferralService';
import Invoice from '../../../model/Invoice';

export default {
  async getInvoiceList ({ commit, state }, isPrint = false) {
    try {
      const list = await InvoiceService.getInvoiceList({
        filters: state.listInvoicesFilters,
        customFilters: state.listInvoicesCustomFilters,
        search: state.listInvoicesSearch,
        page: isPrint ? 0 : state.listInvoicesPaginate.currentPage || 1,
        limit: isPrint ? 0 : state.listInvoicesPaginate.perPage || 10,
        orderBy: state.listInvoicesSort,
      });
      list.data = (list.data || []).map((invoice) => {
        return new Invoice(invoice);
      });
      if (isPrint) {
        commit('SET_LIST_INVOICES_PRINT', list);
      } else {
        commit('SET_LIST_INVOICES', list);
        if (list.meta) {
          commit('SET_INVOICE_LIST_TOTAL_PAGES', list.meta.pagination.totalPages);
        }
      }
    } catch (error) {
      console.error(error);
    }
  },

  async getInvoiceDetail (_, id) {
    try {
      const invoice = await InvoiceService.getInvoiceDetail(id);
      return new Invoice(invoice.data || {});
    } catch (error) {
      console.error(error);
    }
  },

  async getInvoiceReferralDetail (_, id) {
    try {
      const invoice = await InvoiceReferralService.getInvoiceReferralDetail(id);
      return invoice.data || {};
    } catch (error) {
      console.error(error);
    }
  },

  async reprocessInvoiceReferral (_, id) {
    const invoice = await InvoiceReferralService.reprocessInvoiceReferral(id);
    return invoice.data || {};
  },

  async generateInvoicesSpreadsheet (_, filters) {
    const result = await InvoiceService.generateSpreadsheet(filters);
    return result;
  },

  setInvoiceListFilter ({ commit, dispatch }, value) {
    commit('SET_INVOICE_LIST_PAGE', 1);
    commit('ADD_INVOICE_LIST_FILTER', value);
    dispatch('getInvoiceList');
  },

  removeInvoiceListFilter ({ commit, dispatch }, value) {
    commit('SET_INVOICE_LIST_PAGE', 1);
    commit('REMOVE_INVOICE_LIST_FILTER', value);
    dispatch('getInvoiceList');
  },

  setInvoiceListCustomFilter ({ commit, dispatch }, value) {
    commit('SET_INVOICE_LIST_PAGE', 1);
    commit('SET_INVOICE_LIST_CUSTOM_FILTER', value);
    dispatch('getInvoiceList');
  },

  setInvoiceListPage ({ commit, dispatch }, value) {
    commit('SET_INVOICE_LIST_PAGE', value);
    dispatch('getInvoiceList');
  },

  setInvoiceListTotalPages ({ commit, dispatch }, value) {
    commit('SET_INVOICE_LIST_PAGE', 1);
    commit('SET_INVOICE_LIST_TOTAL_PAGES', value);
    dispatch('getInvoiceList');
  },

  setInvoiceListSort ({ commit, dispatch }, value) {
    commit('SET_INVOICE_LIST_SORT', value);
    dispatch('getInvoiceList');
  },

  setInvoiceListSearch ({ commit, dispatch }, value) {
    commit('SET_INVOICE_LIST_SEARCH', value);
    dispatch('getInvoiceList');
  },
  setInvoiceShowFilters ({ commit }, value) {
    commit('SET_INVOICE_SHOW_FILTERS', value);
  },
  async uploadInvoiceReceipt (_, { id, itemId, file, receiptNumber }) {
    const formData = new FormData();
    formData.append('receipt', file);

    const response = await InvoiceService.uploadReceipt(id, itemId, formData, receiptNumber);
    return response;
  },

  async downloadReceiptForProvider (_, { id, itemId }) {
    const response = await InvoiceService.downloadReceiptForProvider(id, itemId);
    return response;
  },

  async finishGiftCard (_, { invoiceId, itemId, pin, lote, serie }) {
    try {
      const response = await InvoiceService.finishGiftCard(invoiceId, itemId, { pin, lote, serie });
      return response;
    } catch (err) {
      console.error(err);
      return err;
    }
  },

  async updateInvoiceDescription (_, { id, description }) {
    const objDescription = {
      description: description,
    };
    try {
      const response = await InvoiceService.updateInvoiceDescription(id, objDescription);
      return response.data;
    } catch (err) {
      return false;
    }
  },

  async editInvoiceItem (_, { id, itemId, data }) {
    const response = await InvoiceService.editItem(id, itemId, data);
    return response;
  },

  async approveExpiredInvoice (_, id) {
    const response = await InvoiceService.approveExpired(id);
    return response.data;
  },

  async approveForAutoExecutableInvoice (_, id) {
    const response = await InvoiceService.approveForAutoExecutableInvoice(id);
    return response.data;
  },

  async emitBills (_, ids) {
    const response = await InvoiceService.emitBills(ids);
    return response.data;
  },

  async verifyInvoiceTransactions (_, { invoiceId }) {
    const invoice = await InvoiceService.verifyTransactions(invoiceId);
    return new Invoice(invoice.data || {});
  },

  async getListInvoiceStatistics ({ commit }) {
    const list = await InvoiceService.listStatistics();
    if (list) {
      commit('SET_LIST_INVOICE_STATISTICS', list.data);
    }
  },
};
