const appData = JSON.parse(unescape(process.env.APP_DATA || '%7B%7D'));

export default {
  name: appData.name || 'pague-com-bitcoin',
  version: appData.version || '0',
  reloadInterval: 60000,
  siteUrl: process.env.VUE_APP_SITE_URL || 'https://paguecombitcoin.com',
  testnet: String(process.env.VUE_APP_TESTNET || false) === 'true',
  invoice: {
    reversalNetworkRate: process.env.REVERSAL_NETWORK_RATE || 0.0001,
  },
};
