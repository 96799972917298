import GiftCardService from '../../../services/GiftCardService';

export default {
  async getGiftCardOperators ({ commit }) {
    const operators = await GiftCardService.getOperators();
    commit('SET_GIFTCARD_OPERATORS', operators.map((operator) => {
      return {
        value: operator.value,
        label: operator.label,
      };
    }));
  },
  async getGiftCardAmounts ({ commit }, { operator }) {
    const amounts = await GiftCardService.getAmounts(operator);
    commit('SET_GIFTCARD_AMOUNTS', amounts);
  },
};
