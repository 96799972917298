import http from './HttpService';
import moment from 'moment';
import { parseJwt } from '../helpers/jtw.helper';

export default {
  async hasAuthorization ({ getters, dispatch }, resourceId, permission) {
    if (getters.getCurrentUser.roles !== undefined) {
      const isLogged = await dispatch('isLogged');
      return isLogged && this.checkPermission(getters.getCurrentUser.roles, permission);
    }
    return getters.isLogged;
  },

  checkPermission (userRoles, routePermission) {
    return userRoles.some(r => routePermission.indexOf(r) >= 0);
  },

  async getCurrentUserData () {
    try {
      const response = await http().get('/api/v1/profile');
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },

  async login (userInfo) {
    const res = await http().post('/api/v1/auth/login', userInfo);
    const response = res.data.data || {};
    if (!response.token) {
      return response;
    }
    const parsedToken = parseJwt(response.token);
    // eslint-disable-next-line no-prototype-builtins
    if (!parsedToken.hasOwnProperty('roles')) {
      return {};
    }
    response.expiresAt = moment().add(response.expiresAt || 0, 'second').toDate();
    return response;
  },

  async twofa (authInfo) {
    const res = await http().post('/api/v1/auth/2fa', authInfo);
    const response = res.data.data || {};
    response.expiresAt = moment().add(response.expiresAt || 0, 'second').toDate();
    return res.data.data || {};
  },

  async logout () {
    try {
      await http().delete('/api/v1/auth/logout');
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  },

  async requestEnable2fa () {
    try {
      const response = await http().get('/api/v1/auth/2fa/enable');
      return response.data;
    } catch (err) {
      console.error(err);
      return false;
    }
  },

  async enable2fa (code) {
    try {
      const codeInfo = {
        code: code,
      };
      const response = await http().post('/api/v1/auth/2fa/enable', codeInfo);
      return response.status && response.status === 204;
    } catch (err) {
      console.error(err);
      return false;
    }
  },

  async disable2fa (code) {
    try {
      const codeInfo = {
        code: code,
      };
      const response = await http().post('/api/v1/auth/2fa/disable', codeInfo);
      return response.status && response.status === 204;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
};
