<template>
  <div class="row">
    <div class="col-xs-12 col-lg-12">
      <div class="form-group" :class="{'is-invalid': errors.has('digitableLine')}">
        <label for="digitableLine">{{ $t('invoices.modalReversal.fiat.billet.digitableLine') }} *</label>
        <input
          class="form-control"
          id="digitableLine"
          name="digitableLine"
          :key="'billetDigitableLine'"
          v-mask="digitableLineMask"
          v-model="billet.digitableLine"
          :class="{'is-invalid': errors.has('digitableLine')}"
          v-validate="{ required: true }"
          :data-vv-as="$t('invoices.modalReversal.fiat.billet.digitableLine')"
          @input="onDigitableLineChange"
          @paste="onDigitableLinePaste"
        />
        <div class="invalid-feedback">{{ errors.first('digitableLine') }}</div>
      </div>
    </div>
    <div class="col-xs-12 col-lg-4">
      <div class="form-group">
        <label for="formatedDueDate">{{ $t('invoices.modalReversal.fiat.billet.dueDate') }} *</label>
        <the-mask
          class="form-control"
          id="formatedDueDate"
          name="formatedDueDate"
          :key="'billetDueDate'"
          mask="##/##/####"
          v-model="billet.formatedDueDate"
          @change="changeDueDate"
          :masked="true"
          :class="{'is-invalid': errors.has('formatedDueDate')}"
          v-validate="{ required: true, date_format: 'dd/mm/yyyy' }"
          :data-vv-as="$t('invoices.modalReversal.fiat.billet.dueDate')"
        ></the-mask>
        <div class="invalid-feedback">{{ errors.first('formatedDueDate') }}</div>
      </div>
    </div>
    <div class="col-xs-12 col-lg-4">
      <div class="form-group">
        <label for="discount">{{ $t('invoices.modalReversal.fiat.billet.discount') }} *</label>
        <ct-input-numeric
          :thousand-separator="`.`"
          :decimal-separator="`,`"
          :precision="2"
          autocomplete="off"
          class="form-control"
          id="discount"
          name="discount"
          :key="'billetDiscount'"
          v-validate="{ decimal: 2 }"
          :data-vv-as="$t('invoices.modalReversal.fiat.billet.discount')"
          v-model="billet.discount"
          @change="changeModel"
        ></ct-input-numeric>
        <div class="invalid-feedback">{{ errors.first('discount') }}</div>
      </div>
    </div>
    <div class="col-xs-12 col-lg-4">
      <div class="form-group">
        <label for="increase">{{ $t('invoices.modalReversal.fiat.billet.increase') }} *</label>
        <ct-input-numeric
          :thousand-separator="`.`"
          :decimal-separator="`,`"
          :precision="2"
          autocomplete="off"
          class="form-control"
          id="increase"
          name="increase"
          :key="'billetIncrease'"
          v-validate="{ decimal: 2 }"
          :data-vv-as="$t('invoices.modalReversal.fiat.billet.increase')"
          v-model="billet.increase"
          @change="changeModel"
        ></ct-input-numeric>
        <div class="invalid-feedback">{{ errors.first('increase') }}</div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-6">
      <div class="form-group">
        <label for="amount">{{ $t('invoices.modalReversal.fiat.billet.value') }} *</label>
        <ct-input-numeric
          :thousand-separator="`.`"
          :decimal-separator="`,`"
          :precision="2"
          autocomplete="off"
          class="form-control"
          id="amount"
          name="amount"
          :key="'billetValue'"
          v-validate="{required: true}"
          :data-vv-as="$t('invoices.modalReversal.fiat.billet.value')"
          v-model="billet.amount"
          @change="changeModel"
        ></ct-input-numeric>
        <div class="invalid-feedback">{{ errors.first('amount') }}</div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6">
      <div class="form-group" :class="{'is-invalid': errors.has('billetFee')}">
        <label for="billetFee">{{ $t('invoices.modalReversal.fiat.fee') }} *</label>
        <ct-input-numeric
          :thousand-separator="$t(`currencies.BRL.thousands`)"
          :decimal-separator="$t(`currencies.BRL.decimal`)"
          :precision="2"
          autocomplete="off"
          class="form-control"
          :class="{'is-invalid': errors.has('billetFee')}"
          v-validate="{ required: true, max_value: maxValueForFee }"
          id="billetFee"
          name="billetFee"
          :key="'billetFee'"
          :data-vv-as="$t('invoices.modalReversal.fiat.fee')"
          :placeholder="$t('invoices.modalReversal.fiat.amountPlaceholder')"
          v-model="billet.fee"
          @change="changeModel"
        ></ct-input-numeric>
        <div class="invalid-feedback">{{ errors.first('billetFee') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import BilletService from '@/services/BilletService';
import { TheMask, mask } from 'vue-the-mask';
import CtInputNumeric from '../../Shared/InputNumeric';

export default {
  name: 'ReversalFiatBillet',
  props: {
    value: {
      type: Object,
      required: true,
    },
    sumReversalValue: {
      type: Number,
    },
  },
  components: {
    CtInputNumeric,
    TheMask,
  },
  directives: {
    mask,
  },
  data: () => ({
    billet: {
      digitableLine: null,
      dueDate: null,
      discount: 0,
      increase: 0,
      amount: 0,
      fee: 0,
      formatedDueDate: null,
    },
  }),
  watch: {
    value () {
      this.billet = { ...this.billet, ...this.value || {} };
    },
  },
  mounted () {
    this.setVal();
  },
  computed: {
    digitableLineMask () {
      return this.billet.digitableLine && !this.billet.digitableLine.startsWith('8')
        ? '##### ##### ##### ###### ##### ###### # ##############'
        : '############ ############ ############ ############';
    },
    maxValueForFee () {
      return Number((this.sumReversalValue - this.billet.amount).toFixed(2));
    },
  },
  methods: {
    setVal () {
      this.billet.validator = this.$validator;
      this.changeModel();
    },
    changeDueDate () {
      if (this.billet.formatedDueDate.length === 10) {
        this.billet.dueDate = moment(this.billet.formatedDueDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      }
      this.changeModel();
    },
    changeModel () {
      this.$emit('input', this.billet);
    },
    async onDigitableLineChange (event) {
      if (event instanceof InputEvent || event instanceof Event) {
        this.checkDigitableLine();
      }
    },
    async onDigitableLinePaste (event) {
      if (event.target.selectionStart === 0 && event.target.selectionEnd === event.target.value.length) {
        const content = (event.clipboardData || window.clipboardData).getData('text');
        this.billet.digitableLine = content;
        this.checkDigitableLine();
        event.preventDefault();
      }
    },
    async checkDigitableLine () {
      try {
        const digitableLine = this.billet.digitableLine.replace(/[^0-9]+/g, ''); // Remove non-numbers

        if (digitableLine.length === 47 || digitableLine.length === 48 || digitableLine.length === 44) {
          const details = await BilletService.getLineDetails(digitableLine);

          this.billet.dueDate = details.dueDate;
          this.billet.formatedDueDate = moment(details.dueDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
          if (details.dueDate === '1997-10-07' && details.value === 0) {
            this.billet.dueDate = '';
            this.billet.formatedDueDate = '';
          }

          this.billet.amount = details.value;
          this.changeModel();
        }
      } catch (err) {
        this.billet.dueDate = '';
        this.billet.amount = '';
      }
    },
  },
};
</script>
