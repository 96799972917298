<template>
  <div class="status-icon">
    <div class="icon" :class="getClassIcon" v-b-tooltip.hover.top :title="help" >
      <i v-if="!iconText" class="icon-symbol" :class="[iconSymbol]"></i>
      <span v-if="!iconSymbol " class="icon-text">
        {{ iconText }}
      </span>
    </div>
    <span v-if="description" class="icon-description">{{ description }}</span>
  </div>
</template>
<script>
export default {
  name: 'StatusIcon',
  props: {
    iconSymbol: {
      type: String,
      required: false,
    },
    iconText: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    color: {
      required: false,
      default: 'green',
    },
    help: {
      required: false,
      default: '',
    },
  },
  computed: {
    getClassIcon () {
      return 'bg-color-' + this.color;
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/themes/color-admin/_ct_variable.scss';
.status-icon {
  display: inline-block;
  width: auto;
  &:first-child{
    margin-left: 0;
  }
  .icon{
    background: orange;
    width: 18px;
    height: 17px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    text-align: center;
    &[class*='bg-color-']{
       &[class*='red']{
         background: $red;
       }
       &[class*='ct-color']{
         background: $ct_color;
       }
       &[class*='blue']{
         background: $blue;
       }
        &[class*='aqua']{
         background: $aqua;
       }
        &[class*='green']{
         background: $green;
       }
       &[class*='orange']{
         background: $orange;
       }
       &[class*='black']{
         background: $black;
       }
       &[class*='silver']{
         background: $silver;
       }
      &[class*='grey']{
         background: $grey;
       }
       &[class*='purple']{
         background: $purple;
       }
       &[class*='lime']{
         background: $lime;
       }
       &[class*='pink']{
         background: $pink;
       }
       &[class*='indigo']{
         background: $indigo;
       }
       &[class*='yellow']{
         background: $yellow;
       }
       &[class*='teal']{
         background: $teal;
       }
    }
    .icon-text {
      position: absolute;
      top: 10px;
      left: 5px;
      line-height: 0;
      font-weight: 500;
      color: $white;
      text-transform: uppercase;
      font-size: 11px;
    }
    .icon-symbol{
      color: $white;
      display: inline-block;
      position: relative;
      font-size: 10px;
      top: -1px;
    }
  }
  .icon-description {
    font-size: 11px;
    color: $heading_small_text_color;
    margin: 0 $margin-default 0 3px;
  }
}
</style>
