import { render, staticRenderFns } from "./InvoiceReferrals.vue?vue&type=template&id=68433367&scoped=true"
import script from "./InvoiceReferrals.vue?vue&type=script&lang=js"
export * from "./InvoiceReferrals.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68433367",
  null
  
)

export default component.exports