<template>
  <div class="content component-settings">
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">
        <a href="javascript:;">Home</a>
      </li>
      <li class="breadcrumb-item active">{{ $t('settings.subtitle') }}</li>
    </ol>
    <h1 class="page-header">
      {{ $t('app.name') }}
      <small>{{ $t('settings.subtitle') }}</small>
    </h1>

    <b-row>
      <b-col cols="12">
        <ct-settings-widget/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CtSettingsWidget from '@/components/Settings/SettingsWidget.vue';

export default {
  name: 'Settings',
  components: {
    CtSettingsWidget,
  },
};
</script>

<style scoped lang="scss">

</style>
