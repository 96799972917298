<template>
  <div class="component-widget-stat"
       :class=" {activated: !this.activated, ['icon-' + color]: !this.activated}"
  >
    <div class="widget widget-stats"
         :class="{['bg-gradient-' + color]: this.activated, ['bg-' + color]: !this.activated}"
         @click="activateFilter($event)">
      <div class="stats-icon stats-icon-lg">
        <i class="fa fa-fw" :class="[icon]"></i>
      </div>
      <div class="stats-content">
        <div class="stats-title">{{ title }}</div>
        <div class="stats-value" v-if="value >= 0">{{ value }}</div>
        <div class="stats-number stats-space">&nbsp;</div>
      </div>
      <div class="call-to-action">
            <span>
              <span class="d-none d-xl-block">{{ ctaText }} </span>
              <span class="d-xl-none">{{ ctaTextMobile }} </span>
              <i v-if="activated" class="fas fa-filter"></i>
              <i v-if="!activated" class="fas fa-times"></i>
            </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WidgetStat',
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: false,
    },
    valueFilter: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'fa-globe',
    },
    color: {
      type: String,
      default: 'green',
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  created () {
    if (this.active) {
      this.activated = !this.activated;
      this.ctaText = this.$t('dashboard.widgetStat.filter.ctaTextActivated');
    }
  },
  data () {
    return {
      activated: true,
      ctaText: this.$t('dashboard.widgetStat.filter.ctaText'),
      ctaTextMobile: this.$t('dashboard.widgetStat.filter.ctaTextMobile'),
    };
  },
  methods: {
    activateFilter (v) {
      this.activated = !this.activated;
      if (this.activated) {
        this.ctaText = this.$t('dashboard.widgetStat.filter.ctaText');
        this.ctaTextMobile = this.$t(
          'dashboard.widgetStat.filter.ctaTextMobile',
        );
      } else {
        this.ctaText = this.$t('dashboard.widgetStat.filter.ctaTextActivated');
        this.ctaTextMobile = this.$t(
          'dashboard.widgetStat.filter.ctaTextActivatedMobile',
        );
      }
      this.$emit('widgetFilter', {
        value: this.valueFilter,
        title: this.title,
        activated: this.activated,
      });
    },
  },
};
</script>

<style scoped lang="scss">
  @import "@/themes/color-admin/_ct_variable.scss";
  .component-widget-stat {
    &.activated {
      .widget-stats {
        box-shadow: 0px 6px 0px rgba(0, 0, 0, 0.1) inset;
      }
    }
    .widget-stats {
      &.widget {
        margin-bottom: 5px;
      }
      cursor: pointer;
      padding: 4px 5px;
      .stats-icon {
        top: -2px;
        right: -16px;
        font-size: 90px;
      }
      .stats-number {
        margin-bottom: 0;
      }
      .stats-value {
        margin-bottom: 0;
        font-size: 20px;
      }
      .span-ctaText {
        height: 18px;
      }
      .stats-space {
        height: 12px;
      }
      @media screen and (min-width: $screen-md-max) {
        .d-xl-block {
          display: inline-block !important;
        }
      }
      .call-to-action {
        position: absolute;
        right: 5px;
        bottom: 4px;
        span {
          font-size: 10px;
          i {
            padding-left: 5px;
            &.fa-times {
              font-size: 14px;
            }
          }
        }
      }
    }
    &.activated {
      position: relative;
      &:before {
        content: "\F0D8";
        font-family: "Font Awesome 5 Free";
        top: 13%;
        position: absolute;
        left: -16px;
        font-size: 36px;
        font-weight: 900;
        transform: rotate(-90deg);
      }
      @media screen and (max-width: $screen-sm-max) {
        &:before {
          display: none;
        }
      }
      &[class*="icon-"] {
        &[class*="red"] {
          &:before {
            color: $red;
          }
        }
        &[class*="ct-color"] {
          &:before {
            color: $ct_color;
          }
        }
        &[class*="blue"] {
          &:before {
            color: $blue;
          }
        }
        &[class*="aqua"] {
          &:before {
            color: $aqua;
          }
        }
        &[class*="green"] {
          &:before {
            color: $green;
          }
        }
        &[class*="orange"] {
          &:before {
            color: $orange;
          }
        }
        &[class*="black"] {
          &:before {
            color: $black;
          }
        }
        &[class*="silver"] {
          &:before {
            color: $silver;
          }
        }
        &[class*="grey"] {
          &:before {
            color: $grey;
          }
        }
        &[class*="purple"] {
          &:before {
            color: $purple;
          }
        }
        &[class*="lime"] {
          &:before {
            color: $lime;
          }
        }
        &[class*="pink"] {
          &:before {
            color: $pink;
          }
        }
        &[class*="indigo"] {
          &:before {
            color: $indigo;
          }
        }
        &[class*="yellow"] {
          &:before {
            color: $yellow;
          }
        }
        &[class*="teal"] {
          &:before {
            color: $teal;
          }
        }
      }
    }
  }
</style>
