import http from '@/services/HttpService';

export default {
  async getOperators (ddd) {
    const res = await http().get(`/api/v1/recharges/operators/${ddd}`);
    return res.data;
  },
  async getAmounts (ddd, operator) {
    const res = await http().get(`/api/v1/recharges/values/${ddd}/${operator}`);
    return res.data;
  },
};
