const formatValue = (value, precision = 2, symbol = '') => {
  if (typeof value === 'undefined') return value;

  if (typeof value !== 'number') {
    value = parseFloat(value);
  }

  return symbol + value
    .toFixed(precision)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+,)/g, '$1.');
};

export default formatValue;
