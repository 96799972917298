<template>
<div class="row">
  <div class="col-xs-12 col-sm-12">
    <div class="form-group" :class="{'is-invalid': errors.has('kind')}">
      <label for="kind">{{ $t('invoices.modalReversal.crypto.network') }} *</label>
      <ct-select
        name="network"
        id="network"
        class="from-control"
        :class="{'is-invalid': errors.has('network')}"
        :data-vv-as="$t('invoices.modalReversal.crypto.network')"
        v-model="crypto.network"
        :options="validNetWorks"
        :filterable="true"
        :multiple="false"
        v-validate="{ required: true }"
      />
      <div class="invalid-feedback">{{ errors.first('network') }}</div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-6">
    <div class="form-group" :class="{'is-invalid': errors.has('txId')}">
      <label for="txId">{{ $t('invoices.modalReversal.crypto.txId') }} *</label>
      <input
        class="form-control"
        id="txId"
        name="txId"
        :placeholder="$t('invoices.modalReversal.crypto.txId')"
        :class="{'is-invalid': errors.has('txId')}"
        :data-vv-as="$t('invoices.modalReversal.crypto.txId')"
        type="text"
        v-model="crypto.txId"
        v-validate="{ required: true }"
      >
      <div class="invalid-feedback">{{ errors.first('txId') }}</div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-6">
    <div class="form-group" :class="{'is-invalid': errors.has('address')}">
      <label for="address">{{ $t('invoices.modalReversal.crypto.address') }} *</label>
      <input
        class="form-control"
        id="address"
        name="address"
        :placeholder="$t('invoices.modalReversal.crypto.address')"
        :class="{'is-invalid': errors.has('address')}"
        :data-vv-as="$t('invoices.modalReversal.crypto.address')"
        type="text"
        v-model="crypto.address"
        v-validate="{ required: true }"
      >
      <div class="invalid-feedback">{{ errors.first('address') }}</div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-12">
    <h5>
      {{ $t('invoices.modalReversal.reversalValue') }}: BTC {{ currency((reversalCryptoValue || 0)) }}
    </h5>
  </div>

  <div class="col-xs-12 col-sm-12">
    <div class="form-group pull-right form-inline">
      <button
        type="submit"
        class="btn btn-default btn-lg"
        @click="close()"
      >{{$t('invoices.modalReversal.close')}}</button>
      <button type="submit" class="btn btn-danger btn-lg" @click="submit()" :disabled="isLoading">
        <i class="fas fa-spinner fa-pulse" v-if="isLoading"></i>
        {{ $t('invoices.modalReversal.submit') }}
      </button>
    </div>
  </div>
</div>
</template>

<script>
import { CtSelect } from '@citartech/core-ui';
import currency from '@/filters/currency';

export default {
  name: 'FormReversalCrypto',
  props: {
    isLoading: {
      type: Boolean,
    },
    reversalCryptoValue: {
      type: Number,
    },
  },
  components: {
    CtSelect,
  },
  computed: {
    validNetWorks () {
      return [
        { value: 'BTC', label: 'Rede Bitcoin' },
        { value: 'BEP20', label: 'Rede BEP20' },
      ];
    },
  },
  destroyed () {
    this.$emit('input', {
      network: null,
      txId: null,
      address: null,
    });
  },
  data: () => ({
    crypto: {
      network: null,
      txId: null,
      address: null,
    },
  }),
  methods: {
    currency (value) {
      return currency(value, 8);
    },
    async submit () {
      const isLocalValid = await this.$validator.validate();
      if (!isLocalValid) { return; }

      this.$emit('input', this.crypto);
      this.$emit('submit');
    },
    close () {
      this.$emit('close');
    },
  },
};
</script>
