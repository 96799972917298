import AuthService from '../../../services/AuthService';
import { createHash } from 'crypto';
import { parseJwt } from '../../../helpers/jtw.helper';

export default {
  isInternal ({ getters }) {
    return getters.isInternal;
  },

  async executeLogin ({ getters, dispatch }, login) {
    if (getters.isLogged) {
      return getters.getCurrentUser;
    }
    const result = await AuthService.login(login);
    if (result.token) {
      dispatch('setSession', result);
      await dispatch('getCurrentUserData');
      return;
    }

    if (result.reference) {
      return result.reference;
    }

    throw new Error('Unauthorized');
  },

  async executeLoginWithTwoFA ({ getters, dispatch }, data) {
    if (getters.isLogged) {
      return getters.getCurrentUser;
    }
    // eslint-disable-next-line no-useless-catch
    try {
      const result = await AuthService.twofa(data);
      if (result.token) {
        dispatch('setSession', result);
        await dispatch('getCurrentUserData');
      }
    } catch (err) {
      throw err;
    }
  },

  async logout ({ dispatch }) {
    await AuthService.logout();
    dispatch('clearState');
  },

  async getCurrentUserData ({ dispatch }) {
    const res = await AuthService.getCurrentUserData();
    dispatch('setUser', res.data);
  },

  setSession ({ commit }, session) {
    commit('SET_SESSION', session);
  },

  setUser ({ commit }, user) {
    commit('SET_USER', user);
  },

  serUserImage ({ commit }, url) {
    commit('SET_USER_IMAGE', url);
  },

  isLogged ({ state, commit }) {
    const session = state.session;
    if (Object.keys(session).length === 0 || !session.token || session.token === '' || !session.expiresAt) {
      commit('SET_IS_LOGGED', false);
      return false;
    }
    if (!(session.expiresAt instanceof Date)) {
      try {
        session.expiresAt = new Date(session.expiresAt);
      } catch (e) {
        console.error(e);
        return false;
      }
    }
    const parsedToken = parseJwt(session.token);
    // eslint-disable-next-line no-prototype-builtins
    if (!parsedToken.hasOwnProperty('roles')) {
      return {};
    }

    const isExpiredToken = (session.expiresAt.getTime() < new Date().getTime());
    if (isExpiredToken) {
      commit('SET_IS_LOGGED', false);
      return false;
    }
    commit('SET_IS_LOGGED', true);
    return true;
  },

  getUserImageByGravatar ({ state, dispatch }) {
    if (!state.user || !state.user.email) return false;

    if (state.image) {
      return state.image;
    }

    const defaultUrl = 'https://s3-sa-east-1.amazonaws.com/citar.tech/images/no_image.png';
    const email = state.user.email.toLowerCase();
    const hash = createHash('md5').update(email).digest('hex');
    const userUrl = 'https://www.gravatar.com/avatar/' + hash;
    const url = userUrl + '?d=' + defaultUrl;
    dispatch('serUserImage', url);
    return url;
  },
};
