<template>
  <div class="settings-form-component">
    <div class="settings-form">
      <form @submit.prevent>
        <div class="row">
          <div class="col-12">
            <legend class="legend-title-content">
              <span class="legend legend-title">{{ $t('formSettings.formTitle') }}</span>
            </legend>
          </div>

          <div class="col-sm-8 col-xs-12">
            <div class="form-group">
              <label for="name">{{ $t('formSettings.name') }} *</label>
              <input
                autocomplete="off"
                class="form-control"
                :class="{'is-invalid': errors.has('name')}"
                id="name"
                name="name"
                v-validate="{ required: true }"
                :data-vv-as="$t('formSettings.name')"
                :placeholder="$t('formSettings.name')"
                type="text"
                v-model="mutableSettings.name"
              >
              <div class="invalid-feedback">{{ errors.first('name') }}</div>
            </div>
          </div>

          <div class="col-sm-4 col-xs-12">
            <div class="form-group">
              <label for="status">{{ $t('formSettings.status') }} *</label>
              <ct-select
                name="status"
                id="status"
                class="select-control"
                :class="{'is-invalid': errors.has('status')}"
                v-validate="{ required: true }"
                :data-vv-as="$t('formSettings.status')"
                v-model="mutableSettings.status"
                :options="settingsStatus"
              >
                <template slot="option" slot-scope="option">
                  <i class="fa" :class="option.icon"></i>
                  {{ option.label }}
                </template>
              </ct-select>
              <div class="invalid-feedback">{{ errors.first('status') }}</div>
            </div>
          </div>

          <div class="col-sm-6 col-xs-12">
            <div class="form-group">
              <label class="expireInvoice" for="expireInvoice">{{ $t('formSettings.expireInvoice') }} *</label>
              <input
                autocomplete="off"
                class="form-control"
                :class="{'is-invalid': errors.has('expireInvoice')}"
                id="expireInvoice"
                name="expireInvoice"
                v-validate="{ required: true }"
                :data-vv-as="$t('formSettings.expireInvoice')"
                :placeholder="$t('formSettings.expireInvoice')"
                type="text"
                v-model="mutableSettings.expireInvoice"
              >
              <div class="invalid-feedback">{{ errors.first('expireInvoice') }}</div>
            </div>
          </div>

          <div class="col-sm-6 col-xs-12">
            <div class="form-group">
              <label for="referralFee">{{ $t('formSettings.referralFee') }}</label>
              <ct-input-numeric
                :thousand-separator="$t(`currencies.BRL.thousands`)"
                :decimal-separator="$t(`currencies.BRL.decimal`)"
                :precision="2"
                autocomplete="off"
                class="form-control"
                :class="{'is-invalid': errors.has('referralFee')}"
                id="referralFee"
                name="referralFee"
                :data-vv-as="$t('formSettings.referralFee')"
                :placeholder="$t('formSettings.referralFee')"
                v-model="mutableSettings.referralFee"
              ></ct-input-numeric>
              <div class="invalid-feedback">{{ errors.first('referralFee') }}</div>
            </div>
          </div>

           <div class="col-sm-6 col-xs-12">
            <div class="form-group">
              <label for="maxPaymentOverpaidDifference">{{ $t('formSettings.maxPaymentOverpaidDifference') }}</label>
              <ct-input-numeric
                :thousand-separator="$t(`currencies.BTC.thousands`)"
                :decimal-separator="$t(`currencies.BTC.decimal`)"
                :precision="8"
                autocomplete="off"
                class="form-control"
                :class="{'is-invalid': errors.has('maxPaymentOverpaidDifference')}"
                id="maxPaymentOverpaidDifference"
                name="maxPaymentOverpaidDifference"
                :data-vv-as="$t('formSettings.maxPaymentOverpaidDifference')"
                :placeholder="$t('formSettings.maxPaymentOverpaidDifference')"
                v-model="mutableSettings.maxPaymentOverpaidDifference"
              ></ct-input-numeric>
              <div class="invalid-feedback">{{ errors.first('maxPaymentOverpaidDifference') }}</div>
            </div>
          </div>

           <div class="col-sm-6 col-xs-12">
            <div class="form-group">
              <label for="maxPaymentUnderpaidDifference">{{ $t('formSettings.maxPaymentUnderpaidDifference') }}</label>
              <ct-input-numeric
                :thousand-separator="$t(`currencies.BTC.thousands`)"
                :decimal-separator="$t(`currencies.BTC.decimal`)"
                :precision="8"
                autocomplete="off"
                class="form-control"
                :class="{'is-invalid': errors.has('maxPaymentUnderpaidDifference')}"
                id="maxPaymentUnderpaidDifference"
                name="maxPaymentUnderpaidDifference"
                :data-vv-as="$t('formSettings.maxPaymentUnderpaidDifference')"
                :placeholder="$t('formSettings.maxPaymentUnderpaidDifference')"
                v-model="mutableSettings.maxPaymentUnderpaidDifference"
              ></ct-input-numeric>
              <div class="invalid-feedback">{{ errors.first('maxPaymentUnderpaidDifference') }}</div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12">
            <fieldset>
              <legend class="legend-title-form">
                <span class="legend">{{ $t('formSettings.statusServices') }}</span>
              </legend>
              <div class="form-group">
                <div class="checkbox-group">
                  <ct-checkbox v-model="mutableSettings.services.status.billet" id="billet-checkbox"/>
                  <label for="billet-checkbox">{{ $t('formSettings.services.billet' ) }}</label>

                  <ct-checkbox v-model="mutableSettings.services.status.shipment" id="shipment-checkbox"/>
                  <label for="shipment-checkbox">{{ $t('formSettings.services.shipment' ) }}</label>

                  <ct-checkbox v-model="mutableSettings.services.status.pix" id="pix-checkbox"/>
                  <label for="pix-checkbox">{{ $t('formSettings.services.pix' ) }}</label>

                  <ct-checkbox v-model="mutableSettings.services.status.recharge" id="recharge-checkbox"/>
                  <label for="recharge-checkbox">{{ $t('formSettings.services.recharge' ) }}</label>

                  <ct-checkbox v-model="mutableSettings.services.status.opey" id="opey-checkbox"/>
                  <label for="opey-checkbox">{{ $t('formSettings.services.opey' ) }}</label>

                  <ct-checkbox v-model="mutableSettings.services.status.giftcard" id="giftcard-checkbox" />
                  <label for="giftcard-checkbox">{{ $t('formSettings.services.giftcard' ) }}</label>
                </div>
              </div>
            </fieldset>
          </div>
          <div class="col-xs-12 col-sm-12">
            <fieldset>
              <legend class="legend-title-form">
                <span class="legend">{{ $t('formSettings.providerMessages.title') }}</span>
              </legend>
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <label for="providerMessages">{{ $t('formSettings.providerMessages.field') }} *</label>
                      <ct-select
                        name="providerMessages"
                        id="providerMessages"
                        class="select-control"
                        :class="{'is-invalid': errors.has('providerMessages')}"
                        v-validate="{ required: true }"
                        :data-vv-as="$t('formSettings.providerMessages.field')"
                        v-model="mutableSettings.providerMessages"
                        :options="providerMessages"
                      ></ct-select>
                      <div class="invalid-feedback">{{ errors.first('providerMessages') }}</div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div class="col-12">
            <fieldset>
              <legend class="legend-title-form">
                <span class="legend">{{ $t('formSettings.tickerProvidersTitle') }}</span>
              </legend>
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <label for="currentProvider">{{ $t('formSettings.currentProvider') }} *</label>
                      <ct-select
                        name="currentProvider"
                        id="currentProvider"
                        class="select-control"
                        :class="{'is-invalid': errors.has('currentProvider')}"
                        v-validate="{ required: true }"
                        :data-vv-as="$t('formSettings.currentProvider')"
                        v-model="mutableSettings.currentProvider"
                        :options="settingsProviders"
                      ></ct-select>
                      <div class="invalid-feedback">{{ errors.first('currentProvider') }}</div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <fieldset>
              <legend class="legend-title-form">
                <span class="legend">{{ $t('formSettings.rechargeTitle') }}</span>
              </legend>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="minRechargeValue">{{ $t('formSettings.minRechargeValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('minRechargeValue')}"
                      id="minRechargeValue"
                      name="minRechargeValue"
                      v-validate="{ required: true }"
                      :data-vv-as="$t('formSettings.minRechargeValue')"
                      :placeholder="$t('formSettings.minRechargeValue')"
                      v-model="mutableSettings.restriction.minRechargeValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('minRechargeValue') }}</div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="maxRechargeValue">{{ $t('formSettings.maxRechargeValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('maxRechargeValue')}"
                      id="maxRechargeValue"
                      name="maxRechargeValue"
                      v-validate="{ required: true, min_value: mutableSettings.restriction.minRechargeValue }"
                      :data-vv-as="$t('formSettings.maxRechargeValue')"
                      :placeholder="$t('formSettings.maxRechargeValue')"
                      v-model="mutableSettings.restriction.maxRechargeValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('maxRechargeValue') }}</div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <fieldset>
              <legend class="legend-title-form">
                <span class="legend">{{ $t('formSettings.billetTitle') }}</span>
              </legend>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="minBilletValue">{{ $t('formSettings.minBilletValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('minBilletValue')}"
                      id="minBilletValue"
                      name="minBilletValue"
                      v-validate="{ required: true }"
                      :data-vv-as="$t('formSettings.minBilletValue')"
                      :placeholder="$t('formSettings.minBilletValue')"
                      v-model="mutableSettings.restriction.minBilletValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('minBilletValue') }}</div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="maxBilletValue">{{ $t('formSettings.maxBilletValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('maxBilletValue')}"
                      id="maxBilletValue"
                      name="maxBilletValue"
                      v-validate="{ required: true, min_value: mutableSettings.restriction.minBilletValue }"
                      :data-vv-as="$t('formSettings.maxBilletValue')"
                      :placeholder="$t('formSettings.maxBilletValue')"
                      v-model="mutableSettings.restriction.maxBilletValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('maxBilletValue') }}</div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <fieldset>
              <legend class="legend-title-form">
                <span class="legend">{{ $t('formSettings.shipmentTitle') }}</span>
              </legend>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="minShipmentValue">{{ $t('formSettings.minShipmentValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('minShipmentValue')}"
                      id="minShipmentValue"
                      name="minShipmentValue"
                      v-validate="{ required: true }"
                      :data-vv-as="$t('formSettings.minShipmentValue')"
                      :placeholder="$t('formSettings.minShipmentValue')"
                      v-model="mutableSettings.restriction.minShipmentValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('minShipmentValue') }}</div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="maxShipmentValue">{{ $t('formSettings.maxShipmentValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('maxShipmentValue')}"
                      id="maxShipmentValue"
                      name="maxShipmentValue"
                      v-validate="{ required: true, min_value: mutableSettings.restriction.minShipmentValue }"
                      :data-vv-as="$t('formSettings.maxShipmentValue')"
                      :placeholder="$t('formSettings.maxShipmentValue')"
                      v-model="mutableSettings.restriction.maxShipmentValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('maxShipmentValue') }}</div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <fieldset>
              <legend class="legend-title-form">
                <span class="legend">{{ $t('formSettings.pixTitle') }}</span>
              </legend>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="minPixValue">{{ $t('formSettings.minPixValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('minPixValue')}"
                      id="minPixValue"
                      name="minPixValue"
                      v-validate="{ required: true }"
                      :data-vv-as="$t('formSettings.minPixValue')"
                      :placeholder="$t('formSettings.minPixValue')"
                      v-model="mutableSettings.restriction.minPixValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('minPixValue') }}</div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="maxPixValue">{{ $t('formSettings.maxPixValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('maxPixValue')}"
                      id="maxPixValue"
                      name="maxPixValue"
                      v-validate="{ required: true, min_value: mutableSettings.restriction.minPixValue }"
                      :data-vv-as="$t('formSettings.maxPixValue')"
                      :placeholder="$t('formSettings.maxPixValue')"
                      v-model="mutableSettings.restriction.maxPixValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('maxPixValue') }}</div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <fieldset>
              <legend class="legend-title-form">
                <span class="legend">{{ $t('formSettings.opeyTitle') }}</span>
              </legend>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="minRechargeOpeyValue">{{ $t('formSettings.minRechargeOpeyValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('minRechargeOpeyValue')}"
                      id="minRechargeOpeyValue"
                      name="minRechargeOpeyValue"
                      v-validate="{ required: true }"
                      :data-vv-as="$t('formSettings.minRechargeOpeyValue')"
                      :placeholder="$t('formSettings.minRechargeOpeyValue')"
                      v-model="mutableSettings.restriction.minRechargeOpeyValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('minRechargeOpeyValue') }}</div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="maxRechargeOpeyValue">{{ $t('formSettings.maxRechargeOpeyValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('maxRechargeOpeyValue')}"
                      id="maxRechargeOpeyValue"
                      name="maxRechargeOpeyValue"
                      v-validate="{ required: true, min_value: mutableSettings.restriction.minRechargeOpeyValue }"
                      :data-vv-as="$t('formSettings.maxRechargeOpeyValue')"
                      :placeholder="$t('formSettings.maxRechargeOpeyValue')"
                      v-model="mutableSettings.restriction.maxRechargeOpeyValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('maxRechargeOpeyValue') }}</div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <fieldset>
              <legend class="legend-title-form">
                <span class="legend">{{ $t('formSettings.giftCardTitle') }}</span>
              </legend>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="minGiftCardValue">{{ $t('formSettings.minGiftCardValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('minGiftCardValue')}"
                      id="minGiftCardValue"
                      name="minGiftCardValue"
                      v-validate="{ required: true }"
                      :data-vv-as="$t('formSettings.minGiftCardValue')"
                      :placeholder="$t('formSettings.minGiftCardValue')"
                      v-model="mutableSettings.restriction.minGiftCardValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('minGiftCardValue') }}</div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="maxGiftCardValue">{{ $t('formSettings.maxGiftCardValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('maxGiftCardValue')}"
                      id="maxGiftCardValue"
                      name="maxGiftCardValue"
                      v-validate="{ required: true, min_value: mutableSettings.restriction.minGiftCardValue }"
                      :data-vv-as="$t('formSettings.maxGiftCardValue')"
                      :placeholder="$t('formSettings.maxGiftCardValue')"
                      v-model="mutableSettings.restriction.maxGiftCardValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('maxGiftCardValue') }}</div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="col-sm-12">
            <fieldset>
              <legend class="legend-title-form">
                <span class="legend">{{ $t('formSettings.shipmentLimitsTitle') }}</span>
              </legend>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="maxShipmentDailyLimitValue">{{ $t('formSettings.maxShipmentDailyLimitValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('maxShipmentDailyLimitValue')}"
                      id="maxShipmentDailyLimitValue"
                      name="maxShipmentDailyLimitValue"
                      v-validate="{ required: true }"
                      :data-vv-as="$t('formSettings.maxShipmentDailyLimitValue')"
                      :placeholder="$t('formSettings.maxShipmentDailyLimitValue')"
                      v-model="mutableSettings.restriction.maxShipmentDailyLimitValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('maxShipmentDailyLimitValue') }}</div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="maxShipmentMonthlyLimitValue">{{ $t('formSettings.maxShipmentMonthlyLimitValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('maxShipmentMonthlyLimitValue')}"
                      id="maxShipmentMonthlyLimitValue"
                      name="maxShipmentMonthlyLimitValue"
                      v-validate="{ required: true }"
                      :data-vv-as="$t('formSettings.maxShipmentMonthlyLimitValue')"
                      :placeholder="$t('formSettings.maxShipmentMonthlyLimitValue')"
                      v-model="mutableSettings.restriction.maxShipmentMonthlyLimitValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('maxShipmentMonthlyLimitValue') }}</div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="col-12 col-item-fees">
            <fieldset>
              <legend class="legend-title-form">
                <span class="legend">{{ $t('formSettings.itemFees') }}</span>
              </legend>
              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <label for="billetFee">{{ $t('formSettings.billetTitle') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('billetFee')}"
                      id="billetFee"
                      name="billetFee"
                      v-validate="{ required: true }"
                      :data-vv-as="$t('formSettings.billetTitle')"
                      v-model="mutableSettings.itemFees.billet"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('billetFee') }}</div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label for="shipmentFee">{{ $t('formSettings.shipmentTitle') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('shipmentFee')}"
                      id="shipmentFee"
                      name="shipmentFee"
                      v-validate="{ required: true }"
                      :data-vv-as="$t('formSettings.shipmentTitle')"
                      v-model="mutableSettings.itemFees.shipment"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('shipmentFee') }}</div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label for="pixFee">{{ $t('formSettings.pixTitle') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('pixFee')}"
                      id="pixFee"
                      name="pixFee"
                      v-validate="{ required: true }"
                      :data-vv-as="$t('formSettings.pixTitle')"
                      v-model="mutableSettings.itemFees.pix"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('pixFee') }}</div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label for="rechargeFee">{{ $t('formSettings.rechargeTitle') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('rechargeFee')}"
                      id="rechargeFee"
                      name="rechargeFee"
                      v-validate="{ required: true }"
                      :data-vv-as="$t('formSettings.rechargeTitle')"
                      v-model="mutableSettings.itemFees.recharge"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('rechargeFee') }}</div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label for="opeyFee">{{ $t('formSettings.opeyTitle') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('opeyFee')}"
                      id="opeyFee"
                      name="opeyFee"
                      v-validate="{ required: true }"
                      :data-vv-as="$t('formSettings.opeyTitle')"
                      v-model="mutableSettings.itemFees.opey"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('opeyFee') }}</div>
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group">
                  <label for="giftCardFee">{{ $t('formSettings.giftCardTitle') }} *</label>
                      <ct-input-numeric
                        :thousand-separator="$t(`currencies.BRL.thousands`)"
                        :decimal-separator="$t(`currencies.BRL.decimal`)"
                        :precision="2"
                        autocomplete="off"
                        class="form-control"
                        :class="{'is-invalid': errors.has('giftCardFee')}"
                        id="giftCardFee"
                        name="giftCardFee"
                        v-validate="{ required: true }"
                        :data-vv-as="$t('formSettings.giftCardTitle')"
                        v-model="mutableSettings.itemFees.giftCard"
                      ></ct-input-numeric>
                      <div class="invalid-feedback">{{ errors.first('giftCardFee') }}</div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <br /><br />
          <div class="col-12 col-table">
            <fieldset>
              <legend class="legend-title-form">
                <span class="legend">{{ $t('formSettings.fees') }}</span>
                <button class="btn btn-primary" @click="addFee" style="float: right;">
                  Adicionar Taxa <i class="fa fa-plus"></i>
                </button>
              </legend>
              <div class="row">
                <div class="col-12">
                  <table class="table dataTable no-footer dtr-inline">
                    <thead>
                      <tr>
                        <th>{{ $t('formSettings.min') }}</th>
                        <th>{{ $t('formSettings.max') }}</th>
                        <th>{{ $t('formSettings.spread') }}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(fee, k) in mutableSettings.fees" :key="k">
                        <td>
                          <ct-input-numeric
                            :thousand-separator="$t(`currencies.BRL.thousands`)"
                            :decimal-separator="$t(`currencies.BRL.decimal`)"
                            :precision="2"
                            autocomplete="off"
                            class="form-control"
                            :class="{'is-invalid': errors.has('min')}"
                            name="min"
                            v-validate="{ required: true }"
                            :data-vv-as="$t('formSettings.min')"
                            :placeholder="$t('formSettings.min')"
                            v-model="mutableSettings.fees[k].min"
                          ></ct-input-numeric>
                        </td>
                        <td>
                          <ct-input-numeric
                            :thousand-separator="$t(`currencies.BRL.thousands`)"
                            :decimal-separator="$t(`currencies.BRL.decimal`)"
                            :precision="2"
                            autocomplete="off"
                            class="form-control"
                            :class="{'is-invalid': errors.has('v')}"
                            name="max"
                            v-validate="{ required: true }"
                            :data-vv-as="$t('formSettings.max')"
                            :placeholder="$t('formSettings.max')"
                            v-model="mutableSettings.fees[k].max"
                          ></ct-input-numeric>
                        </td>
                        <td>
                          <ct-input-numeric
                            :thousand-separator="$t(`currencies.BRL.thousands`)"
                            :decimal-separator="$t(`currencies.BRL.decimal`)"
                            :precision="2"
                            autocomplete="off"
                            class="form-control"
                            :class="{'is-invalid': errors.has('spread')}"
                            name="spread"
                            v-validate="{ required: true }"
                            :data-vv-as="$t('formSettings.spread')"
                            :placeholder="$t('formSettings.spread')"
                            v-model="mutableSettings.fees[k].spread"
                          ></ct-input-numeric>
                        </td>
                        <td style="text-align: center;">
                          <button class="btn btn-xs" style="font-size: 12px" @click="removeFee(k)">
                            <i class="fa fa-times"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </fieldset>

          </div>

        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12">
            <div class="form-group pull-right form-inline">
              <button
                class="btn btn-default btn-lg"
                type="submit"
                @click="close"
              >{{$t('formSettings.cancel')}}</button>
              <button
                class="btn btn-primary btn-lg"
                type="submit"
                @click="save"
              >{{ $t('formSettings.submit') }}</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { CtSelect } from '@citartech/core-ui';
import Settings from '../../model/Settings';
import CtCheckbox from '@/components/Shared/Checkbox';
import date from '../../filters/datetime';
import CtInputNumeric from '@/components/Shared/InputNumeric';

export default {
  name: 'SettingsForm',
  components: {
    CtSelect,
    CtCheckbox,
    CtInputNumeric,
  },
  filters: {
    date,
  },
  props: {
    title: {
      type: String,
    },
    settings: {
      type: Object,
    },
  },
  data () {
    return {
      mutableSettings: new Settings(),
    };
  },
  methods: {
    ...mapActions(['saveSettings']),
    removeFee (index) {
      this.mutableSettings.fees.splice(index, 1);
    },
    addFee () {
      if (!this.mutableSettings.fees) {
        this.mutableSettings.fees = [];
      }
      this.mutableSettings.fees.push({
        min: 0,
        max: 0,
        spread: 0,
        deletedAt: null,
        createdAt: null,
        updatedAt: null,
      });
    },
    close () {
      this.$emit('close');
    },
    async save () {
      try {
        const isValid = await this.$validator.validate();
        if (!isValid) {
          this.$notify({
            type: 'error',
            title: this.$t('notification.notificationErrorTitle'),
            text: this.$t('notification.notificationErrorMessage'),
          });
          return;
        }
        await this.saveSettings(this.mutableSettings);
        this.$notify({
          type: 'success',
          title: this.$t('formSettings.notificationSuccessTitle'),
          text: this.settings._id ? this.$t('formSettings.notificationSuccessMessage') : this.$t('settings.notificationSuccessMessage2'),
        });
        this.close();
      } catch (e) {
        this.processErrorOnSave(e.response || {});
      }
    },
    processErrorOnSave (response) {
      let messageText = '';
      if (response.status === 422 || response.data.errors) {
        Object.keys(response.data.errors).forEach(index => {
          messageText +=
            index.split('.').pop() +
            ': ' +
            response.data.errors[index].shift() +
            '</br>';
        });
      }
      this.$notify({
        type: 'error',
        title:
          response.data.message || this.$t('formSettings.notificationErrorTitle'),
        text:
          messageText !== ''
            ? messageText
            : this.$t('formSettings.notificationErrorMessage'),
      });
    },
  },
  computed: {
    ...mapState({
      settingsStatus: state => state.selects.settingsStatus || {},
      settingsProviders: state => state.selects.settingsProviders || {},
      providerMessages: state => state.selects.providerMessages || {},
    }),
  },
  created () {
    if (this.settings && this.settings instanceof Settings) {
      this.mutableSettings = this.settings;
    }
  },
};
</script>

<style lang="scss" scoped>
.settings-form-component {
  .legend-title-form {
    font-weight: 400;
    font-size: 16px;
  }

  .select-control.is-invalid ~ .invalid-feedback {
    display: block;
  }

  label.expireInvoice {
    font-size: 11px;
  }

  .col-item-fees {
    margin-top: 40px;
  }

  .col-table {
    margin-top: 40px;

    .btn-xs {
      margin-top: 7px;
    }
  }
}
</style>
