<template>
  <div>
    <div v-if="value.length > 0 && !disabled" class="cell-dropdown-action-component" :class="{ 'cell-button-hover': hover }">
      <b-dropdown
        class="input-options-selector"
        :text="text"
        variant="primary"
        slot="prepend"
      >
        <b-dropdown-item
          v-for="(option, index) in value"
          :key="index"
          @click="buttonClick(option)"
        >
          {{ option.text }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div v-else-if="disabled" class="cell-dropdown-action-component-disabled">
       <b-dropdown
        class="input-options-selector"
        :text="text"
        variant="default"
        slot="prepend"
        :disabled="true"
      >
        <b-dropdown-item
          v-for="(option, index) in value"
          :key="index"
          @click="buttonClick(option)"
        >
          {{ option.text }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '#212529',
    },
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    hover: {
      type: Boolean,
      required: false,
    },
    value: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    buttonClick (value) {
      this.$emit('button', { ...value, name: this.name });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
