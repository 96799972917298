const formatValue = (list, sort, columnsNames) => {
  const table = {
    headers: [
      {
        name: '_id',
        text: columnsNames && '_id' in columnsNames ? columnsNames._id : '',
        width: '4%',
        order: sort && '_id' in sort ? sort._id : true,
        isFilter: true,
        isPrint: true,
        isSpecialSearch: true,
      },
      {
        name: 'createdBy.email',
        text: columnsNames && 'createdBy.email' in columnsNames ? columnsNames['createdBy.email'] : '',
        width: '10%',
        order: false,
        isFilter: false,
        isPrint: true,
      },
      {
        name: 'reference',
        text: columnsNames && 'reference' in columnsNames ? columnsNames.reference : '',
        width: '8%',
        order: sort && 'reference' in sort ? sort.reference : true,
        isFilter: true,
        isPrint: true,
      },
      {
        name: 'version',
        text: columnsNames && 'version' in columnsNames ? columnsNames.version : '',
        width: '5%',
        order: sort && 'version' in sort ? sort.version : true,
        isFilter: true,
        isPrint: true,
      },
      {
        name: 'year',
        text: columnsNames && 'year' in columnsNames ? columnsNames.year : '',
        width: '5%',
        order: sort && 'year' in sort ? sort.year : true,
        isFilter: true,
        isPrint: true,
      },
      {
        name: 'month',
        text: columnsNames && 'month' in columnsNames ? columnsNames.month : '',
        width: '5%',
        order: sort && 'month' in sort ? sort.month : true,
        isFilter: true,
        isPrint: true,
      },
      {
        name: 'createdAt',
        text: columnsNames && 'createdAt' in columnsNames ? columnsNames.createdAt : '',
        width: '8%',
        order: sort && 'createdAt' in sort ? sort.createdAt : true,
        isPrint: true,
      },
      {
        name: 'file',
        text: columnsNames && 'file' in columnsNames ? columnsNames.file : '',
        width: '5%',
        order: false,
        isFilter: false,
        isPrint: false,
        isSpecialSearch: false,
      },
    ],
    lines: [],
    linesPrint: [],
  };

  if (list && list.data && list.data.length > 0) {
    for (let i = 0; i < list.data.length; i++) {
      const createdAt = new Date(list.data[i].createdAt);

      const id = { type: 'id', text: list.data[i]._id };

      const download = {
        name: 'download',
        type: 'button-text',
        text: '',
        icon: 'fa-eye',
        value: {
          action: 'download',
          url: list.data[i].file,
        },
        disabled: !list.data[i].file,
      };

      const createdBy = list.data[i].createdBy || {};

      const lines = [
        id,
        createdBy.email || '-',
        list.data[i].reference || '-',
        list.data[i].version,
        list.data[i].year,
        list.data[i].month,
        createdAt.toLocaleString('pt-BR'),
        download,
      ];

      const linesPrint = [
        list.data[i]._id,
        createdBy.email || '-',
        list.data[i].reference || '-',
        list.data[i].version || '-',
        list.data[i].year || '-',
        list.data[i].month || '-',
        createdAt.toLocaleString('pt-BR'),
      ];

      table.lines.push(lines);
      table.linesPrint.push(linesPrint);
    }
  }

  return table;
};

export default formatValue;
