import http from '@/services/HttpService';

export default {
  async create (data) {
    const res = await http().post('/api/v1/settings', data);
    return res.data;
  },

  async update (data) {
    const res = await http().put('/api/v1/settings/' + data._id, data);
    return res.data;
  },

  async getList ({ filters, search, limit, page, orderBy }) {
    try {
      let url = `/api/v1/settings?page=${page}&limit=${limit}`;
      if (!page || limit === 0) {
        url = `/api/v1/settings?limit=${limit}`;
      }

      if (filters && filters.length > 0) {
        const filtersQuery = JSON.stringify({
          field: 'status',
          operation: 'in',
          value: filters,
        });
        url += `&filters[]=${filtersQuery}&`;
      }
      const liked = ['name'];
      if (search && search.value && search.value !== '') {
        const filterSearch = JSON.stringify({
          field: search.field,
          operation: liked.indexOf(search.field) >= 0 ? 'like' : '=',
          value: search.value,
        });
        url += `&filters[]=${filterSearch}`;
      }
      if (orderBy) {
        const key = Object.keys(orderBy)[0];
        url += `&orderBy=${key}&orderByDirection=${orderBy[key] || 'desc'}`;
      }

      const response = await http().get(url);
      return response.data || {
        data: [],
        meta: {},
      };
    } catch (error) {
      return error;
    }
  },

  async getCurrentSettings () {
    const res = await http().get('/api/v1/settings/current');
    return res.data.data || {};
  },
};
