<template>
  <div class="user-form-component">
    <b-tabs nav-class="nav-user-register">
      <b-tab :title="tabs.tab1" active>
        <div class="user-form">
          <form @submit.prevent>
            <div class="row">
              <div class="col-12">
                <legend class="legend-title-content">
                  <span class="legend legend-title"> </span>
                  <span class="pull-right">
                    <div class="icon" :class="mutableUser.statusColor">
                      <i class="icon-symbol" :class="mutableUser.statusIcon"></i>
                      {{ mutableUser.statusLabel }}
                    </div>
                  </span>
                </legend>
              </div>

              <div class="col-xs-12 col-sm-6">
                <div class="form-group">
                  <label for="name">{{ $t('formUser.name') }} *</label>
                  <input
                    autocomplete="off"
                    class="form-control"
                    :class="{'is-invalid': errors.has('name')}"
                    id="name"
                    name="name"
                    v-validate="{ required: true }"
                    :data-vv-as="$t('formUser.name')"
                    :placeholder="$t('formUser.name')"
                    type="text"
                    v-model="mutableUser.name"
                  >
                  <div class="invalid-feedback">{{ errors.first('name') }}</div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-6">
                <div class="form-group" :class="{'is-invalid': errors.has('roles')}">
                  <label for="roles">{{ $t('formUser.roles') }} *</label>
                  <ct-select
                    name="roles"
                    id="roles"
                    class="from-control"
                    :class="{'is-invalid': errors.has('roles')}"
                    v-model="mutableUser.roles"
                    :options="roles"
                    :multiple="true"
                    v-validate="{ required: true }"
                    :disabled="!isAdmin"
                  />
                  <div class="invalid-feedback">{{ errors.first('roles') }}</div>
                </div>
              </div>

              <div class="col-12"
                   :class="{'col-lg-6 col-md-6 col-sm-6': isNewUser, 'col-lg-12 col-md-12 col-sm-12': !isNewUser}">
                <div class="form-group">
                  <label for="email">{{ $t('formUser.email') }}  *</label>
                  <input
                    class="form-control"
                    :class="{'is-invalid': errors.has('email')}"
                    autocomplete="new-email"
                    id="email"
                    name="email"
                    v-validate="{ required: true, email: true }"
                    :placeholder=" $t('formUser.email') "
                    type="email"
                    v-model="mutableUser.email"
                  >
                  <div class="invalid-feedback">{{ errors.first('email') }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-6" v-if="isNewUser">
                <div class="form-group">
                  <label>{{ $t('formUser.password') }} *</label>
                  <div class="input-group mb-2">
                    <input
                      type="password"
                      name="password"
                      autocomplete="new-password"
                      class="form-control"
                      :class="{'is-invalid': errors.has('password')}"
                      v-model="mutableUser.password"
                      :placeholder="$t('formUser.password')"
                      v-if="!showPassword"
                      v-validate="{ required: isNewUser, min: 6 }"
                    >
                    <input
                      type="text"
                      name="password"
                      autocomplete="new-password"
                      class="form-control"
                      :class="{'is-invalid': errors.has('password')}"
                      v-model="mutableUser.password"
                      :placeholder="$t('formUser.password')"
                      v-if="showPassword"
                      v-validate="{ required: isNewUser, min: 6 }"
                    >

                    <div class="input-group-append" @click="toggleShowPassword">
                      <div class="input-group-text">
                        <i
                          class="fa"
                          :class="{'fa-eye-slash': showPassword, 'fa-eye': !showPassword}"
                        ></i>
                      </div>
                    </div>
                    <div class="invalid-feedback">{{ errors.first('password') }}</div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-3">
                <div class="form-group" :class="{'is-invalid': errors.has('status')}">
                  <label for="status">{{ $t('formUser.status') }} *</label>
                  <ct-select
                    name="status"
                    id="status"
                    class="from-control"
                    :class="{'is-invalid': errors.has('status')}"
                    v-model="mutableUser.status"
                    :options="userStatus"
                  >
                    <template slot="option" slot-scope="option">
                      <i class="fa" :class="option.icon"></i>
                      {{ option.label }}
                    </template>
                  </ct-select>
                  <div class="invalid-feedback">{{ errors.first('status') }}</div>
                  <div class v-if="mutableUser.statusDescription">
                    <strong>{{ $t('formUser.statusDescription') + ': ' }}</strong>
                    {{ mutableUser.statusDescription }}
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-2">
                <div class="form-group" :class="{'is-invalid': errors.has('roles')}">
                  <label for="language">{{ $t('formUser.language') }} *</label>
                  <ct-select
                    name="language"
                    id="language"
                    class
                    :class="{'is-invalid': errors.has('language')}"
                    v-model="mutableUser.language"
                    :options="languages"
                    v-validate="{ required: true }"
                  />
                  <div class="invalid-feedback">{{ errors.first('language') }}</div>
                </div>
              </div>

              <div class="col-12 col-sm-4">
                <div class="form-group" :class="{'is-invalid': errors.has('referralId')}">
                  <label for="referralId">{{ $t('formUser.referralId') }}</label>
                  <ct-select
                    name="referralId"
                    id="referralId"
                    class="from-control"
                    :class="{'is-invalid': errors.has('referralId')}"
                    v-model="mutableUser.referralId"
                    :options="listReferrals"
                    :disabled="!isAdmin"
                    :onSearch="onSearchReferral"
                    :clearSearchOnSelect="true"
                    :emptyLabel="'Digite para buscar um usuário'"
                    @input="refreshListUsersReferral"
                    @search:blur="refreshListUsersReferral"
                  />
                  <div class="invalid-feedback">{{ errors.first('referralId') }}</div>
                </div>
              </div>

              <div class="col-12 col-sm-3">
                <div class="form-group">
                  <label for="referralFee">{{ $t('formUser.referralFee') }}</label>
                  <ct-input-numeric
                    :thousand-separator="$t(`currencies.BRL.thousands`)"
                    :decimal-separator="$t(`currencies.BRL.decimal`)"
                    :precision="2"
                    autocomplete="off"
                    class="form-control"
                    :class="{'is-invalid': errors.has('referralFee')}"
                    id="referralFee"
                    name="referralFee"
                    :data-vv-as="$t('formUser.referralFee')"
                    :placeholder="$t('formUser.referralFee')"
                    v-model="mutableUser.referralFee"
                    :disabled="!isAdmin"
                  ></ct-input-numeric>
                  <div class="invalid-feedback">{{ errors.first('referralFee') }}</div>
                </div>
              </div>

            </div>
            <fieldset>
              <legend>
                <span class="legend">{{ $t('formUser.complementTitle') }}</span>
              </legend>

              <div class="row">
                <div class="col-12">
                  <div class="form-group" :class="{'is-invalid': errors.has('country')}">
                    <label for="country">{{ $t('formUser.country') }} *</label>
                    <ct-select
                      name="country"
                      id="country"
                      class
                      :class="{'is-invalid': errors.has('language')}"
                      v-model="mutableUser.country"
                      :options="countriesCodes"
                      v-validate="{ required: true }"
                    />
                    <div class="invalid-feedback">{{ errors.first('country') }}</div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4">
                  <div class="form-group">
                    <label for="kind">{{ $t('formUser.kind') }} *</label>
                    <ct-select
                      name="kind"
                      id="kind"
                      class="from-control"
                      :class="{'is-invalid': errors.has('kind')}"
                      v-model="mutableUser.kind"
                      :options="legalTypes"
                      v-validate="{ required: true }"
                      :data-vv-as="$t('dashboard.player.kind')"
                    />
                    <div class="invalid-feedback">{{ errors.first('kind') }}</div>
                  </div>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-4">
                  <div class="form-group">
                    <label
                      for="document"
                    >{{ (mutableUser.kind === 'F' ? $t('formUser.documentPerson') : $t('formUser.documentLegal')) }} *</label>
                    <the-mask
                      id="document"
                      :mask="documentMask"
                      v-model="mutableUser.document"
                      type="text"
                      :masked="false"
                      :placeholder="(mutableUser.kind === 'F' ? $t('formUser.documentPerson') : $t('formUser.documentLegal'))"
                      class="form-control"
                    ></the-mask>
                  </div>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-4">
                  <div class="form-group">
                    <label
                      for="document-secondary"
                    >{{ (mutableUser.kind === 'F' ? $t('formUser.documentSecondaryPerson') : $t('formUser.documentSecondaryLegal')) }}</label>
                    <input
                      class="form-control"
                      id="document-secondary"
                      name="documentSecondary"
                      :placeholder="(mutableUser.kind === 'F' ? $t('formUser.documentSecondaryPerson') : $t('formUser.documentSecondaryLegal'))"
                      type="text"
                      v-model="mutableUser.documentSecondary"
                    >
                  </div>
                </div>

                <div class="col-xs-12 col-sm-4 col-md-1">
                  <div class="form-group">
                    <label for="phoneCode">{{ $t('formUser.phoneCode') }} *</label>
                    <the-mask
                      mask="####"
                      v-model="mutableUser.phoneCode"
                      type="text"
                      id="phoneCode"
                      name="phoneCode"
                      :masked="false"
                      :placeholder="$t('formUser.phoneCode')"
                      :class="{'is-invalid': errors.has('phoneCode')}"
                      v-validate="{required: true}"
                      :data-vv-as="$t('formUser.phoneCode')"
                      class="form-control"
                    ></the-mask>
                    <div class="invalid-feedback">{{ errors.first('phoneCode') }}</div>
                  </div>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-3">
                  <div class="form-group">
                    <label for="phone">{{ $t('formUser.phone') }} *</label>
                    <the-mask
                      :mask="[$t('formUser.masks.telephone'), $t('formUser.masks.telephone1')]"
                      v-model="mutableUser.phone"
                      type="text"
                      id="phone"
                      name="phone"
                      :masked="false"
                      :placeholder="$t('formUser.phone')"
                      :class="{'is-invalid': errors.has('phone')}"
                      v-validate="{required: true}"
                      :data-vv-as="$t('formUser.phone')"
                      class="form-control"
                    ></the-mask>
                    <div class="invalid-feedback">{{ errors.first('phone') }}</div>
                  </div>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-4">
                  <div class="form-group">
                    <label for="phone-secondary">{{ $t('formUser.phoneSecondary') }}</label>
                    <the-mask
                      :mask="[$t('formUser.masks.telephone'), $t('formUser.masks.telephone1')]"
                      v-model="mutableUser.phoneSecondary"
                      type="text"
                      id="phone-secondary"
                      :masked="false"
                      :placeholder="$t('formUser.phoneSecondary')"
                      class="form-control"
                    ></the-mask>
                  </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-4">
                  <div class="form-group date-picker">
                    <label>{{ $t('formUser.birth') }}</label>
                    <ct-date-picker
                      v-model="mutableUser.birth"
                      :format="dateFormat"
                    ></ct-date-picker>
                  </div>
                </div>
              </div>
            </fieldset>

            <fieldset>
              <legend>
                <span class="legend">{{ $t('formUser.addressesTitle') }}</span>
              </legend>

              <div class="row">
                <div class="col-xs-12 col-sm-3">
                  <div class="form-group">
                    <label for="zip_code">{{ $t('address.zipCode') }} *</label>
                    <the-mask
                      class="form-control"
                      :class="{'is-invalid': errors.has('zipCode')}"
                      id="zip_code"
                      name="zipCode"
                      v-validate="{required: true, zipCode: true, regex: /\d{5}-\d{3}/}"
                      :data-vv-as="$t('address.zipCode')"
                      :mask="$t('formUser.masks.zipCode')"
                      :masked="true"
                      :placeholder="$t('address.zipCode')"
                      type="text"
                      v-model="mutableUser.addresses[0].zipCode"
                      v-on:change.native="onChangeZipCode"
                    ></the-mask>
                    <div class="invalid-feedback">{{ errors.first('zipCode') }}</div>
                  </div>
                </div>

                <div class="col-xs-12 col-sm-6">
                  <div class="form-group">
                    <label for="address">{{ $t('address.address') }} *</label>
                    <input
                      class="form-control"
                      :class="{'is-invalid': errors.has('address')}"
                      id="address"
                      name="address"
                      v-validate="{required: true}"
                      :data-vv-as="$t('address.address')"
                      :placeholder="$t('address.address')"
                      type="text"
                      v-model.trim="mutableUser.addresses[0].address"
                    >
                    <div class="invalid-feedback">{{ errors.first('address') }}</div>
                  </div>
                </div>

                <div class="col-xs-12 col-sm-3">
                  <div class="form-group">
                    <label for="number">{{ $t('address.number') }} *</label>
                    <input
                      class="form-control"
                      :class="{'is-invalid': errors.has('number')}"
                      v-validate="{required: true}"
                      :data-vv-as="$t('address.number')"
                      id="number"
                      name="number"
                      :placeholder="$t('address.number')"
                      type="text"
                      v-model.trim="mutableUser.addresses[0].number"
                    >
                    <div class="invalid-feedback">{{ errors.first('number') }}</div>
                  </div>
                </div>

                <div class="col-xs-12 col-sm-3">
                  <div class="form-group">
                    <label for="complement">{{ $t('address.complement') }}</label>
                    <input
                      class="form-control"
                      id="complement"
                      name="complement"
                      :placeholder="$t('address.complement')"
                      type="text"
                      v-model="mutableUser.addresses[0].complement"
                    >
                  </div>
                </div>

                <div class="col-xs-12 col-sm-3">
                  <div class="form-group">
                    <label for="district">{{ $t('address.district') }} *</label>
                    <input
                      class="form-control"
                      :class="{'is-invalid': errors.has('district')}"
                      v-validate="{required: true}"
                      :data-vv-as="$t('address.district')"
                      id="district"
                      name="district"
                      :placeholder="$t('address.district')"
                      type="text"
                      v-model.trim="mutableUser.addresses[0].district"
                    >
                    <div class="invalid-feedback">{{ errors.first('district') }}</div>
                  </div>
                </div>

                <div class="col-xs-12 col-sm-3">
                  <div class="form-group">
                    <label for="city">{{ $t('address.city') }} *</label>
                    <input
                      class="form-control"
                      :class="{'is-invalid': errors.has('city')}"
                      v-validate="{required: true}"
                      :data-vv-as="$t('address.city')"
                      id="city"
                      name="city"
                      :placeholder="$t('address.city')"
                      type="text"
                      v-model.trim="mutableUser.addresses[0].city"
                    >
                    <div class="invalid-feedback">{{ errors.first('city') }}</div>
                  </div>
                </div>

                <div class="col-xs-12 col-sm-3">
                  <div class="form-group">
                    <label for="state">{{ $t('address.uf') }} *</label>
                    <ct-select
                      name="state"
                      :class="{'is-invalid': errors.has('state')}"
                      v-validate="{required: true}"
                      :data-vv-as="$t('address.uf')"
                      id="state"
                      v-model="mutableUser.addresses[0].uf"
                      :options="states"
                    />
                    <div class="invalid-feedback">{{ errors.first('state') }}</div>
                  </div>
                </div>
              </div>
            </fieldset>

            <div class="row">
              <div class="col-xs-12 col-sm-3">
                <div class="form-group">
                  <label>{{ $t('formUser.enabledReferralSmsNotification') }}</label>
                  <div class="checkbox-group">
                    <ct-checkbox v-model="mutableUser.enabledReferralSmsNotification" />
                    <label>{{ $t('formUser.enabledReferralSmsNotificationLabel') }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-12">
                <ct-user-events :events="mutableUser.events" v-if="isInternal && mutableUser.events" />
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12 col-sm-12">
                <span
                  class="badge badge-default badge-square"
                  :class="{'badge-default': !mutableUser.isEnabled2fa, 'badge-success': mutableUser.isEnabled2fa}"
                >{{ mutableUser.isEnabled2fa ? $t('formUser.faenable') : $t('formUser.fadisable') }}</span>

                <span
                  class="badge badge-default badge-square"
                  v-if="mutableUser.createdAt"
                >{{ $t('formUser.createdAt')}}: {{ mutableUser.createdAt | date}}</span>
                <span
                  class="badge badge-default badge-square"
                  v-if="mutableUser.updatedAt"
                >{{ $t('formUser.updatedAt')}}: {{ mutableUser.updatedAt | date}}</span>

                <span
                  class="badge badge-default badge-square"
                  v-if="mutableUser.partnerCode"
                ><a v-bind:href="referralLink" target="_blank">{{ referralLink }}</a></span>
              </div>
            </div>
          </form>
        </div>
      </b-tab>
      <b-tab :title="tabs.tab2" v-if="isAdmin && !isNewUser">
        <div class="row">
          <div class="col-12" :class="{ 'col-sm-6': mutableUser.changeSettings }">
            <div class="form-group">
              <div class="checkbox-group">
                <ct-checkbox v-model="mutableUser.changeSettings" />
                <label @click="mutableUser.changeSettings = !mutableUser.changeSettings">
                  {{ $t('formUser.changeSettings') }}
                </label>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 text-right" v-if="mutableUser.changeSettings">
            <button class="btn btn-primary" @click="copyDefaultSettings">
              <i class="fas fa-copy" style="color: white; margin-right: 5px"></i> Copiar Configuração Padrão
            </button>
          </div>
        </div>
        <div class="row" v-if="mutableUser.changeSettings">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <fieldset>
              <legend class="legend-title-form">
                <span class="legend">{{ $t('formSettings.rechargeTitle') }}</span>
              </legend>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="minRechargeValue">{{ $t('formSettings.minRechargeValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('minRechargeValue')}"
                      id="minRechargeValue"
                      name="minRechargeValue"
                      v-validate="{}"
                      :empty-value="null"
                      :data-vv-as="$t('formSettings.minRechargeValue')"
                      :placeholder="$t('formSettings.minRechargeValue')"
                      v-model="mutableUser.settings.restriction.minRechargeValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('minRechargeValue') }}</div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="maxRechargeValue">{{ $t('formSettings.maxRechargeValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('maxRechargeValue')}"
                      id="maxRechargeValue"
                      name="maxRechargeValue"
                      :empty-value="null"
                      v-validate="{ min_value: mutableUser.settings.restriction.minRechargeValue }"
                      :data-vv-as="$t('formSettings.maxRechargeValue')"
                      :placeholder="$t('formSettings.maxRechargeValue')"
                      v-model="mutableUser.settings.restriction.maxRechargeValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('maxRechargeValue') }}</div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <fieldset>
              <legend class="legend-title-form">
                <span class="legend">{{ $t('formSettings.billetTitle') }}</span>
              </legend>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="minBilletValue">{{ $t('formSettings.minBilletValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('minBilletValue')}"
                      id="minBilletValue"
                      name="minBilletValue"
                      :empty-value="null"
                      v-validate="{}"
                      :data-vv-as="$t('formSettings.minBilletValue')"
                      :placeholder="$t('formSettings.minBilletValue')"
                      v-model="mutableUser.settings.restriction.minBilletValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('minBilletValue') }}</div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="maxBilletValue">{{ $t('formSettings.maxBilletValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('maxBilletValue')}"
                      id="maxBilletValue"
                      name="maxBilletValue"
                      :empty-value="null"
                      v-validate="{min_value: mutableUser.settings.restriction.minBilletValue }"
                      :data-vv-as="$t('formSettings.maxBilletValue')"
                      :placeholder="$t('formSettings.maxBilletValue')"
                      v-model="mutableUser.settings.restriction.maxBilletValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('maxBilletValue') }}</div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <fieldset>
              <legend class="legend-title-form">
                <span class="legend">{{ $t('formSettings.shipmentTitle') }}</span>
              </legend>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="minShipmentValue">{{ $t('formSettings.minShipmentValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('minShipmentValue')}"
                      id="minShipmentValue"
                      name="minShipmentValue"
                      :empty-value="null"
                      v-validate="{}"
                      :data-vv-as="$t('formSettings.minShipmentValue')"
                      :placeholder="$t('formSettings.minShipmentValue')"
                      v-model="mutableUser.settings.restriction.minShipmentValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('minShipmentValue') }}</div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label for="maxShipmentValue">{{ $t('formSettings.maxShipmentValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('maxShipmentValue')}"
                      id="maxShipmentValue"
                      name="maxShipmentValue"
                      :empty-value="null"
                      v-validate="{ min_value: mutableUser.settings.restriction.minShipmentValue }"
                      :data-vv-as="$t('formSettings.maxShipmentValue')"
                      :placeholder="$t('formSettings.maxShipmentValue')"
                      v-model="mutableUser.settings.restriction.maxShipmentValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('maxShipmentValue') }}</div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <fieldset>
              <legend class="legend-title-form">
                <span class="legend">{{ $t('formSettings.pixTitle') }}</span>
              </legend>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="minPixValue">{{ $t('formSettings.minPixValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('minPixValue')}"
                      id="minPixValue"
                      name="minPixValue"
                      :empty-value="null"
                      v-validate="{}"
                      :data-vv-as="$t('formSettings.minPixValue')"
                      :placeholder="$t('formSettings.minPixValue')"
                      v-model="mutableUser.settings.restriction.minPixValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('minPixValue') }}</div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label for="maxPixValue">{{ $t('formSettings.maxPixValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('maxPixValue')}"
                      id="maxPixValue"
                      name="maxPixValue"
                      :empty-value="null"
                      v-validate="{ min_value: mutableUser.settings.restriction.minPixValue }"
                      :data-vv-as="$t('formSettings.maxPixValue')"
                      :placeholder="$t('formSettings.maxPixValue')"
                      v-model="mutableUser.settings.restriction.maxPixValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('maxPixValue') }}</div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <fieldset>
              <legend class="legend-title-form">
                <span class="legend">{{ $t('formSettings.opeyTitle') }}</span>
              </legend>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="minRechargeOpeyValue">{{ $t('formSettings.minRechargeOpeyValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('minRechargeOpeyValue')}"
                      id="minRechargeOpeyValue"
                      name="minRechargeOpeyValue"
                      :empty-value="null"
                      v-validate="{}"
                      :data-vv-as="$t('formSettings.minRechargeOpeyValue')"
                      :placeholder="$t('formSettings.minRechargeOpeyValue')"
                      v-model="mutableUser.settings.restriction.minRechargeOpeyValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('minRechargeOpeyValue') }}</div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="maxRechargeOpeyValue">{{ $t('formSettings.maxRechargeOpeyValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('maxRechargeOpeyValue')}"
                      id="maxRechargeOpeyValue"
                      name="maxRechargeOpeyValue"
                      :empty-value="null"
                      v-validate="{ min_value: mutableUser.settings.restriction.minRechargeOpeyValue }"
                      :data-vv-as="$t('formSettings.maxRechargeOpeyValue')"
                      :placeholder="$t('formSettings.maxRechargeOpeyValue')"
                      v-model="mutableUser.settings.restriction.maxRechargeOpeyValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('maxRechargeOpeyValue') }}</div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <fieldset>
              <legend class="legend-title-form">
                <span class="legend">{{ $t('formSettings.giftCardTitle') }}</span>
              </legend>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="minGiftCardValue">{{ $t('formSettings.minGiftCardValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('minGiftCardValue')}"
                      id="minGiftCardValue"
                      name="minGiftCardValue"
                      :empty-value="null"
                      v-validate="{}"
                      :data-vv-as="$t('formSettings.minGiftCardValue')"
                      :placeholder="$t('formSettings.minGiftCardValue')"
                      v-model="mutableUser.settings.restriction.minGiftCardValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('minGiftCardValue') }}</div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="maxGiftCardValue">{{ $t('formSettings.maxGiftCardValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('maxGiftCardValue')}"
                      id="maxGiftCardValue"
                      name="maxGiftCardValue"
                      :empty-value="null"
                      v-validate="{ min_value: mutableUser.settings.restriction.minGiftCardValue }"
                      :data-vv-as="$t('formSettings.maxGiftCardValue')"
                      :placeholder="$t('formSettings.maxGiftCardValue')"
                      v-model="mutableUser.settings.restriction.maxGiftCardValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('maxGiftCardValue') }}</div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="col-sm-12">
            <fieldset>
              <legend class="legend-title-form">
                <span class="legend">{{ $t('formSettings.shipmentLimitsTitle') }}</span>
              </legend>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="maxShipmentDailyLimitValue">{{ $t('formSettings.maxShipmentDailyLimitValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('maxShipmentDailyLimitValue')}"
                      id="maxShipmentDailyLimitValue"
                      name="maxShipmentDailyLimitValue"
                      :empty-value="null"
                      v-validate="{}"
                      :data-vv-as="$t('formSettings.maxShipmentDailyLimitValue')"
                      :placeholder="$t('formSettings.maxShipmentDailyLimitValue')"
                      v-model="mutableUser.settings.restriction.maxShipmentDailyLimitValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('maxShipmentDailyLimitValue') }}</div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label for="maxShipmentMonthlyLimitValue">{{ $t('formSettings.maxShipmentMonthlyLimitValue') }} *</label>
                    <ct-input-numeric
                      :thousand-separator="$t(`currencies.BRL.thousands`)"
                      :decimal-separator="$t(`currencies.BRL.decimal`)"
                      :precision="2"
                      autocomplete="off"
                      class="form-control"
                      :class="{'is-invalid': errors.has('maxShipmentMonthlyLimitValue')}"
                      id="maxShipmentMonthlyLimitValue"
                      name="maxShipmentMonthlyLimitValue"
                      :empty-value="null"
                      v-validate="{}"
                      :data-vv-as="$t('formSettings.maxShipmentMonthlyLimitValue')"
                      :placeholder="$t('formSettings.maxShipmentMonthlyLimitValue')"
                      v-model="mutableUser.settings.restriction.maxShipmentMonthlyLimitValue"
                    ></ct-input-numeric>
                    <div class="invalid-feedback">{{ errors.first('maxShipmentMonthlyLimitValue') }}</div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

        </div>

        <div class="row" v-if="mutableUser.changeSettings">
          <br /><br />
          <div class="col-12 col-table">
            <fieldset>
              <legend class="legend-title-form">
                <span class="legend">{{ $t('formSettings.fees') }}</span>
                <button class="btn btn-primary" @click="addFee" style="float: right;">
                  Adicionar Taxa <i class="fa fa-plus"></i>
                </button>
              </legend>
              <div class="row">
                <div class="col-12">
                  <table class="table dataTable no-footer dtr-inline">
                    <thead>
                      <tr>
                        <th>{{ $t('formSettings.min') }}</th>
                        <th>{{ $t('formSettings.max') }}</th>
                        <th>{{ $t('formSettings.spread') }}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(fee, k) in mutableUser.settings.fees" :key="k">
                        <td>
                          <ct-input-numeric
                            :thousand-separator="$t(`currencies.BRL.thousands`)"
                            :decimal-separator="$t(`currencies.BRL.decimal`)"
                            :precision="2"
                            autocomplete="off"
                            class="form-control"
                            :class="{'is-invalid': errors.has('min')}"
                            name="min"
                            v-validate="{ required: true }"
                            :data-vv-as="$t('formSettings.min')"
                            :placeholder="$t('formSettings.min')"
                            v-model="mutableUser.settings.fees[k].min"
                          ></ct-input-numeric>
                        </td>
                        <td>
                          <ct-input-numeric
                            :thousand-separator="$t(`currencies.BRL.thousands`)"
                            :decimal-separator="$t(`currencies.BRL.decimal`)"
                            :precision="2"
                            autocomplete="off"
                            class="form-control"
                            :class="{'is-invalid': errors.has('v')}"
                            name="max"
                            v-validate="{ required: true }"
                            :data-vv-as="$t('formSettings.max')"
                            :placeholder="$t('formSettings.max')"
                            v-model="mutableUser.settings.fees[k].max"
                          ></ct-input-numeric>
                        </td>
                        <td>
                          <ct-input-numeric
                            :thousand-separator="$t(`currencies.BRL.thousands`)"
                            :decimal-separator="$t(`currencies.BRL.decimal`)"
                            :precision="2"
                            autocomplete="off"
                            class="form-control"
                            :class="{'is-invalid': errors.has('spread')}"
                            name="spread"
                            v-validate="{ required: true }"
                            :data-vv-as="$t('formSettings.spread')"
                            :placeholder="$t('formSettings.spread')"
                            v-model="mutableUser.settings.fees[k].spread"
                          ></ct-input-numeric>
                        </td>
                        <td style="text-align: center;">
                          <button class="btn btn-xs" style="font-size: 12px" @click="removeFee(k)">
                            <i class="fa fa-times"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <div class="row">
      <div class="col-xs-12 col-sm-12">
        <div class="form-group pull-right form-inline">
          <button
            class="btn btn-default btn-lg"
            type="submit"
            @click="cancel"
          >{{$t('formUser.cancel')}}</button>
          <button
            class="btn btn-primary btn-lg"
            type="submit"
            @click="sendUser"
          >{{ $t('formUser.submit') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import AuthService from '../../services/UserService';
import ZipCodeService from '../../services/ZipCodeService';
import User from '../../model/User';
import CtDatePicker from '@/components/Shared/DatePicker';
import CtCheckbox from '@/components/Shared/Checkbox';
import date from '../../filters/datetime';
import { TheMask } from 'vue-the-mask';
import CtInputNumeric from '@/components/Shared/InputNumeric';
import { CtSelect } from '@citartech/core-ui';
import CtUserEvents from './UserEvents';

export default {
  name: 'UserForm',
  components: {
    CtSelect,
    CtDatePicker,
    CtCheckbox,
    TheMask,
    CtInputNumeric,
    CtUserEvents,
  },
  filters: {
    date,
  },
  props: {
    title: {
      type: String,
    },
    user: {
      type: Object,
    },
  },
  data () {
    return {
      mutableUser: new User(),
      showPassword: false,
      tabs: {
        tab1: this.$t('formUser.tab1'),
        tab2: this.$t('formUser.tab2'),
      },
    };
  },
  methods: {
    ...mapActions(['getUsersList', 'getUserForSelect']),
    addFee () {
      if (!this.mutableUser.fees) {
        this.mutableUser.fees = [];
      }
      this.mutableUser.settings.fees.push({
        min: 0,
        max: 0,
        spread: 0,
        deletedAt: null,
        createdAt: null,
        updatedAt: null,
      });
    },
    removeFee (index) {
      this.mutableUser.settings.fees.splice(index, 1);
    },
    cancel () {
      this.$emit('cancel');
    },
    finished (isSuccess = false) {
      const response = isSuccess ? this.mutableUser : false;
      this.$emit('finished', response);
    },
    async sendUser () {
      try {
        const isValid = await this.$validator.validate();
        if (!isValid) {
          this.$notify({
            type: 'error',
            title: this.$t('notification.notificationErrorTitle'),
            text: this.$t('notification.notificationErrorMessage'),
          });
        }
        this.mutableUser.addresses[0].country = this.user.country || 'BR';
        let mutableUserResponse = {};
        if (this.isNewUser) {
          mutableUserResponse = await AuthService.create(this.mutableUser);
          this.mutableUser._id = mutableUserResponse.data._id;
        } else {
          mutableUserResponse = await AuthService.update(this.mutableUser);
          this.mutableUser.createdAt = mutableUserResponse.data.createdAt;
        }

        await this.getUsersList();
        this.mutableUser.updatedAt = mutableUserResponse.data.updatedAt;
        this.$emit('finished', this.mutableUser);
        this.$notify({
          type: 'success',
          title: this.$t('user.notificationSuccessTitle'),
          text: this.user._id ? this.$t('user.notificationSuccessMessage') : this.$t('user.notificationSuccessMessage2'),
        });
      } catch (e) {
        this.processErrorOnSave(e.response || {});
      }
    },
    toggleShowPassword () {
      this.showPassword = !this.showPassword;
    },
    processErrorOnSave (response) {
      this.$notify({
        type: 'error',
        title: this.$t('formUser.notificationErrorTitle'),
        text: response.data.message || this.$t('formUser.notificationErrorMessage'),
      });
    },
    async onChangeZipCode () {
      if (this.user.addresses[0].zipCode) {
        const response = await ZipCodeService.getAddressByZipCode(
          this.user.addresses[0].zipCode,
        );

        if (response) {
          this.populateUserByViaCepResponse(response);
        }
        this.user.addresses[0].country = this.user.country || 'BR';
      }
    },
    populateUserByViaCepResponse (response) {
      this.user.addresses[0].address = response.address;
      this.user.addresses[0].district = response.district;
      this.user.addresses[0].complement = response.complement;
      this.user.addresses[0].city = response.city;
      this.user.addresses[0].uf = response.uf;
    },
    async onSearchReferral (search) {
      await this.updateListUsersReferral(search);
    },
    async refreshListUsersReferral () {
      await this.updateListUsersReferral();
    },
    async updateListUsersReferral (search = '') {
      await this.getUserForSelect(
        {
          operation: '$or',
          value: [
            { field: 'name', value: search, operation: 'like' },
            { field: 'email', value: search, operation: 'like' },
          ],
        },
      );
    },
    copyDefaultSettings () {
      this.mutableUser.settings = { ...this.currentSettings };
    },
  },
  computed: {
    ...mapState({
      roles: state => state.selects.roles || {},
      states: state => state.selects.states || {},
      languages: state => state.selects.languages || {},
      legalTypes: state => state.selects.legalTypes || {},
      userStatus: state => state.selects.userStatus || {},
      countriesCodes: state => state.selects.countriesCodes || {},
      listSelectUsers: state => state.users.listSelect || [],
      currentSettings: (state) => state.settings.currentSettings,
    }),
    ...mapGetters(['dateFormat', 'isAdmin', 'isInternal']),
    documentMask () {
      if (this.mutableUser && this.mutableUser.country !== 'BR') {
        return '###################';
      }
      return (this.mutableUser.kind === 'F' ? this.$t('formUser.masks.documentTypeF') : this.$t('formUser.masks.documentTypeJ'));
    },
    isNewUser () {
      return typeof this.mutableUser._id === 'undefined';
    },
    listReferrals () {
      if (this.isNewUser) {
        return this.listSelectUsers;
      }
      return this.listSelectUsers.filter(user => user.value !== this.mutableUser._id);
    },
    referralLink () {
      return `https://paguecombitcoin.com/?ref=${this.mutableUser.partnerCode}`;
    },

  },
  created () {
    if (this.user && this.user instanceof User) {
      this.mutableUser = this.user;
      if (!this.isAdmin) {
        delete this.mutableUser.setttings;
      }

      if (this.mutableUser.status) {
        this.mutableUser.statusLabel = this.$t(
          `formUser.statusItens.${this.mutableUser.status}`,
        );
        switch (this.mutableUser.status) {
          case 'NEW':
            this.mutableUser.statusIcon = 'fa fa-star';
            this.mutableUser.statusColor = 'bg-color-orange';
            break;
          case 'PENDING':
            this.mutableUser.statusIcon = 'fa fa-spinner';
            this.mutableUser.statusColor = 'bg-color-blue';
            break;
          case 'PROCESSING':
            this.mutableUser.statusIcon = 'fa fa-clock';
            this.mutableUser.statusColor = 'bg-color-blue';
            break;
          case 'ACTIVE':
            this.mutableUser.statusIcon = 'fa fa-check-circle';
            this.mutableUser.statusColor = 'bg-color-green';
            break;
          case 'REQUEST_VALIDATE':
            this.mutableUser.statusIcon = 'fa fa-exclamation-circle';
            this.mutableUser.statusColor = 'bg-color-green';
            break;
          case 'VALID':
            this.mutableUser.statusIcon = 'fa fa-award';
            this.mutableUser.statusColor = 'bg-color-green';
            break;
          case 'BLOCKED':
            this.mutableUser.statusIcon = 'fa fa-ban';
            this.mutableUser.statusColor = 'bg-color-red';
            break;
        }
      }
    }
    this.updateListUsersReferral();
  },
};
</script>

<style lang="scss">
@import "@/themes/color-admin/_ct_variable.scss";

.user-form-component {
  margin: 10px;
  [class*="bg-color-"] {
    &[class*="red"] {
      color: $red;
    }
    &[class*="ct-color"] {
      color: $ct_color;
    }
    &[class*="blue"] {
      color: $blue;
    }
    &[class*="aqua"] {
      color: $aqua;
    }
    &[class*="green"] {
      color: $green;
    }
    &[class*="orange"] {
      color: $orange;
    }
    &[class*="black"] {
      color: $black;
    }
    &[class*="silver"] {
      color: $silver;
    }
    &[class*="grey"] {
      color: $grey;
    }
    &[class*="purple"] {
      color: $purple;
    }
    &[class*="lime"] {
      color: $lime;
    }
    &[class*="pink"] {
      color: $pink;
    }
    &[class*="indigo"] {
      color: $indigo;
    }
    &[class*="yellow"] {
      color: $yellow;
    }
    &[class*="teal"] {
      color: $teal;
    }
  }

  .badge-success {
    background: #d6f5db !important;
  }

  .badge {
    margin-right: 10px;
  }

  legend {
    font-size: 0.9rem;
    font-weight: 500;
    padding-top: 10px;
  }

  .legend-title-content {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border: none;
  }

  .legend-title {
    font-size: 22px;
    color: #000;
  }

  ul.nav-user-register {
    border-radius: 0px;
    margin-left: 13px;
    padding-left: 13px;
    border-radius: 0;
    margin-top: 0;

    a.nav-link {
      border-radius: 0px;
    }
  }
}

.checkbox-group {
  .checkbox-component {
    display: inline-block;
    label {
      margin-right: 0px;
    }
  }
  label {
    margin-left: 3px;
    margin-right: 20px;
  }
}
</style>
