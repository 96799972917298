<template>
  <transition name="modal" v-if="show">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" :class="'container-'+size">
          <div class="modal-header hidden-print">
            <slot name="header">
              <h4 class="modal-title">{{ title }}</h4>
            </slot>

            <div class="panel-heading-btn">
              <a href="#" class="btn btn-xs btn-icon align-icon-center btn-circle btn-danger" @click.prevent="close">
                <i class="fa fa-times"></i>
              </a>
            </div>
          </div>

          <div class="modal-body" :style="styleContent">
            <slot name="body">body</slot>
          </div>

          <div class="modal-footer hidden-print" v-if="showFooter">
            <slot name="footer">footer</slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Modal',
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'large',
    },
    styleContent: {
      type: Object,
      required: false,
    },
  },
  data () {
    return {};
  },
  methods: {
    close () {
      this.$emit('close');
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
@import "@/themes/color-admin/_ct_variable.scss";

.modal-mask {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2010;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity 0.3s ease;

  .modal-wrapper {
    max-height: calc(100% - 20px);
    margin: 1rem auto;
    overflow-y: auto;

    .modal-container {
      max-width: 1920px;
      height: 100%;
      margin: 0 auto;
      background-color: $white;
      border-radius: 4px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      font-family: Helvetica, Arial, sans-serif;
      position: relative;

      .modal-header {
        margin-top: 0;
        background-color: $black_darker;
        .modal-title {
          color: $white;
          font-size: 0.9rem;
        }
      }

      .modal-body {
        overflow-y: auto;
        max-height: 93%;
      }
    }
  }
  .container-large {
    width: 80%;
  }
  .container-small {
    width: 50%;
  }
  @media (max-width: $screen-sm-max) {
    .modal-wrapper {
      max-height: calc(100% - 5px);
      margin: 1rem auto;
      overflow-y: auto;

      .modal-container {
        width: 92%;
        height: 100%;

        .modal-body {
          max-height: 100%;
        }
      }
    }
  }

  @media print {
    .modal-wrapper {
      position:absolute;
      background-color: white;
      min-height: 100%;
      min-width: 100%;
      top: 0;
      left: 0;
      border: none;
      margin: 0;
      /* overflow-y: auto; */
      .modal-container {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
    .hidden-print {
      display: none;
    }
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
