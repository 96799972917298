<template>
  <div>
    <section class="gift-card-items-section" v-if="giftCards.length !== 0">
    <div class="row">
      <div class="col-xs-12 col-sm-9">
        <h3 style="padding-left: 16px;">{{ $t('invoices.details.giftCard.title') }}</h3>
      </div>
      <div class="col-xs-12 col-sm-3" style="text-align: right; padding-right: 10px">
        <button class="btn btn-default btn-sm" @click="toogleModalPrint">
          <i class="fa fa-print" style="margin-right: 5px" />{{ $t('invoices.details.giftCard.print') }}
        </button>
      </div>
    </div>
    <div class="table-responsive body-table" v-if="!showModalPrint">
      <table class="table">
        <thead>
          <tr>
            <th width="14%">{{ $t('invoices.details.giftCard.status') }}</th>
            <th width="10%">{{ $t('invoices.details.giftCard.id') }}</th>
            <th width="20%">{{ $t('invoices.details.giftCard.operator') }}</th>
            <th width="25%">{{ $t('invoices.details.giftCard.card') }}</th>
            <th width="12%">{{ $t('invoices.details.giftCard.amount') }}</th>
            <th width="8%">{{ $t('invoices.details.giftCard.fee') }}</th>
            <th width="5%" class="text-center">{{ $t('invoices.details.giftCard.finish') }}</th>
            <th width="14%" class="text-right action-col" v-if="isInternal"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in giftCards" :key="item._id + index">
            <td>
              <ct-status-icon
                :iconSymbol="symbols[item.status]"
                :color="colors[item.status]"
                :description="statusTranslate[item.status]"
              />
            </td>
            <td>
              <ct-cell-id :text="item._id"></ct-cell-id>
            </td>
            <td>{{ item.giftCard.operator || '-' }}</td>
            <td>{{ item.giftCard.cardName || item.giftCard.card || '-' }}</td>
            <td>R$ {{ (item.amount || 0) | currency }}</td>
            <td>R$ {{ (item.fee || 0) | currency }}</td>
            <td class="text-center">
              <span v-if="item.receipt">
                <a :href="item.receipt" target="_blank" >
                  <i class="fa fa-lg fa-eye" />
                </a>
              </span>
              <span v-else-if="item.receiptNumber">
                {{ item.receiptNumber }}
              </span>
              <span v-else>
                <i class="far fa-lg fa-eye-slash disabled" />
              </span>
            </td>
            <td class="text-center" v-if="isInternal">
              <span v-if="isInternal && canSubmitReceipt(item) && item.status !== 'FINISHED'" style="margin: 0 10px">
                <a @click="toggleFinishGiftCard(item._id, !!item.receipt, item.status)">
                  <i class="fa fa-lg fa-upload" />
                </a>
              </span>
              <span>
                <i class="fa fa-lg fa-cog" @click.prevent="openModalEdit(item)" />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <ct-modal-view
      v-if="editingItem"
      :title="$t('invoices.details.editItem.title')"
      :show="showModalEdit"
      size="small"
      @close="toggleModalEdit"
    >
      <ct-edit-item-modal
        slot="body"
        :invoiceId="invoice._id"
        :item="editingItem"
        @close="toggleModalEdit"
        @save="refreshInvoice"
        :notEditing="!canEditItem(editingItem)"
        :isItemReprocess="isDisabledForItem(editingItem)"
        :isItemProcess="isDisabledForItemProcess(editingItem)"
        @reprocessItem="reprocessCurrentItem"
        @processItem="processCurrentItem"
      />
    </ct-modal-view>

  </section>
  <div>
    <ct-modal-view
      v-if="showModalPrint"
      :title="$t('invoices.details.giftCard.title')"
      :show="showModalPrint"
      size="large"
      @close="toogleModalPrint"
    >

      <ct-print-giftcards slot="body" :giftcards="giftCards" />
    </ct-modal-view>
  </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CtCellId from '@/components/Shared/DataGrid/CellId.vue';
import CtStatusIcon from '@/components/Dashboard/StatusIcon';
import CtPrintGiftcards from '@/components/Invoices/InvoiceItems/PrintGiftcards.vue';
import currency from '@/filters/currency';
import ItemsActionsMixin from '@/mixins/ItemsActions';

export default {
  name: 'GiftCardItems',
  components: {
    CtCellId,
    CtStatusIcon,
    CtPrintGiftcards,
  },
  mixins: [
    ItemsActionsMixin,
  ],
  filters: {
    currency,
  },
  props: {
    giftCards: {
      type: [Array, Object],
      required: false,
      default: () => ([]),
    },
  },
  data: () => {
    return {
      showModalPrint: false,
    };
  },
  computed: {
    ...mapGetters([
      'isInternal',
    ]),
    editingItem () {
      return this.giftCards.find((giftCard) => giftCard._id === this.editingItemId);
    },
  },
  methods: {
    openModalEdit (item) {
      this.editingItemId = item._id;
      this.toggleModalEdit();
    },
    async reprocessCurrentItem () {
      await this.reprocessItem(this.editingItem._id);
    },
    async processCurrentItem () {
      await this.processItem(this.editingItem._id);
    },

    toogleModalPrint () {
      this.showModalPrint = !this.showModalPrint;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../themes/color-admin/_ct_variable.scss";
.gift-card-items-section {
  i.fa-lg {
    cursor: pointer;
    &.disabled {
      color: $ct_grey_1;
    }
  }
  h3 {
    font-weight: 400;
    margin-left: -10px;
  }
}
</style>
