<template>
  <div class="invoice-events-component">
    <div class="col-md-12 ct-margin-top-20">
      <h3>
        {{ $t('invoices.details.events.title') }} <small>( {{ events ? events.length : '' }} )</small>
      </h3>
      <div class="ct-show-events">
        <i class="far "
           :class="{'fa-plus-square': !showEvents, 'fa-minus-square': showEvents }"
           @click="showEvents = !showEvents"
        ></i>
      </div>
    </div>
    <div class="table-responsive body-table ct-margin-top-20" v-if="showEvents">
      <table class="table table-wallets">
        <thead>
        <tr>
          <th width="20%">{{ $t('invoices.details.events.createdAt') }}</th>
          <th width="80%">{{ $t('invoices.details.events.message') }}</th>
        </tr>
        </thead>
        <tbody v-if="events">
        <tr v-for="item in events" :key="item._id">
          <td>{{ item.createdAt | datetime}}</td>
          <td>{{ item.message }}</td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr>
          <td colspan="7">{{ $t('invoices.details.events.notFound') }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import datetime from '@/filters/datetime';
export default {
  name: 'InvoiceEvents',
  filters: { datetime },
  data () {
    return {
      showEvents: false,
    };
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  methods: {

  },
};
</script>

<style lang="scss">
.invoice-events-component {
  padding-top: 20px;
  h3 {
    font-weight: 400;
  }
  .ct-show-events {
    text-align: right;
    padding-right: 0;
    margin-top: -30px;
    margin-right: -5px;
    cursor: pointer;
    i {
      font-size: 20px;
    }
  }
}
</style>
