import { render, staticRenderFns } from "./CellLabel.vue?vue&type=template&id=27b6b757&scoped=true"
import script from "./CellLabel.vue?vue&type=script&lang=js"
export * from "./CellLabel.vue?vue&type=script&lang=js"
import style0 from "./CellLabel.vue?vue&type=style&index=0&id=27b6b757&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27b6b757",
  null
  
)

export default component.exports