<template>
<div class="row">
  <div class="col-lg-12" v-for="(fiatItem, index) in fiat" :key="index">
    <hr v-if="index > 0" />

    <button class="btn btn-close" v-if="index > 0" title="Remover item" @click="removeItem(index)">
      <i class="fas fa-times"></i>
    </button>

    <div class="form-group" :class="{'is-invalid': errors.has('fiatKind')}">
      <label for="fiatKind">{{ $t('invoices.modalReversal.fiat.kind') }} {{ fiat[index].kind }} *</label>
      <ct-select
        name="fiatKind"
        id="fiatKind"
        class="from-control"
        :class="{'is-invalid': errors.has('fiatKind')}"
        :data-vv-as="$t('invoices.modalReversal.fiat.kind')"
        v-model="fiatItem.kind"
        :options="fiatReversalKinds"
        :filterable="true"
        :multiple="false"
      />
      <div class="invalid-feedback">{{ errors.first('fiatKind') }}</div>
    </div>

    <ct-reversal-fiat-opey
      :sum-reversal-value="sumReversalValue"
      v-model="fiat[index].data"
      v-if="fiatItem.kind === 'OPEY'"
    />

    <ct-reversal-fiat-shipment
      :sum-reversal-value="sumReversalValue"
      v-model="fiat[index].data"
      v-if="fiat[index].kind === 'SHIPMENT'"
    />

    <ct-reversal-fiat-pix
      :sum-reversal-value="sumReversalValue"
      v-model="fiat[index].data"
      v-if="fiatItem.kind === 'PIX'"
    />

    <ct-reversal-fiat-recharge
      :sum-reversal-value="sumReversalValue"
      v-model="fiat[index].data"
      v-if="fiat[index].kind === 'RECHARGE'"
    />

    <ct-reversal-fiat-gift-card
      :sum-reversal-value="sumReversalValue"
      v-model="fiat[index].data"
      v-if="fiat[index].kind === 'GIFTCARD'"
    />

    <ct-reversal-fiat-billet
      :sum-reversal-value="sumReversalValue"
      v-model="fiat[index].data"
      v-if="fiat[index].kind === 'BILLET'"
    />
  </div>
  <div class="col-lg-12">
    <div class="form-group d-flex flex-column">
      <button class="btn btn-default m-0" @click="addNewItem" :disabled="notHasValueBalance">
        Adicionar item  <i class="fas fa-plus"></i>
      </button>
    </div>
  </div>
  <div class="col-xs-12 col-sm-12">
    <h5>
      {{ $t('invoices.modalReversal.availableBalance') }}: R$ {{ currency((sumReversalValue || 0)) }}
    </h5>
    <h5 v-if="currentFiatAmount" :class="{'red-balance': isNegativeBalance }">
      {{ $t('invoices.modalReversal.reversalBalance') }}: R$ {{ reversalBalanceFormated }}
    </h5>
    <div class="error-label" v-if="isNegativeBalance">O valor restante precisa ser maior ou igual a zero.</div>
  </div>

  <div class="col-xs-12 col-sm-12">
    <div class="form-group pull-right form-inline">
      <button
        type="submit"
        class="btn btn-default btn-lg"
        @click="close()"
      >{{$t('invoices.modalReversal.close')}}</button>
      <button type="submit" class="btn btn-danger btn-lg" @click="submit()" :disabled="isLoading || disableSubmit">
        <i class="fas fa-spinner fa-pulse" v-if="isLoading"></i>
        {{ $t('invoices.modalReversal.submit') }}
      </button>
    </div>
  </div>
</div>
</template>

<script>
import CtReversalFiatBillet from './FiatBillet.vue';
import CtReversalFiatGiftCard from './FiatGiftcard.vue';
import CtReversalFiatRecharge from './FiatRecharge.vue';
import CtReversalFiatOpey from './FiatOpey.vue';
import CtReversalFiatShipment from './FiatShipment.vue';
import CtReversalFiatPix from './FiatPix.vue';
import { CtSelect } from '@citartech/core-ui';
import { mapState } from 'vuex';
import currency from '@/filters/currency';

export default {
  name: 'FormReversalFiat',
  props: {
    sumReversalValue: {
      type: Number,
    },
    isLoading: {
      type: Boolean,
    },
  },
  components: {
    CtSelect,
    CtReversalFiatBillet,
    CtReversalFiatGiftCard,
    CtReversalFiatRecharge,
    CtReversalFiatOpey,
    CtReversalFiatShipment,
    CtReversalFiatPix,
  },
  data: () => ({
    fiat: [{
      kind: '',
      data: {},
    }],
    fiatTest: [{
      kind: '',
      data: {},
    }, {
      kind: '',
      data: {},
    }],
  }),
  destroyed () {
    this.$emit('input', [{
      kind: '',
      data: {},
    }]);
  },
  computed: {
    ...mapState({
      fiatReversalKinds: state => state.selects.fiatReversalKinds || [],
      giftCardOperators: (state) => state.giftCards.giftCardOperators,
    }),
    notHasValueBalance () {
      return this.reversalBalance <= 0;
    },
    currentFiatAmount () {
      return this.fiat.reduce((initial, current) => {
        const amount = {
          OPEY: () => current.data.amount + current.data.fee,
          RECHARGE: () => current.data.amount + current.data.fee,
          PIX: () => current.data.amount + current.data.fee,
          GIFTCARD: () => current.data.amount + current.data.fee,
          BILLET: () => (current.data.amount - current.data.discount + current.data.increase) + current.data.fee,
          SHIPMENT: () => current.data.amount + current.data.fee,
          DEFAULT: () => 0,
        }[current.kind || 'DEFAULT']();

        return initial + amount;
      }, 0);
    },
    isNegativeBalance () {
      return this.reversalBalance < 0;
    },
    reversalBalance () {
      return (this.sumReversalValue - this.currentFiatAmount);
    },
    reversalBalanceFormated () {
      return currency(this.reversalBalance, 2);
    },
    disableSubmit () {
      if (this.isNegativeBalance) return true;
      if (Number(this.reversalBalance || 0) - Number(this.currentFiatAmount || 0) > 1) return true;
      return false;
    },
  },
  methods: {
    addNewItem () {
      this.fiat.push({
        kind: '',
        data: {},
      });
    },
    removeItem (index) {
      if (index > 0) {
        this.fiat.splice(index, 1);
      }
    },
    currency (value) {
      return currency(value, 2);
    },
    async submit () {
      if (this.isNegativeBalance) {
        console.error('O valor restante precisa ser maior ou igual a zero.');
        return;
      }

      if (this.currentFiatAmount - this.sumReversalValue > 1) {
        console.error('Ainda é necessário adicionar mais itens para completar o valor total da reversão.');
        return;
      }

      const isLocalValid = await this.$validator?.validate();
      if (!isLocalValid) {
        console.error('Erro on local validation');
        return;
      }

      for (const index in this.fiat) {
        const isValid = await this.fiat[index]?.data?.validator?.validate();
        if (!isValid) {
          console.error('Erro on valida fiat item', this.fiat[index]?.data);
          return;
        }
      }

      this.$emit('input', [...this.fiat]);
      this.$emit('submit');
    },
    close () {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
  .btn-close {
    position: absolute;
    right: 0;
    margin-top: -15px;
    background: transparent;
    border-color: transparent;
  }

  .red-balance {
    color: #cc4946;
  }

  .error-label {
    font-size: 90%;
    color: #cc4946;
    width: 100%;
    margin-top: 0.25rem;
  }
</style>
