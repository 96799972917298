import SettingsService from './../../../services/SettingsService';

export default {
  async saveSettings ({ dispatch }, data) {
    if (data._id) {
      const res = await SettingsService.update(data);
      await dispatch('getSettingsList');
      return res.data;
    }

    const res = await SettingsService.create(data);
    await dispatch('getSettingsList');
    return res.data;
  },

  async getSettingsList ({ commit, state }, isPrint = false) {
    try {
      const list = await SettingsService.getList({
        filters: state.listSettingsFilters,
        search: state.listSettingsSearch,
        page: isPrint ? 0 : (state.listSettingsPaginate.currentPage || 1),
        limit: isPrint ? 0 : (state.listSettingsPaginate.perPage || 10),
        orderBy: state.listSettingsSort,
      });
      if (isPrint) {
        commit('SET_LIST_SETTINGS_PRINT', list);
      } else {
        commit('SET_LIST_SETTINGS', list);
        if (list.meta) {
          commit('SET_SETTING_LIST_TOTAL_PAGES', list.meta.pagination.totalPages);
        }
      }
    } catch (error) {
      console.error(error);
    }
  },

  async getCurrentSettings ({ commit }) {
    const settings = await SettingsService.getCurrentSettings();
    commit('SET_CURRENT_SETTINGS', settings);
  },

  addSettingsListFilter ({ commit, dispatch }, value) {
    commit('SET_SETTING_LIST_PAGE', 1);
    commit('ADD_SETTING_LIST_FILTER', value);
    dispatch('getSettingsList');
  },

  removeSettingsListFilter ({
    commit,
    dispatch,
  }, value) {
    commit('SET_SETTING_LIST_PAGE', 1);
    commit('REMOVE_SETTING_LIST_FILTER', value);
    dispatch('getSettingsList');
  },

  setSettingsListPage ({
    commit,
    dispatch,
  }, value) {
    commit('SET_SETTING_LIST_PAGE', value);
    dispatch('getSettingsList');
  },

  setSettingsListTotalPages ({
    commit,
    dispatch,
  }, value) {
    commit('SET_SETTING_LIST_PAGE', 1);
    commit('SET_SETTING_LIST_TOTAL_PAGES', value);
    dispatch('getSettingsList');
  },

  setSettingsListSort ({
    commit,
    dispatch,
  }, value) {
    commit('SET_SETTING_LIST_SORT', value);
    dispatch('getSettingsList');
  },

  setSettingsListSearch ({ commit, dispatch }, value) {
    commit('SET_SETTING_LIST_SEARCH', value);
    dispatch('getSettingsList');
  },
};
