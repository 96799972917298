<template>
  <div id="content">
    <!-- begin page-header -->
    <div class="modal-header">
      <h1 class="page-header hidden-print" v-if="modalSubtitle">
        {{ $t('dataGrid.modalPrint.modalTitle') + modalSubtitle }}
      </h1>
      <span class="pull-right hidden-print">
        <!-- <a href="javascript:;" class="btn btn-sm btn-white m-b-10 p-l-5"><i class="fas fa-download"></i></a> -->
        <a href="javascript:;" onclick="window.print()" class="btn btn-sm btn-white m-b-10">
          <i class="fa fa-print t-plus-1 fa-fw fa-lg"></i>
        </a>
      </span>
    </div>
    <!-- end page-header -->
    <!-- begin -->
    <div class="print">
      <div class="print-header row">
        <div class="col-12">
          <div class="row justify-content-between">
            <div class="col-4 content-interval">
              <p class="title">
                <strong>{{ $t('dataGrid.modalPrint.filters') }}</strong>
              </p>
              <p v-if="filtersPrint && filtersPrint.length > 0">
                <small v-for="(value, key) in filtersPrint"
                       :key="key"
                >
                {{ $t('dataGrid.modalPrint.filtersNames.' + value) }}
                <span v-if="key !== (filtersPrint.length - 1)"> | </span>
                </small>
              </p>
              <p v-else> {{ $t('dataGrid.modalPrint.noFilters') }} </p>
            </div>
            <div class="col-4 content-datetime">
              <p>
                <small>{{ $t('dataGrid.modalPrint.createdAt') }}</small>
              </p>
              <p>
                <strong>{{ dateNow | datetime }}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- end header -->
      <!-- begin content -->
      <div class="print-content">
        <!-- begin table-responsive -->
        <div class="table-responsive body-table">
          <table class="table table-print">
            <thead>
              <tr v-if="printListing.headers">
                <th
                  v-for="header in printedHeaders"
                  :key="header.name"
                  :style="{ width: header.width || 'auto' }"
                >
                  {{ header.text || '' }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="printListing.linesPrint.length === 0">
                <td :colspan="printListing.headers.length">{{ emptyTableMessage }}</td>
              </tr>
              <tr
                v-for="row in printListing.linesPrint"
                :key="row[0]"
              >
                <td v-for="(td, index) in row" :key="String(row[0]) + String(index)">
                  <span v-if="td && td.type && td.type === 'status-icon'">{{ $t('dataGrid.modalPrint.status.' + td.text) }}</span>
                  <span v-else-if="td === null">-</span>
                  <span v-else>{{ td }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- end table-responsive -->
      </div>
      <!-- end content -->
    </div>
    <!-- end -->
  </div>
  <!-- end #content -->
</template>
<script>
import currency from '@/filters/currency';
import date from '@/filters/date';
import datetime from '@/filters/datetime';

export default {
  filters: {
    currency,
    date,
    datetime,
  },
  props: {
    showModalPrint: {
      type: Boolean,
    },
    filtersPrint: {
      type: Array,
    },
    printListing: {
      type: Object,
    },
    emptyTableMessage: {
      type: String,
    },
    modalSubtitle: {
      type: String,
    },
  },
  computed: {
    printedHeaders: this?.printListing?.headers?.filter(header => header.isPrint) || [],
  },
  data () {
    return {
      dateNow: new Date(),
    };
  },
};
</script>
<style scoped lang="scss">
@import "@/themes/color-admin/_variable.scss";
.pos {
  position: relative;
}
.ballon {
  z-index: 9999;
  min-width: 40em;
}

.header {
  margin: 0 -5px;
}
.total {
  text-transform: uppercase;
}

.modal-header {
  border: none;
}

.small-title {
  font-size: 14px !important;
}

.history-title {
  margin-top: 50px;
  font-size: 21px;
}

.btn {
  border: none;
  padding: 5px;
  min-width: 35px;
}

p {
  &.last-events {
    width: 100%;
    border-bottom: solid 1px #f0f3f4;
    margin: 0;
    padding: 10px 15px;
    &:last-child {
      border: none;
    }
  }
}

.table-print {
  th {
    text-transform: uppercase;
  }
  a {
    color: $heading_text_color;
    &:hover {
      color: lighten($heading_text_color, 20%);
    }
  }
}

.print-header {
  margin-bottom: 20px;
  background: #f0f3f4;
  padding: 20px;
}

.content-interval p,
.content-datetime p {
  margin: 0;
}

.content-datetime {
  text-align: right;
}
</style>
