<template>
  <!-- v-b-toggle.collapse1 @click.prevent = ' collapse() '-->
  <div
    class="panel panel-inverse"
    :class="{'panel-loading': reloading, 'panel-expand' : !expanded}"
  >
    <b-row class="panel-heading">
      <b-col cols="6">
        <h4 class="panel-title">{{ title }}</h4>
      </b-col>
      <b-col class="panel-control" cols="6">
        <div class="panel-heading-btn">
          <ct-action-button-header
            v-for="button in actionButtons"
            :key="button.name"
            :name="button.name || ''"
            :icon="button.icon || ''"
            :color="button.color || ''"
            :title="button.title || ''"
            @click="actionClick"
          />

          <ct-action-header-separator/>

          <a
            v-if="enableFilterButton"
            @click.prevent="filter"
            href
            class="btn btn-xs btn-icon btn-circle"
             :class="filterColor"
            data-click="panel-expand"
          >
            <i class="fas fa-filter"></i>
          </a>

          <a
            @click.prevent=" expand "
            href
            class="btn btn-xs btn-icon btn-circle btn-default"
            data-click="panel-expand"
          >
            <i class="fa fa-expand"></i>
          </a>

          <a @click="reload" class="btn btn-xs btn-icon btn-circle btn-success" v-if="enableReload">
            <i class="fa fa-redo" :class="{'fa-spin': reloading}"></i>
          </a>

          <a @click="toggleCollapse" class="btn btn-xs btn-icon btn-circle btn-warning">
            <i class="fa" :class="{'fa-minus': !collapsed, 'fa-plus': collapsed}"></i>
          </a>
          <!-- <a @click = ' remove() ' href="" class="btn btn-xs btn-icon btn-circle btn-danger"><i class="fa fa-times"></i></a> -->
        </div>
      </b-col>
    </b-row>
    <b-row class="panel-collapse" v-show="!collapsed">
      <b-col class="panel-body" :style="styleBody">
        <div class="panel-loader" v-if="reloading">
          <span class="spinner-small"></span>
        </div>
        <b-row id="nv-line-chart" :style="styleContent">
          <b-col cols="12" class="content-header" :style="styleContent">
            <slot name="panel-header"></slot>
            <div class="panel-content">
              <slot name="panel-content"></slot>
            </div>

            <div>
              <slot name="panel-footer"></slot>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CtActionButtonHeader from '@/components/Shared/DataGrid/ActionButtonHeader.vue';
import CtActionHeaderSeparator from '@/components/Shared/DataGrid/ActionHeaderSeparator.vue';

export default {
  name: 'Panel',
  components: {
    CtActionButtonHeader,
    CtActionHeaderSeparator,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    reloading: {
      type: Boolean,
      default: false,
    },
    enableReload: {
      type: Boolean,
      default: true,
    },
    actionButtons: {
      type: Array,
      default: () => [],
    },
    styleBody: {
      type: Object,
      required: false,
    },
    styleContent: {
      type: Object,
      required: false,
    },
    enableFilterButton: {
      type: Boolean,
      default: false,
    },
    isFiltersChecked: {
      type: Boolean,
      default: false,
    },
    isShowFilters: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      expanded: 'false',
      collapsed: '',
      filtered: true,
      filterColorsKinds: {
        show: 'btn-purple',
        hide: 'btn-indigo',
        filteredShow: 'btn-danger',
      },
    };
  },
  computed: {
    filterColor () {
      if (this.isFiltersChecked && !this.filtered) {
        return this.filterColorsKinds.filteredShow;
      }
      return this.filtered ? this.filterColorsKinds.show : this.filterColorsKinds.hide;
    },
  },
  created () {
    this.filtered = this.isShowFilters;
  },
  methods: {
    async reload () {
      this.$emit('reload');
    },

    addClick () {
      this.$emit('add-click');
    },

    actionClick (name) {
      this.$emit('button-click', name);
    },

    expand () {
      this.expanded = !this.expanded;
    },

    filter () {
      this.filtered = !this.filtered;
      this.$emit('filter', this.filtered);
    },

    toggleCollapse () {
      this.collapsed = !this.collapsed;
    },

    remove () {
      this.$emit('remove');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/themes/color-admin/_ct_variable.scss";
h4 {
  @media screen and (max-width: $screen-md-max) {
    margin: 15px 2px;
  }
  @media screen and (max-width: $screen-sm-max) {
    margin: 3px 2px 15px 2px;
  }
}
.panel-body {
  background: white;
  padding: $padding-default;
  min-height: 600px;
  .panel-content {
    min-height: 530px;
  }
  .panel-expand & {
    margin-top: 8px;
  }
}

.panel-heading {
  padding: $padding-default;
}

.panel-title {
  font-size: 1.2em;
}

.panel-heading-btn {
  float: right;
}

.panel-control {
  min-width: 118px;
  display: inline-block;
}

.action-btns {
  float: right;
}

.btn {
  &:first-child {
    margin-left: 0;
  }
}

.content-header {
  float: right;
}

.button-new-value {
  display: inline;
  margin-right: 20px;
  font-size: 10px;
  height: 16px;
  border-radius: 16px;
}

@media (max-width: 767px) {
  .separator {
    margin: 0px 5px 0px 19px;
  }
}
</style>
