export default class Settings {
  constructor (data = {}) {
    this._id = data._id;
    this.name = data.name;
    this.status = data.status;
    this.expireInvoice = data.expireInvoice;
    this.currentProvider = data.currentProvider;
    this.expireInvoice = data.expireInvoice;
    this.maxPaymentOverpaidDifference = data.maxPaymentOverpaidDifference || 0;
    this.maxPaymentUnderpaidDifference = data.maxPaymentUnderpaidDifference || 0;
    this.referralFee = data.referralFee || 0;
    this.createdAt = data.createdAt || null;
    this.deletedAt = data.deletedAt || null;
    this.fees = data.fees ? data.fees.map(item => ({ ...item })) : [{
      min: 0,
      max: 0,
      spread: 0,
      deletedAt: null,
      createdAt: null,
      updatedAt: null,
    }];
    this.restriction = data.restriction ? { ...data.restriction } : {
      minRechargeValue: 0,
      minBilletValue: 0,
      minShipmentValue: 0,
      minPixValue: 0,
      minRechargeOpeyValue: 0,
      minGiftCardValue: 0,
      maxBilletValue: 0,
      maxRechargeValue: 0,
      maxShipmentValue: 0,
      maxPixValue: 0,
      maxRechargeOpeyValue: 0,
      maxGiftCardValue: 0,
      maxShipmentDailyLimitValue: 0,
      maxShipmentMonthlyLimitValue: 0,
    };
    this.itemFees = data.itemFees ? { ...data.itemFees } : {
      billet: 0,
      shipment: 0,
      recharge: 0,
      opey: 0,
      giftCard: 0,
      pix: 0,
    };
    this.services = data.services ? { ...data.services } : {
      status: {
        billet: true,
        shipment: true,
        recharge: true,
        opey: true,
        giftcard: true,
        pix: true,
      },
    };
    this.providerMessages = data.providerMessages ? data.providerMessages : 'SMS';
  }
}
