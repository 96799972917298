<template>
  <section class="recharge-items-section" v-if="recharges.length !== 0">
    <div class="col-md-12">
      <h3>{{ $t('invoices.details.recharge.title') }}</h3>
    </div>
    <div class="table-responsive body-table">
      <table class="table table-recharges">
        <thead>
          <tr>
            <th width="10%">{{ $t('invoices.details.recharge.status') }}</th>
            <th width="10%">{{ $t('invoices.details.recharge.id') }}</th>
            <th width="14%">{{ $t('invoices.details.recharge.operator') }}</th>
            <th width="12%">{{ $t('invoices.details.recharge.ddd') }}</th>
            <th width="18%">{{ $t('invoices.details.recharge.number') }}</th>
            <th width="14%">{{ $t('invoices.details.recharge.amount') }}</th>
            <th width="14%">{{ $t('invoices.details.recharge.fee') }}</th>
            <th width="14%" class="text-center">{{ $t('invoices.details.recharge.ProofOfPayment') }}</th>
            <th width="14%" class="text-right action-col" v-if="isInternal"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in recharges" :key="item._id + index">
            <td>
              <ct-status-icon
                :iconSymbol="symbols[item.status]"
                :color="colors[item.status]"
                :description="statusTranslate[item.status]"
              />
            </td>
            <td>
              <ct-cell-id :text="item._id"></ct-cell-id>
            </td>
            <td>{{ item.recharge.operator || '-' }}</td>
            <td>
              {{ item.recharge.ddd || '-' }}
              <i class="far fa-copy"
                @click="copyToClipboard(item.recharge.ddd)"
                v-b-tooltip.hover
                :title="$t('invoices.details.copyTitle')">
              </i>
            </td>
            <td>
              {{ item.recharge.phone || '-' }}
              <i class="far fa-copy"
                @click="copyToClipboard(item.recharge.phone)"
                v-b-tooltip.hover
                :title="$t('invoices.details.copyTitle')">
              </i>
            </td>
            <td>R$ {{ (item.amount || 0) | currency }}</td>
            <td>R$ {{ (item.fee || 0) | currency }}</td>
            <td class="text-center">
              <span v-if="item.receipt">
                <a :href="item.receipt" target="_blank" >
                  <i class="fa fa-lg fa-eye" />
                </a>
              </span>

              <span v-else-if="item.receiptNumber">
                {{ item.receiptNumber }}
              </span>

              <span v-else>
                <i class="far fa-lg fa-eye-slash disabled" />
              </span>
            </td>
            <td class="text-center" v-if="isInternal">
              <span v-if="isInternal && canSubmitReceipt(item)" style="margin: 0 10px">
                <a @click="toggleUploadReceipt({ id: item._id, fileRequired: false, status: item.status })">
                  <i class="fa fa-lg fa-upload" />
                </a>
              </span>
              <span v-if="isInternal && canSubmitReceipt(item)">
                <a @click.prevent="openModalEdit(item)">
                  <i class="fa fa-lg fa-cog"  />
                </a>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <ct-modal-view
      v-if="editingItem"
      :title="$t('invoices.details.editItem.title')"
      :show="showModalEdit"
      size="small"
      @close="toggleModalEdit"
    >
      <ct-edit-item-modal
        slot="body"
        :invoiceId="invoice._id"
        :item="editingItem"
        @close="toggleModalEdit"
        @save="refreshInvoice"
        :notEditing="!canEditItem(editingItem)"
        :isItemReprocess="isDisabledForItem(editingItem)"
        :isItemProcess="isDisabledForItemProcess(editingItem)"
        @reprocessItem="reprocessCurrentItem"
        @processItem="processCurrentItem"
      />
    </ct-modal-view>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import CtCellId from '@/components/Shared/DataGrid/CellId.vue';
import CtStatusIcon from '@/components/Dashboard/StatusIcon';
import currency from '@/filters/currency';
import ItemsActionsMixin from '@/mixins/ItemsActions';

export default {
  name: 'RechargeItems',
  components: {
    CtCellId,
    CtStatusIcon,
  },
  mixins: [
    ItemsActionsMixin,
  ],
  filters: {
    currency,
  },
  props: {
    recharges: {
      type: [Array, Object],
      required: false,
      default: () => ([]),
    },
  },
  computed: {
    ...mapGetters([
      'isInternal',
    ]),
    editingItem () {
      return this.recharges.find((recharge) => recharge._id === this.editingItemId);
    },
  },
  methods: {
    openModalEdit (item) {
      this.editingItemId = item._id;
      this.toggleModalEdit();
    },
    async reprocessCurrentItem () {
      await this.reprocessItem(this.editingItem._id);
    },
    async processCurrentItem () {
      await this.processItem(this.editingItem._id);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../themes/color-admin/_ct_variable.scss";
.recharge-items-section {
  i.fa-lg {
    cursor: pointer;
    &.disabled {
      color: $ct_grey_1;
    }
  }
  h3 {
    font-weight: 400;
    margin-left: -10px;
  }
}
</style>
