import currency from '../filters/currency';

const formatValue = (list, sort, columnsNames, { user, statusNames, billStatusNames, statusSeconday }) => {
  const isInternal = Array.isArray(user.roles) && (user.roles.indexOf('operator') !== -1 || user.roles.indexOf('admin') !== -1);
  const table = {
    headers: [],
    lines: [],
    linesPrint: [],
    listStatus: statusNames,
  };

  if (isInternal) {
    table.headers.push({
      name: 'marker',
      width: '1%',
      padding: '0px 4px',
    });
  }

  table.headers.push(
    {
      name: '_id',
      text: columnsNames && '_id' in columnsNames ? columnsNames._id : '',
      width: '3%',
      order: sort && '_id' in sort ? sort._id : true,
      isFilter: true,
      isPrint: true,
    },
    {
      name: 'createdAt',
      text: columnsNames && 'createdAt' in columnsNames ? columnsNames.createdAt : '',
      width: '12%',
      order: sort && 'createdAt' in sort ? sort.createdAt : true,
      isPrint: true,
    },
    {
      name: 'services',
      text: columnsNames && 'services' in columnsNames ? columnsNames.services : '',
      width: '5%',
      order: false,
      isPrint: true,
    },
  );

  if (isInternal) {
    table.headers.push({
      name: 'email',
      text: columnsNames && 'email' in columnsNames ? columnsNames.email : '',
      width: '15%',
      order: sort && 'email' in sort ? sort.email : true,
      isFilter: true,
      isPrint: true,
      isSpecialSearch: true,
    });
  }

  table.headers.push(
    {
      name: 'fiatAmount',
      text: columnsNames && 'fiatAmount' in columnsNames ? columnsNames.fiatAmount : '',
      width: '15%',
      order: false,
      isPrint: true,
    },
    {
      name: 'cryptoPrice',
      text: columnsNames && 'cryptoPrice' in columnsNames ? columnsNames.cryptoPrice : '',
      width: '15%',
      order: false,
      isPrint: true,
    },
    {
      name: 'cryptoAmount',
      text: columnsNames && 'cryptoAmount' in columnsNames ? columnsNames.cryptoAmount : '',
      width: '15%',
      order: false,
      isPrint: true,
    },
    {
      name: 'status',
      text: columnsNames && 'status' in columnsNames ? columnsNames.status : '',
      width: '10%',
      order: false,
      isPrint: true,
    },
  );

  if (isInternal) {
    table.headers.push({
      name: 'statusSecondary',
      text: columnsNames && 'statusSecondary' in columnsNames ? columnsNames.statusSecondary : '',
      width: '2%',
      order: sort && 'statusSecondary' in sort ? sort.statusSecondary : true,
      isFilter: true,
      isPrint: true,
    });
    table.headers.push({
      name: 'approveExpired',
      text: columnsNames && 'approveExpired' in columnsNames ? columnsNames.approveExpired : '',
      width: '5%',
      order: false,
      isPrint: false,
    });
    table.headers.push({
      name: 'bill',
      text: columnsNames && 'bill' in columnsNames ? columnsNames.bill : '',
      width: '15%',
      order: false,
      isPrint: false,
    });
  }

  if (list && list.data && list.data.length > 0) {
    for (let i = 0; i < list.data.length; i++) {
      const servicesLabels = [...new Set(list.data[i].items.map((item) => item.kind.substring(0, 1)))].join(' | ');
      const createdAt = new Date(list.data[i].createdAt);
      const countServices = {
        type: 'label',
        text: `${list.data[i].items && list.data[i].items.length ? list.data[i].items.length : 0}: ${servicesLabels}`,
        textAlign: 'center',
      };
      const countServicesPrint = list.data[i].items && list.data[i].items.length ? list.data[i].items.length : 0;
      const fiatAmount = currency(list.data[i].fiatAmount, 2, 'R$ ');
      const cryptoPrice = currency(list.data[i].cryptoPrice, 2, 'R$ ');
      const cryptoAmount = currency(list.data[i].cryptoAmount, 8, `${list.data[i].crypto} `);
      let statusIcon = '';
      let statusColor = '';
      switch (list.data[i].status) {
        case 'NEW':
          statusIcon = 'fas fa-star';
          statusColor = 'orange';
          break;
        case 'EXPIRED':
          statusIcon = 'fas fa-stopwatch';
          statusColor = 'black';
          break;
        case 'PENDING':
          statusIcon = 'fas fa-spinner';
          statusColor = 'blue';
          break;
        case 'PAID':
          statusIcon = 'fas fa-check';
          statusColor = 'lime';
          break;
        case 'OVERPAID':
          statusIcon = 'fas fa-exclamation';
          statusColor = 'yellow';
          break;
        case 'UNDERPAID':
          statusIcon = 'fas fa-unlink';
          statusColor = 'grey';
          break;
        case 'PROCESSING':
          statusIcon = 'fas fa-ellipsis-h';
          statusColor = 'aqua';
          break;
        case 'FINISHED':
          statusIcon = 'fas fa-check-double';
          statusColor = 'green';
          break;
        case 'REVERSAL':
          statusIcon = 'fas fa-exchange-alt';
          statusColor = 'indigo';
          break;
        case 'CANCELLED':
          statusIcon = 'fas fa-times';
          statusColor = 'red';
          break;
      }

      let statusSecondaryIcon = '';
      let statusSecondaryColor = '';
      switch (list.data[i].statusSecondary) {
        case 'WAITING':
          statusSecondaryIcon = 'fas fa-exclamation-circle';
          statusSecondaryColor = 'aqua';
          break;
        case 'APPROVING':
          statusSecondaryIcon = 'fas fa-hourglass-half';
          statusSecondaryColor = 'purple';
          break;
        case 'APPROVED':
          statusSecondaryIcon = 'fas fa-thumbs-up';
          statusSecondaryColor = 'lime';
          break;
        case 'PARTIAL':
          statusSecondaryIcon = 'fa fa-not-equal';
          statusSecondaryColor = 'indigo';
          break;
        case 'ERROR':
          statusSecondaryIcon = 'fa fa-bug';
          statusSecondaryColor = 'pink';
          break;
        case 'PAID_EXPIRED':
          statusSecondaryIcon = 'fas fa-stopwatch';
          statusSecondaryColor = 'black';
          break;
        case 'APPROVING_PAID_EXPIRED':
          statusSecondaryIcon = 'far fa-calendar-check';
          statusSecondaryColor = 'black';
          break;
        case 'PENDING_BY_USER_NOT_VALIDED':
          statusSecondaryIcon = 'fas fa-spinner';
          statusSecondaryColor = 'blue';
          break;
      }

      const invoiceId = {
        type: 'id',
        text: list.data[i]._id,
        textAlign: 'center',
      };
      let statusName = statusNames.filter((status) => {
        return status.name === list.data[i].status;
      });
      statusName = statusName.shift();

      const status = {
        name: 'status-invoice-' + list.data[i]._id,
        type: 'status-icon',
        color: statusColor,
        icon: statusIcon,
        help: list.data[i].statusDescription || '',
        text: statusName && statusName.text ? statusName.text : '',
      };

      const statusPrint = {
        name: 'status-invoice-' + list.data[i]._id,
        type: 'status-icon',
        color: statusColor,
        icon: statusIcon,
        text: list.data[i].status,
      };

      const lines = [];

      if (isInternal) {
        lines.push({
          type: 'marker',
          classAux: true,
          color: 'red',
          hidden: !list.data[i].requiresIntervention,
        });
      }

      lines.push(
        invoiceId,
        createdAt.toLocaleString('pt-BR'),
        countServices,
      );

      if (isInternal) {
        lines.push(list.data[i].email);
      }

      lines.push(
        fiatAmount,
        cryptoPrice,
        cryptoAmount,
        status,
      );

      if (isInternal) {
        let statusSecondary = list.data[i].statusSecondary ? {
          name: 'status-invoice-' + list.data[i]._id,
          type: 'status-icon',
          color: statusSecondaryColor,
          icon: statusSecondaryIcon,
          help: list.data[i].statusSecondaryDescription || statusSeconday[list.data[i].statusSecondary] || list.data[i].statusSecondary,
        } : '';

        if (!statusSecondary) {
          const hasProcessing = list.data[i].items.some((item) => item.status === 'PROCESSING');
          if (hasProcessing) {
            statusSecondary = {
              name: 'status-invoice-' + list.data[i]._id,
              type: 'status-icon',
              color: 'aqua',
              icon: 'fas fa-ellipsis-h',
              help: 'PROCESSING',
            };
          }
        }
        lines.push(statusSecondary);
      }

      // approve column
      if (isInternal) {
        const statusTxt = list.data[i].status;
        const statusSecondary = list.data[i].statusSecondary;
        const showApprove = (statusTxt === 'PENDING' && statusSecondary === 'APPROVING_PAID_EXPIRED');
        const showApproveAutoExecutable = !list.data[i].autoExecutable;

        const values = [];
        if (showApprove) {
          values.push({
            action: 'approveExpired',
            data: list.data[i],
            text: 'Aprovar pedido expirado',
          });
        }

        if (showApproveAutoExecutable) {
          values.push({
            action: 'approveExecutable',
            data: list.data[i],
            text: 'Aprovar para execução automática',
          });
        }

        const approveButton = values.length === 0 ? '' : {
          name: 'invoiceApprove-' + list.data[i]._id,
          type: 'dropdown-actions',
          text: 'Ações',
          hover: true,
          value: values,
        };

        lines.push(approveButton);
      }

      // nfse column
      if (isInternal) {
        let billCell = '';
        let billStatusIcon;
        let billStatusColor;

        if (list.data[i].bill) {
          switch (list.data[i].bill.status) {
            case 'OPEN':
              billStatusIcon = 'fa fa-star';
              billStatusColor = 'orange';
              break;
            case 'PROCESSING':
              billStatusIcon = 'fa fa-spinner';
              billStatusColor = 'blue';
              break;
            case 'FINISHED':
              billStatusIcon = 'fas fa-check-double';
              billStatusColor = 'green';
              break;
            case 'CANCELLED':
              billStatusIcon = 'fa fa-ban';
              billStatusColor = 'red';
              break;
            case 'ERROR':
              billStatusIcon = 'fa fa-bug';
              billStatusColor = 'pink';
              break;
          }

          let billStatusName = billStatusNames.filter((status) => {
            return status.name === list.data[i].bill.status;
          });
          billStatusName = billStatusName.shift();

          billCell = {
            type: 'status-icon',
            color: billStatusColor,
            icon: billStatusIcon,
            text: billStatusName && billStatusName.text ? billStatusName.text : '',
          };
        }
        if (!list.data[i].bill && list.data[i].canEmitBill) {
          billCell = {
            name: 'emitBill',
            type: 'button',
            color: 'primary',
            text: 'Emitir NFSe',
            value: {
              action: 'emitBill',
              data: list.data[i],
            },
          };
        }
        lines.push(billCell);
      }

      table.lines.push(lines);

      const linesPrint = [];

      linesPrint.push(
        list.data[i]._id,
        createdAt.toLocaleString('pt-BR'),
        countServicesPrint,
      );

      if (isInternal) {
        linesPrint.push(list.data[i].email);
      }

      linesPrint.push(
        fiatAmount,
        cryptoPrice,
        cryptoAmount,
        statusPrint,
      );

      table.linesPrint.push(linesPrint);
    }
  }

  return table;
};

export default formatValue;
