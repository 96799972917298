export default {
  SET_LIST_TRANSACTION (state, list) {
    state.list = list;
  },
  SET_LIST_TRANSACTION_PRINT (state, list) {
    state.listPrint = list;
  },
  SET_TRANSACTION_LIST_PAGE (state, currentPage) {
    state.listPaginate.currentPage = currentPage;
  },
  SET_TRANSACTION_LIST_TOTAL_PAGES (state, totalPages) {
    state.listPaginate.totalPages = totalPages;
  },
  ADD_TRANSACTION_LIST_FILTER (state, filters) {
    if (state.listFilters.indexOf(filters) === -1) {
      state.listFilters.push(filters);
    }
  },
  SET_LIST_TRANSACTION_STATISTICS (state, list) {
    state.listStatistics = list.status;
  },
  REMOVE_TRANSACTION_LIST_FILTER (state, filters) {
    state.listFilters.splice(state.listFilters.indexOf(filters), 1);
  },
  SET_TRANSACTION_LIST_SEARCH (state, search) {
    state.listSearch = search;
  },
  SET_TRANSACTIONS_SHOW_FILTERS (state, payload) {
    state.showFilters = payload;
  },
  SET_TRANSACTION_LIST_SORT (state, header) {
    const newSort = {};

    if (state.listSort.constructor === Object && header && header.name) {
      if (!(header.name in state.listSort)) {
        newSort[header.name] = 'asc';
      } else {
        if (state.listSort[header.name] === 'asc') {
          newSort[header.name] = 'desc';
        }
        if (state.listSort[header.name] === 'desc') {
          newSort[header.name] = 'asc';
        }
      }
      state.listSort = newSort;
    }
  },
};
