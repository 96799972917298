<template>
<div class="row">
  <div class="col-xs-12 col-sm-6 col-lg-2">
    <div class="form-group" :class="{'is-invalid': errors.has('ddd')}">
      <label for="ddd">{{ $t('invoices.modalReversal.fiat.recharge.ddd') }} *</label>
      <ct-select
        name="ddd"
        id="ddd"
        :key="'rechargeDDD'"
        class="from-control"
        :class="{'is-invalid': errors.has('ddd')}"
        :data-vv-as="$t('invoices.modalReversal.fiat.recharge.ddd')"
        v-model="recharge.ddd"
        :options="ddds"
        :multiple="false"
        v-validate="{ required: true }"
        @input="changeDDD"
      />
      <div class="invalid-feedback">{{ errors.first('ddd') }}</div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-6 col-lg-4">
    <div class="form-group" :class="{'is-invalid': errors.has('phone')}">
      <label for="phone">{{ $t('invoices.modalReversal.fiat.recharge.phone') }} *</label>
      <input
        class="form-control"
        id="phone"
        name="phone"
        :key="'rechargePhone'"
        :placeholder="$t('invoices.modalReversal.fiat.recharge.phone')"
        :class="{'is-invalid': errors.has('phone')}"
        :data-vv-as="$t('invoices.modalReversal.fiat.recharge.phone')"
        type="text"
        v-model="recharge.phone"
        v-validate="{ required: true }"
      >
      <div class="invalid-feedback">{{ errors.first('phone') }}</div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-6 col-lg-6">
    <div class="form-group" :class="{'is-invalid': errors.has('operator')}">
      <label for="operator">{{ $t('invoices.modalReversal.fiat.recharge.operator') }} *</label>
      <ct-select
        name="operator"
        id="operator"
        :key="'rechargeOperator'"
        ref="rechargeOperator"
        class="from-control"
        :class="{'is-invalid': errors.has('operator')}"
        :data-vv-as="$t('invoices.modalReversal.fiat.recharge.operator')"
        v-model="recharge.operator"
        :options="rechargeOperatorsOptions"
        :multiple="false"
        v-validate="{ required: true }"
        @input="changeRechargeOperator"
      />
      <div class="invalid-feedback">{{ errors.first('operator') }}</div>
    </div>
  </div>

  <div class="col-xs-12 col-sm-6 col-lg-6">
    <div class="form-group" :class="{'is-invalid': errors.has('rechargeAmount')}">
      <label for="rechargeAmount">{{ $t('invoices.modalReversal.fiat.amount') }} *</label>
      <ct-select
        name="rechargeAmount"
        id="rechargeAmount"
        :key="'rechargeAmount'"
        ref="rechargeAmount"
        class="from-control"
        :class="{'is-invalid': errors.has('rechargeAmount')}"
        :data-vv-as="$t('invoices.modalReversal.fiat.amount')"
        v-model="recharge.amount"
        :options="rechargeAmountsOptions"
        :multiple="false"
        v-validate="{ required: true }"
      />
      <div class="invalid-feedback">{{ errors.first('rechargeAmount') }}</div>
    </div>
  </div>

  <div class="col-xs-12 col-sm-6">
    <div class="form-group" :class="{'is-invalid': errors.has('rechargeFee')}">
      <label for="rechargeFee">{{ $t('invoices.modalReversal.fiat.fee') }} *</label>
      <ct-input-numeric
        :thousand-separator="$t(`currencies.BRL.thousands`)"
        :decimal-separator="$t(`currencies.BRL.decimal`)"
        :precision="2"
        autocomplete="off"
        class="form-control"
        :class="{'is-invalid': errors.has('rechargeFee')}"
        v-validate="{ required: true, max_value: maxValueForFee }"
        id="rechargeFee"
        name="rechargeFee"
        :key="'rechargeFee'"
        :data-vv-as="$t('invoices.modalReversal.fiat.fee')"
        :placeholder="$t('invoices.modalReversal.fiat.amountPlaceholder')"
        v-model="recharge.fee"
        @change="changeModel"
      ></ct-input-numeric>
      <div class="invalid-feedback">{{ errors.first('rechargeFee') }}</div>
    </div>
  </div>
</div>
</template>

<script>
import CtInputNumeric from '../../Shared/InputNumeric';
import { mapState, mapActions } from 'vuex';
import { CtSelect } from '@citartech/core-ui';

export default {
  name: 'ReversalFiatRecharge',
  props: {
    value: {
      type: Object,
      required: true,
    },
    sumReversalValue: {
      type: Number,
    },
  },
  components: {
    CtInputNumeric,
    CtSelect,
  },
  data: () => ({
    recharge: {
      operator: null,
      ddd: null,
      phone: null,
      amount: 0,
      fee: 0,
    },
  }),
  watch: {
    value () {
      this.recharge = { ...this.recharge, ...this.value || {} };
    },
  },
  mounted () {
    this.setVal();
  },
  computed: {
    ...mapState({
      ddds: state => state.ddds || [],
      rechargeOperators: (state) => state.recharges.rechargeOperators,
      rechargeAmounts: (state) => state.recharges.rechargeAmounts,
    }),
    rechargeOperatorsOptions () {
      if (!this.recharge.ddd) {
        return [];
      }
      return this.rechargeOperators;
    },
    rechargeAmountsOptions () {
      if (!this.recharge.operator) {
        return [];
      }
      return this.rechargeAmounts.filter((amount) => amount.value < this.sumReversalValue);
    },
    maxValueForFee () {
      return Number((this.sumReversalValue - this.recharge.amount).toFixed(2));
    },
  },
  methods: {
    ...mapActions([
      'getRechargeOperators',
      'getRechargeAmounts',
    ]),
    setVal () {
      this.recharge.validator = this.$validator;
      this.changeModel();
    },
    changeModel () {
      this.$emit('input', this.recharge);
    },
    async changeDDD () {
      this.$refs.rechargeOperator.clear();
      if (this.recharge.ddd) {
        await this.getRechargeOperators(this.recharge.ddd);
      }
    },
    async changeRechargeOperator () {
      this.$refs.rechargeAmount.clear();
      if (this.recharge.ddd && this.recharge.operator) {
        await this.getRechargeAmounts({
          ddd: this.recharge.ddd,
          operator: this.recharge.operator,
        });
      }
    },
  },
};
</script>
