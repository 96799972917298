<template>
  <section class="shipment-items-section" v-if="shipments.length !== 0">
    <div class="col-md-12">
      <h3>{{ $t('invoices.details.shipment.title') }}</h3>
    </div>
    <div class="table-responsive body-table">
      <table class="table table-shipments">
        <thead>
          <tr>
            <th width="14%">{{ $t('invoices.details.shipment.status') }}</th>
            <th width="14%">{{ $t('invoices.details.shipment.id') }}</th>
            <th width="14%">{{ $t('invoices.details.shipment.quantity') }}</th>
            <th width="14%" class="text-center">{{ $t('invoices.details.shipment.bankData') }}</th>
            <th width="14%">{{ $t('invoices.details.shipment.amount') }}</th>
            <th width="14%">{{ $t('invoices.details.shipment.fee') }}</th>
            <th width="14%" class="text-center">{{ $t('invoices.details.shipment.ProofOfPayment') }}</th>
            <th width="12%" class="text-right action-col" v-if="isInternal"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in shipments" :key="item._id + index">
            <td>
              <ct-status-icon
                :iconSymbol="symbols[item.status]"
                :color="colors[item.status]"
                :description="statusTranslate[item.status]"
              />
            </td>
            <td>
              <ct-cell-id :text="item._id"></ct-cell-id>
            </td>

            <td>BTC {{ getValueBTC(item.amount || 0) }}</td>
            <td class="text-center">
              <i class="fas fa-lg fa-file-alt" @click="showBankDataFromShipment(item._id || '', item.amount || 0)"></i>
            </td>
            <td>R$ {{ (item.amount || 0) | currency }}</td>
            <td>R$ {{ (item.fee || 0) | currency }}</td>
            <td class="text-center">
              <span v-if="item.receipt">
                <a :href="item.receipt" target="_blank" >
                  <i class="fa fa-lg fa-eye" />
                </a>
              </span>

              <span v-else-if="item.receiptNumber">
                {{ item.receiptNumber }}
              </span>

              <span v-else>
                <i class="far fa-lg fa-eye-slash disabled" />
              </span>
            </td>
            <td class="text-center" v-if="isInternal">
              <span v-if="isInternal && canSubmitReceipt(item)" style="margin: 0 10px">
                <a @click="toggleUploadReceipt({ id: item._id, fileRequired: true, status: item.status })">
                  <i class="fa fa-lg fa-upload" />
                </a>
              </span>
              <span>
                <a @click.prevent="openModalEdit(item)">
                  <i class="fa fa-lg fa-cog"  />
                </a>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ct-modal-view
      :title="$t('invoices.details.shipment.bankData')"
      :show="showModalBankData"
      size="small"
      @close="toggleModalBankData"
    >
      <ct-modal-bank-data
        slot="body"
        :invoiceId="invoice._id"
        :bankData="bankData"
        :banks="banks"
        @close="toggleModalBankData"
        @copy="copyToClipboard"
      />
    </ct-modal-view>

    <ct-modal-view
      v-if="editingItem"
      :title="$t('invoices.details.editItem.title')"
      :show="showModalEdit"
      size="small"
      @close="toggleModalEdit"
    >
      <ct-edit-item-modal
        slot="body"
        :invoiceId="invoice._id"
        :item="editingItem"
        @close="toggleModalEdit"
        @save="refreshInvoice"
        :notEditing="!canEditItem(editingItem)"
        :isItemReprocess="isDisabledForItem(editingItem)"
        :isItemProcess="isDisabledForItemProcess(editingItem)"
        @reprocessItem="reprocessCurrentItem"
        @processItem="processCurrentItem"
      />
    </ct-modal-view>
  </section>
</template>

<script>
import CtCellId from '@/components/Shared/DataGrid/CellId.vue';
import CtStatusIcon from '@/components/Dashboard/StatusIcon';
import currency from '@/filters/currency';
import date from '@/filters/date';
import { mapState, mapGetters } from 'vuex';
import CtModalBankData from '@/components/Invoices/ModalBankData.vue';
import ItemsActionsMixin from '@/mixins/ItemsActions';

export default {
  name: 'ShipmentItems',
  filters: {
    currency,
    date,
  },
  mixins: [
    ItemsActionsMixin,
  ],
  components: {
    CtCellId,
    CtStatusIcon,
    CtModalBankData,
  },
  props: {
    shipments: {
      type: [Array, Object],
      required: false,
      default: () => ([]),
    },
  },
  data: () => ({
    bankData: {},
    showModalBankData: false,
  }),
  computed: {
    ...mapState({
      banks: state => state.selects.banks || {},
      purposes: state => state.selects.purposes || {},
    }),
    ...mapGetters([
      'isInternal',
    ]),
    editingItem () {
      return this.shipments.find((shipment) => shipment._id === this.editingItemId);
    },
  },
  methods: {
    showBankDataFromShipment (id, amount) {
      if (!this.shipments || !id) return false;

      const itemValue = this.shipments.filter(item => {
        return item._id === id;
      });

      this.bankData =
        itemValue[0] && itemValue[0].shipment ? itemValue[0].shipment : {};
      if (this.bankData) {
        this.bankData.amount = amount;
        this.bankData.thirdPartyEmail = '';
        this.bankData.thirdPartyPurpose = '';
        if (this.bankData.isThirdParty) {
          const thirdParty = this.bankData.thirdParty || {};
          this.bankData.thirdPartyEmail = thirdParty.email || '-';
          this.bankData.thirdPartyPurpose = this.getPurposeByCode(thirdParty.purpose) || '-';
        }
        this.toggleModalBankData();
        return true;
      }
      return false;
    },
    getPurposeByCode (code) {
      if (!code) {
        return '';
      }

      const purpose = this.purposes.filter(item => {
        return item.value === code;
      });

      if (!purpose || !purpose[0] || !purpose[0].label) {
        return '';
      }

      return purpose[0].label;
    },
    toggleModalBankData () {
      this.showModalBankData = !this.showModalBankData;
    },
    openModalEdit (item) {
      this.editingItemId = item._id;
      this.toggleModalEdit();
    },
    async reprocessCurrentItem () {
      await this.reprocessItem(this.editingItem._id);
    },
    async processCurrentItem () {
      await this.processItem(this.editingItem._id);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../themes/color-admin/_ct_variable.scss";
.shipment-items-section {
  i.fa-lg {
    cursor: pointer;
    &.disabled {
      color: $ct_grey_1;
    }
  }
  h3 {
    font-weight: 400;
    margin-left: -10px;
  }
}
</style>
