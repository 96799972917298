export const getState = () => ({
  listInvoices: [],
  listInvoicesStatistics: {
    status: {},
  },
  listInvoicesPrint: [],
  listInvoicesFilters: [],
  listInvoicesCustomFilters: {
    requiresIntervention: false,
  },
  status: {},
  listInvoicesSearch: {},
  listInvoicesSort: { createdAt: 'desc' },
  listInvoicesPaginate: {
    total: 0,
    perPage: 10,
    currentPage: 1,
    totalPages: 1,
    hasNextPage: false,
    hasPrevPage: false,
  },
  showInvoicesFilters: true,
});

export default Object.assign(getState(), { getState: getState });
