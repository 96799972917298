<template>
  <div class="cell-label-component">
    <span class="cell-label" :class="[ color ]">
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.cell-label-component {
  text-align: center;
  .cell-label {
    border-radius: 50px;
    padding: 0px 15px;
    right: 0;
    white-space: nowrap;
    display: inline-block;
    margin: 0 3px;
    i {
      font-size: 10px;
    }
  }
  .default {
    background: #384a70;
    color: #fff;
  }
  .danger {
    background: #721c24;
    color: #fff;
  }
  .warning {
    background: #856404;
    color: #fff;
  }
  .success {
    background: #008a8a;
    color: #fff;
  }
  .info {
    background: #0c5460;
    color: #fff;
  }
  .black {
    background: #2d363d;
    color: #fff;
  }
  .transparent {
    background: transparent;
    border: none;
  }
}
</style>
