import http from '@/services/HttpService';

export default {
  async getBankList () {
    try {
      const res = await http().get('/api/v1/banks');
      return res.data;
    } catch (error) {
      return error;
    }
  },
};
