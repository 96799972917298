import Vue from 'vue';
import Router from 'vue-router';
import Dashboard from './views/Dashboard.vue';
import About from './views/About.vue';
import Login from './views/Login.vue';
import AuthMiddleware from './middlewares/AuthMiddleware';
import Users from './views/Users.vue';
import Profile from './views/Profile.vue';
import Invoices from './views/Invoices.vue';
import Settings from './views/Settings.vue';
import Reports from './views/Reports.vue';
import RFReporter from './views/RFReporter.vue';
import Declarations from './views/Declarations.vue';
import InvoiceReferrals from './views/InvoiceReferrals.vue';
import TransactionOrders from './views/TransactionOrders.vue';
import Transactions from './views/Transactions.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/auth/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/',
      name: 'home',
      component: Dashboard,
      beforeEnter: AuthMiddleware.hasAuthorization,
      meta: {
        permission: ['admin', 'operator', 'user'],
      },
    },
    {
      path: '/about',
      name: 'about',
      component: About,
      beforeEnter: AuthMiddleware.hasAuthorization,
      meta: {
        permission: ['admin', 'operator', 'user'],
      },
    },
    {
      path: '/users',
      name: 'users',
      component: Users,
      beforeEnter: AuthMiddleware.hasAuthorization,
      meta: {
        permission: ['admin', 'operator'],
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      beforeEnter: AuthMiddleware.hasAuthorization,
      meta: {
        permission: ['admin', 'operator', 'user'],
      },
    },
    {
      path: '/invoices',
      name: 'invoices',
      component: Invoices,
      beforeEnter: AuthMiddleware.hasAuthorization,
      meta: {
        permission: ['admin', 'operator', 'user'],
      },
    },
    {
      path: '/invoices/:id',
      name: 'invoices-details',
      component: Invoices,
      beforeEnter: AuthMiddleware.hasAuthorization,
      meta: {
        permission: ['admin', 'operator', 'user'],
      },
    },
    {
      path: '/orders',
      name: 'transactionOrders',
      component: TransactionOrders,
      beforeEnter: AuthMiddleware.hasAuthorization,
      meta: {
        permission: ['admin', 'operator'],
      },
    },
    {
      path: '/transactions',
      name: 'transactions',
      component: Transactions,
      beforeEnter: AuthMiddleware.hasAuthorization,
      meta: {
        permission: ['admin', 'operator'],
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      beforeEnter: AuthMiddleware.hasAuthorization,
      meta: {
        permission: ['admin'],
      },
    },
    {
      path: '/reports',
      name: 'reports',
      component: Reports,
      beforeEnter: AuthMiddleware.hasAuthorization,
      meta: {
        permission: ['admin'],
      },
    },
    {
      path: '/rf-reporter',
      name: 'rf-reporter',
      component: RFReporter,
      beforeEnter: AuthMiddleware.hasAuthorization,
      meta: {
        permission: ['admin'],
      },
    },
    {
      path: '/declarations',
      name: 'declarations',
      component: Declarations,
      beforeEnter: AuthMiddleware.hasAuthorization,
      meta: {
        permission: ['admin'],
      },
    },
    {
      path: '/invoice-referrals',
      name: 'invoiceReferrals',
      component: InvoiceReferrals,
      beforeEnter: AuthMiddleware.hasAuthorization,
      meta: {
        permission: ['admin', 'operator', 'user'],
      },
    },
    {
      path: '*',
      component: Dashboard,
      beforeEnter: AuthMiddleware.hasAuthorization,
      meta: {
        permission: ['admin', 'operator', 'user'],
      },
    },
  ],
});

export default router;
