<template>
  <div class="cell-button-component">
    <button class="cell-button btn btn-sm" :class="[ 'btn-'+color ]" @click="buttonClick(value)">
      <i class="icon fa" :class="[ icon ]"></i>
      {{ text }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    value: {
      type: Object,
      required: false,
    },
  },
  methods: {
    buttonClick (value) {
      this.$emit('button', { ...value, name: this.name });
    },
  },
};
</script>

<style lang="scss" scoped>
.cell-button-component {
  text-align: center;
  .cell-button {
      width: 100%;
  }
}
</style>
