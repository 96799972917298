<template>
  <div class="billet-form-component">
    <form @submit.prevent>
        <div class="row">
          <div class="col-12">
            <legend class="legend-title-content">
              <span class="legend legend-title modal-edit">{{ $t('invoices.details.editItem.labelItem') }}</span>
            </legend>
          </div>

          <div class="col-lg-9 col-md-3 col-sm-3">
            <div class="form-group">
              <label for="name">{{ $t('invoices.details.billet.digitableLine') }} *</label>
              <input
                class="form-control"
                id="digitableLine"
                name="digitableLine"
                v-mask="digitableLineMask"
                v-model="mutableItem.billet.digitableLine"
                :placeholder="$t('invoices.details.billet.digitableLine')"
                :class="{ 'is-invalid': errors.has('digitableLine') }"
                v-validate="{ required: true, billet: true }"
                :data-vv-as="$t('invoices.details.billet.digitableLine')"
                @input="onDigitableLineChange"
                @paste="onDigitableLinePaste"
                :disabled="notEditing"
              />
              <div class="invalid-feedback">{{ errors.first('digitableLine') }}</div>
            </div>
          </div>

          <div class="col-lg-3 col-md-3 col-sm-3">
            <div class="form-group">
              <label for="name">{{ $t('invoices.details.billet.dueDate') }} *</label>
              <ct-date-picker
                v-model="mutableItem.billet.dueDate"
                :format="'DD/MM/YYYY'"
                :disabled="notEditing"
              />
              <div class="invalid-feedback">{{ errors.first('digitableLine') }}</div>
            </div>
          </div>
        </div>

        <ct-item-table-events
          slot="body"
          :events="item.events || []"
        />

        <div class="row">
          <div class="col-xs-12 col-sm-12">
            <div class="form-group pull-right form-inline">
              <button class="btn btn-default btn-lg" type="button" @click="reset">
                {{ $t('invoices.details.editItem.cancel') }}
              </button>
              <button :disabled="notEditing" class="btn btn-primary btn-lg" type="submit" @click="save" >
                {{ $t('invoices.details.editItem.confirm') }}
              </button>
            </div>
          </div>
        </div>
      </form>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import CtDatePicker from '@/components/Shared/DatePicker';
import date from '../../../../filters/datetime';
import BilletService from '../../../../services/BilletService';
import moment from 'moment';
import CtItemTableEvents from '@/components/Invoices/InvoiceDetails/EditItem/ItemTableEvents.vue';

export default {
  name: 'EditBilletForm',
  data: () => ({
    mutableItem: {},
  }),
  components: {
    CtDatePicker,
    CtItemTableEvents,
  },
  filters: {
    date,
  },
  directives: {
    mask,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    notEditing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    kind () {
      if (!this.mutableItem.billet.digitableLine) {
        return false;
      }
      return this.mutableItem.billet.digitableLine.startsWith('8') ? 'convênio' : 'cobrança';
    },
    digitableLineMask () {
      return this.mutableItem.billet.digitableLine && this.kind === 'cobrança'
        ? '##### ##### ##### ###### ##### ###### # ##############'
        : '############ ############ ############ ############';
    },
  },
  created () {
    this.mutableItem = JSON.parse(JSON.stringify(this.item));
  },
  methods: {
    async reset () {
      await this.$emit('reset');
    },
    async save () {
      if (await this.$validator.validate()) {
        const data = { billet: this.mutableItem.billet };
        this.$emit('save', data);
      }
    },
    async getLineDetails () {
      if (await this.$validator.validate()) {
        const details = await BilletService.getLineDetails(this.mutableItem.billet.digitableLine);
        if (details.dueDate) {
          this.mutableItem.billet.dueDate = moment(details.dueDate, 'YYYY-MM-DD').toDate();
        }
      }
    },
    async onDigitableLineChange (event) {
      if (event instanceof InputEvent) {
        this.getLineDetails();
      }
    },
    async onDigitableLinePaste (event) {
      if (event.target.selectionStart === 0 && event.target.selectionEnd === event.target.value.length) {
        const content = (event.clipboardData || window.clipboardData).getData('text');
        this.mutableItem.billet.digitableLine = content;
        this.getLineDetails();
        event.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss">
.legend-title-content {
  margin-bottom: 20px;
}
</style>
