<template>
  <div class="user-form-component">
    <div class="user-form">
      <form @submit.prevent>

        <div class="row">
          <div class="col-12">
            <legend class="legend-title-content">
              <span class="legend legend-title">{{ $t('reports.form.subtitle') }}</span>
            </legend>
          </div>
        </div>

        <fieldset>
          <legend>
            <span class="legend">{{ $t('reports.form.selectPeriod') }}</span>
          </legend>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-2">
              <div class="form-group date-picker" :class="{ 'invalid': formErrors.from }">
                <label>{{ $t('reports.form.from') }}</label>
                <ct-date-picker
                  v-model="filters.from"
                  :format="dateFormat"
                  :disabled-date="disabledFromDates"
                ></ct-date-picker>
                <span class="error-msg" v-if="formErrors.from">Obrigatório</span>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2">
              <div class="form-group date-picker" :class="{ 'invalid': formErrors.to }">
                <label>{{ $t('reports.form.to') }}</label>
                <ct-date-picker
                  v-model="filters.to"
                  :format="dateFormat"
                  :disabled-date="disabledToDates"
                ></ct-date-picker>
                <span class="error-msg" v-if="formErrors.to">Obrigatório</span>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>
            <span class="legend">{{ $t('reports.form.filterBy') }}</span>
          </legend>

          <div class="row">
            <div class="col-xs-12 col-sm-3">
              <div class="form-group">
                <label for="orderId">{{ $t('reports.form.order') }}</label>
                <input
                  class="form-control"
                  name="orderId"
                  :placeholder="$t('reports.form.orderPlaceholder')"
                  type="text"
                  v-model="filters.orderId"
                >
              </div>
            </div>
            <div class="col-xs-12 col-sm-3">
              <div class="form-group">
                <label for="userEmail">{{ $t('reports.form.user') }}</label>
                <input
                  class="form-control"
                  name="userEmail"
                  :placeholder="$t('reports.form.userPlaceholder')"
                  type="text"
                  v-model="filters.userEmail"
                >
              </div>
            </div>
          </div>
        </fieldset>

        <div class="row">
          <div class="col-xs-12 col-sm-12">
            <div class="form-group">
              <label>{{ $t('reports.form.kind') }}</label>
              <div class="checkbox-group">
                <ct-checkbox v-model="filters.kind.billet" />
                <label>{{ $t('reports.form.billet') }}</label>

                <ct-checkbox v-model="filters.kind.recharge" />
                <label>{{ $t('reports.form.phoneRecharge') }}</label>

                <ct-checkbox v-model="filters.kind.shipment" />
                <label>{{ $t('reports.form.shipment') }}</label>

                <ct-checkbox v-model="filters.kind.opey" />
                <label>{{ $t('reports.form.opeyRecharge') }}</label>

                <ct-checkbox v-model="filters.kind.giftCard" />
                <label>{{ $t('reports.form.giftCard') }}</label>

                <ct-checkbox v-model="filters.kind.pix" />
                <label>{{ $t('reports.form.pix') }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-12">
            <div class="form-group">
              <label>{{ $t('reports.form.status') }}</label>
              <div class="checkbox-group">
                <ct-checkbox v-model="filters.status.open" />
                <label>{{ $t('invoices.itemStatus.OPEN') }}</label>

                <ct-checkbox v-model="filters.status.pending" />
                <label>{{ $t('invoices.itemStatus.PENDING') }}</label>

                <ct-checkbox v-model="filters.status.cancelled" />
                <label>{{ $t('invoices.itemStatus.CANCELLED') }}</label>

                <ct-checkbox v-model="filters.status.finished" />
                <label>{{ $t('invoices.itemStatus.FINISHED') }}</label>

                <ct-checkbox v-model="filters.status.processing" />
                <label>{{ $t('invoices.itemStatus.PROCESSING') }}</label>

                <ct-checkbox v-model="filters.status.error" />
                <label>{{ $t('invoices.itemStatus.ERROR') }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-12">
            <div class="form-group form-inline">
              <button class="btn btn-primary btn-lg" type="submit" @click="generateReport">
                <i class="fas fa-spinner fa-pulse" v-if="isLoading"></i>
                {{ $t('reports.form.submit') }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CtDatePicker from '@/components/Shared/DatePicker';
import CtCheckbox from '@/components/Shared/Checkbox';
import date from '../../filters/datetime';

export default {
  name: 'UserForm',
  components: {
    CtDatePicker,
    CtCheckbox,
  },
  filters: {
    date,
  },
  data () {
    return {
      isLoading: false,
      filters: {
        orderId: '',
        userEmail: '',
        kind: {
          billet: true,
          recharge: true,
          shipment: true,
          opey: true,
          giftCard: true,
          pix: true,
        },
        status: {
          open: true,
          pending: true,
          cancelled: true,
          finished: true,
          processing: true,
          error: true,
        },
      },
      formErrors: {
        from: false,
        to: false,
      },
    };
  },
  methods: {
    ...mapActions(['generateInvoicesSpreadsheet']),

    async generateReport () {
      this.isLoading = true;
      const isValid = await this.validate();
      if (!isValid) {
        this.isLoading = false;
        return false;
      }

      const result = await this.generateInvoicesSpreadsheet(await this.getFilters());

      if (!result) {
        this.$notify({
          type: 'error',
          title: this.$t('reports.alert.emptyReports.title'),
          text: this.$t('reports.alert.emptyReports.text'),
        });
      }

      this.isLoading = false;
      return result;
    },

    disabledFromDates (date) {
      return this.filters.to ? date > this.filters.to : false;
    },
    disabledToDates (date) {
      return this.filters.from ? date < this.filters.from : false;
    },

    async validate () {
      this.formErrors.from = !this.filters.from;
      this.formErrors.to = !this.filters.to;
      const hasErrors = Object.entries(this.formErrors).some(([k, v]) => v === true);
      return !hasErrors;
    },

    async getFilters () {
      const kindsSelected = [];
      for (const key in this.filters.kind) {
        if (this.filters.kind[key] === true) {
          kindsSelected.push(key.toUpperCase());
        }
      };

      const statusSelected = [];
      for (const key in this.filters.status) {
        if (this.filters.status[key] === true) {
          statusSelected.push(key.toUpperCase());
        }
      };

      return {
        orderId: this.filters.orderId,
        userEmail: this.filters.userEmail,
        from: this.filters.from,
        to: this.filters.to,
        kinds: kindsSelected,
        status: statusSelected,
      };
    },
  },
  computed: {
    ...mapGetters(['dateFormat']),
  },
};
</script>
<style lang="scss" scoped>
@import "@/themes/color-admin/_ct_variable.scss";

.user-form-component {
  .btn {
    margin-left: 0;
    margin-top: 20px;
  }

  .legend-title {
    font-size: 22px;
    color: #000;
  }

  legend {
    font-size: 0.9rem;
    font-weight: 500;
    padding-top: 10px;
    margin-bottom: 20px;
    border: none;
  }

  .checkbox-group {
    .checkbox-component {
      display: inline-block;
    }
    label {
      margin-left: 3px;
      margin-right: 20px;
    }
  }
}
</style>

<style lang="scss">

.date-picker.invalid {
  .mx-input {
    border-color: red;
  }
  .error-msg {
    display: block;
    color: red;
    text-align: right;
  }
}

</style>
