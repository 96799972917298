import { render, staticRenderFns } from "./FormTwoFa.vue?vue&type=template&id=f0f98c6e&scoped=true"
import script from "./FormTwoFa.vue?vue&type=script&lang=js"
export * from "./FormTwoFa.vue?vue&type=script&lang=js"
import style0 from "./FormTwoFa.vue?vue&type=style&index=0&id=f0f98c6e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0f98c6e",
  null
  
)

export default component.exports