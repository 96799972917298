import { boleto } from 'boleto-brasileiro-validator';

export const billetValidator = {
  validate (digitableLine) {
    if (!digitableLine) {
      return false;
    }
    const line = digitableLine.replace(/[^0-9]+/g, ''); // Remove non-numbers
    const isValid = boleto(line, true);
    return isValid;
  },
};
