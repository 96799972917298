
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  extends: DatePicker,

};
</script>

<style lang="scss" scoped>
  .mx-datepicker {
    width: 100%;
    .mx-input {
      font-size: 12px;
    }
  }
  .mx-datepicker-popup {
    z-index: 3000;
  }
</style>
