<script>
export default {
  name: 'OfflineIndicator',
  methods: {
    updateStatus: function () {
      this.isOnline = (typeof window.navigator.onLine === 'undefined') ? true : window.navigator.onLine;
    },
  },
  mounted () {
    this.updateStatus();
    window.addEventListener('online', this.updateStatus);
    window.addEventListener('offline', this.updateStatus);
  },
  data () {
    return {
      isOnline: false,
    };
  },
};
</script>

<template>
  <div class="offline-indicator-component label label-danger rounded-corner m-l-25" v-if="!this.isOnline">
    <i class="fa fa-times-circle"></i> OFFLINE
  </div>
</template>

<style lang="scss">
  .offline-indicator-component {
    font-size: 0.5em;
  }
</style>
