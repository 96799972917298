<template>
  <div class="giftCard-form-component">
    <form @submit.prevent v-if="isAdmin">
      <div class="row">
        <div class="col-12">
          <legend class="legend-title-content">
            <span class="legend legend-title modal-edit">{{ $t('invoices.details.editItem.labelItem') }}</span>
          </legend>
        </div>

        <div class="col-xs-12 col-md-4">
          <div class="form-group">
            <label for="pin">{{ $t('invoices.details.giftCard.pin') }} *</label>
            <input
              type="text"
              class="form-control"
              id="pin"
              name="pin"
              v-model="mutableItem.giftCard.pin"
              :placeholder="$t('invoices.details.giftCard.pin')"
              v-validate="{ required: true }"
              :class="{'is-invalid': errors.has('pin')}"
              :data-vv-as="$t('invoices.details.giftCard.pin')"
              :disabled="notEditing"
            />
            <div class="invalid-feedback">{{ errors.first('pin') }}</div>
          </div>
        </div>

        <div class="col-xs-12 col-md-4">
          <div class="form-group">
            <label for="lote">{{ $t('invoices.details.giftCard.lote') }} *</label>
            <input
              type="text"
              class="form-control"
              id="lote"
              name="lote"
              v-model="mutableItem.giftCard.lote"
              :placeholder="$t('invoices.details.giftCard.lote')"
              v-validate="{ required: true }"
              :class="{'is-invalid': errors.has('lote')}"
              :data-vv-as="$t('invoices.details.giftCard.lote')"
              :disabled="notEditing"
            />
            <div class="invalid-feedback">{{ errors.first('lote') }}</div>
          </div>
        </div>

        <div class="col-xs-12 col-md-4">
          <div class="form-group">
            <label for="serie">{{ $t('invoices.details.giftCard.serie') }} *</label>
            <input
              type="text"
              class="form-control"
              id="serie"
              name="serie"
              v-model="mutableItem.giftCard.serie"
              :placeholder="$t('invoices.details.giftCard.serie')"
              v-validate="{ required: true }"
              :class="{'is-invalid': errors.has('serie')}"
              :data-vv-as="$t('invoices.details.giftCard.serie')"
              :disabled="notEditing"
            />
            <div class="invalid-feedback">{{ errors.first('serie') }}</div>
          </div>
        </div>

      </div>
    </form>

    <ct-item-table-events
      slot="body"
      :events="item.events || []"
    />

    <div class="row">
      <div class="col-xs-12 col-sm-12">
        <div class="form-group pull-right form-inline">
          <button class="btn btn-default btn-lg" type="button" @click="reset">
            {{ $t('invoices.details.editItem.cancel') }}
          </button>
          <button :disabled="notEditing" class="btn btn-primary btn-lg" type="submit" @click="save" >
            {{ $t('invoices.details.editItem.confirm') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CtItemTableEvents from '@/components/Invoices/InvoiceDetails/EditItem/ItemTableEvents.vue';

export default {
  name: 'EditShipmentForm',
  data: () => ({
    mutableItem: {},
  }),
  components: {
    CtItemTableEvents,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    notEditing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'isAdmin',
    ]),
  },
  methods: {
    ...mapActions([
      'getBankList',
    ]),
    async reset () {
      this.$emit('reset');
    },
    async save () {
      if (await this.$validator.validate()) {
        const data = { giftCard: this.mutableItem.giftCard };
        this.$emit('save', data);
      }
    },
  },
  async created () {
    this.mutableItem = JSON.parse(JSON.stringify(this.item));
  },
};
</script>

<style lang="scss">
.legend-title-content {
  margin-bottom: 20px;
}
</style>
