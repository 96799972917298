import http from './HttpService';

export default {
  async getList ({ filters, search, limit, page, orderBy }) {
    try {
      let url = `/api/v1/transactions-orders?page=${page}&limit=${limit}`;
      if (!page || limit === 0) {
        url = `/api/v1/transactions-orders?limit=${limit}`;
      }

      if (filters && filters.length > 0) {
        const filtersQuery = JSON.stringify({
          field: 'status',
          operation: 'in',
          value: filters,
        });
        url += `&filters[]=${filtersQuery}&`;
      }
      const liked = [];
      if (search && search.value && search.value !== '') {
        const filterSearch = JSON.stringify({
          field: search.field,
          operation: liked.indexOf(search.field) >= 0 ? 'like' : '=',
          value: search.value,
        });
        url += `&filters[]=${filterSearch}`;
      }
      if (orderBy) {
        const key = Object.keys(orderBy)[0];
        url += `&orderBy=${key}&orderByDirection=${orderBy[key] || 'desc'}`;
      }

      const response = await http().get(url);
      return response.data || {
        data: [],
        meta: {},
      };
    } catch (error) {
      return error;
    }
  },

  async listStatistics () {
    const res = await http().get('/api/v1/transactions-orders/statistics');
    return res.data;
  },

  async reexecuteOrder (id) {
    const res = await http().get(`/api/v1/transactions-orders/${id}/reexecute`);
    return res.data;
  },
};
