<template>
  <div class="header navbar-default" v-if="isLogged">
    <div class="navbar-header">
      <a href="/" class="navbar-brand">
        <!-- $t('login.brandText') -->
        <span class="text-theme">
          Pague
        </span>
        <span class="version">v{{ getAppVersion }}</span>

        <ct-offline-indicator />
      </a>
      <button type="button" class="navbar-toggle" @click="sidebarToggledMobile">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
    </div>

    <ul class="navbar-nav navbar-right">
      <li class="dropdown navbar-user" :class="{'show': dropdownShow}">
        <a
          @click="toggleDropdown()"
          class="dropdown-toggle dropdown-link-user"
          data-toggle="dropdown"
        >
          <img v-if="userImage" :src="userImage" alt="User Image">
          <span
            v-if="user && (user.name || user.email)"
            class="d-none d-md-inline"
          >{{ user.name || user.email }} </span>
          <b class="caret"></b>
        </a>
        <div
          class="dropdown-menu dropdown-menu-right dropdown-profile"
          :class="{'show': dropdownShow}"
        >
          <router-link
            linkActiveClass="active"
            @click.native="toggleDropdown"
            :to="{ name: 'profile' }"
            class="dropdown-item"
          >{{ $t('header.profile') }}</router-link>
          <a href="#" @click="toggleModalChangePassword" class="dropdown-item">{{ $t('header.password') }}</a>
          <a href="#" @click="logoutUser" class="dropdown-item">{{ $t('header.logout') }}</a>
        </div>
      </li>
    </ul>
    <ct-modal-view
      :title="$t('newPassword.title')"
      :show="showModalChangePassword"
      size="small"
      @close="toggleModalChangePassword"
    >
      <ct-modal-change-password
        slot="body"
        @cancel="toggleModalChangePassword"
        @finished="toggleModalChangePassword"
      ></ct-modal-change-password>
    </ct-modal-view>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import CtModalChangePassword from '@/components/Users/ModalChangePassword.vue';
import CtModalView from '@/components/Shared/ModalView.vue';
import CtOfflineIndicator from '@/components/Dashboard/OfflineIndicator.vue';

export default {
  components: {
    CtModalChangePassword,
    CtModalView,
    CtOfflineIndicator,
  },
  name: 'Header',
  props: {},
  async mounted () {
    if (!this.user.email) {
      this.getCurrentUserData();
    }
    if (!this.user.image) {
      this.getUserImageByGravatar();
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      userImage: state => state.auth.image,
    }),
    ...mapGetters(['isLogged', 'getAppVersion']),
  },
  data () {
    return {
      dropdownShow: false,
      showModalChangePassword: false,
    };
  },
  methods: {
    ...mapActions(['logout', 'getCurrentUserData', 'getUserImageByGravatar']),
    sidebarToggledMobile () {
      this.$emit('mobile-sidebar-toggled');
    },
    async logoutUser () {
      try {
        await this.logout();
      } catch (e) {
        console.error(e);
      }
      this.$router.push({ name: 'login' });
    },
    toggleDropdown () {
      this.dropdownShow = !this.dropdownShow;
    },
    toggleModalChangePassword () {
      this.showModalChangePassword = !this.showModalChangePassword;
      this.dropdownShow = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../themes/color-admin/_ct_variable.scss";
@import "../../themes/color-admin/_variable.scss";
.navbar-header {
  .navbar-brand {
    color: $body_text_color;
    text-decoration: none;
  }
  .version {
    font-size: 0.4em;
    font-weight: 600;
    color: $ct_color;
  }
}

.text-theme,
a {
  color: $ct_color;
}

.dropdown-profile {
  position: absolute !important;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(4px, 50px, 0px);
}

.dropdown-link-user:hover,
.navbar-brand:hover {
  cursor: pointer;
}
@media print {
  .header {
    display: none;
  }
}
</style>
