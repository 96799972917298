<template>
  <div class="ct-row filter-component">
    <div class="row filter-buttons">
      <div :class="getFilterClass">
        <div class="inner-addon" v-if="enableFilterText">
          <div class="form-group input-options">
            <b-input-group>
              <b-dropdown
                class="input-options-selector"
                :text="filterTextOptions[filterTextReference].text"
                variant="default"
                slot="prepend"
                v-if="filterTextOptions[filterTextReference]"
              >
                <b-dropdown-item
                  v-for="(option, index) in filterTextOptions"
                  :key="option.name"
                  @click="changeFilterTextReference(index, option.name)"
                >

                  {{ option.text }}
                  <span v-if="!option.isSpecialSearch && option.isFilter" class="search-field">
                    <i class="fas fa-info-circle"></i>
                  </span>
                </b-dropdown-item>
              </b-dropdown>
              <input
                type="text"
                class="form-control"
                name="search"
                :placeholder="inputFilterSearchText"
                v-model="filterTextValue"
                @change="searchChange"
              >
              <i class="fa fa-search"></i>
            </b-input-group>
          </div>
        </div>
      </div>
      <div class="col-md-1" v-if="this.items && this.items.length > 0">
        <i class="fa fa-filter btn-filter" @click="toggle = !toggle"></i>
        <span class="arrow" v-show="toggle"></span>
      </div>
      <div class="col-1 col-sm-1 col-md-1 text-center" v-if="enablePrint">
        <i class="fa fa-print btn-filter" @click="printClick"></i>
      </div>
    </div>
    <div class="row filters-group" v-if="this.items && this.items.length > 0" v-show="toggle">
      <div class="panel-border">
        <div class="box-shadow">
          <div
            class="filter-container"
            v-for="item in items"
            :key="item.name"
            :style="{ width: item.width || 'auto' }"
          >
            <select class="form-control" :name="item.name" @change="changeFilter">
              <option
                v-for="option in item.options"
                :key="option.value"
                :value="option.value || ''"
              >{{ option.text || '' }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Filters',
  data () {
    return {
      toggle: false,
      filterTextReference: this.getIndexFromText(),
      filterTextField: this.getCurrentFilterField(),
      filterTextValue: this.getCurrentFilterText(),
      searchValue: '',
    };
  },
  props: {
    inputFilterSearchText: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: false,
    },
    filterTextOptions: {
      type: Array,
      required: false,
    },
    enableFilterText: {
      type: Boolean,
      default: false,
    },
    filterTextCurrent: {
      type: Object,
      required: false,
    },
    enablePrint: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getFilterClass () {
      const hasItems = this.items && this.items.length > 0;
      if (hasItems && this.enablePrint) {
        return 'col-12 col-sm-10';
      }
      if (!hasItems && this.enablePrint) {
        return 'col-11 col-sm-11 col-md-11';
      }
      return 'col-12';
    },
  },
  methods: {
    searchChange () {
      const searchEvent = {
        field: this.filterTextField,
        value: this.filterTextValue,
      };
      this.$emit('search', searchEvent);
    },
    changeFilter (event) {
      this.$emit('filter', event.target);
    },
    printClick (event) {
      this.$emit('print', event);
    },
    changeFilterTextReference (index, value) {
      this.filterTextReference = index;
      this.filterTextField = value;
      this.filterTextValue = '';
      this.searchChange();
    },
    getIndexFromText () {
      if (this.filterTextCurrent && this.filterTextCurrent.field) {
        const index = this.filterTextOptions
          .map(function (e) {
            return e.name;
          })
          .indexOf(this.filterTextCurrent.field);
        return index;
      }
      return 0;
    },
    getCurrentFilterText () {
      if (this.filterTextCurrent && this.filterTextCurrent.value) {
        return this.filterTextCurrent.value;
      }
      return '';
    },
    getCurrentFilterField () {
      if (this.filterTextCurrent && this.filterTextCurrent.field) {
        return this.filterTextCurrent.field;
      }
      return this.filterTextOptions[0] ? this.filterTextOptions[0].name : '';
    },
  },
};
</script>

<style lang="scss">
@import "@/themes/color-admin/_ct_variable.scss";

.filter-component {
  width: 100%;

  .search-field {
    font-size: 0.8em;
    color: $ct_color;
  }

  .filter-buttons {
    margin-bottom: 0;
    margin-top: 0;
    .inner-addon {
      position: relative;
      .btn {
        margin: 0;
      }
      .fa-search {
        position: absolute;
        padding: 10px;
        pointer-events: none;
        right: 5px;
        font-size: 15px;
        color: #000;
      }
      input[type="text"] {
        padding-right: 30px;
        padding-left: 20px;
        background: #f2f3f6;
        border: none;
        border-radius: 0px 1rem 1rem 0px;
        margin-left: -5px;
      }
    }
    .text-center {
      text-align: center;
    }
    .btn-filter {
      font-size: 20px;
      color: #000;
      margin-top: 8px;
      cursor: pointer;
    }
  }

  .filters-group {
    width: 100%;

    .panel-border {
      width: 100%;
      .box-shadow {
        position: relative;
        box-shadow: rgba(112, 116, 120, 0.4) 0 0 10px;
        border-collapse: collapse;
        float: right;
        padding: 10px;
        border-radius: 5px;
        max-width: 795px;
        .filter-container {
          width: 250px;
          float: right;
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
