export const getState = () => ({
  listSettings: [],
  listSettingsPrint: [],
  listSettingsSelect: [],
  listSettingsFilters: [],
  listSettingsSearch: {},
  listSettingsSort: { createdAt: 'desc' },
  listSettingsStatistics: {
    status: {},
  },
  listSettingsPaginate: {
    total: 0,
    perPage: 10,
    currentPage: 1,
    totalPages: 1,
    hasNextPage: false,
    hasPrevPage: false,
  },
  listSettingsStatusSelect: [
    { value: 'PENDING', label: 'Pendente' },
    { value: 'ACTIVED', label: 'Ativa' },
    { value: 'DISABLED', label: 'Desabilitada' },
  ],
  currentSettings: {},
});

export default Object.assign(getState(), { getState: getState });
