<template>
  <span
    v-b-tooltip.hover
    :title="title"
    class="action-button-component bg-color"
    :class="color"
    @click="actionClick(name)"
  >
    <i :class="[ icon ]" class="fa"></i>
  </span>
</template>

<script>
export default {
  props: {
    name: {
      required: true,
      type: String,
    },
    title: {
      required: false,
      type: String,
    },
    icon: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      default: 'default',
    },
  },
  methods: {
    actionClick (action) {
      this.$emit('click', action);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/themes/color-admin/_ct_variable.scss";

.action-button-component {
  padding: 2px 7px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  right: 0;
  white-space: nowrap;
  display: inline-block;
  margin: 0 3px;
  color: $white;
  margin-left: 12px;

  @media screen and (max-width: $screen-md-max) {
    margin: 5px 2px;
    float: none;
    text-align: center;
  }

  i {
    font-size: 10px;
  }

  &[class*="bg-color"] {
    &[class*="blue"] {
      background: $blue_darker;
      &:hover {
        background: darken($blue_darker, 10);
      }
      &:active {
        background: darken($blue_darker, 05);
      }
    }

    &[class*="aqua"] {
      background: $aqua_darker;
      &:hover {
        background: darken($aqua_darker, 10);
      }
      &:active {
        background: darken($aqua_darker, 05);
      }
    }

    &[class*="green"] {
      background: $green_darker;
      &:hover {
        background: darken($green_darker, 10);
      }
      &:active {
        background: darken($green_darker, 05);
      }
    }

    &[class*="red"] {
      background: $red_darker;
      &:hover {
        background: darken($red_darker, 10);
      }
      &:active {
        background: darken($red_darker, 05);
      }
    }

    &[class*="orange"] {
      background: $orange_darker;
      &:hover {
        background: darken($orange_darker, 10);
      }
      &:active {
        background: darken($orange_darker, 05);
      }
    }

    &[class*="black"] {
      background: $black_darker;
      &:hover {
        background: darken($black_darker, 10);
      }
      &:active {
        background: darken($black_darker, 05);
      }
    }

    &[class*="silver"] {
      background: $silver_darker;
      &:hover {
        background: darken($silver_darker, 10);
      }
      &:active {
        background: darken($silver_darker, 05);
      }
    }

    &[class*="grey"] {
      background: $grey_darker;
      &:hover {
        background: darken($grey_darker, 10);
      }
      &:active {
        background: darken($grey_darker, 05);
      }
    }

    &[class*="purple"] {
      background: $purple_darker;
      &:hover {
        background: darken($purple_darker, 10);
      }
      &:active {
        background: darken($purple_darker, 05);
      }
    }

    &[class*="lime"] {
      background: $lime_darker;
      &:hover {
        background: darken($lime_darker, 10);
      }
      &:active {
        background: darken($lime_darker, 05);
      }
    }

    &[class*="pink"] {
      background: $pink_darker;
      &:hover {
        background: darken($pink_darker, 10);
      }
      &:active {
        background: darken($pink_darker, 05);
      }
    }

    &[class*="indigo"] {
      background: $indigo_darker;
      &:hover {
        background: darken($indigo_darker, 10);
      }
      &:active {
        background: darken($indigo_darker, 05);
      }
    }

    &[class*="yellow"] {
      background: $yellow_darker;
      &:hover {
        background: darken($yellow_darker, 10);
      }
      &:active {
        background: darken($yellow_darker, 05);
      }
    }

    &[class*="teal"] {
      background: $teal_darker;
      &:hover {
        background: darken($teal_darker, 10);
      }
      &:active {
        background: darken($teal_darker, 05);
      }
    }
  }
}
</style>
