<template>
  <div class="user-form-component">
    <div class="user-form">
      <form @submit.prevent>
        <div class="row">
          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="name">{{ $t('formUser.name') }} *</label>
              <input
                autocomplete="off"
                class="form-control"
                :class="{'is-invalid': errors.has('name')}"
                id="name"
                name="name"
                v-validate="{ required: true }"
                :data-vv-as="$t('formUser.name')"
                :placeholder="$t('formUser.name')"
                type="text"
                v-model="mutableUser.name"
              >
              <div class="invalid-feedback">{{ errors.first('name') }}</div>
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="email">{{ $t('formUser.email') }}</label>
              <input
                class="form-control"
                :class="{'is-invalid': errors.has('email')}"
                autocomplete="new-email"
                id="email"
                name="email"
                v-validate="{ required: true, email: true }"
                :data-vv-as="$t('formUser.email')"
                :placeholder=" $t('formUser.email') "
                type="email"
                v-model="mutableUser.email"
              >
              <div class="invalid-feedback">{{ errors.first('email') }}</div>
            </div>
          </div>

          <div class="col-12 col-sm-4">
            <div class="form-group" :class="{'is-invalid': errors.has('roles')}">
              <label for="language">{{ $t('formUser.language') }}</label>
              <ct-select
                name="language"
                id="language"
                class
                :class="{'is-invalid': errors.has('language')}"
                v-model="mutableUser.language"
                :options="languages"
                v-validate="{ required: true }"
                :data-vv-as="$t('formUser.language')"
              />
              <div class="invalid-feedback">{{ errors.first('language') }}</div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
            <label for="changePassword">{{ $t('formUser.labelPassword') }}</label>
            <button
              type="button"
              id="changePassword"
              @click="toggleModalChangePassword"
              class="btn btn-primary ct-form-button"
            >{{ $t('formUser.buttonPassword') }}</button>
          </div>
          <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-2">
            <label for="change2FA">{{ $t('formUser.label2FA') }}</label>
            <button
              type="button"
              id="change2FA"
              @click="toggleModalChange2Fa"
              class="btn btn-primary ct-form-button"
            >{{ user.isEnabled2fa ? $t('formUser.disable2FA') : $t('formUser.enable2FA') }}</button>
          </div>
          <div class="col-xs-12 col-md-12 col-sm-12 col-lg-6">
            <div class="form-group">
              <label>{{ $t('formUser.enabledReferralSmsNotification') }}</label>
              <div class="checkbox-group">
                <ct-checkbox v-model="mutableUser.enabledReferralSmsNotification" />
                <label>{{ $t('formUser.enabledReferralSmsNotificationLabel') }}</label>
              </div>
            </div>
          </div>
        </div>
        <fieldset>
          <legend>
            <span class="legend">{{ $t('formUser.complementTitle') }}</span>
          </legend>

          <div class="row">
            <div class="col-12 col-sm-12 col-md-4">
              <div class="form-group">
                <label for="kind">{{ $t('formUser.kind') }}</label>
                <ct-select
                  name="kind"
                  id="kind"
                  class="from-control"
                  :class="{'is-invalid': errors.has('kind')}"
                  v-model="mutableUser.kind"
                  :options="legalTypes"
                  v-validate="{ required: true }"
                  :data-vv-as="$t('formUser.kind')"
                />
                <div class="invalid-feedback">{{ errors.first('kind') }}</div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4">
              <div class="form-group">
                <label
                  for="document"
                >{{ (mutableUser.kind === 'F' ? $t('formUser.documentPerson') : $t('formUser.documentLegal')) }}</label>
                <the-mask
                  id="document"
                  name="document"
                  :mask="(mutableUser.kind === 'F'? $t('formUser.masks.documentTypeF') : $t('formUser.masks.documentTypeJ'))"
                  v-model="mutableUser.document"
                  type="text"
                  :masked="false"
                  :placeholder="(mutableUser.kind === 'F' ? $t('formUser.documentPerson') : $t('formUser.documentLegal'))"
                  class="form-control"
                ></the-mask>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4">
              <div class="form-group">
                <label
                  for="document-secondary"
                >{{ (mutableUser.kind === 'F' ? $t('formUser.documentSecondaryPerson') : $t('formUser.documentSecondaryLegal')) }}</label>
                <input
                  class="form-control"
                  id="document-secondary"
                  name="documentSecondary"
                  :placeholder="(mutableUser.kind === 'F' ? $t('formUser.documentSecondaryPerson') : $t('formUser.documentSecondaryLegal'))"
                  type="text"
                  v-model="mutableUser.documentSecondary"
                >
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4">
              <div class="form-group date-picker">
                <label>{{ $t('formUser.birth') }}</label>
                <ct-date-picker
                  v-model="mutableUser.birth"
                  :format="dateFormat"
                ></ct-date-picker>
              </div>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-4">
              <div class="form-group">
                <label for="telephone">{{ $t('formUser.phone') }}</label>
                <the-mask
                  :mask="[$t('formUser.masks.telephone'), $t('formUser.masks.telephone1')]"
                  v-model="mutableUser.phone"
                  type="text"
                  id="telephone"
                  name="telephone"
                  :masked="false"
                  :placeholder="$t('formUser.phone')"
                  v-validate="{required: true}"
                  :data-vv-as="$t('formUser.phone')"
                  class="form-control"
                ></the-mask>
              </div>
            </div>

            <div class="col-12" v-if="mutableUser.kind === 'F'">
              <div class="form-group" :class="{'is-invalid': errors.has('motherName')}">
                <label for="motherName">{{ $t('formUser.motherName') }}</label>
                <input
                  class="form-control"
                  id="motherName"
                  name="motherName"
                  :placeholder="$t('formUser.motherName')"
                  type="text"
                  v-model="mutableUser.motherName"
                >
                <div class="invalid-feedback">{{ errors.first('motherName') }}</div>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>
            <span class="legend">{{ $t('formUser.addressesTitle') }}</span>
          </legend>

          <div class="row">
            <div class="col-xs-12 col-sm-3">
              <div class="form-group">
                <label for="zipCode">{{ $t('address.zipCode') }} *</label>
                <the-mask
                  type="text"
                  class="form-control"
                  :class="{'is-invalid': errors.has('zipCode')}"
                  id="zipCode"
                  name="zipCode"
                  v-validate="{required: true, regex: /\d{5}-\d{3}/}"
                  :data-vv-as="$t('address.zipCode')"
                  :mask="$t('formUser.masks.zipCode')"
                  :masked="true"
                  v-model="mutableUser.addresses[0].zipCode"
                  :placeholder="$t('address.zipCode')"
                  autocomplete="zipCode"
                  @input="onChangeZipCode"
                ></the-mask>
                <div class="invalid-feedback">{{ errors.first('zipCode') }}</div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-6">
              <div class="form-group">
                <label for="address">{{ $t('address.address') }} *</label>
                <input
                  class="form-control"
                  id="address"
                  name="address"
                  :placeholder="$t('address.address')"
                  type="text"
                  :class="{'is-invalid': errors.has('address')}"
                  v-validate="{required: true}"
                  :data-vv-as="$t('address.address')"
                  v-model="mutableUser.addresses[0].address"
                >
                <div class="invalid-feedback">{{ errors.first('address') }}</div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-3">
              <div class="form-group">
                <label for="number">{{ $t('address.number') }} *</label>
                <input
                  class="form-control"
                  id="number"
                  name="number"
                  :placeholder="$t('address.number')"
                  type="text"
                  :class="{'is-invalid': errors.has('number')}"
                  v-validate="{required: true}"
                  :data-vv-as="$t('address.number')"
                  v-model="mutableUser.addresses[0].number"
                >
                <div class="invalid-feedback">{{ errors.first('number') }}</div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-3">
              <div class="form-group">
                <label for="district">{{ $t('address.complement') }}</label>
                <input
                  class="form-control"
                  id="complement"
                  name="district"
                  :placeholder="$t('address.complement')"
                  type="text"
                  v-model="mutableUser.addresses[0].complement"
                >
              </div>
            </div>

            <div class="col-xs-12 col-sm-3">
              <div class="form-group">
                <label for="district">{{ $t('address.district') }} *</label>
                <input
                  class="form-control"
                  id="district"
                  name="district"
                  :placeholder="$t('address.district')"
                  type="text"
                  :class="{'is-invalid': errors.has('district')}"
                  v-validate="{required: true}"
                  :data-vv-as="$t('address.district')"
                  v-model="mutableUser.addresses[0].district"
                >
                <div class="invalid-feedback">{{ errors.first('district') }}</div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-3">
              <div class="form-group">
                <label for="city">{{ $t('address.city') }} *</label>
                <input
                  class="form-control"
                  id="city"
                  name="city"
                  :placeholder="$t('address.city')"
                  type="text"
                  :class="{'is-invalid': errors.has('city')}"
                  v-validate="{required: true}"
                  :data-vv-as="$t('address.city')"
                  v-model="mutableUser.addresses[0].city"
                >
                <div class="invalid-feedback">{{ errors.first('city') }}</div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-3">
              <div class="form-group">
                <label for="state">{{ $t('address.uf') }} *</label>
                <ct-select
                  name="state"
                  id="state"
                  v-model="mutableUser.addresses[0].uf"
                  :options="states"
                  :class="{'is-invalid': errors.has('state')}"
                  v-validate="{required: true}"
                  :data-vv-as="$t('address.uf')"
                />
                <div class="invalid-feedback">{{ errors.first('state') }}</div>
              </div>
            </div>
          </div>
        </fieldset>

        <div class="row">
          <div class="col-xs-12 col-sm-12">
            <span
              class="badge badge-default badge-square"
              v-if="user.isEnabled2fa"
            >{{ $t('formUser.isEnabled2Fa')}}</span>
            <span
              class="badge badge-default badge-square"
              v-if="user.partnerCode"
            ><a v-bind:href="referralLink">{{ referralLink }}</a></span>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-12">
            <div class="form-group pull-right form-inline">
              <button class="btn btn-primary btn-lg" type="submit" @click="sendUser">
                <i class="fas fa-spinner fa-pulse" v-if="isLoading"></i>
                {{ $t('formUser.submit') }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <ct-modal-view
      :title="$t('newPassword.title')"
      :show="showModalChangePassword"
      size="small"
      @close="toggleModalChangePassword"
    >
      <ct-modal-change-password
        slot="body"
        @cancel="toggleModalChangePassword"
        @finished="toggleModalChangePassword"
      ></ct-modal-change-password>
    </ct-modal-view>
    <ct-modal-view
      :title="$t('formUser.enable2FA')"
      :show="showModalEnable2Fa"
      size="small"
      @close="toggleModalChange2Fa"
    >
      <ct-modal-enable-two-fa
        slot="body"
        @cancel="toggleModalChange2Fa"
        @finished="finishedChange2Fa"
      ></ct-modal-enable-two-fa>
    </ct-modal-view>
    <ct-modal-view
      :title="$t('formUser.disable2FA')"
      :show="showModalDisable2Fa"
      size="small"
      @close="toggleModalChange2Fa"
    >
      <ct-modal-disable-two-fa
        slot="body"
        @cancel="toggleModalChange2Fa"
        @finished="finishedChange2Fa"
      ></ct-modal-disable-two-fa>
    </ct-modal-view>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { CtSelect } from '@citartech/core-ui';
import AuthService from '../../services/UserService';
import ZipCodeService from '../../services/ZipCodeService';
import User from '../../model/User';
import CtDatePicker from '@/components/Shared/DatePicker';
import CtCheckbox from '@/components/Shared/Checkbox';
import date from '../../filters/datetime';
import { TheMask } from 'vue-the-mask';
import CtModalView from '@/components/Shared/ModalView.vue';
import CtModalChangePassword from '@/components/Users/ModalChangePassword.vue';
import CtModalEnableTwoFa from '@/components/Users/ModalEnableTwoFa.vue';
import CtModalDisableTwoFa from '@/components/Users/ModalDisableTwoFa.vue';

export default {
  name: 'UserForm',
  components: {
    CtSelect,
    CtDatePicker,
    CtCheckbox,
    TheMask,
    CtModalView,
    CtModalChangePassword,
    CtModalEnableTwoFa,
    CtModalDisableTwoFa,
  },
  filters: {
    date,
  },
  props: {
    title: {
      type: String,
    },
    user: {
      type: Object,
    },
  },
  data () {
    return {
      isLoading: false,
      mutableUser: new User(),
      showPassword: false,
      showModalChangePassword: false,
      showModalEnable2Fa: false,
      showModalDisable2Fa: false,
    };
  },
  methods: {
    finished (isSuccess = false) {
      const response = isSuccess ? this.mutableUser : false;
      this.$emit('finished', response);
    },
    async sendUser () {
      try {
        const isValid = await this.$validator.validate();
        if (!isValid) {
          this.$notify({
            type: 'error',
            title: this.$t('formInvoice.notificationErrorTitle'),
            text: this.$t('formInvoice.notificationErrorMessage'),
          });
          return;
        }

        this.isLoading = true;
        let mutableUserResponse = {};
        mutableUserResponse = await AuthService.update(this.mutableUser);
        this.mutableUser.createdAt = mutableUserResponse.data.createdAt;
        this.mutableUser.updatedAt = mutableUserResponse.data.updatedAt;
        this.$emit('finished', this.mutableUser);
        this.isLoading = false;
      } catch (e) {
        this.processErrorOnSave(e.response || {});
      }
    },
    toggleShowPassword () {
      this.showPassword = !this.showPassword;
    },
    processErrorOnSave (response) {
      this.$notify({
        type: 'error',
        title: response.data.message || this.$t('formUser.notificationErrorTitle'),
        text: this.$t('formUser.notificationErrorMessage'),
      });
    },
    toggleModalChangePassword () {
      this.showModalChangePassword = !this.showModalChangePassword;
    },
    finishedChange2Fa () {
      this.$emit('change2fa', {});
      this.toggleModalChange2Fa();
    },
    toggleModalChange2Fa () {
      if (this.user.isEnabled2fa) {
        this.showModalDisable2Fa = !this.showModalDisable2Fa;
        this.showModalEnable2Fa = false;
      } else {
        this.showModalEnable2Fa = !this.showModalEnable2Fa;
        this.showModalDisable2Fa = false;
      }
    },

    populateUserByViaCepResponse (response) {
      this.mutableUser.addresses[0].address = response.address;
      this.mutableUser.addresses[0].district = response.district;
      this.mutableUser.addresses[0].complement = response.complement;
      this.mutableUser.addresses[0].city = response.city;
      this.mutableUser.addresses[0].uf = response.uf;
    },

    async onChangeZipCode () {
      if (this.mutableUser.addresses[0].zipCode) {
        const response = await ZipCodeService.getAddressByZipCode(
          this.mutableUser.addresses[0].zipCode,
        );
        if (response) {
          this.populateUserByViaCepResponse(response);
        }
      }
    },
  },
  computed: {
    ...mapState({
      roles: state => state.selects.roles || {},
      states: state => state.selects.states || {},
      languages: state => state.selects.languages || {},
      legalTypes: state => state.selects.legalTypes || {},
      listSelectUsers: state => state.users.listSelect || [],
    }),
    ...mapGetters(['dateFormat']),
    referralLink () {
      return `https://paguecombitcoin.com/?ref=${this.mutableUser.partnerCode}`;
    },
  },
  created () {
    if (this.user && this.user instanceof User) {
      this.mutableUser = this.user;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/themes/color-admin/_ct_variable.scss";

.user-form-component {
  .ct-form-button {
    display: block;
    margin-left: 0;
    min-width: 150px;
    margin-bottom: 20px;
  }

  .badge {
    margin-right: 10px;
  }

  .badge-custom {
    background: #d6f5db;
    color: #2d353c;
    margin-bottom: -40px;
  }

  legend {
    font-size: 0.9rem;
    font-weight: 500;
    padding-top: 10px;
  }

  .checkbox-group {
    .checkbox-component {
      display: inline-block;
    }
    label {
      margin-left: 3px;
      margin-right: 20px;
    }
  }
}
</style>
