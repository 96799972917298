<template>
<div class="row">
  <div class="col-xs-12 col-sm-6">
    <div class="form-group" :class="{'is-invalid': errors.has('key')}">
      <label for="key">{{ $t('invoices.modalReversal.fiat.pix.key') }} *</label>
      <input
        class="form-control"
        id="key"
        :key="'pixKey'"
        name="key"
        :placeholder="$t('invoices.modalReversal.fiat.pix.key')"
        :class="{'is-invalid': errors.has('key')}"
        :data-vv-as="$t('invoices.modalReversal.fiat.pix.key')"
        type="text"
        @change="changeModel"
        v-model="pix.key"
        v-validate="{ required: true }"
      >
      <div class="invalid-feedback">{{ errors.first('key') }}</div>
    </div>
  </div>

  <div class="col-xs-12 col-sm-3">
    <div class="form-group" :class="{'is-invalid': errors.has('pixAmount')}">
      <label for="pixAmount">{{ $t('invoices.modalReversal.fiat.amount') }} *</label>
      <ct-input-numeric
        :thousand-separator="$t(`currencies.BRL.thousands`)"
        :decimal-separator="$t(`currencies.BRL.decimal`)"
        :precision="2"
        autocomplete="off"
        class="form-control"
        :class="{'is-invalid': errors.has('pixAmount')}"
        v-validate="{ required: true, max_value: maxValueForAmount }"
        id="pixAmount"
        name="pixAmount"
        :key="'pixAmount'"
        :data-vv-as="$t('invoices.modalReversal.fiat.amount')"
        :placeholder="$t('invoices.modalReversal.fiat.amountPlaceholder')"
        v-model="pix.amount"
        @change="changeModel"
      ></ct-input-numeric>
      <div class="invalid-feedback">{{ errors.first('pixAmount') }}</div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-3">
    <div class="form-group" :class="{'is-invalid': errors.has('pixFee')}">
      <label for="pixFee">{{ $t('invoices.modalReversal.fiat.fee') }} *</label>
      <ct-input-numeric
        :thousand-separator="$t(`currencies.BRL.thousands`)"
        :decimal-separator="$t(`currencies.BRL.decimal`)"
        :precision="2"
        autocomplete="off"
        class="form-control"
        :class="{'is-invalid': errors.has('pixFee')}"
        v-validate="{ required: true, max_value: maxValueForFee }"
        id="pixFee"
        name="pixFee"
        :key="'pixFee'"
        :data-vv-as="$t('invoices.modalReversal.fiat.fee')"
        :placeholder="$t('invoices.modalReversal.fiat.amountPlaceholder')"
        v-model="pix.fee"
        @change="changeModel"
      ></ct-input-numeric>
      <div class="invalid-feedback">{{ errors.first('pixFee') }}</div>
    </div>
  </div>
</div>

</template>

<script>
import { mask } from 'vue-the-mask';
import CtInputNumeric from '../../Shared/InputNumeric';

export default {
  name: 'ReversalFiatPix',
  props: {
    value: {
      type: Object,
      required: true,
    },
    sumReversalValue: {
      type: Number,
    },
  },
  components: {
    CtInputNumeric,
  },
  directives: {
    mask,
  },
  data: () => ({
    pix: {
      key: null,
      amount: 0,
      fee: 0,
    },
  }),
  watch: {
    value () {
      this.pix = { ...this.pix, ...this.value || {} };
    },
  },
  mounted () {
    this.setVal();
  },
  computed: {
    maxValueForFee () {
      return Number((this.sumReversalValue - this.pix.amount).toFixed(2));
    },
    maxValueForAmount () {
      if (this.pix.fee) {
        return Number((this.sumReversalValue - this.pix.fee).toFixed(2));
      }

      return Number((this.sumReversalValue).toFixed(2));
    },
  },
  methods: {
    setVal () {
      this.pix.validator = this.$validator;
      this.changeModel();
    },
    changeModel () {
      this.$emit('input', this.pix);
    },
  },
};
</script>
