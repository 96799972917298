<template>
  <div class="reporter-widget-component">
    <ct-panel
      :title="$t('rfReporter.title')"
      :reloading="isLoading"
      :actionButtons="actionButtons"
      v-if="showPanel"
    >
      <div slot="panel-content" class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h3>{{ $t('rfReporter.form.title') }}</h3>
        </div>
        <form action="">
          <div class="row align-items-end reporter-data-content">
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div class="form-group">
                <label for="">
                  {{ $t('rfReporter.form.labelDate') }}
                </label>
                <ct-date-picker
                  :format="'MM/YYYY'"
                  name="date"
                  class="month"
                  v-model="reporter.date"
                  v-validate="{ required: true }"
                  type="month"
                  :disabled-date="disableMonths"
                  :data-vv-as="$t('rfReporter.form.date')"
                ></ct-date-picker>
                {{ reporter.month }}
                <span class="invalid-feedback" v-if="errors.first('date')">{{ errors.first('date') }}</span>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
              <div class="form-group form-checked align-items-end">
                <ct-checkbox class="form-check-input" v-model="reporter.detailed" />
                <label>{{ $t('rfReporter.form.detailedReport') }}</label>
              </div>
            </div>
          </div>
          <div class="row reporter-data-content">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <div class="form-group form-action">
                <button
                class="btn btn-primary btn-block btn-emit"
                @click.prevent="emitReporter"
                >
                <i class="fas fa-spin fa-circle-notch" v-if="isLoadingReport"></i>
                {{ $t('rfReporter.form.submitButton') }}
                </button>
              </div>
            </div>
          </div>

          <ct-table-invoices
            :invoices="invoices"
            :detailed="reporter.detailed"
            @finish="emitReporter"
            @twoFaAlert="toggleTwoFaAlert"
          ></ct-table-invoices>

          <div class="row" v-if="messageNotFound">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <h3>
                  {{ messageNotFound }}
                </h3>
            </div>
          </div>

          <div class="row reporter-data-content table-content">
            <button class="btn btn-primary btn-lg btn-reporter" @click.prevent="generateNotes" v-if="!isLoadingDeclaration && showDeclarationEmission">
              <i class="fas fa-file-alt"></i>
              {{ $t('rfReporter.form.buttonGenerateDeclaration') }}
            </button>
            <button class="btn btn-success btn-lg btn-reporter" v-else-if="showDeclarationEmission">
              <i class="fas fa-spin fa-circle-notch"></i>
              {{ $t('rfReporter.form.buttonEmitingAlertRF') }}
            </button>
          </div>
        </form>
      </div>
    </ct-panel>

    <ct-modal-view
      :styleContent="{padding: '0px'}"
      :show="showTwoFaAlert"
      @close="toggleTwoFaAlert"
      size="small"
    >
      <ct-enable-two-fa slot="body" @close="toggleTwoFaAlert" />
    </ct-modal-view>

    <ct-modal-view
      :styleContent="{padding: '0px'}"
      :show="showTwoFaForm"
      @close="toggleTwoFaForm"
      size="small"
    >
      <ct-form-two-fa
        :date="date"
        slot="body"
        @close="toggleTwoFaForm"
      />
    </ct-modal-view>

    <ct-modal-view
      :styleContent="{padding: '0px'}"
      :show="showInvalidItemsModal"
      @close="toggleInvalidItemsModal"
      size="small"
    >
      <ct-modal-invalid-items
        :data="errorData"
        slot="body"
        @close="toggleInvalidItemsModal"
      />
    </ct-modal-view>
  </div>

</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import CtPanel from '@/components/Dashboard/Panel.vue';
import CtDatePicker from '@/components/Shared/DatePicker';
import CtCheckbox from '@/components/Shared/Checkbox';
import InvoiceService from '@/services/InvoiceService';
import CtModalView from '@/components/Shared/ModalView.vue';
import CtTableInvoices from './TableInvoices';
import CtEnableTwoFa from './EnableTwoFa';
import CtFormTwoFa from './FormTwoFa';
import CtModalInvalidItems from './ModalInvalidItems';

export default {
  name: 'ReporterWidget',
  data () {
    return {
      showPanel: true,
      isLoading: false,
      actionButtons: [],
      errorData: [],
      reporter: {},
      alertNotes: false,
      emissionDetailed: false,
      showTwoFaAlert: false,
      showTwoFaForm: false,
      showInvalidItemsModal: false,
      invoices: [],
      isLoadingDeclaration: false,
      isLoadingReport: false,
      messageNotFound: null,
    };
  },
  methods: {
    disableMonths (date) {
      return date.getMonth() > (new Date().getMonth()) && date.getFullYear() >= (new Date().getFullYear());
    },
    toggleInvalidItemsModal () {
      this.showInvalidItemsModal = !this.showInvalidItemsModal;
    },
    toggleTwoFaAlert () {
      this.showTwoFaAlert = !this.showTwoFaAlert;
    },
    toggleTwoFaForm (data = {}) {
      this.showTwoFaForm = !this.showTwoFaForm;
      if (data.error) {
        this.errorData = data.errors;
        this.toggleInvalidItemsModal();
      }
    },
    async getInvoices (fullDate) {
      const data = await InvoiceService.getInvoicesForDeclaration(fullDate);
      this.invoices = data.data;
      this.messageNotFound = null;
      if (this.invoices.length === 0) {
        this.messageNotFound = this.$t('rfReporter.itensNotFound');
      }
    },
    async emitReporter () {
      const isValid = await this.$validator.validateAll();
      if (isValid && this.reporter.date) {
        this.isLoadingReport = true;
        this.emissionDetailed = this.reporter.detailed;
        await this.getInvoices(this.date);
        this.isLoadingReport = false;
      }
    },
    generateNotes () {
      if (!this.isEnabled2fa) {
        return this.toggleTwoFaAlert();
      }
      return this.toggleTwoFaForm();
    },
  },
  components: {
    CtPanel,
    CtDatePicker,
    CtCheckbox,
    CtTableInvoices,
    CtModalView,
    CtEnableTwoFa,
    CtFormTwoFa,
    CtModalInvalidItems,
  },
  computed: {
    ...mapGetters(['dateFormat', 'isEnabled2fa']),
    showDeclarationEmission () {
      return (this.invoices.length) > 0;
    },
    date () {
      if (this.reporter.date instanceof Date) {
        return moment(this.reporter.date).format('MM/YYYY');
      }

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/themes/color-admin/ct-components/_grids-size.scss";

.reporter-widget-component {
  h3 {
    margin: 20px 30px 25px;
    @media only screen and (max-width: $grid-sm-max) {
      font-size: 20px;
      margin: 10px;
      text-align: center;
    }
  }
  form {
    width: 100%;
    .row {
      padding: 0;
      margin: 0;

      &.reporter-content {
        width: 100%;
        padding: 0 35px;

        @media only screen and (max-width: $grid-lg-max) {
          width: 100%;
          margin: 0 auto;
        }

        &:last-child {
          margin-bottom: 50px
        }
      }

      &.reporter-data-content {
        width: 100%;
        padding: 0 35px 10px;

        @media only screen and (max-width: $grid-lg-max) {
          width: 100%;
          margin: 0 auto;
        }

        .form-group {
          position: relative;

          .invalid-feedback {
            display: flex;
            justify-content: center;
            position: absolute;
            height: 40px;
          }

          &.form-action {
            margin-bottom: 40px;
          }

          &.form-checked {
            padding: 0 20px;
            font: {
              weight: 400;
              size: 14px;
            }

            .checkbox-component {
              margin: 0;
            }
            label {
              margin-left: 30px;
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import "@/themes/color-admin/ct-components/_grids-size.scss";

.reporter-widget-component {
  .reporter-content {
    &.table-content {
      width: 100%;
      @media only screen and (max-width: $grid-lg-max) {
        width: 100%;
      }

      h4 {
        margin-bottom: 20px;
        font: {
          weight: 400;
          size: 21px;
        }

        @media only screen and (max-width: $grid-sm-max) {
          font-size: 18px
        }
      }

      .footer-emit {
        height: auto;
        width: 100%;
        background: #f2f3f4;
        text-transform: uppercase;
        color: #242a30;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }

  .table {
    @media only screen and (max-width: 1600px) {
      display: block;
      width: 100%;
      overflow-x: auto;
    }
  }

  .panel-content {
    .btn {
      text-transform: uppercase;
      color: white;
      cursor: pointer;
      font: {
        weight: 400;
        size: 14px;
      }

      &:hover {
        color: white;
      }

      &.btn-light {
        color: #242a30;

        &:hover {
          color: #242a30;
        }
      }

      &.btn-sm {
        padding: 2px 12px;
        font-size: 10px;
        min-width: 74px;
      }

      &.btn-lg {
        i {
          font-size: 20px;
        }
      }

      &.btn-emit {
        @media only screen and (max-width: $grid-sm-max) {
          float: right;
          margin-top: 20px;
        }
      }

      &.btn-reporter {
        @media only screen and (max-width: $grid-sm-max) {
          margin: 10px auto;
        }
      }

      i {
        margin: 0 5px;
      }
    }

    .status-icon {
      display: flex;
      font-weight: 400;

      .icon {
        width: 18px;
        height: 18px;
        border-radius: 4px;
        margin-right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          color: #ffffff;
          font-size: 10px;
        }

        &.bg-success {
          background: #32a932!important;
        }
        &.bg-info {
          background: #00acac!important;
        }
      }
    }
  }
  .month .mx-calendar-header {
    display: none;
  }
}
</style>
