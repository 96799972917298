<template>
   <div class="row reporter-content table-content" v-if="invoices.length > 0">
    <h4>
      <i class="far" :class="{'fa-plus-square': !showInvoices && detailed, 'fa-minus-square': showInvoices && detailed}" @click="toogleShowInvoices"></i>
      {{ $t('rfReporter.form.invoices') }}</h4>
    <table class="table table-responsive" v-if="detailed && showInvoices">
      <thead>
        <tr>
          <th width="5%">{{ $t('rfReporter.form.id') }}</th>
          <th width="14%">{{ $t('rfReporter.form.client') }}</th>
          <th width="10%">{{ $t('rfReporter.form.cryptoFee') }}</th>
          <th width="10%">{{ $t('rfReporter.form.cryptoPrice') }}</th>
          <th width="10%">{{ $t('rfReporter.form.fee') }}</th>
          <th width="8%">{{ $t('rfReporter.form.fiatAmount') }}</th>
          <th width="12%">{{ $t('rfReporter.form.cryptoAmount') }}</th>
          <th width="7%">{{ $t('rfReporter.form.createdAt') }}</th>
          <th width="8%">{{ $t('rfReporter.form.nfseNumber') }}</th>
          <th width="20%">{{ $t('rfReporter.form.nfse') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in invoices" :key="item._id">
          <td>{{ getIdResumed(item._id) }}</td>
          <td>{{ item.email || '-'}}</td>
          <td>{{ item.cryptoFee | currency(8)}} BTC</td>
          <td>R$ {{ item.cryptoPrice | currency(2)}}</td>
          <td>R$ {{ item.fee | currency(2)}}</td>
          <td>R$ {{ item.fiatAmount | currency(2)}}</td>
          <td>{{ item.cryptoAmount | currency(8)}} BTC</td>
          <td>{{ item.createdAt | date }}</td>
          <td>{{ item.bill && item.bill.code ? item.bill.code : '' }}</td>
          <td>
            <div class="status-icon" v-if="item.bill && item.bill.code">
              <div class="icon bg-success">
                <i class="fas fa-check"></i>
              </div>
              {{ $t('rfReporter.form.statusIssued') }}
            </div>

            <div class="status-icon" v-else-if="item.bill && !item.bill.code">
              <div class="icon bg-primary">
                <i class="fa fa-spinner"></i>
              </div>
              {{ $t('rfReporter.form.statusPending') }}
            </div>

            <button
              class="btn btn-primary btn-sm"
              @click.prevent="emitNfse(item._id)"
              v-else-if="!isLoadingAllInvoice && currentEmissionInvoiceId !== item._id && item.fee > 0"
            >
              {{ $t('rfReporter.form.issueInvoice') }}
            </button>

            <div class="status-icon" v-else-if="currentEmissionInvoiceId === item._id || isLoadingAllInvoice">
              <div class="icon bg-info">
                <i class="fas fa-spin fa-circle-notch"></i>
              </div>
              {{ $t('rfReporter.form.statusEmiting') }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="footer-emit">
        <div class="row">
          <div class="col-4">
            {{ $t('rfReporter.form.totalInvoices') }}: {{ invoices.length }}
          </div>
          <div class="col-8">
            {{ $t('rfReporter.form.nfsePending') }}: {{ totalPendingInvoices }}
            <button class="btn btn-primary btn-sm" :disabled="totalPendingInvoices === 0" @click.prevent="emitNfse()" v-if="!isLoadingInvoice">
              {{ $t('rfReporter.form.issueAllPendingInvoicesNfse') }}
            </button>

            <button class="btn btn-info btn-sm" v-else>
              <i class="fas fa-spin fa-circle-notch"></i>
              {{ $t('rfReporter.form.emitingAllPendingInvoicesNfse') }}
            </button>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import InvoiceService from '@/services/InvoiceService';
import { mapGetters } from 'vuex';
import currency from '../../filters/currency';
import date from '../../filters/date';

export default {
  name: 'TableInvoices',
  filters: { currency, date },
  props: {
    invoices: {
      type: Array,
      required: true,
    },
    detailed: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    isLoadingInvoice: false,
    isLoadingAllInvoice: false,
    showInvoices: true,
    currentEmissionInvoiceId: '',
  }),
  methods: {
    toogleShowInvoices () {
      this.showInvoices = !this.showInvoices;
    },
    getIdResumed (id) {
      if (!id) {
        return '-';
      }

      return '...' + (id.substr(id.length - 5));
    },
    async emitNfse (id) {
      const isConfirmed = await this.showAlert(!!id);
      if (isConfirmed) {
        let ids = [id];
        if (!id) {
          ids = this.pendingInvoices.map((item) => item._id);
          this.isLoadingAllInvoice = true;
        }

        this.isLoadingInvoice = true;
        this.currentEmissionInvoiceId = id;
        try {
          await InvoiceService.emitBills(ids);
          this.$emit('finish');
        } catch (e) {
          this.showAlertError();
        } finally {
          this.isLoadingInvoice = false;
          this.isLoadingAllInvoice = false;
          this.currentEmissionInvoiceId = '';
        }
      }
    },
    async showAlert (single) {
      const result = await this.$alert({
        title: single ? this.$t('rfReporter.emitNotesModal.messageSingleTitle') : this.$t('rfReporter.emitNotesModal.messageTitle', { kind: 'Depósitos' }),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('rfReporter.emitNotesModal.submitButton'),
        cancelButtonText: this.$t('rfReporter.emitNotesModal.closeButton'),
        confirmButtonColor: '#ffc107',
        reverseButtons: true,
      });

      return result.value;
    },
    async showAlertError () {
      this.$notify({
        title: this.$t('rfReporter.emitNotesModal.messageErrorTitle'),
        type: 'error',
        text: this.$t('rfReporter.emitNotesModal.messageErrorText'),
      });
    },
  },
  computed: {
    ...mapGetters(['isEnabled2fa']),
    pendingInvoices () {
      return (this.invoices.filter((invoice) => {
        return (!invoice.bill) && Number(invoice.fee) > 0;
      }));
    },
    totalPendingInvoices () {
      return this.pendingInvoices.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 0;
  padding: 0 35px;
}

.table {
  min-width: 100%;
}

.footer-emit {
  .row {
    width: 100%;
    padding: 0;
    .col-8 {
      text-align: right;
    }
  }
}
</style>
