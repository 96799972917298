<template>
<div class="row">
  <div class="col-xs-12 col-sm-6">
    <div class="form-group" :class="{'is-invalid': errors.has('name')}">
      <label for="name">{{ $t('invoices.modalReversal.fiat.opey.name') }} *</label>
      <input
        class="form-control"
        id="name"
        name="name"
        :key="'opeyName'"
        :placeholder="$t('invoices.modalReversal.fiat.opey.name')"
        :class="{'is-invalid': errors.has('name')}"
        :data-vv-as="$t('invoices.modalReversal.fiat.opey.name')"
        type="text"
        v-model="opey.name"
        v-validate="{ required: true }"
      >
      <div class="invalid-feedback">{{ errors.first('name') }}</div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-6">
    <div class="form-group" :class="{'is-invalid': errors.has('document')}">
      <label for="document">{{ $t('invoices.modalReversal.fiat.opey.document') }} *</label>
      <input
        class="form-control"
        id="document"
        name="document"
        :key="'opeyDocument'"
        :placeholder="$t('invoices.modalReversal.fiat.opey.document')"
        :class="{'is-invalid': errors.has('document')}"
        :data-vv-as="$t('invoices.modalReversal.fiat.opey.document')"
        type="text"
        v-model="opey.document"
        v-validate="{ required: true }"
      >
      <div class="invalid-feedback">{{ errors.first('document') }}</div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-6">
    <div class="form-group" :class="{'is-invalid': errors.has('opeyAmount')}">
      <label for="opeyAmount">{{ $t('invoices.modalReversal.fiat.amount') }} *</label>
      <ct-input-numeric
        :thousand-separator="$t(`currencies.BRL.thousands`)"
        :decimal-separator="$t(`currencies.BRL.decimal`)"
        :precision="2"
        autocomplete="off"
        class="form-control"
        :class="{'is-invalid': errors.has('opeyAmount')}"
        v-validate="{ required: true, max_value: maxValueForAmount }"
        id="opeyAmount"
        name="opeyAmount"
        :key="'opeyAmount'"
        :data-vv-as="$t('invoices.modalReversal.fiat.amount')"
        :placeholder="$t('invoices.modalReversal.fiat.amountPlaceholder')"
        v-model="opey.amount"
      ></ct-input-numeric>
      <div class="invalid-feedback">{{ errors.first('opeyAmount') }}</div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-6">
    <div class="form-group" :class="{'is-invalid': errors.has('opeyFee')}">
      <label for="opeyFee">{{ $t('invoices.modalReversal.fiat.fee') }} *</label>
      <ct-input-numeric
        :thousand-separator="$t(`currencies.BRL.thousands`)"
        :decimal-separator="$t(`currencies.BRL.decimal`)"
        :precision="2"
        autocomplete="off"
        class="form-control"
        :class="{'is-invalid': errors.has('opeyFee')}"
        v-validate="{ required: true, max_value: maxValueForFee }"
        id="opeyFee"
        name="opeyFee"
        :key="'opeyFee'"
        :data-vv-as="$t('invoices.modalReversal.fiat.fee')"
        :placeholder="$t('invoices.modalReversal.fiat.amountPlaceholder')"
        v-model="opey.fee"
        @change="changeModel"
      ></ct-input-numeric>
      <div class="invalid-feedback">{{ errors.first('opeyFee') }}</div>
    </div>
  </div>
</div>
</template>

<script>
import { mask } from 'vue-the-mask';
import CtInputNumeric from '../../Shared/InputNumeric';

export default {
  name: 'ReversalFiatOpey',
  props: {
    value: {
      type: Object,
      required: true,
    },
    sumReversalValue: {
      type: Number,
    },
  },
  components: {
    CtInputNumeric,
  },
  directives: {
    mask,
  },
  data: () => ({
    opey: {
      name: null,
      document: null,
      amount: 0,
      fee: 0,
    },
  }),
  watch: {
    value () {
      this.opey = { ...this.opey, ...this.value || {} };
    },
  },
  mounted () {
    this.setVal();
  },
  computed: {
    maxValueForFee () {
      return Number((this.sumReversalValue - this.opey.amount).toFixed(2));
    },
    maxValueForAmount () {
      if (this.opey.fee) {
        return Number((this.sumReversalValue - this.opey.fee).toFixed(2));
      }

      return Number((this.sumReversalValue).toFixed(2));
    },
  },
  methods: {
    setVal () {
      this.opey.validator = this.$validator;
      this.changeModel();
    },
    changeModel () {
      this.$emit('input', this.opey);
    },
  },
};
</script>
