<template>
  <input
    :placeholder="placeholder"
    @blur="onBlurHandler"
    @focus="onFocusHandler"
    @input="onInputHandler"
    @change="onChangeHandler"
    type="text"
    v-if="!readOnly"
    v-model="amount"
  >
  <span
    ref="readOnly"
    v-else
  >{{ amount }}</span>
</template>

<script>
import { round, formatMoney, unformat } from '@/helpers/decimal';

export default {
  name: 'VueNumeric',
  props: {
    /**
     * Currency symbol.
     */
    currency: {
      type: String,
      default: '',
      required: false,
    },

    /**
     * Maximum value allowed.
     */
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER || 9007199254740991,
      required: false,
    },

    /**
     * Minimum value allowed.
     */
    min: {
      type: Number,
      default: Number.MIN_SAFE_INTEGER || -9007199254740991,
      required: false,
    },

    /**
     * Enable/Disable minus value.
     */
    minus: {
      type: Boolean,
      default: false,
      required: false,
    },

    /**
     * Input placeholder.
     */
    placeholder: {
      type: String,
      default: '',
      required: false,
    },

    /**
     * Value when the input is empty
     */
    emptyValue: {
      type: [Number, String],
      default: '',
      required: false,
    },

    /**
     * Number of decimals.
     * Decimals symbol are the opposite of separator symbol.
     */
    precision: {
      type: Number,
      default: 0,
      required: false,
    },

    /**
     * Thousand separator type.
     * Separator props accept either . or , (default).
     */
    separator: {
      type: String,
      default: ',',
      required: false,
    },

    /**
     * Forced thousand separator.
     * Accepts any string.
     */
    thousandSeparator: {
      default: undefined,
      required: false,
      type: String,
    },

    /**
     * Forced decimal separator.
     * Accepts any string.
     */
    decimalSeparator: {
      default: undefined,
      required: false,
      type: String,
    },

    /**
     * The output type used for v-model.
     * It can either be String or Number (default).
     */
    outputType: {
      required: false,
      type: String,
      default: 'Number',
    },

    /**
     * v-model value.
     */
    value: {
      type: [Number, String],
      default: 0,
      required: true,
    },

    /**
     * Hide input and show value in text only.
     */
    readOnly: {
      type: Boolean,
      default: false,
      required: false,
    },

    /**
     * Class for the span tag when readOnly props is true.
     */
    readOnlyClass: {
      type: String,
      default: '',
      required: false,
    },

    /**
     * Position of currency symbol
     * Symbol position props accept either 'suffix' or 'prefix' (default).
     */
    currencySymbolPosition: {
      type: String,
      default: 'prefix',
      required: false,
    },
  },

  data: () => ({
    amount: '',
    focus: false,
  }),

  computed: {
    /**
     * Number type of formatted value.
     * @return {Number}
     */
    amountNumber () {
      let value = this.unformat(this.amount);
      if (this.precision > 6) {
        value = this.fixValuePrecision(value, this.amount ? this.amount.toString() : Number(0).toString());
      }
      return value;
    },

    /**
     * Number type of value props.
     * @return {Number}
     */
    valueNumber () {
      let value = this.unformat(this.value);
      let valueString = this.value.toString();
      const valueSplit = valueString.split('-');
      if (valueSplit.length === 2) {
        valueString = this.value.toFixed(valueSplit[1]);
        return valueString;
      }

      if (this.precision > 6) {
        value = this.fixValuePrecision(value, valueString);
      }

      return value;
    },

    /**
     * Define decimal separator based on separator props.
     * @return {String} '.' or ','
     */
    decimalSeparatorSymbol () {
      if (typeof this.decimalSeparator !== 'undefined') return this.decimalSeparator;
      if (this.separator === ',') return '.';
      return ',';
    },

    /**
     * Define thousand separator based on separator props.
     * @return {String} '.' or ','
     */
    thousandSeparatorSymbol () {
      if (typeof this.thousandSeparator !== 'undefined') return this.thousandSeparator;
      if (this.separator === '.') return '.';
      if (this.separator === 'space') return ' ';
      return ',';
    },

    /**
     * Define format position for currency symbol and value.
     * @return {String} format
     */
    symbolPosition () {
      if (!this.currency) return '%v';
      return this.currencySymbolPosition === 'suffix' ? '%v %s' : '%s %v';
    },
  },
  created () {
    this.amount = this.format(this.amount);
  },
  watch: {
    /**
     * Watch for value change from other input with same v-model.
     * @param {Number} newValue
     */
    valueNumber (newValue) {
      let value = round(newValue, this.precision);
      if (this.precision > 6) {
        value = this.fixValuePrecision(value, newValue.toString());
      }

      this.amount = value;
    },

    /**
     * When readOnly is true, replace the span tag class.
     * @param {Boolean} newValue
     * @param {Boolean} oldValue
     */
    readOnly (newValue, oldValue) {
      if (oldValue === false && newValue === true) {
        this.$nextTick(() => {
          this.$refs.readOnly.className = this.readOnlyClass;
        });
      }
    },

    /**
     * Immediately reflect separator changes
     */
    separator () {
      this.process(this.valueNumber);
      this.amount = this.format(this.valueNumber);
    },

    /**
     * Immediately reflect currency changes
     */
    currency () {
      this.process(this.valueNumber);
      this.amount = this.format(this.valueNumber);
    },

    /**
     * Immediately reflect precision changes
     */
    precision () {
      this.process(this.valueNumber);
      this.amount = this.format(this.valueNumber);
    },
  },

  mounted () {
    // Set default value props when placeholder undefined.
    if (!this.placeholder || this.valueNumber) {
      this.process(this.valueNumber);
      this.amount = this.format(this.valueNumber);
      // In case of delayed props value.
      setTimeout(() => {
        this.process(this.valueNumber);
        this.amount = this.format(this.valueNumber);
      }, 500);
    }

    // Set read-only span element's class
    if (this.readOnly) this.$refs.readOnly.className = this.readOnlyClass;
  },

  methods: {
    /**
     * Handle blur event.
     * @param {Object} e
     */
    onBlurHandler (e) {
      this.$emit('blur', e);
      this.focus = false;
      this.amount = this.format(this.amountNumber);
    },

    /**
     * Handle change event.
     * @param {Object} e
     */
    onChangeHandler (e) {
      this.$emit('change', e);
      this.process(this.amountNumber);
    },

    /**
     * Handle focus event.
     * @param {Object} e
     */
    onFocusHandler (e) {
      this.$emit('focus', e);
      this.focus = true;

      if (this.value.toString() === '') {
        this.amount = null;
      } else {
        let valueNumber = round(this.valueNumber, Number(this.precision));
        if (this.precision > 6) {
          valueNumber = this.fixValuePrecision(valueNumber, this.valueNumber.toString());
        }
        this.amount = valueNumber;
      }
    },

    /**
     * Handle input event.
     */
    onInputHandler () {
      this.process(this.amountNumber);
    },

    /**
     * Validate value before update the component.
     * @param {Number} value
     */
    process (value) {
      if (value >= this.max) this.update(this.max);
      if (value <= this.min) this.update(this.min);
      if (value > this.min && value < this.max) this.update(value);
      if (!this.minus && value < 0) this.min >= 0 ? this.update(this.min) : this.update(0);
    },

    /**
     * Update parent component model value.
     * @param {Number} value
     */
    update (value) {
      const fixedValue = round(value, this.precision);
      let output = this.outputType.toLowerCase() === 'string' ? fixedValue : Number(fixedValue);
      if (this.precision > 6 && this.outputType.toLowerCase() !== 'string') {
        output = this.fixValuePrecision(output, value.toString());
      }

      this.$emit('input', this.amount.toString() === '' ? '' : output);
    },

    fixValuePrecision (value, newValue) {
      let newValues = newValue.split('.');
      if (newValues.length < 2) {
        return value;
      }

      newValues = newValues[1];
      if (newValues.length > 6 && newValues.length <= this.precision) {
        value = value.toFixed(newValues.length);
      } else if (newValues.length > 6 && newValues.length > this.precision) {
        value = value.toFixed(this.precision);
      }

      return value;
    },

    /**
     * Format value using symbol and separator.
     * @param {Number} value
     * @return {String}
     */
    format (value) {
      const moneyFormat = formatMoney(Number(value), {
        symbol: this.currency,
        format: this.symbolPosition,
        precision: Number(this.precision),
        decimal: this.decimalSeparatorSymbol,
        thousand: this.thousandSeparatorSymbol,
      });
      return moneyFormat;
    },

    /**
     * Remove symbol and separator.
     * @param {Number} value
     * @return {Number}
     */
    unformat (value) {
      const toUnformat = typeof value === 'string' && value === '' ? this.emptyValue : value;
      let unformatedNumber = unformat(toUnformat, '.');
      if (isNaN(round(toUnformat, this.precision))) {
        unformatedNumber = unformat(toUnformat, this.decimalSeparatorSymbol);
      }
      return unformatedNumber;
    },
  },
};
</script>
