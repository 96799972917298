import { isDefinitiveStatus, validStatus as validInvoiceStatus } from '@/model/Invoice';

import CtEditItemModal from '@/components/Invoices/InvoiceDetails/EditItem/Modal.vue';
import CtModalTableEvents from '@/components/Invoices/ModalTableEvents.vue';
import CtModalView from '@/components/Shared/ModalView.vue';
import InvoiceService from '@/services/InvoiceService';
import { mapState } from 'vuex';
import { validStatus } from '@/model/InvoiceItem';

export default {
  props: {
    invoice: {
      type: Object,
      required: true,
    },
    availableBalance: {
      type: Number,
      required: true,
    },
    colors: {
      type: Object,
      required: true,
    },
    symbols: {
      type: Object,
      required: true,
    },
    statusTranslate: {
      type: Object,
      required: true,
    },
  },
  components: {
    CtModalView,
    CtModalTableEvents,
    CtEditItemModal,
  },
  data: () => ({
    receiptStatusItem: [
      validStatus.ERROR,
      validStatus.OPEN,
      validStatus.FINISHED,
    ],
    receiptStatusInvoice: [
      validInvoiceStatus.PAID,
      validInvoiceStatus.OVERPAID,
      validInvoiceStatus.UNDERPAID,
      validInvoiceStatus.FINISHED,
    ],
    editableItemStatus: [
      validStatus.ERROR,
      validStatus.OPEN,
    ],
    showModalEvents: false,
    showModalEdit: false,
    events: [],
    editingItemId: null,
  }),
  methods: {
    refreshInvoice () {
      this.$emit('refreshInvoice');
    },
    getValueBTC (amount) {
      if (!this.invoice.cryptoPrice || !amount) return 0;
      return (amount / this.invoice.cryptoPrice).toFixed(8);
    },
    copyToClipboard (value) {
      const el = document.createElement('textarea');
      el.value = value;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },
    canSubmitReceipt (item) {
      if (['FINISHED', 'PROCESSING', 'ERROR', 'UNDERPAID', 'OVERPAID', 'PAID', 'OPEN', 'PENDING'].includes(item.status)) {
        return true;
      }
      const paymentDifferenceValue = {
        UNDERPAID: this.currentSettings && this.currentSettings.maxPaymentUnderpaidDifference
          ? Number(this.currentSettings.maxPaymentUnderpaidDifference) : 0,
        OVERPAID: this.currentSettings && this.currentSettings.maxPaymentOverpaidDifference
          ? Number(this.currentSettings.maxPaymentOverpaidDifference) : 0,
        PAID: 0,
        FINISEHD: 0,
      }[this.invoice.status] || 0;

      const totalItemCrypto = Number((item.total / this.invoice.cryptoPrice).toFixed(8));
      const availableBalanceDifference = Number((this.availableBalance + paymentDifferenceValue).toFixed(8));

      return (
        totalItemCrypto <= availableBalanceDifference &&
        this.receiptStatusItem.includes(item.status) &&
        this.receiptStatusInvoice.includes(this.invoice.status)
      );
    },
    isDisabledForItem (item) {
      return !(item.status === 'ERROR' && !isDefinitiveStatus(this.invoice.status));
    },
    isDisabledForItemProcess (item) {
      return !(item.status === 'OPEN' && this.hasBalanceForItem(item));
    },
    isDisabledEventsForItem (item) {
      return item.events.length === 0;
    },
    canEditItem (item) {
      return this.editableItemStatus.includes(item.status) && !isDefinitiveStatus(this.invoice.status);
    },
    hasBalanceForItem (item) {
      if (this.availableBalanceFiat < item.total) {
        return false;
      }

      return true;
    },
    async reprocessItem (itemId) {
      const result = await this.confirmActions(
        this.$t('invoices.details.reprocess.title'),
        this.$t('invoices.details.reprocess.confirmText'),
        this.$t('invoices.details.reprocess.cancelText'),
      );

      if (result) {
        try {
          await InvoiceService.reprocessItem(this.invoice._id, itemId);
          this.refreshInvoice();
          this.notifySuccess(
            this.$t('invoices.details.reprocess.messageSuccessTitle'),
            this.$t('invoices.details.reprocess.messageSuccess'),
          );
        } catch (e) {
          this.$notify({
            title: this.$t('invoices.details.reprocess.messageErrorTitle'),
            type: 'error',
            text: e.response.data.message,
          });
        }
      }
    },
    async processItem (itemId) {
      const result = await this.confirmActions(
        this.$t('invoices.details.process.title'),
        this.$t('invoices.details.process.confirmText'),
        this.$t('invoices.details.process.cancelText'),
      );

      if (result) {
        try {
          await InvoiceService.processItem(this.invoice._id, itemId);
          this.notifySuccess(
            this.$t('invoices.details.process.messageSuccessTitle'),
            this.$t('invoices.details.process.messageSuccess'),
          );
        } catch (e) {
          this.$notify({
            title: this.$t('invoices.details.reprocess.messageErrorTitle'),
            type: 'error',
            text: e.response.data.message,
          });
        } finally {
          this.refreshInvoice();
        }
      }
    },
    notifySuccess (title, text) {
      this.$notify({
        type: 'success',
        title,
        text,
      });
    },
    async confirmActions (title, confirm, cancel) {
      const result = await this.$alert({
        title: title,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: confirm,
        cancelButtonText: cancel,
        confirmButtonColor: '#ffc107',
        reverseButtons: true,
      });
      return result.value;
    },
    toggleUploadReceipt (item) {
      this.$emit('toggleUploadReceipt', item);
    },
    toggleFinishGiftCard (id) {
      this.$emit('toggleFinishGiftCard', { id });
    },
    toggleModalEvents (item) {
      this.events = item.events ? item.events : [];
      this.showModalEvents = !this.showModalEvents;
      if (!this.showModalEvents) {
        this.events = [];
      }
    },
    toggleModalEdit () {
      this.showModalEdit = !this.showModalEdit;
    },
  },
  computed: {
    ...mapState({
      currentSettings: (state) => state.settings.currentSettings,
    }),
    availableBalanceFiat () {
      return Number((this.availableBalance * this.invoice.cryptoPrice).toFixed(2));
    },
  },
};
