import UserService from './../../../services/UserService';

export default {
  async getUsersList ({ commit, state }, isPrint = false) {
    try {
      const list = await UserService.getUsersList({
        filters: state.listFilters,
        search: state.listSearch,
        page: isPrint ? 0 : (state.listPaginate.currentPage || 1),
        limit: isPrint ? 0 : (state.listPaginate.perPage || 10),
        orderBy: state.listSort,
      });
      if (isPrint) {
        commit('SET_LIST_USERS_PRINT', list);
      } else {
        commit('SET_LIST_USERS', list);
        if (list.meta) {
          commit('SET_USER_LIST_TOTAL_PAGES', list.meta.pagination.totalPages);
        }
      }
    } catch (error) {
      console.error(error);
    }
  },

  async getUserForSelect ({ commit }, search = '') {
    try {
      if (!search) {
        return commit('SET_LIST_USERS_FOR_SELECT', []);
      }

      const searchObject = typeof search === 'string' ? { field: 'name', value: search } : search;
      const list = await UserService.getUserForSelect({
        search: searchObject,
      });
      commit('SET_LIST_USERS_FOR_SELECT', list);
    } catch (e) {
      console.error(e);
    }
  },

  async getPlayersForSelect ({ commit }, itens = []) {
    const list = await UserService.getPlayersForSelect();
    commit('SET_LIST_PLAYERS_FOR_SELECT', [...itens, ...list]);
  },

  addUsersListFilter ({ commit, dispatch }, value) {
    commit('SET_USER_LIST_PAGE', 1);
    commit('ADD_USER_LIST_FILTER', value);
    dispatch('getUsersList');
  },

  removeUsersListFilter ({
    commit,
    dispatch,
  }, value) {
    commit('SET_USER_LIST_PAGE', 1);
    commit('REMOVE_USER_LIST_FILTER', value);
    dispatch('getUsersList');
  },

  setUsersListPage ({
    commit,
    dispatch,
  }, value) {
    commit('SET_USER_LIST_PAGE', value);
    dispatch('getUsersList');
  },

  setUsersListTotalPages ({
    commit,
    dispatch,
  }, value) {
    commit('SET_USER_LIST_PAGE', 1);
    commit('SET_USER_LIST_TOTAL_PAGES', value);
    dispatch('getUsersList');
  },

  setUsersListSort ({
    commit,
    dispatch,
  }, value) {
    commit('SET_USER_LIST_SORT', value);
    dispatch('getUsersList');
  },

  setListSearch ({ commit, dispatch }, value) {
    commit('SET_USER_LIST_SEARCH', value);
    dispatch('getUsersList');
  },

  async getListUserStatistics ({ commit }) {
    const list = await UserService.listStatistics();
    if (list) {
      commit('SET_LIST_USERS_STATISTICS', list.data);
    }
  },
};
