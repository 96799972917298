<template>
  <section class="billet-items-section" v-if="billets.length !== 0">
    <div class="col-md-12">
      <h3>{{ $t('invoices.details.billet.title') }}</h3>
    </div>
    <div class="table-responsive body-table">
      <table class="table table-billets">
        <thead>
          <tr>
            <th width="10%">{{ $t('invoices.details.billet.status') }}</th>
            <th width="10%">{{ $t('invoices.details.billet.id') }}</th>
            <th width="28%">{{ $t('invoices.details.billet.digitableLine') }}</th>
            <th width="5%">{{ $t('invoices.details.billet.dueDate') }}</th>

            <th width="14%">{{ $t('invoices.details.billet.value') }}</th>
            <th width="12%">{{ $t('invoices.details.billet.discount') }}</th>
            <th width="12%">{{ $t('invoices.details.billet.increase') }}</th>
            <th width="18%">{{ $t('invoices.details.billet.amount') }}</th>
            <th width="18%">{{ $t('invoices.details.billet.fee') }}</th>
            <!-- <th width="14%">{{ $t('invoices.details.billet.totalAmount') }}</th> -->
            <th width="8%" class="text-right">{{ $t('invoices.details.billet.ProofOfPayment') }}</th>
            <th width="12%" class="text-right action-col" v-if="isInternal"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in billets" :key="item._id + index">
            <td>
              <ct-status-icon
                :iconSymbol="symbols[item.status]"
                :color="colors[item.status]"
                :description="statusTranslate[item.status]"
              />
            </td>
            <td>
              <ct-cell-id :text="item._id"></ct-cell-id>
            </td>
            <td style="word-break: break-all;">
              {{ item.billet.digitableLine || '-' }}
              <i class="far fa-copy"
                @click="copyToClipboard(item.billet.digitableLine)"
                v-b-tooltip.hover
                :title="$t('invoices.details.copyTitle')">
              </i>
            </td>
            <td>{{ item.billet.dueDate | date }}</td>

            <td>R$ {{ (item.billet.value || 0) | currency }}</td>
            <td>R$ {{ (item.billet.discount || 0) | currency }}</td>
            <td>R$ {{ (item.billet.increase || 0) | currency }}</td>
            <td>R$ {{ (item.amount || 0) | currency }}</td>
            <td>R$ {{ (item.fee || 0) | currency }}</td>
            <td class="text-center">
              <span v-if="item.receipt">
                <a :href="item.receipt" target="_blank" >
                  <i class="fa fa-lg fa-eye" />
                </a>
              </span>

              <span v-else-if="item.receiptNumber">
                  {{ item.receiptNumber }}
              </span>

              <span v-else>
                <i class="far fa-lg fa-eye-slash disabled" />
              </span>
            </td>
            <td class="text-center" v-if="isInternal">
              <span v-if="isInternal && canSubmitReceipt(item)" style="margin: 0 10px">
                <a @click="toggleUploadReceipt({ id: item._id, fileRequired: true, status: item.status })">
                  <i class="fa fa-lg fa-upload" />
                </a>
              </span>
              <span>
                <a @click.prevent="openModalEdit(item)" >
                  <i class="fa fa-lg fa-cog" />
                </a>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <ct-modal-view
      v-if="editingItem"
      :title="$t('invoices.details.editItem.title')"
      :show="showModalEdit"
      size="small"
      @close="toggleModalEdit"
    >
      <ct-edit-item-modal
        slot="body"
        :invoiceId="invoice._id"
        :item="editingItem"
        @close="toggleModalEdit"
        @save="refreshInvoice"
        :notEditing="!canEditItem(editingItem)"
        :isItemReprocess="isDisabledForItem(editingItem)"
        :isItemProcess="isDisabledForItemProcess(editingItem)"
        @reprocessItem="reprocessCurrentItem"
        @processItem="processCurrentItem"
      />
    </ct-modal-view>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import CtCellId from '@/components/Shared/DataGrid/CellId.vue';
import CtStatusIcon from '@/components/Dashboard/StatusIcon';
import currency from '@/filters/currency';
import date from '@/filters/date';
import ItemsActionsMixin from '@/mixins/ItemsActions';

export default {
  name: 'BilletItems',
  filters: {
    currency,
    date,
  },
  components: {
    CtCellId,
    CtStatusIcon,
  },
  props: {
    billets: {
      type: [Array, Object],
      required: false,
      default: () => ([]),
    },
  },
  mixins: [
    ItemsActionsMixin,
  ],
  computed: {
    ...mapGetters([
      'isInternal',
    ]),
    editingItem () {
      return this.billets.find((billet) => billet._id === this.editingItemId);
    },
  },
  methods: {
    copyToClipboard (value) {
      const el = document.createElement('textarea');
      el.value = value.replace(/[^\d]/g, '');
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },
    openModalEdit (item) {
      this.editingItemId = item._id;
      this.toggleModalEdit();
    },
    async reprocessCurrentItem () {
      await this.reprocessItem(this.editingItem._id);
    },
    async processCurrentItem () {
      await this.processItem(this.editingItem._id);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../themes/color-admin/_ct_variable.scss";
.billet-items-section {
  i.fa-lg {
    cursor: pointer;
    &.disabled {
      color: $ct_grey_1;
    }
  }
  h3 {
    font-weight: 400;
    margin-left: -10px;
  }
}
</style>
