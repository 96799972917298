<template>
  <div class="row ct-bank-data-content">
    <div class="col-12 col-sm-12 col-md-12 ct-page-header">
      <h1 class="page-header">
        {{ $t('invoices.modalReferralData.title') }}
        <small>
          {{ invoiceId || '' }}
          <i class="far fa-copy"
             @click="copyToClipboard(invoiceId || '')"
             :title="$t('invoices.details.copyTitle')"
             v-b-tooltip.hover
          >
          </i>
        </small>
      </h1>
    </div>
    <div class="col-12 col-sm-12 col-md-12">
      <table class="table table-accounts">
        <thead>
          <tr>
            <th width="50%" class="text-left">{{ $t('invoices.modalReferralData.description') }}</th>
            <th width="50%" class="text-right">{{ $t('invoices.modalReferralData.value') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-left">{{ $t('invoices.modalReferralData.userId') }}</td>
            <td class="text-right">
              <i class="far fa-copy"
                @click="copyToClipboard(invoiceReferral.user._id || '')"
                :title="$t('invoices.details.copyTitle')"
                v-b-tooltip.hover
              >
              </i>
              {{ invoiceReferral.user._id || '-' }}
            </td>
          </tr>
          <tr>
            <td class="text-left">{{ $t('invoices.modalReferralData.userName') }}</td>
            <td class="text-right">{{ invoiceReferral.user.name || '-' }}</td>
          </tr>
          <tr>
            <td class="text-left">{{ $t('invoices.modalReferralData.userEmail') }}</td>
             <td class="text-right">{{ invoiceReferral.user.email || '-' }}</td>
          </tr>
          <tr>
            <td class="text-left">{{ $t('invoices.modalReferralData.document') }}</td>
             <td class="text-right">{{ invoiceReferral.user.document || '-' }}</td>
          </tr>
          <tr>
            <td class="text-left">{{ $t('invoices.modalReferralData.status') }}</td>
             <td class="text-right">{{ $t(`invoices.modalReferralData.statusText.${invoiceReferral.status}`) }}</td>
          </tr>
          <tr>
            <td class="text-left">{{ $t('invoices.modalReferralData.kind') }}</td>
             <td class="text-right">{{ invoiceReferral.kind || '-' }}</td>
          </tr>
          <tr>
            <td class="text-left">{{ $t('invoices.modalReferralData.value') }}</td>
             <td class="text-right">{{ invoiceReferral.value | currency }}</td>
          </tr>
          <tr>
            <td class="text-left">{{ $t('invoices.modalReferralData.fee') }}</td>
             <td class="text-right">{{ invoiceReferral.fee | currency }}</td>
          </tr>
          <tr v-if="referralWidget && invoiceReferral.invoiceId">
            <td class="text-left">{{ $t('invoices.modalReferralData.invoice') }}</td>
             <td class="text-right" >
               <button @click="goToInvoice" class="btn btn-info btn-invoice">
                 <i class="fas fa-eye" ></i>
                {{ $t('invoices.modalReferralData.openInvoice') }}
               </button>
             </td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="form-group pull-left form-inline" v-if="showReexecuteButton">
            <button
              class="btn btn-info btn-lg"
              type="submit"
              @click="reexecuteItem"
            >
              {{$t('invoices.modalReferralData.reexecute')}}
              <i class="fas fa-spinner fa-pulse" v-if="isLoading"></i>
            </button>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group pull-right form-inline">
            <button
              class="btn btn-default btn-lg"
              type="submit"
              @click="close"
            >{{$t('invoices.modalBankData.close')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import currency from '@/filters/currency';
import { mapGetters } from 'vuex';

export default {
  filters: {
    currency,
  },
  props: {
    invoiceId: {
      type: String,
    },
    referralWidget: {
      type: Boolean,
      default: false,
    },
    invoiceReferral: {
      type: Object,
    },
    reexecute: {
      type: Function,
      required: false,
      default: () => () => {},
    },
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapGetters(['isInternal']),
    showReexecuteButton () {
      return this.isInternal && this.invoiceReferral.status === 'ERROR';
    },
  },
  methods: {
    close () {
      this.$emit('close');
    },
    copyToClipboard (value) {
      this.$emit('copy', value);
    },
    goToInvoice () {
      this.$router.push({ path: `/invoices/${this.invoiceReferral.invoiceId}` });
    },
    async reexecuteItem () {
      if (!this.isLoading && this.showReexecuteButton) {
        try {
          this.isLoading = true;
          await this.reexecute();
        } catch (e) {
          console.error(e);
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
  created () {
  },
};
</script>
<style lang="scss">
@import "../../themes/color-admin/_variable.scss";

.ct-bank-data-content {
  padding: 5px;
}
.ct-page-header {
  margin-bottom: 15px;
  border-bottom: 1px solid #e1e7ec;
}
.fa-copy {
  cursor: pointer;
  font-size: 1.2em;
  color: #707478;
}
.tooltip.show {
  z-index: 9999999 !important;
}
.btn.btn-invoice {
  margin: 0;
}
</style>
