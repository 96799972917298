export const getState = () => ({
  list: [],
  listPrint: [],
  listFilters: [],
  listSearch: {},
  showFilters: true,
  listSort: { createdAt: 'desc' },
  listStatistics: {
    status: {},
  },
  listPaginate: {
    total: 0,
    perPage: 10,
    currentPage: 1,
    totalPages: 1,
    hasNextPage: false,
    hasPrevPage: false,
  },
});

export default Object.assign(getState(), { getState: getState });
