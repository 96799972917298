<template>
  <div>
    <div class="data-grid-component">
      <ct-filter
        :inputFilterSearchText="filters.inputSearchText"
        :items="filters.items || []"
        :filterTextOptions="filterOptions"
        :filterStatusOptions="table.listStatus || []"
        :enableFilterText="filters.enableFilterText || false"
        :enablePrint="filters.enablePrint || false"
        :filterTextCurrent="filters.filterTextCurrent || {}"
        :filterStatusCurrent="filters.filterStatusCurrent || []"
        @search="searchChange"
        @filter="changeFilter"
        @print="printClick"
      ></ct-filter>
      <div class="ct-row">
        <slot name="filters"></slot>
      </div>
      <div class="ct-datagrid">
        <div class="row">
          <div class="col-sm-12">
            <div class="table-responsive">
              <table
                class="table table-striped table-bordered dataTable no-footer dtr-inline"
                role="grid"
              >
                <thead>
                  <tr role="row">
                    <th
                      v-for="header in table.headers"
                      :key="header.name"
                      :style="{ width: header.width || 'auto', padding: header.padding }"
                      class="sorter"
                      :class="[sortClass(header.order)]"
                      @click="sortColumnClick(header)"
                    >
                      <span
                        v-if="header.isSpecialSearch || false"
                        class="search-field"
                      >
                        <i class="fas fa-bolt"></i>
                      </span>
                      {{ header.text || "" }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="table.lines.length === 0">
                    <td :colspan="table.headers.length">
                      {{ emptyTableMessage }}
                    </td>
                  </tr>
                  <tr
                    class="datagrid-row"
                    role="row"
                    v-for="row in table.lines"
                    :key="typeof row[0] === 'object' ? row[0].text : row[0]"
                    @click.ctrl="selectRow(typeof row[0] === 'object' ? row[0].text : row[0])"
                    @dblclick="doubleClickRow(row)"
                    :class="{ selected: rowsSelected.indexOf(typeof row[0] === 'object' ? row[0].text : row[0]) !== -1 }"
                  >
                    <td
                      v-for="(td, index) in row"
                      :key="String(row[0]) + String(index)"
                      :class="{
                        'td-text-left': !td || !td.textAlign,
                        'marker' : td.classAux,
                        ['td-text-' + td.textAlign || 'left']: td && td.textAlign
                      }"
                    >
                      <ct-cell-marker
                        v-if="td && td.type && td.type === 'marker'"
                        :color="td.color"
                        :hidden="td.hidden"
                      />
                      <ct-cell-id
                        v-if="td && td.type && td.type === 'id'"
                        :text="td.text"
                      ></ct-cell-id>
                      <ct-cell-resumed-link
                        v-if="td && td.type && td.type === 'resumed-link'"
                        :text="td.text"
                        :href="td.href"
                        :size="td.size || 5"
                      ></ct-cell-resumed-link>
                      <ct-cell-label
                        v-else-if="td && td.type && td.type === 'label'"
                        :color="td.color"
                        :text="td.text"
                      ></ct-cell-label>
                      <ct-cell-button
                        v-else-if="td && td.type && td.type === 'button'"
                        :name="td.name"
                        :color="td.color"
                        :text="td.text"
                        :icon="td.icon"
                        :value="td.value"
                        @button="buttonClick"
                      ></ct-cell-button>
                      <ct-cell-button-text
                        v-else-if="td && td.type && td.type === 'button-text'"
                        :name="td.name"
                        :text="td.text"
                        :icon="td.icon"
                        :hover="td.hover"
                        :value="td.value"
                        :color="td.color"
                        :disabled="td.disabled"
                        @button="buttonClick"
                      ></ct-cell-button-text>
                      <ct-cell-dropdown-actions
                        v-else-if="td && td.type && td.type === 'dropdown-actions'"
                        :name="td.name"
                        :text="td.text"
                        :icon="td.icon"
                        :hover="td.hover"
                        :value="td.value"
                        :color="td.color"
                        :disabled="td.disabled"
                        @button="buttonClick"
                      ></ct-cell-dropdown-actions>
                    <span
                        v-else-if="td && td.type && td.type === 'status-icon'"
                        class="td-span-center"
                      >
                        <ct-status-icon
                          :iconSymbol="td.icon"
                          :color="td.color"
                          :description="td.description"
                          :help="td.help"
                        ></ct-status-icon>
                        <span class="span-status-icon">{{ td.text }}</span>
                      </span>
                      <span v-else-if="td && !td.type">{{ td.text || td }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ct-pagination
        v-if="pagination"
        :totalPages="pagination.totalPages"
        :currentPage="pagination.currentPage"
        @page="changePage"
      ></ct-pagination>
      <ct-action-button
        v-for="button in actionButtons"
        :key="button.name"
        :name="button.name || ''"
        :title="button.title || ''"
        :icon="button.icon || ''"
        :color="button.color || ''"
        @click="actionClick"
      ></ct-action-button>
    </div>
    <ct-modal-view
      :title="$t('dataGrid.modalPrint.title')"
      :show="showModal"
      @close="toggleModal"
    >
      <ct-modal-print
        slot="body"
        @cancel="toggleModal"
        :filtersPrint="filtersPrint"
        :printListing="printListing"
        :emptyTableMessage="emptyTableMessage"
        :modalSubtitle="modalSubtitle"
      ></ct-modal-print>
    </ct-modal-view>
  </div>
</template>

<script>
import CtActionButton from '@/components/Shared/DataGrid/ActionButton.vue';
import CtFilter from '@/components/Shared/DataGrid/Filter.vue';
import CtPagination from '@/components/Shared/DataGrid/Pagination.vue';
import CtCellMarker from './CellMarker';
import CtCellId from '@/components/Shared/DataGrid/CellId.vue';
import CtCellResumedLink from '@/components/Shared/DataGrid/CellResumedLink.vue';
import CtCellLabel from '@/components/Shared/DataGrid/CellLabel.vue';
import CtCellButton from '@/components/Shared/DataGrid/CellButton.vue';
import CtCellButtonText from '@/components/Shared/DataGrid/CellButtonText.vue';
import CtCellDropdownActions from '@/components/Shared/DataGrid/CellDropdownActions.vue';
import CtStatusIcon from '@/components/Dashboard/StatusIcon.vue';
import CtModalView from '@/components/Shared/ModalView.vue';
import CtModalPrint from './ModalPrint';

export default {
  name: 'DataGrid',
  components: {
    CtActionButton,
    CtFilter,
    CtPagination,
    CtCellMarker,
    CtCellId,
    CtCellResumedLink,
    CtCellLabel,
    CtCellButton,
    CtCellButtonText,
    CtCellDropdownActions,
    CtStatusIcon,
    CtModalView,
    CtModalPrint,
  },
  data () {
    return {
      rowsSelected: [],
      showModal: this.showModalPrint,
    };
  },
  computed: {
    filterOptions () {
      const headerFilters = this.getFiltersByHeaders();
      const filtersParam = this.filters.additional || [];
      return [...headerFilters, ...filtersParam];
    },
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    table: {
      type: Object,
      required: true,
    },
    pagination: {
      type: Object,
      required: false,
    },
    actionButtons: {
      type: Array,
      required: true,
    },
    emptyTableMessage: {
      type: String,
      default: 'Nothing to show',
    },
    showModalPrint: {
      type: Boolean,
      default: false,
    },
    filtersPrint: {
      type: Array,
      required: false,
    },
    printListing: {
      type: Object,
      required: false,
    },
    modalSubtitle: {
      type: String,
      required: false,
    },
  },
  methods: {
    getFiltersByHeaders () {
      return this.table.headers.filter(header => {
        return header.isFilter;
      });
    },
    searchChange (search) {
      this.$emit('search', search);
    },
    changeFilter (filter) {
      this.$emit('filter', filter);
    },
    printClick (print) {
      this.$emit('print', print);
      this.toggleModal();
    },
    sortClass (order) {
      if (!order) {
        return '';
      }
      if (order === 'asc') {
        return 'sorting_asc';
      }
      if (order === 'desc') {
        return 'sorting_desc';
      }
      return 'sorting';
    },
    sortColumnClick (header) {
      if (!header.order) return false;
      this.$emit('sort', header);
    },
    changePage (page) {
      this.$emit('page', page);
    },
    actionClick (action) {
      this.$emit('action', action);
    },
    selectRow (row) {
      const index = this.rowsSelected.indexOf(row);
      if (index !== -1) {
        this.rowsSelected.splice(index, 1);
      } else {
        this.rowsSelected.push(row);
      }
      this.$emit('selected', this.rowsSelected);
    },
    buttonClick (value) {
      this.$emit('button', value);
    },
    doubleClickRow (row) {
      const idCell = row.find(cell => cell.type === 'id');
      if (idCell && idCell.text) {
        this.$emit('doubleClick', idCell.text);
      }
    },
    toggleModal () {
      this.showModal = !this.showModal;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/themes/color-admin/_ct_variable.scss";

.panel-body {
  background-color: #ffffff;

  thead {
    /* text-align: center; */
    .search-field {
      position: relative;
      top: -1px;
      color: $ct_color;
      font-size: 0.8em;
      opacity: 0.5;
    }
    .sorter {
      position: relative;
      cursor: pointer;
      &:after {
        position: absolute;
        bottom: 8px;
        right: 8px;
        display: block;
      }
    }
    .sorting {
      &:after {
        opacity: 0.2;
      }
    }
  }
  tbody {
    .td-text-left {
      text-align: left;

      &.marker {
        padding: 0px;
        vertical-align: inherit
      }
    }
    .td-span-center {
      text-align: center;
    }
    .span-status-icon {
      text-align: left;
      display: block;
      margin-top: -16px;
      margin-left: 25px;
      white-space: nowrap;
    }
  }
  .datagrid-row {
    text-align: center;
    cursor: pointer;
  }
}
</style>
