<template>
  <div class="print-giftcard-component" id="print-giftcard-component">
    <div class="logo" >
      <img src="/assets/img/icons/apple-touch-icon-60x60.png">
      <h1>{{ $t('invoices.details.giftCard.title') }}</h1>
    </div>
    <table class="table" style="margin-top: 30px">
      <thead>
        <tr>
          <th>{{ $t('invoices.details.giftCard.id') }}</th>
          <th>{{ $t('invoices.details.giftCard.operator') }}</th>
          <th>{{ $t('invoices.details.giftCard.card') }}</th>
          <th>{{ $t('invoices.details.giftCard.amount') }}</th>
          <th>{{ $t('invoices.details.giftCard.pin') }}</th>
          <th >{{ $t('invoices.details.giftCard.lote') }}</th>
          <th>{{ $t('invoices.details.giftCard.serie') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in giftcards" :key="'print-'+item._id + index">
          <td>{{ item._id }}</td>
          <td>{{ item.giftCard.operator || '-' }}</td>
          <td>{{ item.giftCard.cardName || item.giftCard.card || '-' }}</td>
          <td>R$ {{ (item.amount || 0) | currency }}</td>
          <td>{{ item.giftCard.pin || '-' }}</td>
          <td>{{ item.giftCard.lote || '-' }}</td>
          <td>{{ item.giftCard.serie || '-' }}</td>
        </tr>
      </tbody>
    </table>
    <hr style="margin-top: 30px" class="hidden-print"/>
    <div style="text-align: center;" class="hidden-print">
      <a @click="print" class="btn btn-md btn-default">
        <i class="fa fa-print fa-lg" style="margin-right: 5px" />{{ $t('invoices.details.giftCard.print') }}
      </a>
    </div>
  </div>
</template>

<script>
import currency from '@/filters/currency';

export default {
  props: {
    giftcards: {
      type: Array,
      required: true,
    },
  },
  filters: {
    currency,
  },
  data () {
    return {};
  },
  methods: {
    print () {
      const DocumentContainer = document.getElementById('print-giftcard-component');

      const WindowObject = window.open('', 'PrintWindow',
        'width=800,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=no');
      WindowObject.document.writeln('<!DOCTYPE html>');
      WindowObject.document.writeln('<html><head><title></title>');
      WindowObject.document.writeln('<link rel="stylesheet" href="https://getbootstrap.com/docs/3.3/dist/css/bootstrap.min.css">');
      WindowObject.document.writeln('<style></style>');
      WindowObject.document.writeln('</head><body>');
      WindowObject.document.writeln(DocumentContainer.innerHTML);
      WindowObject.document.writeln('</body></html>');
      WindowObject.document.close();
      setTimeout(function () {
        WindowObject.focus();
        WindowObject.print();
        WindowObject.close();
      }, 200);
    },
  },
  computed: {
  },

};
</script>

<style lang="scss" scoped>

.logo {
  display: none;
  @media print {
    display: table;
  }

  img {
    vertical-align: middle;
    display: table-cell;
  }
  h1 {
    padding-left: 20px;
    vertical-align: middle;
    display: table-cell;
  }
}
@media print {
  .hidden-print {
    display: none;
  }
}
</style>
