<template>
  <div class="content component-users">
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">
        <a href="javascript:;">Home</a>
      </li>
      <li class="breadcrumb-item active">{{ $t('transactions.subtitle') }}</li>
    </ol>
    <h1 class="page-header">
      {{ $t('app.name') }}
      <small>{{ $t('transactions.subtitle') }}</small>
    </h1>

    <b-row>
      <b-col cols="12">
        <ct-transactions-widget/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CtTransactionsWidget from '@/components/Transactions/TransactionWidget.vue';

export default {
  name: 'Transactions',
  components: {
    CtTransactionsWidget,
  },
};
</script>

<style scoped lang="scss">

</style>
