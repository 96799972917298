import InvoiceItem from './InvoiceItem';
import Transaction from './Transaction';
import Event from './Event';

export const validStatus = {
  NEW: 'NEW',
  EXPIRED: 'EXPIRED',
  PENDING: 'PENDING',
  PAID: 'PAID',
  OVERPAID: 'OVERPAID',
  UNDERPAID: 'UNDERPAID',
  PROCESSING: 'PROCESSING',
  FINISHED: 'FINISHED',
  REVERSAL: 'REVERSAL',
  CANCELLED: 'CANCELLED',
};

export const definitiveStatus = [
  validStatus.FINISHED,
  validStatus.REVERSAL,
  validStatus.CANCELLED,
];

export const isDefinitiveStatus = (status) => {
  return definitiveStatus.indexOf(status) >= 0;
};

const startItems = (items) => {
  return items.map((item) => {
    return new InvoiceItem(item);
  });
};

const startTransactions = (items) => {
  return items.map((item) => {
    return new Transaction(item);
  });
};

const startEvents = (items) => {
  return items.map((item) => {
    return new Event(item);
  });
};

export default class Invoice {
  constructor (data = {}) {
    this._id = data._id;
    this.userId = data.userId || null;
    this.source = data.source || null;
    this.user = data.user || {};
    this.referralUserId = data.referralUserId || null;
    this.referralPaymentId = data.referralPaymentId || null;
    this.referralFee = data.referralFee || null;
    this.code = data.code || null;
    this.autoExecutable = data.autoExecutable;
    this.status = data.status || validStatus.NEW;
    this.statusDescription = data.statusDescription || null;
    this.statusSecondary = data.statusSecondary || null;
    this.statusSecondaryDescription = data.statusSecondaryDescription || null;
    this.email = data.email || null;
    this.document = data.document || null;
    this.fiat = data.fiat || 'BRL';
    this.fiatAmount = data.fiatAmount ? Number(data.fiatAmount) : null;
    this.fiatAmountPaid = data.fiatAmountPaid ? Number(data.fiatAmountPaid) : null;
    this.network = data.network || 'BTC';
    this.crypto = data.crypto || 'BTC';
    this.cryptoAmount = data.cryptoAmount ? Number(data.cryptoAmount) : null;
    this.cryptoAmountOrig = data.cryptoAmountOrig ? Number(data.cryptoAmountOrig) : null;
    this.cryptoAmountPaid = data.cryptoAmountPaid ? Number(data.cryptoAmountPaid) : null;
    this.cryptoPrice = data.cryptoPrice ? Number(data.cryptoPrice) : null;
    this.spread = data.spread ? Number(data.spread) : null;
    this.fee = data.fee || null;
    this.cryptoFee = data.cryptoFee || null;
    this.ipRegister = data.ipRegister || null;
    this.description = data.description || null;
    this.expiryAt = data.expiryAt || null;
    this.bill = data.bill || null;
    this.canEmitBill = data.canEmitBill || null;
    this.requiresIntervention = data.requiresIntervention || null;
    this.createdAt = data.createdAt || null;
    this.deletedAt = data.deletedAt || null;
    this.updatedAt = data.updatedAt || null;
    this.transactions = startTransactions(data.transactions || []);
    this.items = startItems(data.items);
    this.events = startEvents(data.events || []);
  }
}
