export const isAdmin = (user) => {
  const roles = Array.isArray(user.roles) ? user.roles : [];
  return roles.indexOf('admin') >= 0;
};

export const isInternal = (user) => {
  const roles = Array.isArray(user.roles) ? user.roles : [];
  return roles.indexOf('admin') >= 0 || roles.indexOf('operator') >= 0;
};

export default {
  isInternal,
  isAdmin,
};
