<template>
  <div>
    <div class="col-12 col-sm-12 col-md-12">
      <h1 class="page-header">{{ user.name || '' }} <small> {{ user.email || '' }}  </small></h1>
    </div>
    <div class="col-12 col-sm-12 col-md-12">
      <form class="margin-bottom-0"  @submit.prevent>
          <div class="ct-password-default">
            <label for="password">{{  $t('newPassword.formPlaceholder.password') }}</label>
            <div class="ct-form-group">
              <input
                autocomplete="off"
                class="form-control"
                :class="{'is-invalid': errors.has('password')}"
                id="password"
                name="password"
                v-validate="{ required: true }"
                :data-vv-as="$t('newPassword.formPlaceholder.password')"
                :placeholder="$t('newPassword.formPlaceholder.password')"
                type="password"
              />
              <div class="invalid-feedback">{{ errors.first('password') }}</div>
            </div>

            <div class="ct-form-group">
              <label for="newPassword">{{  $t('newPassword.formPlaceholder.newPassword') }}</label>
              <input
                autocomplete="off"
                class="form-control"
                :class="{'is-invalid': errors.has('newPassword')}"
                id="newPassword"
                name="newPassword"
                v-validate="{ required: true }"
                :data-vv-as="$t('newPassword.formPlaceholder.newPassword')"
                :placeholder="$t('newPassword.formPlaceholder.newPassword')"
                type="password"
                ref="newPassword"
              />
              <div class="invalid-feedback">{{ errors.first('newPassword') }}</div>
            </div>

            <div class="ct-form-group">
              <label for="repeatNewPassword">{{  $t('newPassword.formPlaceholder.repeatNewPassword') }}</label>
              <input
                autocomplete="off"
                class="form-control"
                :class="{'is-invalid': errors.has('repeatNewPassword')}"
                id="repeatNewPassword"
                name="repeatNewPassword"
                v-validate="'required|confirmed:newPassword'"
                :data-vv-as="$t('newPassword.formPlaceholder.newPassword')"
                :placeholder="$t('newPassword.formPlaceholder.repeatNewPassword')"
                type="password"
              />
              <div class="invalid-feedback">{{ errors.first('repeatNewPassword') }}</div>
            </div>

            <div class="ct-form-group" v-if="isEnabled2fa">
              <label for="token2Fa">{{  $t('newPassword.formPlaceholder.token2Fa') }}</label>
              <input
                autocomplete="off"
                class="form-control"
                :class="{'is-invalid': errors.has('token2Fa')}"
                id="token2Fa"
                name="token2Fa"
                v-validate="{ required: true }"
                :data-vv-as="$t('newPassword.formPlaceholder.token2Fa')"
                :placeholder="$t('newPassword.formPlaceholder.token2Fa')"
                type="text"
              />
              <div class="invalid-feedback">{{ errors.first('token2Fa') }}</div>
            </div>

            <br>
            <br>
            <div class="row">
              <div class="col-12 col-sm-6">

              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group pull-right form-inline">
                  <button
                    class="btn btn-default btn-lg"
                    type="submit"
                    @click="cancel"
                  >{{$t('newPassword.cancel')}}</button>
                  <button
                    class="btn btn-primary btn-lg"
                    type="submit"
                    @click="savePassword"
                    :disabled="isLoading"
                  >
                    <i class="fas fa-spinner fa-pulse" v-if="isLoading"></i>
                    {{ $t('newPassword.submit') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import UserService from '@/services/UserService';

export default {
  props: {},
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
  methods: {
    cancel () {
      this.$emit('cancel');
    },
    async savePassword () {
      if (this.isLoading) return;

      try {
        const isValid = await this.$validator.validate();

        if (!isValid) {
          this.$notify({
            type: 'error',
            title: this.$t('newPassword.notificationErrorTitle'),
            text: this.$t('newPassword.notificationErrorMessage'),
          });
          return;
        }

        const newPassword = document.querySelector('input[name=newPassword]')
          .value;
        const repeatNewPassword = document.querySelector(
          'input[name=repeatNewPassword]',
        ).value;
        if (
          !newPassword ||
          !repeatNewPassword ||
          newPassword !== repeatNewPassword
        ) {
          this.$notify({
            type: 'error',
            title: this.$t('newPassword.notificationErrorTitle'),
            text: this.$t('newPassword.notificationErrorDifferentPasswords'),
          });
          return;
        }

        this.isLoading = true;
        const formPassword = {
          password: document.querySelector('input[name=password]').value,
          newPassword: newPassword,
          token2Fa: this.isEnabled2fa
            ? document.querySelector('input[name=token2Fa]').value
            : '',
        };
        const response = await UserService.changePassword(formPassword);
        this.$notify({
          type: 'success',
          title: this.$t('newPassword.notificationSuccessTitle'),
          text: this.$t('newPassword.notificationSuccessText'),
        });
        this.$emit('finished', response);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        console.error(e);
        this.$notify({
          type: 'error',
          title: this.$t('newPassword.notificationErrorTitle'),
          text:
            e.response.data.message ||
            e.message ||
            this.$t('newPassword.notificationErrorMessage'),
        });
      }
    },
  },
  data () {
    return {
      isLoading: false,
      isEnabled2fa:
        this.user && this.user.isEnabled2fa ? this.user.isEnabled2fa : false,
    };
  },
};
</script>
<style scoped lang="scss">
.ct-form-group {
  margin-bottom: 10px;
}
label {
  margin-bottom: 2px;
}
</style>
