<template>
  <div class="users-widget-component">
    <ct-panel
      :title="$t('users.title')"
      :reloading="isLoading"
      :actionButtons="actionButtons"
      @button-click="buttonClick('create')"
      @reload="updateUserList"
      v-if="showPanel"
    >
      <b-row slot="panel-content">
        <b-col cols="12" sm="12" md="12" lg="10" order-lg="1" order-md="2" order="2">
          <ct-data-grid
            :filters="filters"
            :table="list | usersFormat(listSort, listColumns, listStatusColumns)"
            :pagination="listPaginate"
            :actionButtons="actionButtons"
            :emptyTableMessage="$t('dataGrid.emptyTableMessage')"
            :showModalPrint="showModalPrint"
            :printListing="listPrint | usersFormat(listSort, listColumns, listStatusColumns)"
            :modalSubtitle="$t('users.title')"
            :filtersPrint="listFilters"
            @sort="changeSort"
            @search="searchChange"
            @filter="changeFilter"
            @print="printClick"
            @page="changePage"
            @action="actionClick"
            @selected="selectRows"
            @button="buttonClick"
            @doubleClick="doubleClickRow"
          ></ct-data-grid>
        </b-col>
        <b-col
          cols="12"
          sm="12"
          md="12"
          lg="2"
          order-lg="2"
          order-md="1"
          order="1"
          class="status-filters"
        >
          <b-row>
            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="NEW"
                :title="$t('users.status.NEW')"
                :value="listUsersStatistics.NEW || 0"
                color="orange"
                :active="verifyStateFilter('NEW')"
                icon="fa-star"
              />
            </b-col>
            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="PENDING"
                :title="$t('users.status.PENDING')"
                :value="listUsersStatistics.PENDING || 0"
                color="blue"
                :active="verifyStateFilter('PENDING')"
                icon="fa-spinner"
              />
            </b-col>
            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="PROCESSING"
                :title="$t('users.status.PROCESSING')"
                :value="listUsersStatistics.PROCESSING || 0"
                color="aqua"
                :active="verifyStateFilter('PROCESSING')"
                icon="fa-clock"
              />
            </b-col>
            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="ACTIVE"
                :title="$t('users.status.ACTIVE')"
                :value="listUsersStatistics.ACTIVE || 0"
                color="lime"
                :active="verifyStateFilter('ACTIVE')"
                icon="fa-check-circle"
              />
            </b-col>
            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="REQUEST_VALIDATE"
                :title="$t('users.status.REQUEST_VALIDATE')"
                :value="listUsersStatistics.REQUEST_VALIDATE || 0"
                color="yellow"
                :active="verifyStateFilter('REQUEST_VALIDATE')"
                icon="fa-exclamation-circle"
              />
            </b-col>
            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="VALID"
                :title="$t('users.status.VALID')"
                :value="listUsersStatistics.VALID || 0"
                color="green"
                :active="verifyStateFilter('VALID')"
                icon="fa-award"
              />
            </b-col>
            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="BLOCKED"
                :title="$t('users.status.BLOCKED')"
                :value="listUsersStatistics.BLOCKED || 0"
                color="red"
                :active="verifyStateFilter('BLOCKED')"
                icon="fa-ban"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </ct-panel>

    <ct-modal-view
      :styleContent="{padding: '10px'}"
      :title="$t('formUser.title')"
      :show="showUser"
      @close="toggleUser"
      size="large"
    >
      <ct-user-form slot="body" :user="user" @cancel="toggleUser" @finished="finishUser"/>
    </ct-modal-view>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CtDataGrid from '@/components/Shared/DataGrid/DataGrid.vue';
import CtWidgetStatFilter from '@/components/Dashboard/WidgetStatFilter.vue';
import CtPanel from '@/components/Dashboard/Panel.vue';
import CtModalView from '@/components/Shared/ModalView.vue';
import CtUserForm from '@/components/Users/Form.vue';
import usersFormat from '@/filters/usersFormat';
import User from '@/model/User';

export default {
  name: 'UsersWidget',
  components: {
    CtDataGrid,
    CtPanel,
    CtWidgetStatFilter,
    CtModalView,
    CtUserForm,
  },
  filters: {
    usersFormat,
  },
  computed: {
    ...mapState({
      list: state => state.users.list,
      listUsersStatistics: state => state.users.listUsersStatistics,
      listPrint: state => state.users.listPrint,
      listPaginate: state => state.users.listPaginate,
      listSort: state => state.users.listSort,
      listFilters: state => state.users.listFilters,
      listSearch: state => state.users.listSearch,
    }),
  },
  methods: {
    ...mapActions([
      'addUsersListFilter',
      'removeUsersListFilter',
      'getUsersList',
      'setUsersListSort',
      'getListUserStatistics',
      'setListSearch',
      'setUsersListPage',
    ]),
    async updateUserList () {
      try {
        this.isLoading = true;
        await this.getUserListAndStatistics();
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    filterResults (v) {
      if (!v.activated) {
        this.addUsersListFilter(v.value);
      } else {
        this.removeUsersListFilter(v.value);
      }
    },
    verifyStateFilter (filter) {
      if (this.listFilters.indexOf(filter) !== -1) {
        return true;
      }
      return false;
    },
    printClick (event) {
      const isPrint = true;
      this.getUserListAndStatistics(isPrint);
    },
    toggleModalPrint () {
      this.showModalPrint = !this.showModalPrint;
    },
    reloadPanel () {
      this.toggleReload();
    },
    toggleReload () {
      this.panel = !this.panel;
    },
    removePanel () {
      this.showPanel = false;
    },
    searchEnterPress (value) {},
    changeFilter (filter) {},
    actionClick (action) {
      this.buttonClick(action);
    },
    searchChange (search) {
      this.setListSearch(search);
    },
    changeSort (header) {
      this.setUsersListSort(header);
    },
    changePage (page) {
      this.setUsersListPage(page);
    },
    buttonClick (button) {
      if (button === 'create') {
        this.user = new User();
        this.toggleUser();
      }
    },
    async finishUser (user) {
      this.user = user;
      await this.updateUserList();
      this.toggleUser();
    },
    toggleUser () {
      this.showUser = !this.showUser;
    },
    selectRows (rows) {},
    doubleClickRow (id) {
      if (id) {
        const userItem = this.getStateUserFromId(id);
        this.user = userItem ? new User(userItem) : {};
        if (this.user) {
          this.toggleUser();
        }
      }
    },
    getStateUserFromId (id) {
      if (this.list && id) {
        for (const index in this.list.data) {
          if (
            this.list.data[index]._id &&
            this.list.data[index]._id === id
          ) {
            return this.list.data[index] ? this.list.data[index] : {};
          }
        }
        return {};
      }
    },
    async getUserListAndStatistics (isPrint = false) {
      await this.getUsersList(isPrint);
      await this.getListUserStatistics();
    },
  },
  created () {
    this.filters.filterTextCurrent = this.listSearch;
    this.getUserListAndStatistics();
  },
  data () {
    return {
      user: new User(),
      isLoading: false,
      showPanel: true,
      showUser: false,
      showModalPrint: false,
      filters: {
        inputSearchText: this.$t('dataGrid.inputSearchText'),
        items: [],
        enableFilterText: true,
        enablePrint: true,
        filterTextCurrent: {},
        additional: [
          {
            name: 'referralId',
            text: this.$t('users.listColumns.referralId'),
            isSpecialSearch: true,
          },
        ],
      },
      actionButtons: [
        {
          name: 'create',
          title: this.$t('users.newUser'),
          color: 'blue',
          icon: 'fa-plus',
        },
      ],
      listColumns: {
        _id: this.$t('users.listColumns._id'),
        name: this.$t('users.listColumns.name'),
        email: this.$t('users.listColumns.email'),
        kind: this.$t('users.listColumns.kind'),
        document: this.$t('users.listColumns.document'),
        createdAt: this.$t('users.listColumns.createdAt'),
        status: this.$t('users.listColumns.status'),
      },
      listStatusColumns: [
        {
          name: '',
          text: this.$t('users.status.ALL'),
        },
        {
          name: 'NEW',
          text: this.$t('users.status.NEW'),
        },
        {
          name: 'PENDING',
          text: this.$t('users.status.PENDING'),
        },
        {
          name: 'PROCESSING',
          text: this.$t('users.status.PROCESSING'),
        },
        {
          name: 'ACTIVE',
          text: this.$t('users.status.ACTIVE'),
        },
        {
          name: 'REQUEST_VALIDATE',
          text: this.$t('users.status.REQUEST_VALIDATE'),
        },
        {
          name: 'VALID',
          text: this.$t('users.status.VALID'),
        },
        {
          name: 'BLOCKED',
          text: this.$t('users.status.BLOCKED'),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.users-widget-component {
  .status-filters {
    padding-left: 10px;
  }
}
</style>
