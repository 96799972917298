<template>
  <div class="invoiceReferrals-widget-component">
    <ct-panel
      :title="$t('invoiceReferrals.title')"
      :reloading="isLoading"
      @reload="updateInvoiceReferralsList"
      :isFiltersChecked="isFiltersChecked"
      :actionButtons="actionButtons"
      :isShowFilters="showFilters"
      @button-click="actionClick"
      @filter="updateFilters"
      :enableFilterButton="true"
      v-if="showPanel"
    >
      <b-row slot="panel-content">
        <b-col cols="12" sm="12" md="12" :lg="showFilters ? '10' : '12'" order-lg="1" order-md="2" order="2">
          <ct-data-grid
            :filters="filters"
            :table="list | invoiceReferralsFormat(listSort, listColumns, { user, statusNames: listStatusColumns })"
            :pagination="listPaginate"
            :actionButtons="actionButtons"
            :emptyTableMessage="$t('dataGrid.emptyTableMessage')"
            :showModalPrint="showModalPrint"
            :printListing="listPrint | invoiceReferralsFormat(listSort, listColumns, { user, statusNames: listStatusColumns })"
            :modalSubtitle="$t('invoiceReferrals.title')"
            :filtersPrint="listFilters"
            @sort="changeSort"
            @search="searchChange"
            @filter="changeFilter"
            @print="printClick"
            @page="changePage"
            @action="actionClick"
            @selected="selectRows"
            @button="buttonClick"
            @doubleClick="doubleClickRow"
          ></ct-data-grid>
        </b-col>
        <b-col
          cols="12"
          sm="12"
          md="12"
          lg="2"
          order-lg="2"
          order-md="1"
          order="1"
          class="status-filters"
          v-if="showFilters"
        >
          <b-row>
            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="OPEN"
                :title="$t('invoiceReferrals.listStatus.OPEN')"
                :value="listStatistics.OPEN || 0"
                color="orange"
                :active="verifyStateFilter('OPEN')"
                icon="fa-star"
              />
            </b-col>

            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="PROCESSING"
                :title="$t('invoiceReferrals.listStatus.PROCESSING')"
                :value="listStatistics.PROCESSING || 0"
                color="blue"
                :active="verifyStateFilter('PROCESSING')"
                icon="fa-spinner"
              />
            </b-col>

            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="ERROR"
                :title="$t('invoiceReferrals.listStatus.ERROR')"
                color="pink"
                :value="listStatistics.ERROR || 0"
                :active="verifyStateFilter('ERROR')"
                icon="fa fa-bug"
              />
            </b-col>

            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="CANCELLED"
                :title="$t('invoiceReferrals.listStatus.CANCELLED')"
                color="red"
                :value="listStatistics.CANCELLED || 0"
                :active="verifyStateFilter('CANCELLED')"
                icon="fa-ban"
              />
            </b-col>

            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="FINISHED"
                :title="$t('invoiceReferrals.listStatus.FINISHED')"
                :value="listStatistics.FINISHED || 0"
                color="green"
                :active="verifyStateFilter('FINISHED')"
                icon="fa-check-double"
              />
            </b-col>
          </b-row>
        </b-col>

      </b-row>
    </ct-panel>
    <ct-modal-view
      :title="$t('invoices.details.modalReferralTitle')"
      :show="showModalReferral"
      size="small"
      @close="toggleReferral"
    >
      <ct-modal-referral-payment
        slot="body"
        :invoiceReferral="invoiceReferral"
        :invoiceId="invoiceReferral.invoiceId"
        @close="toggleReferral"
        :reexecute="reexecuteReferral"
        @copy="copyToClipboard"
        :referralWidget="true"
      ></ct-modal-referral-payment>
    </ct-modal-view>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import config from '../../config';
import CtDataGrid from '@/components/Shared/DataGrid/DataGrid.vue';
import CtPanel from '@/components/Dashboard/Panel.vue';
import invoiceReferralsFormat from '@/filters/invoiceReferralsFormat';
import InvoiceReferral from '@/model/InvoiceReferral';
import CtWidgetStatFilter from '@/components/Dashboard/WidgetStatFilter.vue';
import CtModalReferralPayment from '../Invoices/ModalReferralPayment.vue';
import CtModalView from '@/components/Shared/ModalView.vue';

export default {
  name: 'InvoiceReferralsWidget',
  components: {
    CtDataGrid,
    CtPanel,
    CtWidgetStatFilter,
    CtModalReferralPayment,
    CtModalView,
  },
  filters: {
    invoiceReferralsFormat,
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      list: state => state.invoiceReferrals.list,
      listPrint: state => state.invoiceReferrals.listPrint,
      listPaginate: state => state.invoiceReferrals.listPaginate,
      listSort: state => state.invoiceReferrals.listSort,
      listFilters: state => state.invoiceReferrals.listFilters,
      listSearch: state => state.invoiceReferrals.listSearch,
      listStatistics: state => state.invoiceReferrals.listStatistics,
      showFilters: state => state.invoiceReferrals.showFilters,
      authUser: state => (state.auth.user ? state.auth.user : []),
    }),
    isFiltersChecked () {
      return !!(this.listFilters && this.listFilters.length);
    },
  },
  methods: {
    ...mapActions([
      'addInvoiceReferralsListFilter',
      'removeInvoiceReferralsListFilter',
      'getInvoiceReferralsList',
      'getListInvoiceReferralStatistics',
      'setInvoiceReferralsListSort',
      'setInvoiceReferralListSearch',
      'setInvoiceReferralsListPage',
      'getListInvoiceReferralsStatistics',
      'setInvoiceReferralsShowFilters',
      'reprocessInvoiceReferral',
    ]),
    toggleReferral () {
      this.showModalReferral = !this.showModalReferral;
    },
    updateFilters (isFiltered) {
      this.setInvoiceReferralsShowFilters(isFiltered);
    },
    async reexecuteReferral () {
      try {
        this.invoiceReferral = await this.reprocessInvoiceReferral(this.invoiceReferral._id);
        this.$notify({
          type: 'success',
          title: this.$t('invoices.modalReferralData.sucessTitleReexecuteReferral'),
          text: this.$t('invoices.modalReferralData.sucessMessageReexecuteReferral'),
        });
        await this.updateInvoiceReferralsList();
      } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
          this.$notify({
            type: 'error',
            title: this.$t('invoices.modalReferralData.errorTitleReexecuteReferral'),
            text: e.response.data.message,
          });
        }
      }
    },
    copyToClipboard (value) {
      const el = document.createElement('textarea');
      el.value = value;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },
    async updateInvoiceReferralsList (background = false) {
      try {
        this.isLoading = !background;
        await this.getInvoiceReferralsList();
        await this.getListInvoiceReferralsStatistics();
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    verifyStateFilter (filter) {
      if (this.listFilters.indexOf(filter) !== -1) {
        return true;
      }
      return false;
    },
    filterResults (v) {
      if (!v.activated) {
        this.addInvoiceReferralsListFilter(v.value);
      } else {
        this.removeInvoiceReferralsListFilter(v.value);
      }
    },
    printClick (event) {
      const isPrint = true;
      this.getInvoiceReferralsList(isPrint);
    },
    toggleModalPrint () {
      this.showModalPrint = !this.showModalPrint;
    },
    reloadPanel () {
      this.toggleReload();
    },
    toggleReload () {
      this.panel = !this.panel;
    },
    removePanel () {
      this.showPanel = false;
    },
    searchEnterPress (value) {},
    changeFilter (filter) {},
    actionClick (action) {
      this.buttonClick(action);
    },
    async searchChange (search) {
      await this.setInvoiceReferralListSearch(search);
      await this.updateInvoiceReferralsList();
    },
    async changeSort (header) {
      await this.setInvoiceReferralsListSort(header);
      await this.updateInvoiceReferralsList();
    },
    changePage (page) {
      this.setInvoiceReferralsListPage(page);
    },
    buttonClick (button) {
      if (button && button.action === 'reexecute') {
        this.invoiceReferral = button.data;
        this.reexecuteReferral();
      }

      if (button && button.action === 'invoice') {
        this.$router.push({ path: `/invoices/${button.data.invoiceId}` });
      }
    },
    selectRows (rows) {},
    doubleClickRow (id) {
      if (id) {
        this.invoiceReferral = this.list.data.find(i => i._id === id);
        this.toggleReferral();
      }
    },
  },
  async created () {
    this.filters.filterTextCurrent = this.listSearch;
    await this.updateInvoiceReferralsList();
    this.interval = setInterval(async () => {
      await this.updateInvoiceReferralsList(true);
    }, config.reloadInterval);
  },
  async beforeDestroy () {
    clearInterval(this.interval);
  },
  data () {
    return {
      invoiceReferral: new InvoiceReferral(),
      interval: false,
      isLoading: false,
      showPanel: true,
      showModalPrint: false,
      showModalReferral: false,
      filters: {
        inputSearchText: this.$t('dataGrid.inputSearchText'),
        items: [],
        enableFilterText: true,
        enablePrint: true,
        filterTextCurrent: {},
      },
      actionButtons: [
      ],
      listColumns: {
        _id: this.$t('invoiceReferrals.listColumns._id'),
        userName: this.$t('invoiceReferrals.listColumns.userName'),
        userEmail: this.$t('invoiceReferrals.listColumns.userEmail'),
        origin: this.$t('invoiceReferrals.listColumns.origin'),
        fee: this.$t('invoiceReferrals.listColumns.fee'),
        value: this.$t('invoiceReferrals.listColumns.value'),
        status: this.$t('invoiceReferrals.listColumns.status'),
        reexecuteReferral: this.$t('invoices.modalReferralData.reexecute'),
        invoice: this.$t('invoiceReferrals.listColumns.invoice'),
        createdAt: this.$t('invoiceReferrals.listColumns.createdAt'),
      },
      listStatusColumns: [
        {
          name: 'OPEN',
          text: this.$t('invoiceReferrals.listStatus.OPEN'),
          icon: 'fas fa-star',
          color: 'orange',
        },
        {
          name: 'PROCESSING',
          text: this.$t('invoiceReferrals.listStatus.PROCESSING'),
          icon: 'fas fa-spinner',
          color: 'blue',
        },
        {
          name: 'FINISHED',
          text: this.$t('invoiceReferrals.listStatus.FINISHED'),
          icon: 'fas fa-check-double',
          color: 'green',
        },
        {
          name: 'ERROR',
          text: this.$t('invoiceReferrals.listStatus.ERROR'),
          icon: 'fa fa-bug',
          color: 'pink',
        },
        {
          name: 'CANCELLED',
          text: this.$t('invoiceReferrals.listStatus.CANCELLED'),
          icon: 'fas fa-times',
          color: 'red',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.invoiceReferrals-widget-component {
  .status-filters {
    padding-left: 10px;
  }
}
</style>
