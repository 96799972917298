<template>
  <div class="users-widget-component">
    <ct-panel :title="$t('user.title')" :reloading="isLoading" :enable-reload="false" :action-buttons="actionButtons">
      <div slot="panel-content">
        <ct-user-form :user="user" @finished="finished" @change2fa="change2fa"/>
      </div>
    </ct-panel>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CtPanel from '@/components/Dashboard/Panel.vue';
import CtUserForm from '@/components/Users/FormProfile.vue';
import usersFormat from '@/filters/usersFormat';
import User from '@/model/User';

export default {
  name: 'UsersWidget',
  components: {
    CtPanel,
    CtUserForm,
  },
  filters: {
    usersFormat,
  },
  computed: {
    ...mapState({
      userState: state => state.auth.user,
    }),
  },
  methods: {
    ...mapActions(['getCurrentUserData']),
    async finished () {
      await this.getCurrentUserData();
      this.$notify({
        type: 'success',
        title: this.$t('user.notificationSuccessTitle'),
        text: this.$t('user.notificationSuccessMessage'),
      });
    },
    async change2fa () {
      await this.getCurrentUserData();
      this.user = new User(this.userState);
    },
  },
  created () {
    this.user = new User(this.userState);
  },
  data () {
    return {
      user: null,
      actionButtons: [],
      isLoading: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.users-widget-component {
  .status-filters {
    padding-left: 10px;
  }
}
</style>
