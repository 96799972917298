<template>
  <div class="row">
    <div class="col-xs-12 col-sm-6">
      <div class="form-group" :class="{'is-invalid': errors.has('giftCardOperator')}">
        <label for="operator">{{ $t('invoices.modalReversal.fiat.giftCard.operator') }} *</label>
        <ct-select
          name="giftCardOperator"
          id="giftCardOperator"
          :key="'giftCardOperator'"
          class="from-control"
          :class="{'is-invalid': errors.has('giftCardOperator')}"
          :data-vv-as="$t('invoices.modalReversal.fiat.giftCard.operator')"
          v-model="giftCard.operator"
          :options="giftCardOperatorsOptions"
          :multiple="false"
          v-validate="{ required: true }"
          @input="changeGiftCardOperator"
        />
        <div class="invalid-feedback">{{ errors.first('giftCardOperator') }}</div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-6">
      <div class="form-group" :class="{'is-invalid': errors.has('giftCardAmount')}">
        <label for="giftCardAmount">{{ $t('invoices.modalReversal.fiat.giftCard.card') }} *</label>
        <ct-select
          name="giftCardAmount"
          id="giftCardAmount"
          :key="'giftCardAmount'"
          ref="giftCardAmount"
          class="from-control"
          :class="{'is-invalid': errors.has('giftCardAmount')}"
          :data-vv-as="$t('invoices.modalReversal.fiat.amount')"
          v-model="giftCard.card"
          :reduce="card => card.value"
          :options="giftCardAmountsOptions"
          :multiple="false"
          v-validate="{ required: true }"
          @input="changeGiftCard"
        />
        <div class="invalid-feedback">{{ errors.first('giftCardAmount') }}</div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-12">
      <div class="form-group" :class="{'is-invalid': errors.has('giftcardFee')}">
        <label for="giftcardFee">{{ $t('invoices.modalReversal.fiat.fee') }} *</label>
        <ct-input-numeric
          :thousand-separator="$t(`currencies.BRL.thousands`)"
          :decimal-separator="$t(`currencies.BRL.decimal`)"
          :precision="2"
          autocomplete="off"
          class="form-control"
          :class="{'is-invalid': errors.has('giftcardFee')}"
          v-validate="{ required: true, max_value: maxValueForFee }"
          id="giftcardFee"
          name="giftcardFee"
          :key="'giftcardFee'"
          :data-vv-as="$t('invoices.modalReversal.fiat.fee')"
          :placeholder="$t('invoices.modalReversal.fiat.amountPlaceholder')"
          v-model="giftCard.fee"
          @change="changeModel"
        ></ct-input-numeric>
        <div class="invalid-feedback">{{ errors.first('giftcardFee') }}</div>
      </div>
    </div>

  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import CtInputNumeric from '../../Shared/InputNumeric';
import { CtSelect } from '@citartech/core-ui';
import GiftCardService from '../../../services/GiftCardService';

export default {
  name: 'ReversalFiatGiftcard',
  props: {
    value: {
      type: Object,
      required: true,
    },
    sumReversalValue: {
      type: Number,
    },
  },
  components: {
    CtInputNumeric,
    CtSelect,
  },
  directives: {
    mask,
  },
  data: () => ({
    giftCard: {
      operator: null,
      card: null,
      amount: 0,
      fee: 0,
    },
    giftCardOperators: [],
    giftCardAmounts: [],
  }),
  watch: {
    value () {
      this.giftCard = { ...this.giftCard, ...this.value || {} };
    },
  },
  async created () {
    const operators = await GiftCardService.getOperators();
    this.giftCardOperators = operators.map((operator) => {
      return {
        value: operator.value,
        label: operator.label,
      };
    });
  },
  mounted () {
    this.setVal();
  },
  computed: {
    giftCardOperatorsOptions () {
      return this.giftCardOperators;
    },
    giftCardAmountsOptions () {
      if (!this.giftCard.operator) {
        return [];
      }
      return this.giftCardAmounts.filter((card) => card.cardValue < this.sumReversalValue);
    },
    maxValueForFee () {
      return Number((this.sumReversalValue - this.giftCard.amount).toFixed(2));
    },
  },
  methods: {
    setVal () {
      this.giftCard.validator = this.$validator;
      this.changeModel();
    },
    changeModel () {
      this.$emit('input', this.giftCard);
    },
    async changeGiftCardOperator () {
      this.$refs.giftCardAmount.clear();
      if (this.giftCard.operator) {
        this.giftCardAmounts = await GiftCardService.getAmounts(this.giftCard.operator);
      }
    },
    async changeGiftCard () {
      if (!this.giftCard.card) {
        this.giftCard.amount = 0;
      }

      const card = this.giftCardAmountsOptions.find(card => card.value === this.giftCard.card);
      this.giftCard.amount = card ? Number(card.cardValue) : 0;
      this.changeModel();
    },
  },
};
</script>
