<template>
  <div class="recharge-form-component">
    <form @submit.prevent>
        <div class="row">
          <div class="col-12">
            <legend class="legend-title-content">
              <span class="legend legend-title modal-edit">{{ $t('invoices.details.editItem.labelItem') }}</span>
            </legend>
          </div>

          <div class="col-lg-2 col-md-3 col-sm-3">
            <div class="form-group">
              <label for="name">{{ $t('invoices.details.recharge.ddd') }} *</label>
              <input
                autocomplete="off"
                class="form-control"
                :class="{'is-invalid': errors.has('ddd')}"
                id="ddd"
                name="ddd"
                v-validate="{ required: true }"
                :data-vv-as="$t('invoices.details.recharge.ddd')"
                :placeholder="$t('invoices.details.recharge.ddd')"
                type="text"
                v-model="mutableItem.recharge.ddd"
                :disabled="notEditing"
              >
              <div class="invalid-feedback">{{ errors.first('ddd') }}</div>
            </div>
          </div>

          <div class="col-lg-3 col-md-3 col-sm-3">
            <div class="form-group">
              <label for="name">{{ $t('invoices.details.recharge.number') }} *</label>
              <the-mask
                autocomplete="off"
                class="form-control"
                :class="{'is-invalid': errors.has('phone')}"
                id="phone"
                name="phone"
                mask="#####-####"
                :masked="false"
                v-validate="{ required: true }"
                :data-vv-as="$t('invoices.details.recharge.number')"
                :placeholder="$t('invoices.details.recharge.number')"
                type="text"
                v-model="mutableItem.recharge.phone"
                :disabled="notEditing"
              />
              <div class="invalid-feedback">{{ errors.first('phone') }}</div>
            </div>
          </div>
        </div>

        <ct-item-table-events
          slot="body"
          :events="item.events || []"
        />

        <div class="row">
          <div class="col-xs-12 col-sm-12">
            <div class="form-group pull-right form-inline">
              <button class="btn btn-default btn-lg" type="button" @click="reset">
                {{ $t('invoices.details.editItem.cancel') }}
              </button>
              <button :disabled="notEditing" class="btn btn-primary btn-lg" type="submit" @click="save" >
                {{ $t('invoices.details.editItem.confirm') }}
              </button>
            </div>
          </div>
        </div>
      </form>

  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask';
import CtItemTableEvents from '@/components/Invoices/InvoiceDetails/EditItem/ItemTableEvents.vue';

export default {
  name: 'EditRechargeForm',
  data: () => ({
    mutableItem: {},
  }),
  components: {
    TheMask,
    CtItemTableEvents,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    notEditing: {
      type: Boolean,
      default: false,
    },
  },
  created () {
    this.mutableItem = JSON.parse(JSON.stringify(this.item));
  },
  methods: {
    async reset () {
      this.$emit('reset');
    },
    async save () {
      if (await this.$validator.validate()) {
        const data = { recharge: this.mutableItem.recharge };
        this.$emit('save', data);
      }
    },
  },
};
</script>

<style lang="scss">
.legend-title-content {
  margin-bottom: 20px;
}
</style>
