import TransactionOrderService from '../../../services/TransactionOrderService';

export default {
  async getTransactionOrdersList ({ commit, state }, isPrint = false) {
    try {
      const list = await TransactionOrderService.getList({
        filters: state.listFilters,
        search: state.listSearch,
        page: isPrint ? 0 : (state.listPaginate.currentPage || 1),
        limit: isPrint ? 0 : (state.listPaginate.perPage || 10),
        orderBy: state.listSort,
      });

      if (isPrint) {
        commit('SET_LIST_TRANSACTION_ORDER_PRINT', list);
      } else {
        commit('SET_LIST_TRANSACTION_ORDER', list);
        if (list.meta) {
          commit('SET_TRANSACTION_ORDER_LIST_TOTAL_PAGES', list.meta.pagination.totalPages);
        }
      }
    } catch (error) {
      console.error(error);
    }
  },

  async getListTransactionOrdersStatistics ({ commit }) {
    const list = await TransactionOrderService.listStatistics();
    if (list) {
      commit('SET_LIST_TRANSACTION_ORDER_STATISTICS', list.data);
    }
  },

  async reexecuteOrder (_, id) {
    const res = await TransactionOrderService.reexecuteOrder(id);
    return res;
  },

  addTransactionOrdersListFilter ({ commit, dispatch }, value) {
    commit('SET_TRANSACTION_ORDER_LIST_PAGE', 1);
    commit('ADD_TRANSACTION_ORDER_LIST_FILTER', value);
    dispatch('getTransactionOrdersList');
  },

  setTransactionOrdersShowFilters ({ commit }, value) {
    commit('SET_INVOICE_REFERRALS_SHOW_FILTERS', value);
  },

  removeTransactionOrdersListFilter ({ commit, dispatch }, value) {
    commit('SET_TRANSACTION_ORDER_LIST_PAGE', 1);
    commit('REMOVE_TRANSACTION_ORDER_LIST_FILTER', value);
    dispatch('getTransactionOrdersList');
  },

  setTransactionOrdersListPage ({ commit, dispatch }, value) {
    commit('SET_TRANSACTION_ORDER_LIST_PAGE', value);
    dispatch('getTransactionOrdersList');
  },

  setTransactionOrdersListTotalPages ({ commit }, value) {
    commit('SET_TRANSACTION_ORDER_LIST_PAGE', 1);
    commit('SET_TRANSACTION_ORDER_LIST_TOTAL_PAGES', value);
  },

  setTransactionOrdersListSort ({ commit }, value) {
    commit('SET_TRANSACTION_ORDER_LIST_SORT', value);
  },

  setTransactionOrderListSearch ({ commit }, value) {
    commit('SET_TRANSACTION_ORDER_LIST_SEARCH', value);
  },
};
