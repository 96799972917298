export default {
  menuItems: [
    {
      name: 'Dashboard',
      icon: 'fa-th-large',
      route: 'home',
      params: {},
    },
    {
      name: 'Pagamento de Afiliados',
      icon: 'fas fa-money-check-alt',
      route: 'invoiceReferrals',
      params: {},
    },
    {
      name: 'Ordens em execução',
      icon: 'fa-align-center',
      route: 'transactionOrders',
      params: {},
    },
    {
      name: 'Transações',
      icon: 'fa-chart-line',
      route: 'transactions',
      params: {},
    },
    {
      name: 'Declarações',
      icon: 'fas fa-file-download',
      route: 'declarations',
      params: {},
    },
    {
      name: 'Usuários',
      icon: 'fa-users',
      route: 'users',
      params: {},
    },
    {
      name: 'Configurações',
      icon: 'fa fa-cogs',
      route: 'settings',
      params: {},
    },
    {
      name: 'Relatórios',
      icon: 'fa fa-file-alt',
      route: 'reports',
      params: {},
    },
    {
      name: 'Gerador de declaração',
      icon: 'fa fa-university',
      route: 'rf-reporter',
      params: {},
    },
  ],
};
