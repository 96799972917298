import currency from '../filters/currency';

const formatValue = (list, sort, columnsNames, { user, statusNames }) => {
  const isInternal = Array.isArray(user.roles) && (user.roles.indexOf('operator') !== -1 || user.roles.indexOf('admin') !== -1);

  const table = {
    headers: [
      {
        name: '_id',
        text: columnsNames && '_id' in columnsNames ? columnsNames._id : '',
        width: '4%',
        order: sort && '_id' in sort ? sort._id : true,
        isFilter: true,
        isPrint: true,
        isSpecialSearch: true,
      },
      {
        name: 'amount',
        text: columnsNames && 'amount' in columnsNames ? columnsNames.amount : '',
        width: '10%',
        order: true,
        isFilter: false,
        isPrint: true,
      },
      {
        name: 'amountExecuted',
        text: columnsNames && 'amountExecuted' in columnsNames ? columnsNames.amountExecuted : '',
        width: '10%',
        order: true,
        isFilter: false,
        isPrint: true,
      },
      {
        name: 'currencyPrimary',
        text: columnsNames && 'currencyPrimary' in columnsNames ? columnsNames.currencyPrimary : '',
        width: '3%',
        order: false,
        isPrint: true,
        isFilter: true,
      },
      {
        name: 'currencySecondary',
        text: columnsNames && 'currencySecondary' in columnsNames ? columnsNames.currencySecondary : '',
        width: '3%',
        order: false,
        isPrint: true,
        isFilter: true,
      },
      {
        name: 'executedAt',
        text: columnsNames && 'executedAt' in columnsNames ? columnsNames.executedAt : '',
        width: '8%',
        order: true,
        isPrint: true,
      },
      {
        name: 'invoice',
        text: columnsNames && 'invoice' in columnsNames ? columnsNames.invoice : '',
        width: '5%',
        order: false,
        isPrint: false,
      },
      {
        name: 'operation',
        text: columnsNames && 'operation' in columnsNames ? columnsNames.operation : '',
        width: '10%',
        order: false,
        isFilter: true,
        isPrint: true,
      },
      {
        name: 'priceExecuted',
        text: columnsNames && 'priceExecuted' in columnsNames ? columnsNames.priceExecuted : '',
        width: '3%',
        order: true,
        isFilter: false,
        isPrint: true,
      },
      {
        name: 'status',
        text: columnsNames && 'status' in columnsNames ? columnsNames.status : '',
        width: '3%',
        order: false,
        isPrint: true,
      },
      {
        name: 'reexecute',
        text: columnsNames && 'reexecute' in columnsNames ? columnsNames.reexecute : '',
        width: '5%',
        order: false,
        isPrint: false,
      },
      {
        name: 'events',
        text: columnsNames && 'events' in columnsNames ? columnsNames.events : '',
        width: '5%',
        order: false,
        isPrint: false,
      },
      {
        name: 'createdAt',
        text: columnsNames && 'createdAt' in columnsNames ? columnsNames.createdAt : '',
        width: '8%',
        order: sort && 'createdAt' in sort ? sort.createdAt : true,
        isPrint: true,
      },
    ],
    lines: [],
    linesPrint: [],
  };

  table.headers = table.headers.filter((_, index) => {
    const indexesForRemove = isInternal ? [] : [1, 2, 6, 9];
    return indexesForRemove.indexOf(index) < 0;
  });

  if (list && list.data && list.data.length > 0) {
    for (let i = 0; i < list.data.length; i++) {
      const createdAt = new Date(list.data[i].createdAt);
      const executedAt = list.data[i].executedAt ? (new Date(list.data[i].executedAt)).toLocaleString('pt-BR') : '-';

      const id = { type: 'id', text: list.data[i]._id };
      const amount = currency(list.data[i].amount, 8, 'BTC ');
      const amountExecuted = currency(list.data[i].amountExecuted, 8, 'BTC ');
      const priceExecuted = currency(list.data[i].priceExecuted, 2, 'R$ ');

      const showReexecuteButton = isInternal && list.data[i].status === 'ERROR';
      let reexecutePreviewButton = {
        text: '-',
        textAlign: 'center',
      };
      if (showReexecuteButton) {
        reexecutePreviewButton = {
          name: 'reexecute-preview-' + list.data[i]._id,
          type: 'button',
          text: '',
          hover: true,
          icon: 'fas fa-sync-alt',
          color: 'primary',
          textAlign: 'center',
          value: {
            action: 'reexecute',
            data: list.data[i],
          },
          disabled: true,
          // disabled: !showReexecuteButton,
        };
      }

      const invoiceView = {
        name: 'invoice-preview-' + list.data[i]._id,
        type: 'button-text',
        text: '',
        icon: 'fas fa-eye',
        color: '#348fe2',
        textAlign: 'center',
        value: {
          action: 'invoice',
          data: list.data[i],
        },
      };

      let statusIcon = '';
      let statusColor = '';
      switch (list.data[i].status) {
        case 'OPEN':
          statusIcon = 'fas fa-star';
          statusColor = 'orange';
          break;
        case 'PROCESSING':
          statusIcon = 'fas fa-spinner';
          statusColor = 'blue';
          break;
        case 'FINISHED':
          statusIcon = 'fas fa-check-double';
          statusColor = 'green';
          break;
        case 'ERROR':
          statusIcon = 'fa fa-bug';
          statusColor = 'pink';
          break;
        case 'CANCELLED':
          statusIcon = 'fa-ban';
          statusColor = 'red';
          break;
      }

      let statusName = statusNames.filter((status) => {
        return status.name === list.data[i].status;
      });
      statusName = statusName.shift();

      const status = {
        name: 'status-invoice-' + list.data[i]._id,
        type: 'status-icon',
        color: statusColor,
        icon: statusIcon,
        textAlign: 'center',
        help: list.data[i].statusDescription || '',
        text: '',
      };

      const statusPrint = {
        name: 'status-invoice-' + list.data[i]._id,
        type: 'status-icon',
        color: statusColor,
        icon: statusIcon,
        text: list.data[i].status,
      };

      const eventsView = list.data[i].events.length > 0 ? {
        name: 'invoice-preview-' + list.data[i]._id,
        type: 'button-text',
        text: '',
        icon: 'fas fa-eye',
        color: '#348fe2',
        textAlign: 'center',
        value: {
          action: 'events',
          data: list.data[i],
        },
      } : '';

      let lines = [
        id,
        amount,
        amountExecuted,
        list.data[i].currencyPrimary || '-',
        list.data[i].currencySecondary || '-',
        executedAt,
        invoiceView,
        list.data[i].operation || '-',
        priceExecuted,
        status,
        reexecutePreviewButton,
        eventsView,
        createdAt.toLocaleString('pt-BR'),
      ];

      let linesPrint = [
        list.data[i]._id,
        amount || '-',
        amountExecuted || '-',
        list.data[i].currencyPrimary || '-',
        list.data[i].currencySecondary || '-',
        executedAt,
        list.data[i].operation || '-',
        priceExecuted,
        statusPrint,
        createdAt.toLocaleString('pt-BR'),
      ];

      lines = lines.filter((_, index) => {
        const indexesForRemove = isInternal ? [] : [1, 2, 6, 9];
        return indexesForRemove.indexOf(index) < 0;
      });

      linesPrint = linesPrint.filter((_, index) => {
        const indexesForRemove = isInternal ? [] : [1, 2];
        return indexesForRemove.indexOf(index) < 0;
      });

      table.lines.push(lines);
      table.linesPrint.push(linesPrint);
    }
  }

  return table;
};

export default formatValue;
