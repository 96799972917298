<template>
  <div class="invoice-events-component">
    <div class="table-responsive body-table ct-margin-top-20">
      <table class="table table-wallets">
        <thead>
        <tr>
          <th width="20%">{{ $t('invoices.details.events.createdAt') }}</th>
          <th width="80%">{{ $t('invoices.details.events.message') }}</th>
        </tr>
        </thead>
        <tbody v-if="events">
        <tr v-for="item in events" :key="item._id">
          <td>{{ item.createdAt | datetime}}</td>
          <td>{{ item.message }}</td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr>
          <td colspan="7">{{ $t('invoices.details.events.notFound') }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import datetime from '@/filters/datetime';
export default {
  name: 'InvoiceEvents',
  filters: { datetime },
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.invoice-events-component {
  padding-top: 20px;

  .table-responsive.body-table {
    overflow-y: auto;
    height: 450px;
  }

  h3 {
    font-weight: 400;
  }
}
</style>
