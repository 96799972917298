<template>
  <ul class="user-menu-component nav" >
    <li class="nav-profile" :class="{'expanding': isExpanded}">
      <a class="nav-profile" href="#" @click.prevent="toggleExpanded">
        <div class="cover with-shadow"></div>
        <div class="image" v-if="image">
          <img :src="image" alt="User Image">
        </div>
        <div class="info" >
          <b class="caret pull-right" ></b>
          <span v-if="name">{{ name }}</span>
          <small v-if="email"> {{ email }}</small>
        </div>
      </a>
    </li>

    <li>
      <ul class="nav nav-profile" :class="{'nav-profile-expanded': isExpanded}">
        <li>
          <a href="#" @click.prevent="logoutUser" >
            <i class="fas fa-sign-out-alt"></i> {{ $t('header.logout') }}
          </a>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'UserMenu',
  props: {
  },
  computed: {
    ...mapState({
      image: (state) => state.auth.image || false,
      name: (state) => state.auth.user && state.auth.user.name ? state.auth.user.name : '',
      email: (state) => state.auth.user && state.auth.user.email ? state.auth.user.email : '',
      sidebarMinified: (state) => state.dashboard.sidebarMinified,
    }),
    ...mapGetters(['getUserImage']),
    isExpanded () {
      return this.expanded && !this.sidebarMinified;
    },
  },
  data () {
    return {
      expanded: false,
    };
  },
  methods: {
    ...mapActions(['logout']),
    toggleExpanded () {
      this.expanded = !this.expanded;
    },
    async logoutUser () {
      await this.logout();
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style scoped lang="scss">
  .user-menu-component {
    .nav-profile-expanded {
      &.nav-profile {
        display: block;
      }
    }
  }
</style>
