<template>
  <div  class="balloon-alert" :class="['bg-color-' + color]">
    <span>
     <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'FormErrorMsg',
  props: {
    color: {
      type: String,
      default: 'red',
    },
    // message:{
    //   type: String,
    //   required: true
    // }
  },
};
</script>

<style lang="scss" scoped>
  @import '@/themes/color-admin/_ct_variable.scss';
  .balloon-alert{
    position: absolute;
    z-index: 2;
    color: white;
    border-radius: 3px;
    padding: 5px 16px;
    left: 8px;
    top: 43px;
    transition: opacity .5s ease-out;
    -moz-transition: opacity .5s ease-out;
    -webkit-transition: opacity .5s ease-out;
    -o-transition: opacity .5s ease-out;
    &:before {
      content: '\f0d8';
      font-family: 'Font Awesome 5 Free';
      top: -18px;
      position: absolute;
      left: 38px;
      font-size: 21px;
      font-weight: 900;
    }

    &[class*='bg-color']{
       &[class*='red']{
         background: $red_darker;
         &:before{
           color: $red_darker;
         }
       }
       &[class*='ct-color']{
         background: $ct_color_darker;
         &:before{
           color: $ct_color_darker;
         }
       }
       &[class*='blue']{
         background: $blue_darker;
         &:before{
           color: $blue_darker;
         }
       }
        &[class*='aqua']{
         background: $aqua_darker;
         &:before{
           color: $aqua_darker;
         }
       }
        &[class*='green']{
         background: $green_darker;
         &:before{
           color: $green_darker;
         }
       }
       &[class*='orange']{
         background: $orange_darker;
         &:before{
           color: $orange_darker;
         }
       }
       &[class*='black']{
         background: $black_darker;
         &:before{
           color: $black_darker;
         }
       }
       &[class*='silver']{
         background: $silver_darker;
         &:before{
           color: $silver_darker;
         }
       }
      &[class*='grey']{
         background: $grey_darker;
         &:before{
           color: $grey_darker;
         }
       }
       &[class*='purple']{
         background: $purple_darker;
         &:before{
           color: $purple_darker;
         }
       }
       &[class*='lime']{
         background: $lime_darker;
         &:before{
           color: $lime_darker;
         }
       }
       &[class*='pink']{
         background: $pink_darker;
         &:before{
           color: $pink_darker;
         }
       }
       &[class*='indigo']{
         background: $indigo_darker;
         &:before{
           color: $indigo_darker;
         }
       }
       &[class*='yellow']{
         background: $yellow_darker;
         &:before{
           color: $yellow_darker;
         }
       }
       &[class*='teal']{
         background: $teal_darker;
         &:before{
           color: $teal_darker;
         }
       }
    }
  }
</style>
