<template>
<div class="modal-invalid-items container">
  <div class="row">
    <div class="col-12">
      <p>
        Alguns dados para o período selecionado,
        não são válidos para a geração do arquivo de declaração.
        Valide-os e tente novamente.
        <a href="javascript;;" @click.prevent="download">Baixar relatório de registro com inválidos.</a>
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <button type="button" class="btn btn-light" @click.prevent="$emit('close')" aria-label="">
        Fechar
      </button>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'ModalInvalidItems',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  methods: {
    download () {
      this.exportCSVFile({
        type: 'Tipo',
        _id: 'ID',
        error: 'Erro',
      }, this.data, 'invalid-items');
    },
    convertToCSV (objArray) {
      var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      var str = '';

      for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
          if (line !== '') {
            line += ',';
          }

          line += array[i][index];
        }

        str += line + '\r\n';
      }

      return str;
    },
    exportCSVFile (headers, items, fileTitle) {
      if (headers) {
        items.unshift(headers);
      }
      var jsonObject = JSON.stringify(items);
      var csv = this.convertToCSV(jsonObject);
      var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

      var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement('a');
        if (link.download !== undefined) {
          var url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', exportedFilenmae);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }

      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-invalid-items {
  padding: 20px 20px;
  text-align: center;
  p {
    font-size: 16px;
  }

  input {
    text-align: center;
  }
}
</style>
