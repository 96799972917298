export default [
  {
    code: 'AF',
    phone: 93,
    name: 'Afeganistão',
  },
  {
    code: 'AL',
    phone: 355,
    name: 'Albânia',
  },
  {
    code: 'DZ',
    phone: 213,
    name: 'Algéria',
  },
  {
    code: 'AS',
    phone: 1684,
    name: 'Samoa Americana',
  },
  {
    code: 'AD',
    phone: 376,
    name: 'Andorra',
  },
  {
    code: 'AO',
    phone: 244,
    name: 'Angola',
  },
  {
    code: 'AI',
    phone: 1264,
    name: 'Anguilla',
  },
  {
    code: 'AQ',
    phone: 672,
    name: 'Antártida',
  },
  {
    code: 'AG',
    phone: 1268,
    name: 'Antigua e Barbuda',
  },
  {
    code: 'AR',
    phone: 54,
    name: 'Argentina',
  },
  {
    code: 'AM',
    phone: 374,
    name: 'Armênia',
  },
  {
    code: 'AW',
    phone: 297,
    name: 'Aruba',
  },
  {
    code: 'AU',
    phone: 61,
    name: 'Austrália',
  },
  {
    code: 'AT',
    phone: 43,
    name: 'Áustria',
  },
  {
    code: 'AZ',
    phone: 994,
    name: 'Azerbaijão',
  },
  {
    code: 'BS',
    phone: 1242,
    name: 'Bahamas',
  },
  {
    code: 'BH',
    phone: 973,
    name: 'Bahrein',
  },
  {
    code: 'BD',
    phone: 880,
    name: 'Bangladesh',
  },
  {
    code: 'BB',
    phone: 246,
    name: 'Barbados',
  },
  {
    code: 'BY',
    phone: 375,
    name: 'Bielorrússia',
  },
  {
    code: 'BE',
    phone: 32,
    name: 'Bélgica',
  },
  {
    code: 'BZ',
    phone: 501,
    name: 'Belize',
  },
  {
    code: 'BJ',
    phone: 229,
    name: 'Benin',
  },
  {
    code: 'BM',
    phone: 1441,
    name: 'Bermuda',
  },
  {
    code: 'BT',
    phone: 975,
    name: 'Butão',
  },
  {
    code: 'BO',
    phone: 591,
    name: 'Bolívia',
  },
  {
    code: 'BA',
    phone: 387,
    name: 'Bósnia e Herzegovina',
  },
  {
    code: 'BW',
    phone: 267,
    name: 'Botswana',
  },
  {
    code: 'BV',
    phone: 47,
    name: 'Ilha Bouvet',
  },
  {
    code: 'BR',
    phone: 55,
    name: 'Brasil',
  },
  {
    code: 'IO',
    phone: 246,
    name: 'Território Britânico do Oceano Índico',
  },
  {
    code: 'BN',
    phone: 673,
    name: 'Brunei',
  },
  {
    code: 'BG',
    phone: 359,
    name: 'Bulgária',
  },
  {
    code: 'BF',
    phone: 226,
    name: 'Burkina Faso',
  },
  {
    code: 'BI',
    phone: 257,
    name: 'Burundi',
  },
  {
    code: 'KH',
    phone: 855,
    name: 'Camboja',
  },
  {
    code: 'CM',
    phone: 237,
    name: 'Camarões',
  },
  {
    code: 'CA',
    phone: 1,
    name: 'Canadá',
  },
  {
    code: 'CV',
    phone: 238,
    name: 'Cabo Verde',
  },
  {
    code: 'KY',
    phone: 1345,
    name: 'Ilhas Cayman',
  },
  {
    code: 'CF',
    phone: 236,
    name: 'República Centro-Africana',
  },
  {
    code: 'TD',
    phone: 235,
    name: 'Chade',
  },
  {
    code: 'CL',
    phone: 56,
    name: 'Chile',
  },
  {
    code: 'CN',
    phone: 86,
    name: 'China',
  },
  {
    code: 'CX',
    phone: 61,
    name: 'Ilha Christmas',
  },
  {
    code: 'CC',
    phone: 672,
    name: 'Ilhas Cocos (Keeling)',
  },
  {
    code: 'CO',
    phone: 57,
    name: 'Colômbia',
  },
  {
    code: 'KM',
    phone: 269,
    name: 'Comores',
  },
  {
    code: 'CG',
    phone: 242,
    name: 'Congo',
  },
  {
    code: 'CD',
    phone: 242,
    name: 'Congo (DR)',
  },
  {
    code: 'CK',
    phone: 682,
    name: 'Ilhas Cook',
  },
  {
    code: 'CR',
    phone: 506,
    name: 'Costa Rica',
  },
  {
    code: 'CI',
    phone: 225,
    name: 'Costa do Marfim',
  },
  {
    code: 'HR',
    phone: 385,
    name: 'Croácia',
  },
  {
    code: 'CU',
    phone: 53,
    name: 'Cuba',
  },
  {
    code: 'CY',
    phone: 357,
    name: 'Chipre',
  },
  {
    code: 'CZ',
    phone: 420,
    name: 'República Tcheca',
  },
  {
    code: 'DK',
    phone: 45,
    name: 'Dinamarca',
  },
  {
    code: 'DJ',
    phone: 253,
    name: 'Djibuti',
  },
  {
    code: 'DM',
    phone: 1767,
    name: 'Dominica',
  },
  {
    code: 'DO',
    phone: 1809,
    name: 'República Dominicana',
  },
  {
    code: 'EC',
    phone: 593,
    name: 'Equador',
  },
  {
    code: 'EG',
    phone: 20,
    name: 'Egito',
  },
  {
    code: 'SV',
    phone: 503,
    name: 'El Salvador',
  },
  {
    code: 'GQ',
    phone: 240,
    name: 'Guiné Equatorial',
  },
  {
    code: 'ER',
    phone: 291,
    name: 'Eritreia',
  },
  {
    code: 'EE',
    phone: 372,
    name: 'Estônia',
  },
  {
    code: 'ET',
    phone: 251,
    name: 'Etiópia',
  },
  {
    code: 'FK',
    phone: 500,
    name: 'Ilhas Malvinas',
  },
  {
    code: 'FO',
    phone: 298,
    name: 'Ilhas Faroe',
  },
  {
    code: 'FJ',
    phone: 679,
    name: 'Fiji',
  },
  {
    code: 'FI',
    phone: 358,
    name: 'Finlândia',
  },
  {
    code: 'FR',
    phone: 33,
    name: 'França',
  },
  {
    code: 'GF',
    phone: 594,
    name: 'Guiana Francesa',
  },
  {
    code: 'PF',
    phone: 689,
    name: 'Polinésia Francesa',
  },
  {
    code: 'TF',
    phone: 33,
    name: 'Terras Austrais e Antárticas Francesas',
  },
  {
    code: 'GA',
    phone: 241,
    name: 'Gabão',
  },
  {
    code: 'GM',
    phone: 220,
    name: 'Gâmbia',
  },
  {
    code: 'GE',
    phone: 995,
    name: 'Geórgia',
  },
  {
    code: 'DE',
    phone: 49,
    name: 'Alemanha',
  },
  {
    code: 'GH',
    phone: 233,
    name: 'Gana',
  },
  {
    code: 'GI',
    phone: 350,
    name: 'Gibraltar',
  },
  {
    code: 'GR',
    phone: 30,
    name: 'Grécia',
  },
  {
    code: 'GL',
    phone: 299,
    name: 'Groelândia',
  },
  {
    code: 'GD',
    phone: 1473,
    name: 'Granada',
  },
  {
    code: 'GP',
    phone: 590,
    name: 'Guadalupe',
  },
  {
    code: 'GU',
    phone: 1671,
    name: 'Guão',
  },
  {
    code: 'GT',
    phone: 502,
    name: 'Guatemala',
  },
  {
    code: 'GN',
    phone: 224,
    name: 'Guiné',
  },
  {
    code: 'GW',
    phone: 245,
    name: 'Guiné-Bissau',
  },
  {
    code: 'GY',
    phone: 592,
    name: 'Guiana',
  },
  {
    code: 'HT',
    phone: 509,
    name: 'Haiti',
  },
  {
    code: 'HM',
    phone: 672,
    name: 'Ilhas Heard e McDonald',
  },
  {
    code: 'VA',
    phone: 39,
    name: 'Vaticano',
  },
  {
    code: 'HN',
    phone: 504,
    name: 'Honduras',
  },
  {
    code: 'HK',
    phone: 852,
    name: 'Hong Kong',
  },
  {
    code: 'HU',
    phone: 36,
    name: 'Hungria',
  },
  {
    code: 'IS',
    phone: 354,
    name: 'Islândia',
  },
  {
    code: 'IN',
    phone: 91,
    name: 'Índia',
  },
  {
    code: 'ID',
    phone: 62,
    name: 'Indonésia',
  },
  {
    code: 'IR',
    phone: 98,
    name: 'Iran',
  },
  {
    code: 'IQ',
    phone: 964,
    name: 'Iraque',
  },
  {
    code: 'IE',
    phone: 353,
    name: 'Irlanda',
  },
  {
    code: 'IL',
    phone: 972,
    name: 'Israel',
  },
  {
    code: 'IT',
    phone: 39,
    name: 'Italia',
  },
  {
    code: 'JM',
    phone: 1876,
    name: 'Jamaica',
  },
  {
    code: 'JP',
    phone: 81,
    name: 'Japão',
  },
  {
    code: 'JO',
    phone: 962,
    name: 'Jornânia',
  },
  {
    code: 'KZ',
    phone: 7,
    name: 'Cazaquistão',
  },
  {
    code: 'KE',
    phone: 254,
    name: 'Quênia',
  },
  {
    code: 'KI',
    phone: 686,
    name: 'Kiribati',
  },
  {
    code: 'KP',
    phone: 850,
    name: 'Coreia do Norte',
  },
  {
    code: 'KR',
    phone: 82,
    name: 'Coreia do Sul',
  },
  {
    code: 'KW',
    phone: 965,
    name: 'Kuwait',
  },
  {
    code: 'KG',
    phone: 996,
    name: 'Quirguistão',
  },
  {
    code: 'LA',
    phone: 856,
    name: 'Laos',
  },
  {
    code: 'LV',
    phone: 371,
    name: 'Letônia',
  },
  {
    code: 'LB',
    phone: 961,
    name: 'Líbano',
  },
  {
    code: 'LS',
    phone: 266,
    name: 'Lesoto',
  },
  {
    code: 'LR',
    phone: 231,
    name: 'Libéria',
  },
  {
    code: 'LY',
    phone: 218,
    name: 'Líbia',
  },
  {
    code: 'LI',
    phone: 423,
    name: 'Liechtenstein',
  },
  {
    code: 'LT',
    phone: 370,
    name: 'Lituânia',
  },
  {
    code: 'LU',
    phone: 352,
    name: 'Luxemburgo',
  },
  {
    code: 'MO',
    phone: 853,
    name: 'Macao',
  },
  {
    code: 'MK',
    phone: 389,
    name: 'Macedônia',
  },
  {
    code: 'MG',
    phone: 261,
    name: 'Madagascar',
  },
  {
    code: 'MW',
    phone: 265,
    name: 'Malawi',
  },
  {
    code: 'MY',
    phone: 60,
    name: 'Malásia',
  },
  {
    code: 'MV',
    phone: 960,
    name: 'Maldivas',
  },
  {
    code: 'ML',
    phone: 223,
    name: 'Mali',
  },
  {
    code: 'MT',
    phone: 356,
    name: 'Malta',
  },
  {
    code: 'MH',
    phone: 692,
    name: 'Ilhas Marshall',
  },
  {
    code: 'MQ',
    phone: 596,
    name: 'Martinica',
  },
  {
    code: 'MR',
    phone: 222,
    name: 'Mauritânia',
  },
  {
    code: 'MU',
    phone: 230,
    name: 'Maurício',
  },
  {
    code: 'YT',
    phone: 269,
    name: 'Mayotte',
  },
  {
    code: 'MX',
    phone: 52,
    name: 'México',
  },
  {
    code: 'FM',
    phone: 691,
    name: 'Micronésia',
  },
  {
    code: 'MD',
    phone: 373,
    name: 'Moldova',
  },
  {
    code: 'MC',
    phone: 377,
    name: 'Mônaco',
  },
  {
    code: 'MN',
    phone: 976,
    name: 'Mongólia',
  },
  {
    code: 'MS',
    phone: 1664,
    name: 'Montserrat',
  },
  {
    code: 'MA',
    phone: 212,
    name: 'Marrocos',
  },
  {
    code: 'MZ',
    phone: 258,
    name: 'Moçambique',
  },
  {
    code: 'MM',
    phone: 95,
    name: 'Birmânia',
  },
  {
    code: 'NA',
    phone: 264,
    name: 'Namíbia',
  },
  {
    code: 'NR',
    phone: 674,
    name: 'Nauru',
  },
  {
    code: 'NP',
    phone: 977,
    name: 'Nepal',
  },
  {
    code: 'NL',
    phone: 31,
    name: 'Holanda',
  },
  {
    code: 'AN',
    phone: 599,
    name: 'Antilhas Holandesas',
  },
  {
    code: 'NC',
    phone: 687,
    name: 'Nova Caledônia',
  },
  {
    code: 'NZ',
    phone: 64,
    name: 'Nova Zelândia',
  },
  {
    code: 'NI',
    phone: 505,
    name: 'Nicarágua',
  },
  {
    code: 'NE',
    phone: 227,
    name: 'Niger',
  },
  {
    code: 'NG',
    phone: 234,
    name: 'Nigéria',
  },
  {
    code: 'NU',
    phone: 683,
    name: 'Niue',
  },
  {
    code: 'NF',
    phone: 672,
    name: 'Ilha Norfolk',
  },
  {
    code: 'MP',
    phone: 1670,
    name: 'Ilhas Marianas do Norte',
  },
  {
    code: 'NO',
    phone: 47,
    name: 'Noruega',
  },
  {
    code: 'OM',
    phone: 968,
    name: 'Omã',
  },
  {
    code: 'PK',
    phone: 92,
    name: 'Paquistão',
  },
  {
    code: 'PW',
    phone: 680,
    name: 'Palau',
  },
  {
    code: 'PS',
    phone: 970,
    name: 'Palestina',
  },
  {
    code: 'PA',
    phone: 507,
    name: 'Panamá',
  },
  {
    code: 'PG',
    phone: 675,
    name: 'Papua-Nova Guiné',
  },
  {
    code: 'PY',
    phone: 595,
    name: 'Paraguai',
  },
  {
    code: 'PE',
    phone: 51,
    name: 'Peru',
  },
  {
    code: 'PH',
    phone: 63,
    name: 'Filipinas',
  },
  {
    code: 'PN',
    phone: 672,
    name: 'Ilhas Picárnia',
  },
  {
    code: 'PL',
    phone: 48,
    name: 'Polônia',
  },
  {
    code: 'PT',
    phone: 351,
    name: 'Portugal',
  },
  {
    code: 'PR',
    phone: 1787,
    name: 'Porto Rico',
  },
  {
    code: 'QA',
    phone: 974,
    name: 'Catar',
  },
  {
    code: 'RE',
    phone: 262,
    name: 'Reunião',
  },
  {
    code: 'RO',
    phone: 40,
    name: 'Romênia',
  },
  {
    code: 'RU',
    phone: 70,
    name: 'Rússia',
  },
  {
    code: 'RW',
    phone: 250,
    name: 'Ruanda',
  },
  {
    code: 'SH',
    phone: 290,
    name: 'Santa Helena',
  },
  {
    code: 'KN',
    phone: 1869,
    name: 'São Cristóvão',
  },
  {
    code: 'LC',
    phone: 1758,
    name: 'Santa Lúcia',
  },
  {
    code: 'PM',
    phone: 508,
    name: 'São Pedro e Miquelon',
  },
  {
    code: 'VC',
    phone: 1784,
    name: 'São Vicente e Granadinas',
  },
  {
    code: 'WS',
    phone: 684,
    name: 'Samoa',
  },
  {
    code: 'SM',
    phone: 378,
    name: 'São Marino',
  },
  {
    code: 'ST',
    phone: 239,
    name: 'Sao Tomé e Príncipe',
  },
  {
    code: 'SA',
    phone: 966,
    name: 'Arábia Saudita',
  },
  {
    code: 'SN',
    phone: 221,
    name: 'Senegal',
  },
  {
    code: 'CS',
    phone: 381,
    name: 'Sérvia e Montenegro',
  },
  {
    code: 'SC',
    phone: 248,
    name: 'Seicheles',
  },
  {
    code: 'SL',
    phone: 232,
    name: 'República da Serra Leoa',
  },
  {
    code: 'SG',
    phone: 65,
    name: 'Singapura',
  },
  {
    code: 'SK',
    phone: 421,
    name: 'Eslováquia',
  },
  {
    code: 'SI',
    phone: 386,
    name: 'Eslovênia',
  },
  {
    code: 'SB',
    phone: 677,
    name: 'Ilhas Salomão',
  },
  {
    code: 'SO',
    phone: 252,
    name: 'Somália',
  },
  {
    code: 'ZA',
    phone: 27,
    name: 'África do Sul',
  },
  {
    code: 'GS',
    phone: 500,
    name: 'Ilhas Geórgia do Sul e Sandwich do Sul',
  },
  {
    code: 'ES',
    phone: 34,
    name: 'Espanha',
  },
  {
    code: 'LK',
    phone: 94,
    name: 'Sri Lanka',
  },
  {
    code: 'SD',
    phone: 249,
    name: 'Sudão',
  },
  {
    code: 'SR',
    phone: 597,
    name: 'Suriname',
  },
  {
    code: 'SJ',
    phone: 47,
    name: 'Esvalbarde',
  },
  {
    code: 'SZ',
    phone: 268,
    name: 'Suazilândia',
  },
  {
    code: 'SE',
    phone: 46,
    name: 'Suécia',
  },
  {
    code: 'CH',
    phone: 41,
    name: 'Suiça',
  },
  {
    code: 'SY',
    phone: 963,
    name: 'Síria',
  },
  {
    code: 'TW',
    phone: 886,
    name: 'Taiwan',
  },
  {
    code: 'TJ',
    phone: 992,
    name: 'Tajiquistão',
  },
  {
    code: 'TZ',
    phone: 255,
    name: 'Tanzânia',
  },
  {
    code: 'TH',
    phone: 66,
    name: 'Tailândia',
  },
  {
    code: 'TL',
    phone: 670,
    name: 'Timor-Leste',
  },
  {
    code: 'TG',
    phone: 228,
    name: 'Togo',
  },
  {
    code: 'TK',
    phone: 690,
    name: 'Toquelau',
  },
  {
    code: 'TO',
    phone: 676,
    name: 'Tonga',
  },
  {
    code: 'TT',
    phone: 1868,
    name: 'Trinidad e Tobago',
  },
  {
    code: 'TN',
    phone: 216,
    name: 'Tunísia',
  },
  {
    code: 'TR',
    phone: 90,
    name: 'Turquia',
  },
  {
    code: 'TM',
    phone: 7370,
    name: 'Turcomenistão',
  },
  {
    code: 'TC',
    phone: 1649,
    name: 'Ilhas Turks e Caicos',
  },
  {
    code: 'TV',
    phone: 688,
    name: 'Tuvalu',
  },
  {
    code: 'UG',
    phone: 256,
    name: 'Uganda',
  },
  {
    code: 'UA',
    phone: 380,
    name: 'Ucrânia',
  },
  {
    code: 'AE',
    phone: 971,
    name: 'Emirados Árabes',
  },
  {
    code: 'GB',
    phone: 44,
    name: 'Reino Unido',
  },
  {
    code: 'US',
    phone: 1,
    name: 'Estados Unidos',
  },
  {
    code: 'UM',
    phone: 1,
    name: 'Ilhas Menores Distantes dos Estados Unidos',
  },
  {
    code: 'UY',
    phone: 598,
    name: 'Uruguai',
  },
  {
    code: 'UZ',
    phone: 998,
    name: 'Uzbequistão',
  },
  {
    code: 'VU',
    phone: 678,
    name: 'Vanuatu',
  },
  {
    code: 'VE',
    phone: 58,
    name: 'Venezuela',
  },
  {
    code: 'VN',
    phone: 84,
    name: 'Vietnam',
  },
  {
    code: 'VG',
    phone: 1284,
    name: 'Ilhas Virgens Inglesas',
  },
  {
    code: 'VI',
    phone: 1340,
    name: 'Ilhas Virgens (USA)',
  },
  {
    code: 'WF',
    phone: 681,
    name: 'Wallis e Futuna',
  },
  {
    code: 'EH',
    phone: 212,
    name: 'Saara Ocidental',
  },
  {
    code: 'YE',
    phone: 967,
    name: 'Iêmen',
  },
  {
    code: 'ZM',
    phone: 260,
    name: 'Zâmbia',
  },
  {
    code: 'ZW',
    phone: 263,
    name: 'Zimbábue',
  },
];
