<template>
  <div>
    <div v-if="!disabled" class="cell-button-text-component" :class="{ 'cell-button-hover': hover }" @click="buttonClick(value)">
      {{ text }}
      <i class="icon fa" :class="[ icon ]" :title="[ title ]" :style="{ 'color': color }"></i>
    </div>
    <div v-else class="cell-button-text-component-disabled">
      {{ text }}
      <i class="icon fa" :class="[ icon ]" :title="[ title ]"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '#212529',
    },
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    hover: {
      type: Boolean,
      required: false,
    },
    value: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    buttonClick (value) {
      this.$emit('button', { ...value, name: this.name });
    },
  },
};
</script>

<style lang="scss" scoped>
.cell-button-text-component {
  text-align: center;
  padding: 4px 8px 2px 8px;
  i {
    font-size: 15px;
  }
}
.cell-button-text-component-disabled {
  text-align: center;
  padding: 4px 8px 2px 8px;
  i {
    font-size: 15px;
    color: #cccccc;
  }
}
.cell-button-hover:hover {
  background-color: red;
  color: white;
}

</style>
