<template>
  <div class="edit-item-component">
    <div class="edit-line">
      <h3 class="edit-id">{{ $t('invoices.details.events.id') }} <span>{{ item._id }}</span></h3>
        <ct-status-icon
          :iconSymbol="symbols[item.status]"
          :color="colors[item.status]"
          :description="statusTranslate[item.status]"
        />
    </div>

    <hr>

    <div class="edit-line kind">
      <span
        v-if="item.kind === 'RECHARGE'"
        class="legend legend-title modal-edit black-text">
        {{ $t('invoices.itemKinds.recharge') }}
      </span>

      <span
        v-if="item.kind === 'SHIPMENT'"
        class="legend legend-title modal-edit black-text">
        {{ $t('invoices.itemKinds.shipment') }}
      </span>

      <span
        v-if="item.kind === 'OPEY'"
        class="legend legend-title modal-edit black-text">
        {{ $t('invoices.itemKinds.opey') }}
      </span>

      <span
        v-if="item.kind === 'BILLET'"
        class="legend legend-title modal-edit black-text">
        {{ $t('invoices.itemKinds.billet') }}
      </span>

      <span
        v-if="item.kind === 'GIFTCARD'"
        class="legend legend-title modal-edit black-text">
        {{ $t('invoices.itemKinds.giftCard') }}
      </span>

      <span>
        <button
          v-if="isItemProcess"
          class="btn btn-xs btn-primary btn-modal-edit"
          :disabled="isItemReprocess"
          @click='reprocessItem'
        >
          <i class="icon fa fas fa-sync-alt fa-icon"></i>
          {{ $t('invoices.details.reprocess.labelItem') }}
        </button>
        <button
          v-if="!isItemProcess"
          class="btn btn-xs btn-primary btn-modal-edit"
          @click="processItem"
        >
          <i class="icon fa fas fa-sync-alt fa-icon"></i>
          {{ $t('invoices.details.process.labelItem') }}
        </button>
      </span>

    </div>

    <ct-edit-recharge-form
      v-if="item.kind === 'RECHARGE'"
      :item="item"
      :notEditing="notEditing"
      @save="save"
      @close="close"
      @reset="reset"
      :key="componentKey"
    ></ct-edit-recharge-form>

    <ct-edit-shipment-form
      v-if="item.kind === 'SHIPMENT'"
      :item="item"
      :notEditing="notEditing"
      @save="save"
      @close="close"
      @reset="reset"
      :key="componentKey"
    ></ct-edit-shipment-form>

    <ct-edit-pix-form
      v-if="item.kind === 'PIX'"
      :item="item"
      :notEditing="notEditing"
      @save="save"
      @close="close"
      @reset="reset"
      :key="componentKey"
    ></ct-edit-pix-form>

    <ct-edit-opey-recharge-form
      v-if="item.kind === 'OPEY'"
      :item="item"
      :notEditing="notEditing"
      @save="save"
      @close="close"
      @reset="reset"
      :key="componentKey"
    ></ct-edit-opey-recharge-form>

    <ct-edit-billet-form
      v-if="item.kind === 'BILLET'"
      :item="item"
      :notEditing="notEditing"
      @save="save"
      @close="close"
      @reset="reset"
      :key="componentKey"
    ></ct-edit-billet-form>

    <ct-edit-gift-card-form
      v-if="item.kind === 'GIFTCARD'"
      :item="item"
      :notEditing="true"
      @save="save"
      @close="close"
      @reset="reset"
      :key="componentKey"
    ></ct-edit-gift-card-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CtEditRechargeForm from '@/components/Invoices/InvoiceDetails/EditItem/RechargeForm.vue';
import CtEditShipmentForm from '@/components/Invoices/InvoiceDetails/EditItem/ShipmentForm.vue';
import CtEditPixForm from '@/components/Invoices/InvoiceDetails/EditItem/PixForm.vue';
import CtEditOpeyRechargeForm from '@/components/Invoices/InvoiceDetails/EditItem/OpeyRechargeForm.vue';
import CtEditBilletForm from '@/components/Invoices/InvoiceDetails/EditItem/BilletForm.vue';
import CtEditGiftCardForm from '@/components/Invoices/InvoiceDetails/EditItem/GiftCardForm.vue';
import CtStatusIcon from '@/components/Dashboard/StatusIcon';

export default {
  name: 'EditItemModal',
  components: {
    CtEditRechargeForm,
    CtEditShipmentForm,
    CtEditOpeyRechargeForm,
    CtEditBilletForm,
    CtEditGiftCardForm,
    CtEditPixForm,
    CtStatusIcon,
  },
  data () {
    return {
      colors: {
        NEW: 'orange',
        EXPIRED: 'black',
        PENDING: 'blue',
        PAID: 'lime',
        OVERPAID: 'yellow',
        UNDERPAID: 'grey',
        PROCESSING: 'aqua',
        FINISHED: 'green',
        REVERSAL: 'indigo',
        CANCELLED: 'red',
        OPEN: 'orange',
        ERROR: 'pink',
        WAITING: 'aqua',
        PAID_EXPIRED: 'black',
        APPROVING_PAID_EXPIRED: 'black',
        APPROVING: 'purple',
        APPROVED: 'lime',
        PARTIAL: 'indigo',
      },
      symbols: {
        NEW: 'fas fa-star',
        EXPIRED: 'fas fa-stopwatch',
        PENDING: 'fas fa-spinner',
        PAID: 'fas fa-check',
        OVERPAID: 'fas fa-exclamation',
        UNDERPAID: 'fas fa-unlink',
        PROCESSING: 'fas fa-ellipsis-h',
        FINISHED: 'fas fa-check-double',
        REVERSAL: 'fas fa-exchange-alt',
        CANCELLED: 'fas fa-times',
        OPEN: 'fas fa-star',
        ERROR: 'fa fa-bug',
        WAITING: 'fas fa-exclamation-circle',
        PAID_EXPIRED: 'fas fa-stopwatch',
        APPROVING_PAID_EXPIRED: 'far fa-calendar-check',
        APPROVING: 'fas fa-hourglass-half',
        APPROVED: 'fas fa-thumbs-up',
        PARTIAL: 'fa fa-not-equal',
      },
      statusTranslate: {
        NEW: this.$t('invoices.listStatus.NEW'),
        EXPIRED: this.$t('invoices.listStatus.EXPIRED'),
        PENDING: this.$t('invoices.listStatus.PENDING'),
        PAID: this.$t('invoices.listStatus.PAID'),
        OVERPAID: this.$t('invoices.listStatus.OVERPAID'),
        UNDERPAID: this.$t('invoices.listStatus.UNDERPAID'),
        PROCESSING: this.$t('invoices.listStatus.PROCESSING'),
        FINISHED: this.$t('invoices.listStatus.FINISHED'),
        REVERSAL: this.$t('invoices.listStatus.REVERSAL'),
        CANCELLED: this.$t('invoices.listStatus.CANCELLED'),
        OPEN: this.$t('invoices.listStatus.OPEN'),
        ERROR: this.$t('invoices.listStatus.ERROR'),
        WAITING: this.$t('invoices.listStatusSecondary.WAITING'),
        PAID_EXPIRED: this.$t('invoices.listStatusSecondary.PAID_EXPIRED'),
        APPROVING_PAID_EXPIRED: this.$t('invoices.listStatusSecondary.APPROVING_PAID_EXPIRED'),
        APPROVING: this.$t('invoices.listStatusSecondary.APPROVING'),
        APPROVED: this.$t('invoices.listStatusSecondary.APPROVED'),
        PARTIAL: this.$t('invoices.listStatusSecondary.PARTIAL'),
      },
      componentKey: 0,
    };
  },
  props: {
    invoiceId: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    isItemReprocess: {
      type: Boolean,
      required: true,
      default: false,
    },
    isItemProcess: {
      type: Boolean,
      required: true,
      default: false,
    },
    notEditing: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions([
      'editInvoiceItem',
    ]),
    async reprocessItem () {
      await this.$emit('reprocessItem');
    },
    async processItem () {
      await this.$emit('processItem');
    },
    async close () {
      await this.$emit('close');
    },
    async reset () {
      this.componentKey += 1;
      await this.$emit('close');
    },
    async save (data) {
      try {
        await this.editInvoiceItem({ id: this.invoiceId, itemId: this.item._id, data });
        this.$notify({
          type: 'success',
          title: this.$t('invoices.details.editItem.notificationSuccessTitle'),
          text: this.$t('invoices.details.editItem.notificationSuccessMessage'),
        });
        this.$emit('save');
      } catch (error) {
        const errorMsg = error.response.data.error
          ? error.response.data.error[0].message
          : this.$t('invoices.details.editItem.notificationErrorTitle');
        this.$notify({
          type: 'error',
          title: errorMsg,
          text: this.$t('invoices.details.editItem.notificationErrorMessage'),
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/themes/color-admin/_variable.scss";

.edit-item-component {
  .edit-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    &.kind {
      margin-bottom: 20px;
    }
    .edit-id {
      font-weight: 300;
      margin-bottom: 0px;
      span {
        color: $body_text_color
      }

      @media only screen and (max-width: $grid-sm-max) {
        font-size: 18px;
      }
    }
  }
}
</style>
