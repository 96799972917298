<template>
  <div class="reports-widget-component">
    <ct-panel :title="$t('reports.form.title')" :enableReload="false">
      <div slot="panel-content">
        <ct-reports-form />
      </div>
    </ct-panel>
  </div>
</template>

<script>
import CtPanel from '@/components/Dashboard/Panel.vue';
import CtReportsForm from '@/components/Reports/Form.vue';

export default {
  name: 'ReportsWidget',
  components: {
    CtPanel,
    CtReportsForm,
  },
};
</script>

<style lang="scss" scoped>
.reports-widget-component {
  .status-filters {
    padding-left: 10px;
  }
}
</style>
