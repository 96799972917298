<script>
export default {
  props: {
    id: {
      type: String,
      default: function () {
        return 'checkbox-id-' + this._uid;
      },
    },
    name: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onInput () {
      this.$emit('input', !this.checked);
      this.$emit('change', !this.checked);
    },
  },
  watch: {
    value: function (newVal) {
      if (!this.disabled && !this.readonly) {
        this.checked = newVal;
      }
    },
  },
  data () {
    return {
      checked: this.value,
    };
  },
};
</script>

<template>
  <div class="checkbox-component" :disabled="disabled" :readonly="readonly">
    <input type="checkbox"
           :id="id"
           :name="name"
           :value="checked"
           :class="className"
           :required="required"
           @input="onInput"
           :checked="checked"
           :disabled="disabled"
           :readonly="readonly">
    <label :for="id">
      <slot name="input-box">
                <span class="input-box">
                    <svg class="input-box-tick" viewBox="0 0 16 16">
                        <path fill="none" d="M1.7,7.8l3.8,3.4l9-8.8"></path>
                    </svg>
                </span>
      </slot>
      <slot></slot>
    </label>
  </div>
</template>

<style lang="scss">
  @import '@/themes/color-admin/_ct_variable.scss';

  .checkbox-component {
    > label {
      font-size: 1em;
      font-weight: bold;
    }
    > input {
      opacity: 0;
      position: absolute;
      cursor: pointer;

      + label > .input-box {
        cursor: pointer;
        display: inline-block;
        border-radius: 3px;
        margin: 0 5px 0 0;
        padding: 0px 1px 2px 2px;
        width: 1.4em;
        height: 1.4em;
        background: #ddd;
        overflow: hidden;
        vertical-align: -20%;
        user-select: none;
        > .input-box-tick {
          width: 100%;
          height: 100%;
          > path {

            opacity: 0;
            stroke: #fff;
            stroke-width: 3.0px;
            stroke-dashoffset: 10;
            stroke-dasharray: 20;
            transition: stroke-dashoffset 0.15s ease-in;
          }
        }
      }
      &:hover:not(:checked) + label > .input-box {
        border: solid 2px $primary-color;
        border-radius: 5px;
      }

      &:checked + label > .input-box {
        background-color: $primary-color;
        color: #fff;
        > .input-box-tick > path {
          opacity: 1;
          stroke-dashoffset: 4;
        }
      }
      &[disabled]  {
        cursor: default;
        &:hover + label > .input-box{
          border: solid 2px $border-color;
        }
        &:checked + label > .input-box {
          border: solid 2px $primary-color;
          background-color: $primary-color;
          > .input-box-tick > path {
            opacity: 0.6;
          }
        }
        + label > .input-box {
          background-color: $border-color;
          cursor: default;
          opacity: 0.6;
        }
      }
    }
  }
</style>
