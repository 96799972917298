<template>
  <div class="settingss-widget-component">
    <ct-panel
      :title="$t('settings.title')"
      :reloading="isLoading"
      :actionButtons="actionButtons"
      @button-click="buttonClick('create')"
      @reload="updateSettingsList"
      v-if="showPanel"
    >
      <b-row slot="panel-content">
        <b-col cols="12" sm="12" md="12" lg="10" order-lg="1" order-md="2" order="2">
          <ct-data-grid
            :filters="filters"
            :table="list | settingsFormat(listSort, listColumns, listStatusColumns)"
            :pagination="listPaginate"
            :actionButtons="actionButtons"
            :emptyTableMessage="$t('dataGrid.emptyTableMessage')"
            :showModalPrint="showModalPrint"
            :printListing="listPrint | settingsFormat(listSort, listColumns, listStatusColumns)"
            :modalSubtitle="$t('settings.title')"
            :filtersPrint="listFilters"
            @sort="changeSort"
            @search="searchChange"
            @filter="changeFilter"
            @print="printClick"
            @page="changePage"
            @action="actionClick"
            @selected="selectRows"
            @button="buttonClick"
            @doubleClick="doubleClickRow"
          ></ct-data-grid>
        </b-col>
        <b-col
          cols="12"
          sm="12"
          md="12"
          lg="2"
          order-lg="2"
          order-md="1"
          order="1"
          class="status-filters"
        >
          <b-row>
            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="PENDING"
                :title="$t('settings.status.PENDING')"
                color="blue"
                :active="verifyStateFilter('PENDING')"
                icon="fa-spinner"
              />
            </b-col>
            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="ACTIVED"
                :title="$t('settings.status.ACTIVED')"
                color="green"
                :active="verifyStateFilter('ACTIVED')"
                icon="fa-check-circle"
              />
            </b-col>
            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="DISABLED"
                :title="$t('settings.status.DISABLED')"
                color="red"
                :active="verifyStateFilter('DISABLED')"
                icon="fa-ban"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </ct-panel>

    <ct-modal-view
      :styleContent="{padding: '10px'}"
      :title="$t('formSettings.title')"
      :show="showSettings"
      @close="toggleSettings"
      size="large"
    >
      <ct-settings-form slot="body" :settings="settings" @close="toggleSettings" />
    </ct-modal-view>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CtDataGrid from '@/components/Shared/DataGrid/DataGrid.vue';
import CtPanel from '@/components/Dashboard/Panel.vue';
import CtModalView from '@/components/Shared/ModalView.vue';
import CtWidgetStatFilter from '@/components/Dashboard/WidgetStatFilter.vue';
import CtSettingsForm from './Form';
import settingsFormat from '@/filters/settingsFormat';
import Settings from '@/model/Settings';

export default {
  name: 'SettingsWidget',
  components: {
    CtDataGrid,
    CtPanel,
    CtModalView,
    CtWidgetStatFilter,
    CtSettingsForm,
  },
  filters: {
    settingsFormat,
  },
  computed: {
    ...mapState({
      list: state => state.settings.listSettings,
      listPrint: state => state.settings.listSettingsPrint,
      listPaginate: state => state.settings.listSettingsPaginate,
      listSort: state => state.settings.listSettingsSort,
      listFilters: state => state.settings.listSettingsFilters,
      listSearch: state => state.settings.listSettingsSearch,
    }),
  },
  methods: {
    ...mapActions([
      'addSettingsListFilter',
      'removeSettingsListFilter',
      'getSettingsList',
      'setSettingsListSort',
      'setSettingsListSearch',
      'setSettingsListPage',
    ]),
    async updateSettingsList () {
      try {
        this.isLoading = true;
        await this.getSettingsList();
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    filterResults (v) {
      if (!v.activated) {
        this.addSettingsListFilter(v.value);
      } else {
        this.removeSettingsListFilter(v.value);
      }
    },
    verifyStateFilter (filter) {
      if (this.listFilters.indexOf(filter) !== -1) {
        return true;
      }
      return false;
    },
    printClick (event) {
      const isPrint = true;
      this.getSettingsList(isPrint);
    },
    toggleModalPrint () {
      this.showModalPrint = !this.showModalPrint;
    },
    reloadPanel () {
      this.toggleReload();
    },
    toggleReload () {
      this.panel = !this.panel;
    },
    removePanel () {
      this.showPanel = false;
    },
    searchEnterPress (value) {},
    changeFilter (filter) {},
    actionClick (action) {
      this.buttonClick(action);
    },
    searchChange (search) {
      this.setSettingsListSearch(search);
    },
    changeSort (header) {
      this.setSettingsListSort(header);
    },
    changePage (page) {
      this.setSettingsListPage(page);
    },
    buttonClick (button) {
      if (button === 'create') {
        this.settings = new Settings();
        this.toggleSettings();
      }
    },
    selectRows (rows) {},
    toggleSettings () {
      this.showSettings = !this.showSettings;
      if (!this.showSettings) {
        this.settings = new Settings();
      }
    },
    doubleClickRow (id) {
      if (id) {
        const settingsItem = this.getStateSettingsFromId(id);
        this.settings = settingsItem ? new Settings(settingsItem) : {};
        if (this.settings) {
          this.toggleSettings();
        }
      }
    },
    getStateSettingsFromId (id) {
      if (this.list && id) {
        const index = this.list.data.findIndex((item) => {
          return item._id &&
            item._id === id;
        });

        if (index >= 0) {
          return this.list.data[index];
        }
        return {};
      }
    },
  },
  async created () {
    this.filters.filterTextCurrent = this.listSearch;
    this.getSettingsList();
  },
  data () {
    return {
      settings: new Settings(),
      isLoading: false,
      showPanel: true,
      showModalPrint: false,
      showSettings: false,
      filters: {
        inputSearchText: this.$t('dataGrid.inputSearchText'),
        items: [],
        enableFilterText: true,
        enablePrint: true,
        filterTextCurrent: {},
      },
      actionButtons: [
        {
          name: 'create',
          title: this.$t('settings.create'),
          color: 'blue',
          icon: 'fa-plus',
        },
      ],
      listColumns: {
        _id: this.$t('settings.listColumns._id'),
        name: this.$t('settings.listColumns.name'),
        status: this.$t('settings.listColumns.status'),
        enable: this.$t('settings.listColumns.enable'),
        disable: this.$t('settings.listColumns.disable'),
        createdAt: this.$t('settings.listColumns.createdAt'),
      },
      listStatusColumns: [
        {
          name: '',
          text: this.$t('settings.status.ALL'),
        },
        {
          name: 'PENDING',
          text: this.$t('settings.status.PENDING'),
        },
        {
          name: 'ACTIVED',
          text: this.$t('settings.status.ACTIVED'),
        },
        {
          name: 'DISABLED',
          text: this.$t('settings.status.DISABLED'),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.settingss-widget-component {
  .status-filters {
    padding-left: 10px;
  }
}
</style>
