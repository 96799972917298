import { isInternal, isAdmin } from '../../../helpers/auth.helper';

export default {
  isLogged (state) {
    return state.isLogged;
  },

  getCurrentUser (state) {
    return state.user;
  },
  isEnabled2fa (state) {
    return state.user.isEnabled2fa;
  },
  isInternal (state) {
    if (!state.user || !state.user.roles) return false;

    return isInternal(state.user);
  },
  isAdmin (state) {
    if (!state.user || !state.user.roles) return false;

    return isAdmin(state.user);
  },
};
