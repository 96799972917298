<template>
  <div class="invoiceReferrals-widget-component">
    <ct-panel
      :title="$t('transactions.title')"
      :reloading="isLoading"
      @reload="updateTransactionsList"
      :isFiltersChecked="isFiltersChecked"
      :actionButtons="actionButtons"
      :isShowFilters="showFilters"
      @button-click="actionClick"
      @filter="updateFilters"
      :enableFilterButton="true"
      v-if="showPanel"
    >
      <b-row slot="panel-content">
        <b-col cols="12" sm="12" md="12" :lg="showFilters ? '10' : '12'" order-lg="1" order-md="2" order="2">
          <ct-data-grid
            :filters="filters"
            :table="list | transactionFormat(listSort, listColumns, { user, statusNames: listStatusColumns })"
            :pagination="listPaginate"
            :actionButtons="actionButtons"
            :emptyTableMessage="$t('dataGrid.emptyTableMessage')"
            :showModalPrint="showModalPrint"
            :printListing="listPrint | transactionFormat(listSort, listColumns, { user, statusNames: listStatusColumns })"
            :modalSubtitle="$t('transactions.title')"
            :filtersPrint="listFilters"
            @sort="changeSort"
            @search="searchChange"
            @filter="changeFilter"
            @print="printClick"
            @page="changePage"
            @action="actionClick"
            @selected="selectRows"
            @button="buttonClick"
            @doubleClick="doubleClickRow"
          ></ct-data-grid>
        </b-col>
        <b-col
          cols="12"
          sm="12"
          md="12"
          lg="2"
          order-lg="2"
          order-md="1"
          order="1"
          class="status-filters"
          v-if="showFilters"
        >
          <b-row>
            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="OPEN"
                :title="$t('transactions.listStatus.OPEN')"
                :value="listStatistics.OPEN || 0"
                color="orange"
                :active="verifyStateFilter('OPEN')"
                icon="fa-star"
              />
            </b-col>

            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="UNCONFIRMED"
                :title="$t('transactions.listStatus.UNCONFIRMED')"
                :value="listStatistics.UNCONFIRMED || 0"
                color="blue"
                :active="verifyStateFilter('UNCONFIRMED')"
                icon="fa-spinner"
              />
            </b-col>

            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="CONFIRMED"
                :title="$t('transactions.listStatus.CONFIRMED')"
                :value="listStatistics.CONFIRMED || 0"
                color="green"
                :active="verifyStateFilter('CONFIRMED')"
                icon="fa-check-double"
              />
            </b-col>

            <b-col cols="12">
              <ct-widget-stat-filter
                @widgetFilter="filterResults"
                valueFilter="CANCELLED"
                :title="$t('transactions.listStatus.CANCELLED')"
                color="red"
                :value="listStatistics.CANCELLED || 0"
                :active="verifyStateFilter('CANCELLED')"
                icon="fa-ban"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </ct-panel>

    <ct-modal-view
      key="modal-events-view"
      :title="$t('transactions.modal.titleEvents')"
      :show="showModalEvents"
      @close="closeModalEvents()"
      size="small"
    >
      <ct-modal-table-events slot="body" :events="events" />
    </ct-modal-view>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import config from '../../config';
import CtDataGrid from '@/components/Shared/DataGrid/DataGrid.vue';
import CtPanel from '@/components/Dashboard/Panel.vue';
import transactionFormat from '@/filters/transactionFormat';
import CtWidgetStatFilter from '@/components/Dashboard/WidgetStatFilter.vue';
import CtModalTableEvents from '@/components/Invoices/ModalTableEvents';
import CtModalView from '@/components/Shared/ModalView.vue';

export default {
  name: 'TransactionsWidget',
  components: {
    CtDataGrid,
    CtPanel,
    CtWidgetStatFilter,
    CtModalTableEvents,
    CtModalView,
  },
  filters: {
    transactionFormat,
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      list: state => state.transactions.list,
      listPrint: state => state.transactions.listPrint,
      listPaginate: state => state.transactions.listPaginate,
      listSort: state => state.transactions.listSort,
      listFilters: state => state.transactions.listFilters,
      listSearch: state => state.transactions.listSearch,
      listStatistics: state => state.transactions.listStatistics,
      showFilters: state => state.transactions.showFilters,
      authUser: state => (state.auth.user ? state.auth.user : []),
    }),
    isFiltersChecked () {
      return !!(this.listFilters && this.listFilters.length);
    },
  },
  methods: {
    ...mapActions([
      'addTransactionsListFilter',
      'removeTransactionsListFilter',
      'getTransactionsList',
      'setTransactionsListSort',
      'setTransactionsListSearch',
      'setTransactionsListPage',
      'getListTransactionsStatistics',
      'setTransactionsShowFilters',
      'resendTransaction',
    ]),
    updateFilters (isFiltered) {
      this.setTransactionsShowFilters(isFiltered);
    },
    copyToClipboard (value) {
      const el = document.createElement('textarea');
      el.value = value;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },
    async updateTransactionsList (background = false) {
      try {
        this.isLoading = !background;
        await this.getTransactionsList();
        await this.getListTransactionsStatistics();
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    verifyStateFilter (filter) {
      if (this.listFilters.indexOf(filter) !== -1) {
        return true;
      }
      return false;
    },
    filterResults (v) {
      if (!v.activated) {
        return this.addTransactionsListFilter(v.value);
      }

      this.removeTransactionsListFilter(v.value);
    },
    printClick (event) {
      const isPrint = true;
      this.getTransactionsList(isPrint);
    },
    toggleModalPrint () {
      this.showModalPrint = !this.showModalPrint;
    },
    reloadPanel () {
      this.toggleReload();
    },
    toggleReload () {
      this.panel = !this.panel;
    },
    removePanel () {
      this.showPanel = false;
    },
    searchEnterPress (value) {},
    changeFilter (filter) {},
    actionClick (action) {
      this.buttonClick(action);
    },
    async searchChange (search) {
      await this.setTransactionsListSearch(search);
      await this.updateTransactionsList();
    },
    async changeSort (header) {
      await this.setTransactionsListSort(header);
      await this.updateTransactionsList();
    },
    changePage (page) {
      this.setTransactionsListPage(page);
    },
    buttonClick (button) {
      if (button && button.action === 'invoice') {
        this.$router.push({ path: `/invoices/${button.data.invoiceId}` });
      }

      if (button && button.action === 'events') {
        this.events = button.data.events;
        this.showModalEvents = true;
      }

      if (button && button.action === 'resend') {
        this.resend(button.data._id);
      }
    },
    closeModalEvents () {
      this.events = [];
      this.showModalEvents = false;
    },
    async resend (id) {
      const result = await this.$alert({
        title: this.$t('transactions.resendTransaction.title'),
        type: 'success',
        showCancelButton: true,
        confirmButtonText: this.$t('transactions.resendTransaction.confirmText'),
        cancelButtonText: this.$t('transactions.resendTransaction.cancelText'),
        confirmButtonColor: '#32a932',
        reverseButtons: true,
      });
      if (result.value) {
        try {
          await this.resendTransaction(id);
          this.$notify({
            type: 'success',
            title: this.$t('transactions.resendTransaction.notificationSuccessTitle'),
            text: this.$t('transactions.resendTransaction.notificationSuccessMessage'),
          });
          this.updateTransactionsList();
        } catch (error) {
          this.$notify({
            type: 'error',
            title: this.$t('transactions.resendTransaction.notificationErrorTitle'),
            text: error.response.data.message,
          });
        }
      }
    },
    selectRows (rows) {},
    doubleClickRow (id) {
    },
  },
  async created () {
    this.filters.filterTextCurrent = this.listSearch;
    await this.updateTransactionsList();
    this.interval = setInterval(async () => {
      await this.updateTransactionsList(true);
    }, config.reloadInterval);
  },
  async beforeDestroy () {
    clearInterval(this.interval);
  },
  data () {
    return {
      interval: false,
      isLoading: false,
      showPanel: true,
      showModalPrint: false,
      showModalEvents: false,
      events: [],
      filters: {
        inputSearchText: this.$t('dataGrid.inputSearchText'),
        items: [],
        enableFilterText: true,
        enablePrint: true,
        filterTextCurrent: {},
      },
      actionButtons: [
      ],
      listColumns: {
        _id: this.$t('transactions.listColumns._id'),
        amount: this.$t('transactions.listColumns.amount'),
        kind: this.$t('transactions.listColumns.kind'),
        network: this.$t('transactions.listColumns.network'),
        crypto: this.$t('transactions.listColumns.crypto'),
        address: this.$t('transactions.listColumns.address'),
        transferTxId: this.$t('transactions.listColumns.transferTxId'),
        transferFeeTxId: this.$t('transactions.listColumns.transferFeeTxId'),
        confirmations: this.$t('transactions.listColumns.confirmations'),
        exchangeStatus: this.$t('transactions.listColumns.exchangeStatus'),
        exchangePrice: this.$t('transactions.listColumns.exchangePrice'),
        invoice: this.$t('transactions.listColumns.invoice'),
        reexecute: this.$t('transactions.listColumns.reexecute'),
        confirmedAt: this.$t('transactions.listColumns.confirmedAt'),
        createdAt: this.$t('transactions.listColumns.createdAt'),
        receivedAt: this.$t('transactions.listColumns.receivedAt'),
        events: this.$t('transactions.listColumns.events'),
      },

      listStatusColumns: [
        {
          name: 'OPEN',
          text: this.$t('transactions.listStatus.OPEN'),
          icon: 'fas fa-star',
          color: 'orange',
        },
        {
          name: 'UNCONFIRMED',
          text: this.$t('transactions.listStatus.UNCONFIRMED'),
          icon: 'fas fa-spinner',
          color: 'blue',
        },
        {
          name: 'CONFIRMED',
          text: this.$t('transactions.listStatus.CONFIRMED'),
          icon: 'fas fa-check-double',
          color: 'green',
        },
        {
          name: 'CANCELLED',
          text: this.$t('transactions.listStatus.CANCELLED'),
          icon: 'fas fa-times',
          color: 'red',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.invoiceReferrals-widget-component {
  .status-filters {
    padding-left: 10px;
  }
}
</style>
