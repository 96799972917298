import XLSX from 'xlsx';

export default {
  async download (data, colSizes, sheetName = 'Sheet1', fileName = 'download.xlsx') {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);

    ws['!cols'] = [];
    colSizes.map(size => {
      ws['!cols'].push({ wch: size });
    });

    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  },
};
