export const round = (number, precision = 2) => {
  const factor = Math.pow(10, precision);
  const tempNumber = number * factor;
  const roundedTempNumber = Math.round(tempNumber);
  return roundedTempNumber / factor;
};

export const formatMoney = (value, options = {}) => {
  value = Number(value);
  let precision = options.precision || 2;
  const valuesWithFixed = value.toFixed(precision).split('.');
  const values = value.toFixed(precision).split('.');
  const lenPrecision = valuesWithFixed.length > 1 ? valuesWithFixed[1].length : 0;
  const realLen = values.length > 1 ? values[1].length : 0;
  precision = lenPrecision <= precision ? lenPrecision : precision;
  const regexPrecision = new RegExp(`(\\d+)((\\d){${precision}})`, 'g');

  const numberRound = realLen > precision ? round(value, precision) : value.toFixed(precision);
  const numberFormated = numberRound.toString().replace(/[,]+/g, '')
    .replace(/[.]+/g, '')
    .replace(regexPrecision, `$1${precision > 0 ? (options.decimal || ',') : '|'}$2`)
    .replace(/(\d)(?=(\d{3})+,)/g, `$1${options.thousand || '.'}`);

  return (options.format || '%v').replace('%v', numberFormated).replace('%s', options.symbol || '');
};

export const unformat = (value = 0, decimal = ',') => {
  const regex = new RegExp(`[^0-9-${decimal}]`, ['g']);
  const unformatted = parseFloat(
    ('' + value)
      .replace(/\((?=\d+)(.*)\)/, '-$1')
      .replace(regex, '')
      .replace(decimal, '.'),
  );

  // This will fail silently which may cause trouble, let's wait and see:
  return !isNaN(unformatted) ? unformatted : 0;
};

export const toNumber = (string, precision = 2) => {
  if (!string) return 0;

  const clearString = string.replace(/\./g, '').replace(',', '.');
  if (isNaN(clearString)) return 0;

  return round(Number(clearString), precision);
};

/**
 *
 * @param {String|Number} number
 * @param {Number} precision
 * @param {String} symbol
 * @return {String}
 */
export const toString = (number, precision = 2, symbol = '') => {
  if (typeof number === 'string') {
    number = Number(number);
  }

  if (typeof number !== 'number') return '0';

  const value = number.toFixed(precision)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+,)/g, '$1.');
  if (symbol !== '') symbol += ' ';

  return `${symbol}${value}`;
};

/**
 *
 * @param {String} currency
 * @param {String} defaultSymbol
 * @return {String}
 */
export const getSymbolByCurrency = (currency, defaultSymbol = '') => {
  if (currency === 'BRL') return 'R$';
  if (currency === 'BTC') return 'BTC';
  return defaultSymbol;
};

/**
 *
 * @param {String} currency
 * @param {Number} defaultPrecision
 * @return {Number}
 */
export const getPrecisionByCurrency = (currency, defaultPrecision = 8) => {
  if (currency === 'BRL') return 2;
  if (currency === 'BTC') return 8;
  return defaultPrecision;
};

export default {
  round,
  toNumber,
  toString,
  getSymbolByCurrency,
  getPrecisionByCurrency,
  unformat,
  formatMoney,
};
