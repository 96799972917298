import AuthService from './../services/AuthService';
import store from './../store/store';

export default {

  async hasAuthorization (to, from, next) {
    const hasAuthorization = await AuthService.hasAuthorization(store, to.meta.resource, to.meta.permission);
    if (!hasAuthorization) {
      return next({
        name: 'login',
      });
    }
    next();
  },
};
