<template>
<div class="form-two-fa-component container">
  <div class="row">
    <div class="col-12">
      <p> {{ $t('rfReporter.form2FA.message', { date }) }}</p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-6">
      <div class="form-group">
        <input type="text" class="form-control" v-model="code" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <button type="button" class="btn btn-primary" aria-label="" @click.prevent="generateDeclaration">
        <i class="fas fa-spin fa-circle-notch" v-if="isLoading"></i>
        {{ isLoading ? 'Gerando...' : 'Gerar' }}
      </button>
      <button type="button" class="btn btn-light" @click.prevent="$emit('close')" aria-label="">
        {{ $t('rfReporter.form2FA.closeButton') }}
      </button>
    </div>
  </div>
</div>
</template>

<script>
import DeclarationService from '@/services/DeclarationService';

export default {
  name: 'FormTwoFa',
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    code: null,
    isLoading: false,
  }),
  methods: {
    async generateDeclaration () {
      if (this.code) {
        this.isLoading = true;
        try {
          await DeclarationService.create(this.date, this.code);
          this.showSucessMessage();
          this.$emit('close');
        } catch (e) {
          if (e.response.data.errors && e.response.data.errors.length > 0) {
            return this.$emit('close', { error: true, errors: e.response.data.errors });
          }

          this.showAlertError(e.response.data.message);
        } finally {
          this.isLoading = false;
        }
      }
    },
    showSucessMessage () {
      this.$notify({
        title: this.$t('rfReporter.emitNotesModal.messageSuccessTitle'),
        type: 'success',
        text: this.$t('rfReporter.emitNotesModal.messageSuccessText'),
      });
    },
    showAlertError (msg) {
      this.$notify({
        title: this.$t('rfReporter.emitNotesModal.messageErrorTitle'),
        type: 'error',
        text: msg,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-two-fa-component {
  padding: 20px 20px;
  text-align: center;
  p {
    font-size: 16px;
  }

  input {
    text-align: center;
  }
}
</style>
