const formatValue = (list, sort, columnsNames, statusNames) => {
  const table = {
    headers: [
      {
        name: '_id',
        text: columnsNames && '_id' in columnsNames ? columnsNames._id : '',
        width: '4%',
        order: sort && '_id' in sort ? sort._id : true,
        isFilter: true,
        isPrint: true,
      },
      {
        name: 'name',
        text: columnsNames && 'name' in columnsNames ? columnsNames.name : '',
        width: '18%',
        order: sort && 'name' in sort ? sort.name : true,
        isFilter: true,
        isPrint: true,
        isSpecialSearch: true,
      },
      {
        name: 'createdAt',
        text: columnsNames && 'createdAt' in columnsNames ? columnsNames.createdAt : '',
        width: '12%',
        order: sort && 'createdAt' in sort ? sort.createdAt : true,
        isPrint: true,
      },
      {
        name: 'status',
        text: columnsNames && 'status' in columnsNames ? columnsNames.status : '',
        width: '6%',
        order: sort && 'status' in sort ? sort.status : true,
        isPrint: true,
      },
    ],
    lines: [],
    linesPrint: [],
    listStatus: statusNames,
  };

  if (list && list.data && list.data.length > 0) {
    for (let i = 0; i < list.data.length; i++) {
      const createdAt = new Date(list.data[i].createdAt);

      let statusIcon = '';
      let statusColor = '';
      switch (list.data[i].status) {
        case 'PENDING':
          statusIcon = 'fa fa-spinner';
          statusColor = 'blue';
          break;
        case 'ACTIVED':
          statusIcon = 'fa fa-check-circle';
          statusColor = 'green';
          break;
        case 'DISABLED':
          statusIcon = 'fa fa-ban';
          statusColor = 'red';
          break;
      }

      const settingsId = {
        type: 'id',
        text: list.data[i]._id,
        textAlign: 'center',
      };
      let statusName = statusNames.filter((status) => {
        return status.name === list.data[i].status;
      });
      statusName = statusName.shift();

      const status = {
        name: 'status-settings-' + list.data[i]._id,
        type: 'status-icon',
        color: statusColor,
        icon: statusIcon,
        text: statusName && statusName.text ? statusName.text : '',
      };

      const statusPrint = {
        ...status,
        text: list.data[i].status,
      };

      table.lines.push([
        settingsId,
        list.data[i].name,
        createdAt.toLocaleDateString('pt-BR'),
        status,
      ]);

      table.linesPrint.push([
        list.data[i]._id,
        list.data[i].name,
        createdAt.toLocaleDateString('pt-BR'),
        statusPrint,
      ]);
    }
  }

  return table;
};

export default formatValue;
