export const validStatus = {
  NEW: 'NEW',
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  BLOCKED: 'BLOCKED',
};

export default class User {
  constructor (data = {}) {
    this._id = data._id;
    this.name = data.name || null;
    this.email = data.email || null;
    this.password = data.password || null;
    this.code = data.code || null;
    this.referralFee = data.referralFee || '';
    this.referralId = data.referralId || null;
    if (data.referral && data.referralId) {
      const label = `${data.referral.name} <${data.referral.email}>`;
      this.referralId = { label, value: data.referral._id };
    }

    this.country = data.country || 'BR';
    this.partnerCode = data.partnerCode || null;
    this.secret = data.secret || null;
    this.kind = data.kind || null;
    this.document = data.document || null;
    this.documentSecondary = data.documentSecondary || null;
    this.phoneCode = data.phoneCode || '55';
    this.phone = data.phone || null;
    this.phoneSecondary = data.phoneSecondary || null;
    this.birth = data.birth || null;
    this.language = data.language || 'pt';
    this.roles = data.roles || ['user'];
    this.status = data.status || validStatus.NEW;
    this.statusDescription = data.statusDescription || null;
    this.isEnabled2fa = data.isEnabled2fa || false;
    this.enabledReferralSmsNotification = data.hasOwnProperty('enabledReferralSmsNotification') ? data.enabledReferralSmsNotification : true;
    this.createdAt = data.createdAt || null;
    this.updatedAt = data.updatedAt || null;
    this.deletedAt = data.deletedAt || null;
    this.events = data.events || [];
    this.addresses = data.addresses && data.addresses[0] ? data.addresses.map(a => ({ ...a })) : [{
      zipCode: null,
      address: null,
      number: null,
      complement: null,
      district: null,
      city: null,
      uf: null,
      country: null,
    }];
    this.events = data.events || [];
    this.changeSettings = data.changeSettings || false;
    this.settings = data.settings ? { ...data.settings, fees: data.settings.fees || [] } : {
      createdAt: null,
      restriction: {},
      fees: [],
    };
  }
}
