export default {
  app: {
    name: 'Pague com Bitcoin',
    nameFormat: 'Pague com ',
  },
  header: {
    logout: 'Sair',
    profile: 'Meus Dados',
    password: 'Alterar Senha',
    search: 'O que você procura?',
  },
  currencies: {
    BTC: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      suffix: '',
      masked: false,
    },
    BRL: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      suffix: '',
      masked: false,
    },
  },
  login: {
    title1: 'Administrativo',
    caption: {
      part1: 'Área exclusiva à operadores',
      link: 'https://paguecombitcoin.com/',
      linkText: 'registre-se',
      part2: 'e comece agora!',
    },
    brandText: 'Administrativo',
    subtitleLight: '| Acesso restrito',
    formPlaceholder: {
      email: 'E-mail',
      password: 'Senha',
      key: 'Key',
      secret: ' Secret',
      provider: ' Provider',
    },
    forgetPassword: 'Esqueceu a senha?',
    register: {
      text: 'Problemas com login? entre em contato com o suporte.',
    },
    twoFA: {
      title: 'Autenticação em dois passos',
      formPlaceholder: {
        accessCode: 'Digite seu código de acesso',
      },
    },
    btnSendText: 'Entrar',
    backStep: 'Voltar para o login',
    terms: {
      text: 'Ao usar e acessar essa plataforma você aceita os nossos',
      linkText: 'termos e condições de uso',
    },
    msgErrorForm: {
      notEmail: 'Este campo deve ser preechido com um e-mail válido',
      required: 'Campo obrigatório',
      invalidLogin: 'E-mail ou senha estão incorretos. Verifique seus dados e tente novamente.',
      unauthorized: 'Acesso não autorizado',
      twoFA: 'Código de acesso inválido ou incorreto.',
    },
  },
  dashboard: {
    title: 'Dashboard',
    subtitle: 'Serviços',
    panel: {
      buttonNewValue: 'Novo',
      filter: {
        search: {
          placeHolder: 'Procure neste painel...',
        },
      },
      paginate: {
        per_page: 'Items por página',
        page: 'Página',
      },
    },
    widgetStat: {
      filter: {
        ctaText: 'Filtrar',
        ctaTextActivated: 'Remover',
        ctaTextMobile: 'Filtrar',
        ctaTextActivatedMobile: 'Remover',
      },
    },
    alertKeys: {
      title: 'Chaves Ativas',
    },
    formLight: {
      addProvider: 'Cadastre uma Chave',
      providerSelect: 'Selecione um Provedor',
      key: 'Chave de Acesso',
      secret: 'Secret',
      password: 'Senha',
      submit: 'Enviar',
    },
    contentButtonText: 'Selecione',
    player: {
      title: 'Sacado',
      kind: 'Tipo',
      name: 'Nome',
      email: 'E-mail',
      documentPerson: 'CPF',
      documentLegal: 'CNPJ',
    },
  },
  notification: {
    notificationErrorTitle: 'Erro ao salvar a cobrança',
    notificationErrorMessage: 'Preencha os campos adequadamente',
  },
  formUser: {
    formTitle: 'Dados do usuário',
    title: 'Usuário',
    name: 'Nome',
    email: 'E-mail',
    password: 'Senha',
    roles: 'Grupos',
    referralId: 'Indicado por',
    kind: 'Tipo',
    country: 'País',
    documents: 'Documentos',
    documentPerson: 'CPF',
    documentSecondaryPerson: 'RG',
    documentLegal: 'CNPJ',
    documentSecondaryLegal: 'Inscrição Estadual',
    phoneCode: 'Cód.',
    phone: 'Celular',
    phoneSecondary: 'Telefone secundário',
    motherName: 'Nome da mãe',
    birth: 'Data de Nascimento',
    language: 'Idioma',
    referralFee: 'Taxa de indicação (%)',
    complementTitle: 'Dados Cadastrais',
    addressesTitle: 'Endereço',
    status: 'Status',
    statusDescription: 'Descrição',
    isEnabled2fa: '2fa Habilitado',
    enabledReferralSmsNotification: 'Notificações via SMS',
    enabledReferralSmsNotificationLabel: 'Pagamento de referral',
    rules: 'Grupos',
    checkBox: 'Receber alertas via SMS',
    createdAt: 'Cadastrado Em',
    updatedAt: 'Atualizado Em',
    cancel: 'Fechar',
    submit: 'Salvar',
    notificationErrorTitle: 'Erro ao salvar o usuário',
    notificationErrorMessage: 'Não foi possível salvar o usuário, verifique o preenchimento dos campos',
    tab1: 'Dados do usuário',
    tab2: 'Configuração',
    faenable: '2fa habilitado',
    fadisable: '2fa desabilitado',
    statusItens: {
      NEW: 'Novo',
      PENDING: 'Pendente',
      PROCESSING: 'Em Processamento',
      ACTIVE: 'Ativo',
      REQUEST_VALIDATE: 'Aguardando Validação',
      VALID: 'Validado',
      BLOCKED: 'Bloqueado',
    },
    masks: {
      documentTypeF: '###.###.###-##',
      documentTypeJ: '##.###.###/####-##',
      telephone: '(##) ####-####',
      telephone1: '(##) #####-####',
      zipCode: '#####-###',
    },
    labelPassword: 'Senha',
    buttonPassword: 'Trocar senha',
    label2FA: '2FA',
    enable2FA: 'Habilitar 2FA',
    disable2FA: 'Desabilitar 2FA',
    isEnabled2Fa: '2fa habilitado',
    changeSettings: 'Atualizar configurações',
  },
  datePicker: {
    days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    pickers: ['próximos 7 dias', 'próximos 30 dias', 'anteriores 7 dias', 'anteriores 30 dias'],
    placeholder: {
      date: 'Data',
      dateRange: 'Período',
    },
  },
  dataGrid: {
    emptyTableMessage: 'Nenhum registro encontrado',
    inputSearchText: 'Busque nesta tabela',
    removeFilter: 'Remover filtro',
    modalPrint: {
      title: 'Impressão',
      modalTitle: 'Listagem de ',
      filters: 'Filtros',
      createdAt: 'Criado em:',
      status: {
        NEW: 'Novo',
        OPEN: 'Aberto',
        ERROR: 'Erro',
        EXPIRED: 'Expirado',
        PENDING: 'Pendente',
        PAID: 'Pago',
        OVERPAID: 'Valor Excedido',
        UNDERPAID: 'Valor Parcial',
        PROCESSING: 'Pocessando',
        ACTIVE: 'Ativo',
        ACTIVED: 'Ativo',
        REQUEST_VALIDATE: 'Aguardando Validação',
        VALID: 'Validado',
        BLOCKED: 'Bloqueado',
        DISABLED: 'Desabilitado',
        FINISHED: 'Finalizado',
        REVERSAL: 'Estornado',
        CANCELLED: 'Cancelado',
      },
      filtersNames: {
        NEW: 'Novos',
        ACTIVE: 'Ativos',
        ACTIVED: 'Ativos',
        VALID: 'Validados',
        EXPIRED: 'Expirados',
        PENDING: 'Pendentes',
        REQUEST_VALIDATE: 'Aguardando Validação',
        PAID: 'Pagos',
        OVERPAID: 'Valor Excedido',
        UNDERPAID: 'Valor Parcial',
        PROCESSING: 'Pocessando',
        FINISHED: 'Finalizados',
        REVERSAL: 'Estornados',
        CANCELLED: 'Cancelados',
        BLOCKED: 'Bloqueados',
        DISABLED: 'Desabilitados',
        F: 'Física',
        J: 'Jurídica',
      },
      noFilters: 'Nenhum filtro selecionado',
    },
  },
  address: {
    address: 'Endereço',
    number: 'Número',
    district: 'Bairro',
    zipCode: 'CEP',
    complement: 'Complemento',
    city: 'Cidade',
    country: 'País',
    uf: 'Estado',
  },
  status: {
    open: 'Aberto',
    pending: 'Pendente',
    finished: 'Finalizado',
    cancelled: 'Cancelado',
    registered: 'Registrado',
    waitQuittance: 'Aguardando compensação',
  },

  paginate: {
    previous: 'Anterior',
    next: 'Próximo',
  },

  reports: {
    title: 'Relatórios',
    form: {
      title: 'Relatórios',
      subtitle: 'Selecione as configurações do relatório',
      selectPeriod: 'Selecione o período',
      filterBy: 'Filtrar por',
      from: 'De',
      to: 'Até',
      order: 'Pedido',
      orderPlaceholder: 'Digite o id do pedido para filtrar',
      user: 'Usuário',
      userPlaceholder: 'Digite o e-mail do usuário para filtrar',
      submit: 'Emitir Relatório',
      kind: 'Tipo de serviço',
      billet: 'Boleto',
      giftCard: 'GiftCard',
      pix: 'PIX',
      phoneRecharge: 'Recarga de celular',
      shipment: 'Conversão de Bitcoin',
      opeyRecharge: 'Recarga Opey',
      status: 'Status do serviço',
    },
    alert: {
      emptyReports: {
        title: 'Erro ao gerar relatório!',
        text: 'Nenhum registro encontrado.',
      },
    },
  },

  users: {
    newUser: 'Novo Usuário',
    title: 'Usuários',
    subtitle: 'Listagem de usuários',
    listColumns: {
      _id: 'ID',
      name: 'Nome',
      email: 'E-mail',
      kind: 'Tipo',
      document: 'Documento',
      createdAt: 'Criado em',
      status: 'Status',
      referralId: 'Afiliado Id',
    },
    events: {
      title: 'Eventos',
      createdAt: 'Data de criação',
      message: 'Mensagem',
      notFound: 'Nenhum evento encontrado',
    },
    status: {
      ALL: 'Todos',
      NEW: 'Novo',
      PENDING: 'Pendente',
      PROCESSING: 'Em Processamento',
      ACTIVE: 'Ativo',
      REQUEST_VALIDATE: 'Aguardando Validação',
      VALID: 'Validado',
      BLOCKED: 'Bloqueado',
    },
  },
  user: {
    title: 'Usuário',
    subtitle: 'Meu perfil',
    notificationSuccessTitle: 'Sucesso!',
    notificationSuccessMessage: 'Usuário atualizado com sucesso!',
    notificationSuccessMessage2: 'Usuário cadastrado com sucesso!',
  },
  newPassword: {
    title: 'Alterar Senha',
    formPlaceholder: {
      password: 'Senha atual',
      newPassword: 'Nova senha',
      repeatNewPassword: 'Confirme a nova senha',
      token2Fa: 'Token 2FA',
    },
    msgErrorForm: {
      required: 'Campo obrigatório',
      twoFA: 'Código de acesso inválido ou incorreto.',
    },
    cancel: 'Fechar',
    submit: 'Salvar',
    saving: 'Salvando, Aguarde...',
    notificationErrorTitle: 'Erro ao salvar a nova senha',
    notificationErrorMessage: 'Preencha os campos corretamente',
    notificationErrorDifferentPasswords: 'As senhas digitadas não correspondem',
    notificationSuccessTitle: 'Sucesso',
    notificationSuccessText: 'Senha alterada com sucesso',
  },
  invoices: {
    title: 'Ordens',
    filters: {
      requiresIntervention: 'Necessita de intervenção',
    },
    listColumns: {
      marker: '',
      _id: 'ID',
      createdAt: 'Data',
      services: 'Serviços',
      email: 'E-mail',
      user: 'Id Usuário',
      fiatAmount: 'Valor BRL',
      cryptoPrice: 'Cotação (BRL)',
      cryptoAmount: 'Valor Cripto',
      status: 'Status',
      statusSecondary: 'Ref',
      billet: 'Boleto',
      approveExpired: 'Aprovar',
      bill: 'NFSe',
      origin: 'Origem',
    },
    listStatus: {
      ALL: 'Todos',
      NEW: 'Novo',
      EXPIRED: 'Expirado',
      PENDING: 'Pendente',
      PAID: 'Pago',
      OVERPAID: 'Valor Excedido',
      UNDERPAID: 'Valor Parcial',
      PROCESSING: 'Processando',
      FINISHED: 'Finalizado',
      REVERSAL: 'Estornado',
      CANCELLED: 'Cancelado',
      OPEN: 'Aberto',
      ERROR: 'Erro',
    },
    listStatusSecondary: {
      WAITING: 'Aguardando',
      PAID_EXPIRED: 'Pagamento Expirado',
      APPROVING_PAID_EXPIRED: 'Aguardando aprovação de pagamento expirado',
      PENDING_BY_USER_NOT_VALIDED: 'Pagamento pendente por usuário não validado',
      ERROR: 'Erro',
      APPROVING: 'Aprovando',
      APPROVED: 'Aprovado',
      PARTIAL: 'Parcial',
    },
    listBillStatus: {
      OPEN: 'Aguardando processamento',
      PROCESSING: 'Processando',
      FINISHED: 'Finalizada',
      CANCELLED: 'Cancelada',
      ERROR: 'Erro',
    },
    itemKinds: {
      billet: 'Boleto',
      recharge: 'Recarga',
      shipment: 'Conversão de Bitcoins',
      opey: 'Recarga Opey',
      giftCard: 'Gift Card',
    },
    itemStatus: {
      OPEN: 'Aberto',
      PENDING: 'Pendente',
      CANCELLED: 'Cancelado',
      FINISHED: 'Finalizado',
      PROCESSING: 'Processando',
      ERROR: 'Erro',
    },
    details: {
      title: 'Descrição do Pedido',
      subtitle: 'Pedido',
      subtitleBill: 'Nota fiscal',
      origin: 'Origem',
      network: 'Rede',
      user: 'Usuário',
      cellphone: 'Celular',
      document: 'Documento',
      bankData: 'Dados bancários',
      createdAt: 'Criado em',
      total: 'Total',
      totalFiat: 'Total Reais',
      priceInvoice: 'Cotação do Pedido',
      copyTitle: 'Copiar',
      withouReferralTitle: 'Não possue um invoice de referral',
      modalReferralTitle: 'Dados de pagamento de referral',
      invalidDescription: 'Descrição inválida',
      reversalSaveError: 'Erro ao salvar a solicitação de estorno',
      reversalDescriptionPlaceholder: 'Inclua uma descrição para o estorno',
      cancelConfirm: {
        title: 'Atenção! Ao realizar o estorno o status deste invoice será alterado para "Extornado"',
        confirmText: 'Realizar estorno',
        cancelText: 'Fechar',
      },
      editItem: {
        title: 'Edição de item',
        labelItem: 'Editar item',
        confirm: 'Salvar',
        cancel: 'Cancelar',
        notificationSuccessTitle: 'Sucesso',
        notificationSuccessMessage: 'Item editado com sucesso',
        notificationErrorTitle: 'Erro ao editar o item',
        notificationErrorMessage: 'Preencha os campos adequadamente',
      },
      reprocess: {
        messageErrorTitle: 'Erro na solicitação',
        label: 'Ações',
        labelItem: 'Reprocessar item',
        title: 'Deseja realmente solicitar o reprocessamento do item?',
        confirmText: 'Reprocessar item',
        cancelText: 'Fechar',
        messageSuccessTitle: 'Sucesso',
        messageSuccess: 'Item adicionado com sucesso para reprocessamento',
      },
      process: {
        messageErrorTitle: 'Erro na solicitação',
        labelItem: 'Processar pagamento',
        title: 'Deseja realmente solicitar o processamento do pagamento do item?',
        confirmText: 'Processar pagamento',
        cancelText: 'Fechar',
        messageSuccessTitle: 'Sucesso',
        messageSuccess: 'Item adicionado com sucesso para processamento de pagamento',
      },
      billet: {
        title: 'Boletos',
        id: 'ID',
        digitableLine: 'Linha Digitável',
        dueDate: 'Vencimento',
        status: 'Status',
        amount: 'Valor a pagar',
        fee: 'Taxa',
        value: 'Valor',
        discount: 'Desconto',
        increase: 'Acréscimo',
        totalAmount: 'Valor Total',
        ProofOfPayment: 'Comprovante',
        noBilletFound: 'Nenhum boleto encontrado',
      },
      recharge: {
        title: 'Recargas',
        id: 'ID',
        operator: 'Operadora',
        ddd: 'DDD',
        number: 'Número',
        status: 'Status',
        amount: 'Valor da recarga',
        fee: 'Taxa',
        ProofOfPayment: 'Comprovante',
        noRechargeFound: 'Nenhuma recarga encontrada',
      },
      shipment: {
        title: 'Conversão de Bitcoin',
        id: 'ID',
        quantity: 'Quantidade',
        status: 'Status',
        bankData: 'Dados Bancários',
        amount: 'Valor a pagar',
        fee: 'Taxa',
        ProofOfPayment: 'Comprovante',
        noShipmentFound: 'Nenhuma conversão encontrada',
      },
      pix: {
        title: 'PIX',
        id: 'ID',
        key: 'Chave',
        brcode: 'BrCode',
        quantity: 'Quantidade',
        status: 'Status',
        bankData: 'Dados Bancários',
        amount: 'Valor a pagar',
        fee: 'Taxa',
        ProofOfPayment: 'Comprovante',
        noShipmentFound: 'Nenhuma conversão encontrada',
      },
      opeyRecharge: {
        title: 'Recarga Opey',
        id: 'ID',
        name: 'Nome',
        status: 'Status',
        document: 'Documento',
        amount: 'Valor da recarga',
        fee: 'Taxa',
        ProofOfPayment: 'Comprovante',
      },
      giftCard: {
        title: 'Gift Cards',
        print: 'Imprimir',
        id: 'ID',
        operator: 'Operadora',
        card: 'Cartão',
        pin: 'PIN',
        lote: 'Lote',
        serie: 'Série',
        status: 'Status',
        amount: 'Valor',
        fee: 'Taxa',
        finish: 'Finalizar',
      },
      wallet: {
        title: 'Transações',
        ordersTitle: 'Ordens da Transação',
        id: 'Id',
        type: 'Tipo',
        address: 'Endereço',
        titleReversal: 'Estorno',
        txid: 'TxId',
        crypto: 'Moeda',
        confirm: 'Confirmações',
        quantity: 'Quantidade',
        transfer: 'Transf.',
        yes: 'Sim',
        no: 'Não',
        notFound: 'Nenhum pagamento encontrado',
        totalReceived: 'Recebido',
        totalReversal: 'Confirmado',
        totalBalance: 'Saldo',
        exchangeStatus: 'Execução',
        exchangeReleased: 'Execução Liberada',
        actions: {
          title: 'Ações',
          verifyTransactions: {
            labelItem: 'Verificar atualização',
            messageSuccessTitle: 'Sucesso',
            messageSuccess: 'Transaçoes atualizadas, o pedido foi atualizado',
            messageErrorTitle: 'Erro na solicitação',
          },
          editTransaction: 'Editar',
          releaseTransaction: 'Liberar execução',
          showTransactionOrders: 'Visualizar Ordens',
          successReleased: 'Liberação executada com sucesso',
          errorReleased: 'Não foi possível fazer a liberação',
          errorUpdateOrders: 'Erro ao atualizar listagem de ordens',
        },
        orders: {
          headers: {
            id: 'Id',
            status: 'Status',
            market: 'Mercado',
            amount: 'Qtd',
            amountExecuted: 'Qtd Executada',
            price: 'Preço',
            priceExecuted: 'Preço Executado',
            fee: 'Taxa',
            providerId: 'Id Ref',
            executedAt: 'Executado',
            actions: 'Ações',
          },
          recheckOrder: 'Atualizar Ordens',
          successRecheck: 'Ordem atualizada com sucesso',
          errorRecheck: 'Não foi possível atualizar a ordem',
        },
      },
      events: {
        id: 'ID',
        title: 'Eventos',
        createdAt: 'Data',
        message: 'Mensagem',
        notFound: 'Nenhum evento registrado',
        notEvent: 'Nenhum evento encontrado',
        labelItem: 'Listar eventos',
      },
      description: {
        title: 'Descrição de Pedido',
        noDescription: 'Nenhuma descrição adicionada.',
        clickToEdit: 'Clique no ícone de edição para adicioná-la.',
        saveSuccessTitle: 'Sucesso',
        saveSuccessDesc: 'Descrição atualizada com sucesso',
      },
      returnButton: 'Voltar à listagem de pedidos',
      reversed: 'Estornado',
      reversal: 'Estornar Valor',
      approveExpired: 'Aprovar',
      confirmReversal: 'Atenção! Ao realizar o estorno o status deste pedido será alterado para "Estornado"',
      confirmOk: 'Realizar estorno',
      confirmCancel: 'Fechar',
      reversalNotifyTitle: 'Estorno efetuado com sucesso',
      emitBill: 'Emitir',
    },
    modalReceipt: {
      title: 'Upload de comprovante',
      subtitle: 'Ordem',
      receiptNumber: 'Número do recibo',
      uploadSuccessTitle: 'Sucesso',
      uploadSuccessDesc: 'Upload de arquivo feito com sucesso',
      uploadErrorTitle: 'Erro',
      cancel: 'Fechar',
      upload: 'Upload',
      fileRequired: 'O arquivo de comprovante é obrigatório',
      numberRequired: 'O número do recibo é obritagório',
      downloadReceiptForProviderHandler: 'Baixar do Provedor',
      downloadReceiptSuccessTitle: 'Sucesso',
      downloadReceiptSuccessDesc: 'Donwload do recibo feito com sucesso',
      downloadReceiptErrorTitle: 'Erro',
    },
    modalFinishGiftCard: {
      title: 'Finalização de Gift Card',
      subtitle: 'Ordem',
      pin: 'PIN',
      lote: 'Lote',
      serie: 'Serie',
      successTitle: 'Sucesso',
      successDesc: 'Item finalizado com sucesso',
      errorTitle: 'Erro',
      cancel: 'Fechar',
      send: 'Enviar',
      pinRequired: 'O PIN é obrigatório',
    },
    modalBankData: {
      title: 'Pedido ID',
      description: 'Descrição',
      value: 'Valor',
      isThirdParty: 'Para Terceiro',
      isThirdPartyYes: 'Sim',
      isThirdPartyNo: 'Não',
      thirdPartyEmail: 'E-mail do terceiro',
      thirdPartyPurpose: 'Finalidade',
      key: 'Chave',
      brcode: 'BrCode',
      name: 'Nome',
      document: 'Documento',
      bankName: 'Banco',
      agency: 'Agência',
      account: 'Conta',
      kindAccount: 'Tipo',
      kindAccountCC: 'Conta Corrente',
      kindAccountCP: 'Conta Poupança',
      operation: 'Operação',
      close: 'Fechar',
    },
    modalReferralData: {
      errorMessage: 'Erro ao tentar buscar dados do invoice de referral',
      errorTitleReexecuteReferral: 'Erro ao tentar reexecutar referral',
      sucessTitleReexecuteReferral: 'Sucesso',
      sucessMessageReexecuteReferral: 'Referral adicionado com sucesso para reprocessamento',
      title: 'Pedido ID',
      description: 'Descrição',
      value: 'Valor',
      userName: 'Nome',
      userEmail: 'E-mail',
      userId: 'Id do usuário',
      status: 'Status',
      fee: 'Taxa (%)',
      document: 'Documento',
      kind: 'Tipo',
      close: 'Fechar',
      invoice: 'Pedido',
      statusText: {
        OPEN: 'Aberto',
        PROCESSING: 'Processando',
        ERROR: 'Erro',
        FINISHED: 'Finalizado',
      },
      reexecute: 'Reexecutar',
      openInvoice: 'Visualizar',
    },
    approveExpired: {
      title: 'Deseja realmente aprovar esta ordem expirada?',
      confirmText: 'Aprovar Ordem',
      cancelText: 'Fechar',
      notificationErrorTitle: 'Erro ao tentar aprovar',
    },
    approveForAutoExecutableInvoice: {
      title: 'Deseja realmente aprovar esta ordem para ser executada automaticamente?',
      confirmText: 'Aprovar Ordem',
      cancelText: 'Fechar',
      notificationErrorTitle: 'Erro ao tentar aprovar',
    },
    emitBill: {
      title: 'Deseja realmente emitir esta nota fiscal?',
      confirmText: 'Emitir NFSe',
      cancelText: 'Fechar',
      notificationErrorTitle: 'Erro ao tentar emitir NFSe',
    },
    modalReversal: {
      title: 'Estorno',
      subtitle: 'Pedido',
      kind: 'Tipo de estorno',
      close: 'Cancelar',
      submit: 'Estornar',
      reversalValue: 'Valor a ser estornado',
      reversalFee: 'Taxa apurada',
      reversalBalance: 'Valor restante',
      availableBalance: 'Saldo disponível',
      crypto: {
        network: 'Rede',
        txId: 'TxId',
        address: 'Endereço',
      },
      fiat: {
        kind: 'Forma de estorno',
        amount: 'Valor',
        amountPlaceholder: 'Ex.: 100,00',
        fee: 'Taxa',
        shipment: {
          bank: 'Banco',
          agency: 'Agência',
          account: 'Conta',
          kind: 'Tipo',
          operation: 'Operação',
        },
        pix: {
          key: 'Chave Pix',
        },
        opey: {
          name: 'Nome',
          document: 'Documento',
        },
        recharge: {
          ddd: 'DDD',
          phone: 'Número',
          operator: 'Operadora',
        },
        giftCard: {
          operator: 'Operadora',
          card: 'Cartão',
        },
        billet: {
          digitableLine: 'Linha Digitável',
          dueDate: 'Vencimento',
          value: 'Valor',
          discount: 'Desconto',
          increase: 'Acréscimo',
        },
      },
    },
  },
  settings: {
    title: 'Configurações',
    subtitle: 'Configurações',
    create: 'Nova configuração',
    listColumns: {
      _id: 'Id',
      name: 'Nome',
      status: 'Status',
      createdAt: 'Data de criação',
      enable: 'Habilitar',
      disable: 'Desabilitar',
    },
    status: {
      ALL: 'Todas',
      PENDING: 'Pendente',
      ACTIVED: 'Ativa',
      DISABLED: 'Desabilitada',
    },
  },
  enable2FA: {
    title: 'Habilitar 2fa',
    titleHeader: 'Escaneie ou digite a chave no seu autenticador para habilitar o 2fa.',
    key: 'Chave',
    changeKey: 'Trocar chave',
    codeLabel: 'Para habilitar, insira o código 2fa',
    code: 'Código 2fa',
    cancel: 'Fechar',
    submit: 'Salvar',
    notificationSuccessTitle: 'Sucesso!',
    notificationSuccessMessage: '2FA habilitado com sucesso!',
    notificationErrorTitle: 'Erro!',
    notificationErrorMessage: 'Não foi possível habilitar o 2FA, verifique o código digitado',
    alert: 'Para habilitar a autenticação em dois passos(2fa), baixe o aplicativo de autenticação de sua preferência (ex: "Google Authenticator" ou "Authy"), escaneie o QR code ou digite a chave e insira o código 2fa gerado no aplicativo, no campo acima. Clique em Salvar para habilitar.',
  },
  disable2FA: {
    title: 'Desabilitar 2fa',
    codeLabel: 'Para desabilitar, insira o código 2fa',
    code: 'Código 2fa',
    cancel: 'Fechar',
    submit: 'Salvar',
    notificationSuccessTitle: 'Sucesso!',
    notificationSuccessMessage: '2FA desabilitado com sucesso!',
    notificationErrorTitle: 'Erro!',
    notificationErrorMessage: 'Não foi possível desabilitar o 2FA, verifique o código digitado',
    alert: 'Desabilitando o 2FA, você enfraquece a segurança de sua conta. Se esse é realmente seu desejo, volte a habilitá-lo assim que possível. Nosso conselho é manter a autenticação em dois passos(2FA) sempre ativada.',
  },
  formSettings: {
    formTitle: 'Configuração do sistema',
    title: 'Configurações',
    name: 'Nome',
    status: 'Status',
    fees: 'Taxa por volume (Spread)',
    max: 'Valor final',
    min: 'Valor inicial',
    spread: 'Spread (%)',
    restriction: 'Restrições',
    itemFees: 'Taxa por item',
    minRechargeValue: 'Valor minimo',
    minBilletValue: 'Valor minimo',
    minShipmentValue: 'Valor minimo',
    minPixValue: 'Valor minimo',
    minRechargeOpeyValue: 'Valor minimo',
    minGiftCardValue: 'Valor minimo',
    maxBilletValue: 'Valor máximo',
    maxRechargeValue: 'Valor máximo',
    maxShipmentValue: 'Valor máximo',
    maxPixValue: 'Valor máximo',
    maxRechargeOpeyValue: 'Valor máximo',
    maxGiftCardValue: 'Valor máximo',
    maxShipmentDailyLimitValue: 'Valor máximo diário',
    maxShipmentMonthlyLimitValue: 'Valor máximo mensal',
    rechargeTitle: 'Recarga de celular',
    pixTitle: 'Pix',
    opeyTitle: 'Recarga Opey',
    giftCardTitle: 'Gift Card',
    shipmentTitle: 'Conversão de Bitcoin',
    shipmentLimitsTitle: 'Limite para Transferências via TED e PIX',
    billetTitle: 'Pagamento de boleto',
    expireInvoice: 'Tempo de expiração de invoice (minutos)',
    maxPaymentOverpaidDifference: 'Diferença máxima para valores pagos a mais (OVERPAID)',
    maxPaymentUnderpaidDifference: 'Diferença máxima para valores pagos a menos (UNDERPAID)',
    cancel: 'Cancelar',
    submit: 'Salvar',
    notificationSuccessTitle: 'Sucesso',
    notificationSuccessMessage: 'Configuração cadastrada com sucesso!',
    notificationErrorTitle: 'Erro',
    notificationErrorMessage: 'Ocorreu um erro ao tentar salvar configuração',
    referralFee: 'Taxa de indicação (%)',
    tickerProvidersTitle: 'Provedor de Cotação',
    currentProvider: 'Provedor atual',
    statusServices: 'Ativar/Desativar os Serviços na Plataforma',
    services: {
      shipment: 'Conversão de Bitcoin',
      billet: 'Boleto',
      pix: 'Pix',
      recharge: 'Recarga de Celular',
      opey: 'Recarga Opey',
      giftcard: 'Gitcard',
    },
    providerMessages: {
      title: 'Provedor de Mensagens de Texto',
      field: 'Tipo atual',
    },
  },
  rfReporter: {
    title: 'Gerador de declaração',
    itensNotFound: 'Nenhum item foi encontrado dentro do período informado',
    form: {
      title: 'Selecione as configurações do relatório',
      id: 'ID',
      cryptoFee: 'Taxa (Cripto)',
      cryptoPrice: 'Cotação',
      fee: 'Taxa (FIAT)',
      fiatAmount: 'Valor (FIAT)',
      cryptoAmount: 'Valor (Cripto)',
      createdAt: 'Criado em',
      reference: 'Referência',
      client: 'Cliente',
      wallet: 'Carteira',
      detailedReport: 'Relatório detalhado',
      txId: 'TxId',
      nfsePending: 'Pendente de emissão de nfse',
      totalFeeTrades: 'Taxas apuradas',
      totalFeePendingTrades: 'Taxas pendentes',
      totalInvoices: 'Total de Pedidos',
      issueAllPendingInvoicesNfse: 'Emitir todas as nfse de pedidos pendentes',
      emitingAllPendingInvoicesNfse: 'Emitindo todas as nfse de pedidos pendentes',
      createdIn: 'Criado em',
      month: 'Mês',
      year: 'Ano',
      date: 'Data',
      labelDate: 'Selecione o ano e o mês:',
      submitButton: 'Emitir Relatório',
      issueInvoice: 'emitir nf',
      invoices: 'Pedidos',
      amount: 'Quantidade',
      nfseNumber: 'Nº NFSE',
      nfseIssued: 'NFSE Emitida',
      nfsePedding: 'NFSE Pendente',
      nfse: 'NFSE',
      statusIssued: 'Emitida',
      statusPending: 'Pendente',
      statusEmiting: 'Emitindo...',
      buttonGenerateDeclaration: 'Gerar arquivo de declaração da RF',
      buttonEmitingAlertRF: 'Emitindo arquivo...',
    },
    emitNotesModal: {
      messageTitle: 'Deseja realmente emitir todas as notas para esse período?',
      messageSingleTitle: 'Deseja realmente emitir esta nota?',
      submitButton: 'Emitir notas',
      closeButton: 'Cancelar',
      messageErrorTitle: 'Ocorreu um erro',
      messageErrorText: 'Erro na emissão solicitada, verifique se o(s) item(s) atendem os critérios necessários. Tente novamente mais tarde ou contate o suporte!',
      messageSuccessTitle: 'Declaração emitida com sucesso',
      messageSuccessText: 'Sua declaração foi gerada!',
    },
    generateNotesModal: {
      error: {
        messageTitle: 'Atenção!',
        message: 'É obrigatório que todas as notas estejam emitidas.',
      },
    },
    enable2FA: {
      title: 'Deseja prosseguir com a operação?',
      message: 'Para a emissão de notas ou relatórios, é obrigatório ativar o 2FA,',
      messageAction: 'Clique aqui',
      messageComplementary: 'para acessar a área "Meus dados" e ativá-lo.',
      closeButton: 'Ok',
    },
    form2FA: {
      message: 'Se deseja gerar o arquivo de declaração do mês {date}, digite o seu código 2FA abaixo:',
      closeButton: 'Fechar',
    },
  },
  declarations: {
    title: 'Declarações',
    subtitle: 'Listagem de Declarações',
    listColumns: {
      _id: 'ID',
      createdBy: 'Criado por',
      reference: 'Referência',
      version: 'Versão',
      year: 'Ano',
      month: 'Mês',
      file: 'Arquivo',
      createdAt: 'Criado em',
    },
  },
  validations: {
    messages: {
      billet: 'Linha digitável inválida',
    },
  },
  invoiceReferrals: {
    title: 'Pagamento de afiliados',
    subtitle: 'Listagem de pagamentos de afiliados',
    listColumns: {
      _id: 'ID',
      userName: 'Nome',
      userEmail: 'E-mail',
      value: 'Valor',
      origin: 'Origem',
      fee: 'Taxa (%)',
      status: 'Status',
      createdAt: 'Criado em',
      invoice: 'Pedido',
    },
    listStatus: {
      OPEN: 'Aberto',
      PROCESSING: 'Processando',
      ERROR: 'Erro',
      CANCELLED: 'Cancelado',
      FINISHED: 'Finalizado',
    },
  },
  transactionOrders: {
    title: 'Ordens em execução',
    subtitle: 'Listagem de ordens em execução',
    modal: {
      titleEvents: 'Eventos',
    },
    listColumns: {
      _id: 'ID',
      amount: 'Valor',
      amountExecuted: 'Valor executado',
      currencyPrimary: 'Moeda primária',
      currencySecondary: 'Moeda secundária',
      operation: 'Operação',
      status: 'Status',
      priceExecuted: 'Preço executado',
      createdAt: 'Criado em',
      executedAt: 'Excutado em',
      invoice: 'Pedido',
      reexecute: 'Reexecutar ordem',
      events: 'Eventos',
    },
    listStatus: {
      OPEN: 'Aberto',
      PROCESSING: 'Processando',
      ERROR: 'Erro',
      CANCELLED: 'Cancelado',
      FINISHED: 'Finalizado',
      PARTIAL: 'Parcial',
    },
    reexecuteOrder: {
      title: 'Deseja realmente reexecutar esta ordem com erro?',
      confirmText: 'Executar',
      cancelText: 'Fechar',
      notificationErrorTitle: 'Erro ao tentar executar',
      notificationSuccessTitle: 'Sucesso',
      notificationSuccessMessage: 'Ordem enviada para nova tentativa de execução',
    },
  },
  transactions: {
    title: 'Transações',
    subtitle: 'Listagem de transações',
    modal: {
      titleEvents: 'Eventos',
    },
    listColumns: {
      _id: 'ID',
      amount: 'Valor',
      kind: 'Tipo',
      network: 'Rede',
      crypto: 'Crypto',
      address: 'Endereço',
      transferTxId: 'TxId transferência',
      transferFeeTxId: 'TxId taxa transferência',
      confirmations: 'Confirmações',
      exchangeStatus: 'Status da exchange',
      exchangePrice: 'Preço da exchange',
      reexecute: 'Reenviar',
      invoice: 'Pedido',
      receivedAt: 'Recebido em',
      confirmedAt: 'Confirmado em',
      createdAt: 'Criado em',
      events: 'Eventos',
    },
    listStatus: {
      OPEN: 'Aberta',
      UNCONFIRMED: 'Sem confirmação',
      CONFIRMED: 'Confirmada',
      CANCELLED: 'Cancelada',
    },
    resendTransaction: {
      title: 'Deseja realmente renviar esta transação para a carteira principal?',
      confirmText: 'Reenviar',
      cancelText: 'Fechar',
      notificationErrorTitle: 'Erro ao tentar reenviar',
      notificationSuccessTitle: 'Sucesso',
      notificationSuccessMessage: 'Tentaiva de reenviar transação para carteira feita com sucesso!',
    },
  },
};
