import http from './HttpService';

export default {
  async create (userInfo) {
    const data = { ...userInfo };
    if (!data.changeSettings) {
      delete data.settings;
    }
    const res = await http().post('/api/v1/users', data);
    return res.data;
  },

  async update (userInfo) {
    const data = { ...userInfo };
    if (!data.changeSettings) {
      delete data.settings;
    }
    const res = await http().put('/api/v1/users/' + userInfo._id, data);
    return res.data;
  },

  async getUsersList ({ filters, search, limit, page, orderBy }) {
    try {
      let url = `/api/v1/users?page=${page}&limit=${limit}`;
      if (!page || limit === 0) {
        url = `/api/v1/users?limit=${limit}`;
      }

      if (filters && filters.length > 0) {
        const filtersQuery = JSON.stringify({
          field: 'status',
          operation: 'in',
          value: filters,
        });
        url += `&filters[]=${filtersQuery}&`;
      }
      const liked = ['name', 'email', 'document'];
      if (search && search.value && search.value !== '') {
        const filterSearch = JSON.stringify({
          field: search.field,
          operation: search.operation ? search.operation : liked.indexOf(search.field) >= 0 ? 'like' : '=',
          value: search.value,
        });
        url += `&filters[]=${filterSearch}`;
      }
      if (orderBy) {
        const key = Object.keys(orderBy)[0];
        url += `&orderBy=${key}&orderByDirection=${orderBy[key] || 'desc'}`;
      }

      const response = await http().get(url);
      return response.data || {
        data: [],
        meta: {},
      };
    } catch (error) {
      return error;
    }
  },

  async listStatistics () {
    const res = await http().get('/api/v1/users/statistics');
    return res.data;
  },

  async getUserForSelect ({ search }) {
    try {
      const listUsers = await this.getUsersList({ search, limit: 100, page: 1, orderBy: 'name' });
      return listUsers.data.map((user) => {
        return { value: user._id, label: user.name + ' <' + user.email + '>' };
      });
    } catch (e) {
      console.error(e);
      return false;
    }
  },

  async getPlayersForSelect () {
    const res = await http().get('/api/v1/users/players');
    return res.data.data.map((player) => {
      return { value: player, label: player.name + ' - ' + player.document };
    });
  },

  async changePassword (passwordInfo) {
    const res = await http().patch('/api/v1/users/password', passwordInfo);
    return res.data;
  },
};
