import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import VueI18n from 'vue-i18n';
import pt from './languages/pt';
import BootstrapVue from 'bootstrap-vue';
import VeeValidate, { Validator } from 'vee-validate';
import ptValidation from 'vee-validate/dist/locale/pt_BR';
import enValidation from 'vee-validate/dist/locale/en';
import { billetValidator } from './validators/billetValidator';
import AuthMiddleware from './middlewares/AuthMiddleware';
import AlertManager from './middlewares/AlertManager';
import { zipCodeValidator } from './validators/ZipCodeValidator';
import Notifications from 'vue-notification';
import './registerServiceWorker';
import '@citartech/core-ui/dist/core-ui.css';

// import CtNotification from './components/Shared/Notification';

Validator.extend('zipCode', zipCodeValidator);
Validator.extend('billet', billetValidator);

Vue.use(BootstrapVue);
Vue.use(AlertManager);
Vue.use(Notifications);

Vue.config.productionTip = process.env.NODE_ENV === 'production';

Vue.use(VueI18n);
const i18n = new VueI18n({ locale: 'pt', fallbackLocale: 'pt', messages: { pt: pt } });

Vue.use(VeeValidate, {
  i18nRootKey: 'validations',
  i18n,
  dictionary: {
    en: enValidation,
    pt: ptValidation,
  },
});
Vue.use(AuthMiddleware);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
