import Event from './Event';

const startEvents = (items) => {
  return items.map((item) => {
    return new Event(item);
  });
};

export default class Invoice {
  constructor (data = {}) {
    this._id = data._id;
    this.userId = data.userId || null;
    this.user = data.user || {};
    this.kind = data.kind || null;
    this.status = data.status || null;
    this.value = data.value || null;
    this.fee = data.fee || null;
    this.events = startEvents(data.events || []);
    this.createdAt = data.createdAt || null;
    this.deletedAt = data.deletedAt || null;
  }
}
