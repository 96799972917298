import Transactionservice from '../../../services/TransactionService';

export default {
  async getTransactionsList ({ commit, state }, isPrint = false) {
    try {
      const list = await Transactionservice.getList({
        filters: state.listFilters,
        search: state.listSearch,
        page: isPrint ? 0 : (state.listPaginate.currentPage || 1),
        limit: isPrint ? 0 : (state.listPaginate.perPage || 10),
        orderBy: state.listSort,
      });

      if (isPrint) {
        commit('SET_LIST_TRANSACTION_PRINT', list);
      } else {
        commit('SET_LIST_TRANSACTION', list);
        if (list.meta) {
          commit('SET_TRANSACTION_LIST_TOTAL_PAGES', list.meta.pagination.totalPages);
        }
      }
    } catch (error) {
      console.error(error);
    }
  },

  async getListTransactionsStatistics ({ commit }) {
    const list = await Transactionservice.listStatistics();
    if (list) {
      commit('SET_LIST_TRANSACTION_STATISTICS', list.data);
    }
  },

  async resendTransaction (_, id) {
    const res = await Transactionservice.resendTransaction(id);
    return res;
  },

  addTransactionsListFilter ({ commit, dispatch }, value) {
    commit('SET_TRANSACTION_LIST_PAGE', 1);
    commit('ADD_TRANSACTION_LIST_FILTER', value);
    dispatch('getTransactionsList');
  },

  setTransactionsShowFilters ({ commit }, value) {
    commit('SET_INVOICE_REFERRALS_SHOW_FILTERS', value);
  },

  removeTransactionsListFilter ({ commit, dispatch }, value) {
    commit('SET_TRANSACTION_LIST_PAGE', 1);
    commit('REMOVE_TRANSACTION_LIST_FILTER', value);
    dispatch('getTransactionsList');
  },

  setTransactionsListPage ({ commit, dispatch }, value) {
    commit('SET_TRANSACTION_LIST_PAGE', value);
    dispatch('getTransactionsList');
  },

  setTransactionsListTotalPages ({ commit }, value) {
    commit('SET_TRANSACTION_LIST_PAGE', 1);
    commit('SET_TRANSACTION_LIST_TOTAL_PAGES', value);
  },

  setTransactionsListSort ({ commit }, value) {
    commit('SET_TRANSACTION_LIST_SORT', value);
  },

  setTransactionsListSearch ({ commit }, value) {
    commit('SET_TRANSACTION_LIST_SEARCH', value);
  },
};
