<template>
  <div class="pix-form-component">
    <form @submit.prevent>
      <div class="row">
        <div class="col-12">
          <legend class="legend-title-content">
            <span class="legend legend-title modal-edit">{{ $t('invoices.details.editItem.labelItem') }}</span>
          </legend>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label for="agency">{{ $t('invoices.modalBankData.key') }} *</label>
            <input
              type="text"
              class="form-control"
              id="key"
              name="key"
              v-model="mutableItem.pix.key"
              :placeholder="$t('invoices.modalBankData.key')"
              v-validate="{ required: true }"
              maxlength="100"
              :class="{'is-invalid': errors.has('key')}"
              :data-vv-as="$t('invoices.modalBankData.key')"
              :disabled="notEditing"
            />
            <div class="invalid-feedback">{{ errors.first('key') }}</div>
          </div>
        </div>

        <div class="col-lg-12 col-md-3 col-sm-3">
          <div class="form-group">
            <label for="bank">{{ $t('invoices.modalBankData.bankName') }}</label>
            <ct-select
              name="bank"
              id="bank"
              class="from-control"
              :class="{'is-invalid': errors.has('bank')}"
              :data-vv-as="$t('invoices.modalBankData.bankName')"
              v-model="mutableItem.pix.bank"
              :options="banks"
              :multiple="false"
              v-validate="{ required: false }"
              :disabled="notEditing"
            />
            <div class="invalid-feedback">{{ errors.first('bank') }}</div>
          </div>
        </div>

        <div class="col-lg-2 col-md-3 col-sm-3">
          <div class="form-group">
            <label for="agency">{{ $t('invoices.modalBankData.agency') }} *</label>
            <input
              type="text"
              class="form-control"
              id="agency"
              name="agency"
              v-model="mutableItem.pix.agency"
              :placeholder="$t('invoices.modalBankData.agency')"
              v-validate="{ required: false }"
              @input="changeAgency"
              maxlength="6"
              :class="{'is-invalid': errors.has('agency')}"
              :data-vv-as="$t('invoices.modalBankData.agency')"
              :disabled="notEditing"
            />
            <div class="invalid-feedback">{{ errors.first('agency') }}</div>
          </div>
        </div>

        <div class="col-lg-4 col-md-3 col-sm-3">
          <div class="form-group">
            <label for="account">{{ $t('invoices.modalBankData.account') }} *</label>
            <input
              type="text"
              class="form-control"
              id="account"
              name="account"
              v-model="mutableItem.pix.account"
              :placeholder="$t('invoices.modalBankData.account')"
              v-validate="{ required: false }"
              @input="applyAccountMask(false)"
              @change="changeAccount"
              maxlength="13"
              :class="{'is-invalid': errors.has('account')}"
              :data-vv-as="$t('invoices.modalBankData.account')"
              :disabled="notEditing"
            />
            <div class="invalid-feedback">{{ errors.first('account') }}</div>
          </div>
        </div>

        <div :class="{ 'col-sm-3': isCaixa , 'col-sm-6': !isCaixa }">
          <div class="form-group">
            <label for="kind">{{ $t('invoices.modalBankData.kindAccount') }} *</label>
            <ct-select
              name="kind"
              id="kind"
              class="from-control"
              :class="{'is-invalid': errors.has('kind')}"
              :data-vv-as="$t('invoices.modalBankData.kindAccount')"
              v-model="mutableItem.pix.kind"
              :options="accountTypes"
              :multiple="false"
              v-validate="{ required: true }"
              :disabled="notEditing"
            />
            <div class="invalid-feedback">{{ errors.first('kind') }}</div>
          </div>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-3" v-if="isCaixa">
          <div class="form-group">
            <label for="operation">{{ $t('invoices.modalBankData.operation') }} *</label>
            <input
              type="text"
              class="form-control"
              id="operation"
              name="operation"
              v-model="mutableItem.pix.operation"
              :placeholder="$t('invoices.modalBankData.operation')"
              v-validate="{ required: false }"
              @change="changeOperation"
              :class="{'is-invalid': errors.has('operation')}"
              :data-vv-as="$t('invoices.modalBankData.operation')"
              :disabled="notEditing"
            />
            <div class="invalid-feedback">{{ errors.first('operation') }}</div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 check-group">
          <div class="form-group">
            <div class="checkbox" :class="{'checked': mutableItem.pix.isThirdParty}">
              <input type="checkbox" :value="true" v-model="mutableItem.pix.isThirdParty" />
            </div>
            <label>{{ $t('invoices.modalBankData.isThirdParty') }}</label>
          </div>
        </div>
      </div>

      <div class="row third-party-data" v-if="mutableItem.pix.isThirdParty">
        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
          <div class="form-group">
            <label>{{ $t('invoices.modalBankData.name') }}</label>
            <input
              type="text"
              class="form-control"
              id="name"
              name="name"
              v-model.trim="mutableItem.pix.name"
              :placeholder="$t('invoices.modalBankData.name')"
              v-validate="{ required: true }"
              :class="{'is-invalid': errors.has('name')}"
              :data-vv-as="$t('invoices.modalBankData.name')"
            />
            <div class="invalid-feedback">{{ errors.first('name') }}</div>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12">
          <div class="form-group">
            <label>{{ $t('invoices.modalBankData.document') }}</label>
            <the-mask
              type="text"
              class="form-control"
              id="document"
              name="document"
              :mask="documentMask"
              :masked="false"
              v-model.trim="mutableItem.pix.document"
              :placeholder="$t('invoices.modalBankData.document')"
              v-validate="{ required: true }"
              :class="{'is-invalid': errors.has('document')}"
              :data-vv-as="$t('invoices.modalBankData.document')"
            />
            <div class="invalid-feedback">{{ errors.first('document') }}</div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
          <div class="form-group">
            <label>{{ $t('invoices.modalBankData.thirdPartyEmail') }}</label>
            <input
              type="text"
              class="form-control"
              id="email"
              name="email"
              v-model.trim="mutableItem.pix.thirdParty.email"
              :placeholder="$t('invoices.modalBankData.thirdPartyEmail')"
              v-validate="{ required: true, email: true }"
              :class="{'is-invalid': errors.has('email')}"
              :data-vv-as="$t('invoices.modalBankData.thirdPartyEmail')"
            />
            <div class="invalid-feedback">{{ errors.first('email') }}</div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
          <div class="form-group">
            <label>{{ $t('invoices.modalBankData.thirdPartyPurpose') }}</label>
            <ct-select
              name="purpose"
              id="purpose"
              class="from-control"
              v-model="mutableItem.pix.thirdParty.purpose"
              :reduce="purpose => purpose.value"
              :placeholder="$t('invoices.modalBankData.thirdPartyPurpose')"
              v-validate="{ required: true }"
              :class="{'is-invalid': errors.has('purpose')}"
              :data-vv-as="$t('invoices.modalBankData.thirdPartyPurpose')"
              :options="purposes"
            />
            <div class="invalid-feedback">{{ errors.first('purpose') }}</div>
          </div>
        </div>
      </div>

      <ct-item-table-events
        slot="body"
        :events="item.events || []"
      />

      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="form-group pull-right form-inline">
            <button class="btn btn-default btn-lg" type="button" @click="reset">
              {{ $t('invoices.details.editItem.cancel') }}
            </button>
            <button :disabled="notEditing" class="btn btn-primary btn-lg" type="submit" @click="save" >
              {{ $t('invoices.details.editItem.confirm') }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { TheMask } from 'vue-the-mask';
import { CtSelect } from '@citartech/core-ui';
import CtItemTableEvents from '@/components/Invoices/InvoiceDetails/EditItem/ItemTableEvents.vue';

export default {
  name: 'EditPixForm',
  data: () => ({
    mutableItem: {},
  }),
  components: {
    CtSelect,
    CtItemTableEvents,
    TheMask,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    notEditing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      banks: state => state.selects.banks || {},
      accountTypes: state => state.selects.accountTypes || {},
      purposes: state => state.selects.purposes || {},
    }),
    isCaixa () {
      return this.mutableItem.pix && String(this.mutableItem.pix.bank) === '104';
    },
    documentMask () {
      return ['###.###.###-##', '##.###.###/####-##'];
    },
  },
  methods: {
    ...mapActions([
      'getBankList',
    ]),
    async changeAgency () {
      this.applyAgencyMask();
    },
    async changeAccount () {
      this.applyAccountMask(true);
    },
    changeOperation () {
      const operation = this.mutableItem.pix.operation;
      if (operation && operation.length < 3) {
        this.mutableItem.pix.operation = operation.toString().padStart(3, '0');
      }
    },
    applyAgencyMask () {
      const agency = this.mutableItem.pix.agency.replace(/[^\d]/g, '');
      if (agency && agency.length > 4) {
        this.mutableItem.pix.agency = [
          agency.slice(0, agency.length - 1),
          '-',
          agency.slice(agency.length - 1),
        ].join('');
      }
    },
    applyAccountMask (changed = false) {
      const account = this.mutableItem.pix.account.replace(/[^\d]/g, '');
      const position = account.length - 1;
      if (account.length > 11 || changed) {
        this.mutableItem.pix.account = [
          account.slice(0, position),
          '-',
          account.slice(position),
        ].join('');
      }
    },
    async reset () {
      this.$emit('reset');
    },
    async save () {
      if (await this.$validator.validate()) {
        const data = { pix: this.mutableItem.pix };
        this.$emit('save', data);
      }
    },
  },
  async created () {
    this.mutableItem = JSON.parse(JSON.stringify(this.item));
    await this.getBankList();
  },
};
</script>

<style lang="scss">
.legend-title-content {
  margin-bottom: 20px;
}
.check-group {
  .checkbox {
    display: inline-block;
    margin-right: 5px;
  }
}
</style>
